import { Injectable } from '@angular/core';

declare let gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor() {}

  public eventEmitter(name: string, label: string): void {
    gtag('event', name, {
      event_label: label,
    });
  }
}
