import { Component, OnInit } from '@angular/core';
import {
  CustomIntegrationsLocalization,
  CustomIntegrationsLocalizationClass,
} from './custom-integrations.localization';
import { LocalizationService } from 'app/services/localization.service';

@Component({
  selector: 'app-custom-integrations',
  templateUrl: './custom-integrations.component.html',
  styleUrls: ['./custom-integrations.component.scss'],
})
export class TechnologyCustomIntegrationsComponent implements OnInit {
  public localizedText: CustomIntegrationsLocalizationClass;

  constructor(private localizationService: LocalizationService) {
    this.localizedText = this.localizationService.getTranslation(
      CustomIntegrationsLocalization
    );
  }

  ngOnInit(): void {}
}
