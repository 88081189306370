import { Localization } from 'app/share/data-type/common.types';

export const ServicesSupplyChainSolutionLocalization: {
  [key: string]: ServicesSupplyChainSolutionLocalizationClass;
} = {
  en: {
    subTitle: 'Logistics',
    title: 'Supply Chain Solutions',
    description1: `ClearFreight's supply chain solutions are tailored to meet the diverse needs of businesses across industries. Our comprehensive approach covers everything from procurement to distribution, optimizing each stage of the supply chain. With advanced technology and a global network, ClearFreight provides visibility, efficiency, and cost-effectiveness throughout your supply chain operations.`,
    description2: `Partner with ClearFreight for customized supply chain solutions designed to enhance your operational efficiency and reduce costs. Our experienced team collaborates with you to understand your unique requirements, offering strategic insights and innovative solutions. From inventory management to order fulfillment, ClearFreight is your trusted partner for end-to-end supply chain optimization.`,
    servicesList: [
      'Comprehensive freight services',
      'Global network & technology',
      'End-to-end visibility',
      'Supply chain optimization',
      'Competitive rates & expedited clearance',
    ],
  },
  es: {},
  jp: {},
  cn: {},
  th: {},
  nl: {},
};

export interface ServicesSupplyChainSolutionLocalizationClass
  extends Localization {}
