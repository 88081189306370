import { Localization } from 'app/share/data-type/common.types';

export const ServicesChemicalBulkLiquidLocalization: {
  [key: string]: ServicesChemicalBulkLiquidLocalizationClass;
} = {
  en: {
    subTitle: 'Logistics',
    title: 'Chemical Bulk Liquid',
    description1: `Discover Clearfreight Inc.'s unrivaled expertise in handling bulk liquids for industries such as lubricating additives, finished lubricants, base oil, and waxes. Our stellar track record of success speaks volumes, with clients that include some of the largest oil companies globally.`,
    description2: `At Clearfreight Inc., we specialize in transporting bulk liquids using flexibags, boasting a flawless 100% clean record without a single leak or spill. Trust us for your liquid transportation needs, where reliability meets excellence. `,
    description3: `Furthermore, our team is well-versed in the intricate documentation requirements of parcel tankers, ensuring smooth operations and compliance with regulatory standards.  `,
    description4: `Benefit from ClearFreight's expertise in bulk liquid logistics, including specialized equipment, safety protocols, and regulatory compliance. Our team is committed to ensuring the integrity of your chemical bulk liquid shipments, providing end-to-end visibility and peace of mind throughout the transportation process. `,
    servicesList: [
      'Safe chemical transport',
      'Hazardous & non-hazardous handling',
      'Tailored logistics solutions',
      'Specialized equipment & compliance',
      'End-to-end visibility',
    ],
  },
  es: {},
  jp: {},
  cn: {},
  th: {},
  nl: {},
};

export interface ServicesChemicalBulkLiquidLocalizationClass
  extends Localization {}
