<div class="quote">
  <app-center-pic src="./assets/images/Request%20a%20Quote/CF-Request-a-Quote.jpg" [top]="-350"></app-center-pic>
  <div class="quote-main">
    <div class="body">
      <div class="left">
        <h1>{{ localizedText.title }}</h1>
        <div class="text">{{ localizedText.description1 }}</div>
        <div class="text">{{ localizedText.description2 }}</div>
        <div>
          <app-item-list [item_data]="localizedText.fieldsList ?? []"></app-item-list>
        </div>
      </div>
      <div class="split"></div>
      <div class="right">
        <form>
          <ul>
            <li>
              <mat-form-field class="full" floatLabel="never">
                <mat-label>{{ localizedText.name }}</mat-label>
                <input
                  matInput
                  type="string"
                  [placeholder]="localizedText.name ?? 'Name'"
                  [formControl]="nameFormControl"
                  [errorStateMatcher]="matcher"
                />
              </mat-form-field>
            </li>
            <li>
              <mat-form-field class="full" floatLabel="never">
                <mat-label>{{ localizedText.company }}</mat-label>
                <input
                  matInput
                  [placeholder]="localizedText.company ?? 'Company'"
                  [formControl]="companyNameFormControl"
                />
              </mat-form-field>
            </li>
            <li>
              <mat-form-field
                [ngClass]="{
                  half: !isMobile,
                  full: isMobile
                }"
                floatLabel="never"
              >
                <mat-label>{{ localizedText.email }}</mat-label>
                <input matInput [placeholder]="localizedText.email ?? 'Email'" [formControl]="emailFormControl" />
              </mat-form-field>
              <mat-form-field
                [ngClass]="{
                  half: !isMobile,
                  full: isMobile
                }"
                floatLabel="never"
              >
                <mat-label>{{ localizedText.phone }}</mat-label>
                <input matInput [placeholder]="localizedText.phone ?? 'Phone No'" [formControl]="phoneFormControl" />
              </mat-form-field>
            </li>
            <li>
              <div class="opt-in-container">
                <mat-checkbox [formControl]="textingControl" color="primary">
                  <p class="opt-in">
                    {{ localizedText.textOptIn }}
                  </p>
                </mat-checkbox>
              </div>
            </li>
            <li>
              <mat-form-field class="full" floatLabel="never">
                <mat-label>{{ localizedText.country }}</mat-label>
                <input matInput [placeholder]="localizedText.country ?? 'Country'" [formControl]="countryControl" />
              </mat-form-field>
            </li>

            <li>
              <div class="multiple">
                <div class="multiple-body">
                  <ng-multiselect-dropdown
                    [placeholder]="localizedText.select ?? 'Select Service'"
                    [settings]="dropdownSettings"
                    [data]="service_options"
                    [(ngModel)]="selectedItems"
                    (onSelect)="onItemSelect($event)"
                    (onDeSelect)="onItemDeSelect($event)"
                    (onSelectAll)="onSelectAll($event)"
                    (onDeSelectAll)="onDeSelectAll($event)"
                  ></ng-multiselect-dropdown>
                </div>
              </div>
            </li>
            <li>
              <mat-form-field
                [ngClass]="{
                  half: !isMobile,
                  full: isMobile
                }"
                floatLabel="never"
              >
                <mat-label>{{ localizedText.origin }}</mat-label>
                <input matInput [placeholder]="localizedText.origin ?? 'Origin'" [formControl]="originControl" />
              </mat-form-field>

              <mat-form-field
                [ngClass]="{
                  half: !isMobile,
                  full: isMobile
                }"
                floatLabel="never"
              >
                <mat-label>{{ localizedText.destination }}</mat-label>
                <input
                  matInput
                  [placeholder]="localizedText.destination ?? 'Destination'"
                  [formControl]="destinationControl"
                />
              </mat-form-field>
            </li>
            <li>
              <mat-form-field class="full mobile-textarea" floatLabel="never">
                <mat-label>{{ localizedText.subTitle }}</mat-label>
                <textarea
                  style="height: 100px"
                  matInput
                  [formControl]="descriptionControl"
                  placeholder="How can we help?"
                ></textarea>
              </mat-form-field>
            </li>
            <li>
              <div class="submit">
                <div class="button-container">
                  <a (click)="handleSubmit()">
                    <div
                      class="btn"
                      [ngStyle]="{ height: isMobile ? '40px' : '50px', width: isMobile ? '66vw' : '150px' }"
                    >
                      {{ localizedText.submit }}
                    </div>
                  </a>
                </div>
              </div>
            </li>
            <li>
              <div class="texting-disclaimer">
                {{ localizedText.textingDisclaimer }}
                <a href="./privacy-policy" target="_blank">{{ localizedText.privacyPolicy }}</a>
                .
              </div>
            </li>
          </ul>
        </form>
      </div>
    </div>
  </div>
</div>
