import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import {
  filePostData,
  NewsType,
  pagerData,
  postItem,
  relatedArticle,
} from '../share/data-type/post.types';

@Injectable({
  providedIn: 'root',
})
export class PostService {
  private readonly clearfreightAPIKey: string = environment.cfApiKey;
  private readonly requestHeaders: HttpHeaders = new HttpHeaders({
    ApiKey: this.clearfreightAPIKey,
  });

  constructor(private http: HttpClient) {}

  public currentPost: postItem | null = null;

  getPostList(category:string, offset: number, pageSize: number): Observable<pagerData<postItem>> {
    let requestURL = `${environment.cfApiUrl}Blog/GetPublishedBlogList/${category}?offset=${offset}&pageSize=${pageSize}`;
    return this.http.get<pagerData<postItem>>(requestURL);
  }

  getPostFileList(fileUrl: string): Observable<filePostData> {
    const d = new Date();
    const timestamp = Math.round(d.getTime());
    return this.http.get<filePostData>(fileUrl + '?v=' + timestamp);
  }

  getFirstBlogFile(): Observable<string> {
    let requestURL = `${environment.cfApiUrl}Blog/GetFirstBlogFile`;
    return this.http.get(requestURL, { responseType: 'text' });
  }

  getPostDetailFile(fileUrl: string): Observable<postItem> {
    const d = new Date();
    const timestamp = Math.round(d.getTime());
    return this.http.get<postItem>(fileUrl + '?v=' + timestamp);
  }

  getRelatedPostList(category?:string): Observable<postItem[]> {
    if(!category)category=NewsType.Comm;
    let requestURL = `${environment.cfApiUrl}Blog/GetRecentBlogList/${category}`;
    // let requestURL = `https://dev-site.clearfreight.com/api/Blog/GetRecentBlogList`;
    return this.http.get<postItem[]>(requestURL);
  }

  mapPostDataToRelatedArticles(postData: postItem[]): relatedArticle[] {
    return postData.map((article) => {
      return {
        id: article.id,
        name: article.name,
        title: article.title,
        imgUrl: article.listImageUrl,
      };
    });
  }
}
