import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Renderer2 } from '@angular/core';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UntypedFormControl, Validators } from '@angular/forms';
import { contactParams } from 'app/share/data-type/common.types';
import { ContactService } from 'app/services/contact.service';
import { FormDataService } from '../nl-form-submitted/form-data.service';
import { Meta } from '@angular/platform-browser';
import { ScreenService } from 'app/services/screen.service';

@Component({
  selector: 'app-nl-warehouse',
  templateUrl: './nl-warehouse.component.html',
  styleUrls: ['./nl-warehouse.component.scss'],
  animations: [
    trigger('listAnimation', [
      transition('* => *', [
        // each time the binding value changes
        query(':enter', [style({ opacity: 0 }), stagger(50, [animate('0.5s', style({ opacity: 1 }))])], {
          optional: true,
        }),
      ]),
    ]),
    trigger('fadeIn', [transition(':enter', [style({ opacity: 0 }), animate('500ms', style({ opacity: 1 }))])]),
  ],
})
export class NlWarehouseComponent implements OnInit, OnDestroy {
  name = new UntypedFormControl('', [Validators.required]);
  email = new UntypedFormControl('', [Validators.required, Validators.email]);
  goals = new UntypedFormControl('');

  features = [] as string[];
  currentFeatureIndex: number;

  title = [] as string[];
  contactUs = [] as string[];
  reachedPoint1: boolean;
  reachedPoint2: boolean;
  reachedPoint3: boolean;
  reachedPoint4: boolean;
  reachedPoint5: boolean;
  contactEmail: string;
  contactPhone: string;
  contactPhoneFormatted: string;
  lineStyle: string;
  public isMobile: boolean = false;

  @ViewChild('ContactUs', { static: true, read: ElementRef })
  ContactUs: ElementRef;

  constructor(
    public router: Router,
    private toastr: ToastrService,
    private renderer: Renderer2,
    private contactService: ContactService,
    private sharedDataService: FormDataService,
    private screenService: ScreenService,
    private metaService: Meta
  ) {
    this.reachedPoint1 = false;
    this.reachedPoint2 = false;
    this.reachedPoint3 = false;
    this.reachedPoint4 = false;
    this.reachedPoint5 = false;
    this.contactEmail = 'info@clearfreight.nl';
    this.contactPhone = '31884291930';
    this.contactPhoneFormatted = '+31 (0)88 4291930';
    this.lineStyle = 'width: 850px; align-items: start';
    this.features = [
      '24/7 video surveillance',
      'Orderpicking',
      'CO2 gas fire extinguishing system',
      'Storage of all common hazardous classes (including Aerosols)',
    ];
    this.currentFeatureIndex = 0;
    this.isMobile = this.screenService.isMobile();
    if (this.isMobile) {
      this.reachedPoint1 = true;
      this.reachedPoint2 = true;
      this.reachedPoint3 = true;
      this.reachedPoint4 = true;
      this.reachedPoint5 = true;
      this.contactUs = "Let's partner up".split('');
    }
  }

  ngOnInit(): void {
    this.showTitle();

    this.metaService.addTags([
      {
        name: 'description',
        content: 'bonded warehouse Rotterdam, warehouse Rotterdam',
      },
      {
        name: 'keywords',
        content: 'bonded warehouse Rotterdam, warehouse Rotterdam',
      },
      { name: 'robots', content: 'index, follow' },
    ]);

    // const scriptToRemove = document.querySelector(
    //   // `script[src="https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalyticsId}"]`
    //   'script[src="https://www.google-analytics.com/analytics.js"]'
    // );

    // if (scriptToRemove) {
    //   scriptToRemove.addEventListener('load', () => {
    //     console.log(scriptToRemove);
    //     this.renderer.removeChild(document.head, scriptToRemove);
    //   });
    // }
  }

  ngOnDestroy(): void {
    // remember to add analytics script back if you can get removal script to work
  }

  handleScrollTrigger1(): void {
    if (!this.reachedPoint1) {
      this.reachedPoint1 = true;
    }
  }

  handleScrollTrigger2(): void {
    if (!this.reachedPoint2) {
      this.reachedPoint2 = true;
    }
  }

  handleScrollTrigger3(): void {
    if (!this.reachedPoint3) {
      this.reachedPoint3 = true;
    }
  }

  handleScrollTrigger4(): void {
    if (!this.reachedPoint4) {
      this.reachedPoint4 = true;
    }
  }

  handleScrollTrigger5(): void {
    if (!this.reachedPoint5) {
      this.reachedPoint5 = true;
      this.showContactUs();
    }
  }

  items = [] as number[];

  showTitle(): void {
    this.title = 'Netherlands Warehouse'.split('');
  }

  showContactUs(): void {
    this.contactUs = "Let's partner up".split('');
  }

  mailTo(): void {
    open('mailto:' + this.contactEmail);
  }

  copyToClipboard(val: string): void {
    const textToCopy = val;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        console.log('Text copied to clipboard');
        this.toastr.success(val + ' copied to clipboard');
      })
      .catch(error => {
        console.error('Error copying to clipboard:', error);
        this.toastr.error('Error copying to the clipboard... ' + error);
      });
  }

  handleSubmit(): void {
    if (this.name.status !== 'VALID' || this.email.status !== 'VALID') {
      this.toastr.warning('Required field cannot be empty');
      return;
    }

    const params: contactParams = {
      name: this.name.value,
      email: this.email.value,
      company: '',
      phone: '',
      address: '',
      goals: this.goals.value,
      isNL: true,
    };

    this.contactService.sendContactNL(params).subscribe(res => {
      try {
        if (res) {
          this.sharedDataService.setName(this.name.value);
          this.sharedDataService.setEmail(this.email.value);
          this.sharedDataService.setGoals(this.goals.value);

          this.name.reset();
          this.email.reset();
          this.goals.reset();

          this.router.navigate(['/nl_form_submitted']);
        }
      } catch (ex) {
        this.toastr.error(
          'There was an error handling your request, please inqure by directly emailing info@clearfreight.nl'
        );
      }
    });
  }

  next() {
    this.currentFeatureIndex = (this.currentFeatureIndex + 1) % this.features.length;
  }

  prev() {
    this.currentFeatureIndex = (this.currentFeatureIndex - 1 + this.features.length) % this.features.length;
  }

  getDate(): string {
    return new Date().getFullYear().toString();
  }

  public scrollToSection(name: string) {
    let offsetTop = 0;
    switch (name) {
      case 'ContactUs':
        offsetTop = (this.ContactUs.nativeElement as HTMLElement).offsetTop;
        break;
    }
    window.scroll({
      top: offsetTop - 219,
      left: 0,
      behavior: 'smooth',
    });
  }
}
