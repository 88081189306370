import { Component, OnInit, ChangeDetectionStrategy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { animate, style, transition, trigger, useAnimation } from '@angular/animations';

import { services_data, article_service, AreaType, AreaItemType, LngLatType } from './data';
import { location_data } from '../location_data';
import { ActivatedRoute } from '@angular/router';

import { continents } from './continents';
import { coverage_data } from '../coverage_data';
import { ToastrService } from 'ngx-toastr';
import { ScreenService } from 'app/services/screen.service';
const filterName = 'country-boundaries';
const coverageFilterName = 'covered-countries';
const defaultLngLat: LngLatType = { lat: 34.0549, lng: -118.2426 };
@Component({
  selector: 'app-location',
  templateUrl: './locaition.component.html',
  styleUrls: ['./locaition.component.scss'],
})
export class LocationComponent implements OnInit, AfterViewInit {
  article_service_data = article_service;
  services_data = services_data;

  isShowSearch = false;
  searchResult: AreaItemType[] = [];
  searchKey: string = '';
  locationPoints: LngLatType[] = [];
  currentContinentName: string = '';
  currentCityName: string = '';
  public pageStatus: 1 | 2 | 3 = 1;
  public coverage_sub_area_data: AreaType = {
    center: defaultLngLat,
    areaName: '',
    children: [],
  };
  public sub_area_data: AreaType = {
    center: defaultLngLat,
    areaName: '',
    children: [],
  };
  public current_city_data?: AreaItemType;
  public mapStyle = { paint: { 'background-color': '#000000' } };

  public mapCenter: [number, number] = [defaultLngLat.lng, defaultLngLat.lat];
  public zoomNumber: number;
  public mapDimensions: [number, number] = [0, 0];

  public offset = 0;
  public map: any = null;

  public elsDepartmentEmails: { name: string; email: string }[] = [
    { name: 'Ocean Export', email: 'laoceanexport@clearfreight.com' },
    { name: 'Air Export', email: 'laairexport@clearfreight.com' },
    { name: 'Import Traffic', email: 'trafficla@clearfreight.com' },
    { name: 'Customs Brokerage', email: 'lacustomsbrokerage@clearfreight.com' },
  ];
  public isMobile: boolean = false;

  constructor(private router: ActivatedRoute, private toastr: ToastrService, private screenService: ScreenService) {
    this.isMobile = this.screenService.isMobile();
  }

  ngOnInit(): void {
    let id = this.router.snapshot.params['id'] ?? '';
    if (id) {
      if (['EUROPE', 'AMERICAS', 'ASIA'].indexOf(id.toUpperCase()) != -1) {
        this.currentContinentName = id.toUpperCase();
      } else {
        this.currentCityName = id;

        //then get the continent name
        location_data.forEach(c => {
          c.children.forEach(city => {
            if (city.id == this.currentCityName) {
              this.currentContinentName = c.areaName;
            }
          });
        });
      }
    }
  }
  ngAfterViewInit(): void {}

  showSearch() {
    if (this.searchKey != '') {
      this.isShowSearch = true;
      // update this.searchResult by filtering through location_data based on city name, country name
      this.searchResult = location_data
        .map(continent => {
          return continent.children.filter(city => {
            return (
              city.name.toLowerCase().includes(this.searchKey.toLowerCase()) ||
              city.country.toLowerCase().includes(this.searchKey.toLowerCase()) ||
              (city.countryFull ? city.countryFull.toLowerCase().includes(this.searchKey.toLowerCase()) : false)
            );
          });
        })
        .flat();
    }
    if (this.searchKey == '') {
      this.isShowSearch = false;
    }
  }
  discardSearch() {
    if (this.searchKey == '') {
      return;
    } else {
      this.searchKey = '';
      this.isShowSearch = false;
    }
  }
  setCurrentLocation(continentName: string, cityName: string) {
    this.currentContinentName = continentName;
    this.currentCityName = cityName;
    this.updatePageStatus();
  }
  setLocationByID(id: string) {
    // refresh the current page to ./location/id

    window.open('/location/' + id, '_self');

    // location_data.forEach((continent) => {
    //   continent.children.forEach((city) => {
    //     if (city.id == id) {
    //       this.setCurrentLocation(continent.areaName, city.name);
    //     }
    //   });
    // });
  }
  copyToClipboard(str: string) {
    try {
      const el = document.createElement('textarea');
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.toastr.success('Successfully copied to clipboard');
    } catch (e) {
      this.toastr.error('Failed to copy to clipboard');
    }
  }
  onMouseWheelUp($event: any) {
    this.zoomNumber += 0.2;
  }

  onMouseWheelDown($event: any) {
    this.zoomNumber -= 0.2;
  }
  updatePageStatus() {
    if (this.currentContinentName == '') {
      this.pageStatus = 1;
    } else {
      if (this.currentCityName == '') {
        this.pageStatus = 2;
      } else {
        this.pageStatus = 3;
      }
    }
    if (this.pageStatus == 2 || this.pageStatus == 3) {
      switch (this.currentContinentName) {
        case 'EUROPE':
          this.sub_area_data = location_data[1];
          break;
        case 'AMERICAS':
          this.sub_area_data = location_data[0];
          break;
        case 'ASIA':
          this.sub_area_data = location_data[2];
          break;
        default:
          this.sub_area_data = {
            center: defaultLngLat,
            areaName: '',
            children: [],
          };
          break;
      }
      if (this.pageStatus == 3) {
        this.sub_area_data.children.forEach(city => {
          if (city.id == this.currentCityName) {
            this.current_city_data = city;
            return;
          }
        });
      }
    }
    this.updateMap();
  }

  updateMap() {
    if (this.pageStatus == 1) {
      const filtersAll = [
        'all',
        //['match', ['get', 'worldview'], ['all', 'IN'], true, false],
        ['match', ['get', 'iso_3166_1'], this.getAllCityCountry(), true, false],
      ];
      const filtersCoverage = [
        'all',
        //['match', ['get', 'worldview'], ['all', 'IN'], true, false],
        ['match', ['get', 'iso_3166_1'], coverage_data, true, false],
      ];
      this.map.setFilter(filterName, filtersAll);
      this.map.setFilter(coverageFilterName, filtersCoverage);
      this.mapCenter = [defaultLngLat.lng, defaultLngLat.lat];
      this.zoomNumber = 2;
    } else if (this.pageStatus == 2 || this.pageStatus == 3) {
      const filtersCity = [
        'all',
        //['match', ['get', 'worldview'], ['all', 'IN'], true, false],
        ['match', ['get', 'iso_3166_1'], this.getContinentCountry(), true, false],
      ];
      if (this.pageStatus == 2) {
        this.mapCenter = [this.sub_area_data.center.lng, this.sub_area_data.center.lat];
        this.zoomNumber = 3;
      } else {
        this.mapCenter = [
          this.current_city_data?.latlng?.lng ?? defaultLngLat.lng,
          this.current_city_data?.latlng?.lat ?? defaultLngLat.lat,
        ];
        this.zoomNumber = 4;
      }
      this.map.setFilter(filterName, filtersCity);
    }
  }

  checkIsCurrent(id: string): boolean {
    if (this.pageStatus == 3) {
      return (this.current_city_data?.id ?? '') == id;
    }
    return false;
  }

  getAllCityCountry(): string[] {
    let data = location_data;
    let rev: string[] = [];
    data.forEach(c => {
      c.children.forEach(city => {
        if (rev.indexOf(city.country) == -1) {
          rev.push(city.country);
        }
      });
    });
    return rev;
  }
  getContinentCountry(): string[] {
    let data = this.sub_area_data;
    let rev: string[] = [];
    data.children.forEach((city: AreaItemType) => {
      if (rev.indexOf(city.country) == -1) {
        rev.push(city.country);
      }
    });
    return rev;
  }

  coveredCountriesData = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [-10, 20],
              [10, 20],
              [10, 30],
              [-10, 30],
              [-10, 20],
            ],
          ],
        },
        properties: {
          name: 'Sample Country 1',
        },
      },
      // Add more features for other countries as needed
    ],
  };

  public mapInit(e: any): void {
    this.mapDimensions = [e._canvas.scrollWidth, e._canvas.scrollHeight];
    //this.offset = this.mapDimensions[0] / 8;
    this.map = e;
    this.map.addLayer(
      {
        id: 'covered-countries',
        source: {
          type: 'vector',
          url: 'mapbox://mapbox.country-boundaries-v1',
        },
        'source-layer': 'country_boundaries',
        type: 'fill',
        paint: {
          'fill-color': '#9EE5FF',
          'fill-opacity': 1,
        },
      },
      'country-label'
    );
    this.map.addLayer(
      {
        id: 'country-boundaries',
        source: {
          type: 'vector',
          url: 'mapbox://mapbox.country-boundaries-v1',
        },
        'source-layer': 'country_boundaries',
        type: 'fill',
        paint: {
          'fill-color': '#00adee',
          'fill-opacity': 1,
        },
      },
      'country-label'
    );

    this.updatePageStatus();
  }
}
