<app-newsroom current="MARKET UPDATES" [showSearch]="false">
  <div class="news-body">
    <ng-container *ngFor="let item of articles; let i = index">
      <div class="item">
        <div><a href="" class="gray">date</a></div>
        <h3>
          <a href="" class="blue"> {{ item.title }}</a>
        </h3>
        <div class="desc">{{ item.title }}</div>
        <app-btn class="read-more-btn" fontSize="small" theme="no-border"
          >READ MORE <app-icon-btn [size]="16"></app-icon-btn
        ></app-btn>
      </div>
      <div class="split" *ngIf="i % 3 == 0 || i % 3 == 1"></div>
    </ng-container>
  </div>
  <div class="pager">
    <mat-paginator
      [hidePageSize]="false"
      [showFirstLastButtons]="true"
    ></mat-paginator>
  </div>
</app-newsroom>
