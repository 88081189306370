<div class="industry">
  <app-sub-header
    src="./assets/images/Template%202.0/CF-1.0-Header.jpg"
    top="-300px"
  ></app-sub-header>
  <div class="manufacturing">
    <div class="body">
      <h3>{{ localizedText.subTitle }}</h3>
      <h1>{{ localizedText.title }}</h1>
      <div class="text">{{ localizedText.description1 }}</div>
      <div class="text">{{ localizedText.description2 }}</div>
      <div>
        <!-- <app-item-list
          [item_data]="localizedText.industryList ?? []"
        ></app-item-list> -->
      </div>
      <app-icon-img-desc
        svgSrc="assets/icons/automotive.svg"
        destination="/industry/automotive"
        [title]="localizedText.automotive ?? ''"
        [description]="localizedText.automotiveDescription ?? ''"
      ></app-icon-img-desc>
      <app-icon-img-desc
        svgSrc="assets/icons/chemicals.svg"
        destination="/industry/chemicals"
        [title]="localizedText.chemicals ?? ''"
        [description]="localizedText.chemicalsDescription ?? ''"
      ></app-icon-img-desc>
      <app-icon-img-desc
        svgSrc="assets/icons/oilgas.svg"
        destination="/industry/oil-gas"
        [title]="localizedText.oilGas ?? ''"
        [description]="localizedText.oilGasDescription ?? ''"
      ></app-icon-img-desc>
      <app-icon-img-desc
        svgSrc="assets/icons/government.svg"
        destination="/industry/government"
        [title]="localizedText.government ?? ''"
        [description]="localizedText.governmentDescription ?? ''"
      ></app-icon-img-desc>
      <app-icon-img-desc
        svgSrc="assets/icons/healthcare.svg"
        destination="/industry/healthcare"
        [title]="localizedText.healthcare ?? ''"
        [description]="localizedText.healthcareDescription ?? ''"
      ></app-icon-img-desc>
      <app-icon-img-desc
        svgSrc="assets/icons/retail.svg"
        destination="/industry/retail"
        [title]="localizedText.retail ?? ''"
        [description]="localizedText.retailDescription ?? ''"
      ></app-icon-img-desc>
      <app-icon-img-desc
        svgSrc="assets/icons/consumergoods.svg"
        destination="/industry/consumer-goods"
        [title]="localizedText.consumerGoods ?? ''"
        [description]="localizedText.consumerGoodsDescription ?? ''"
      ></app-icon-img-desc>
      <app-icon-img-desc
        svgSrc="assets/icons/cosmetics.svg"
        destination="/industry/skin-care-cosmetics"
        [title]="localizedText.cosmetics ?? ''"
        [description]="localizedText.cosmeticsDescription ?? ''"
      ></app-icon-img-desc>
      <app-icon-img-desc
        svgSrc="assets/icons/electronics.svg"
        destination="/industry/electronics"
        [title]="localizedText.electronics ?? ''"
        [description]="localizedText.electronicsDescription ?? ''"
      ></app-icon-img-desc>
      <app-icon-img-desc
        svgSrc="assets/icons/fashion.svg"
        destination="/industry/fashion-apparel"
        [title]="localizedText.fashionApparel ?? ''"
        [description]="localizedText.fashionApparelDescription ?? ''"
      ></app-icon-img-desc>
      <app-icon-img-desc
        svgSrc="assets/icons/pets.svg"
        destination="/industry/pet-products"
        [title]="localizedText.petProducts ?? ''"
        [description]="localizedText.petProductsDescription ?? ''"
      ></app-icon-img-desc>
      <app-icon-img-desc
        svgSrc="assets/icons/foodbev.svg"
        destination="/industry/food-beverage"
        [title]="localizedText.foodBeverage ?? ''"
        [description]="localizedText.foodBeverageDescription ?? ''"
      ></app-icon-img-desc>
      <app-icon-img-desc
        svgSrc="assets/icons/jpfood.svg"
        destination="/industry/japanese-food"
        [title]="localizedText.japaneseFood ?? ''"
        [description]="localizedText.japaneseFoodDescription ?? ''"
      ></app-icon-img-desc>
      <app-icon-img-desc
        svgSrc="assets/icons/dairy.svg"
        destination="/industry/dairy"
        [title]="localizedText.dairy ?? ''"
        [description]="localizedText.dairyDescription ?? ''"
      ></app-icon-img-desc>

      <app-contact-and-related
        [outro]="localizedText.outro ?? ''"
        [services_data]="services_data"
        [article_service_data]="articles"
      ></app-contact-and-related>
    </div>
  </div>
</div>
