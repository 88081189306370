import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { leaders } from '../data';
@Component({
  selector: 'app-leadership',
  templateUrl: './leadership.component.html',
  styleUrls: ['./leadership.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeadershipComponent implements OnInit {
  leaderDatas = leaders;
  constructor() {}

  ngOnInit(): void {}
}
