<div class="news-list">
  <div class="services-content">
    <ng-container *ngFor="let item of news_data;let i=index">
      <div class="item">
        <div><a href="" class="gray">date</a></div>
        <h3><a href="" class="blue"> {{item.title}}</a></h3>
        <div class="desc">{{item.desc}}</div>
        <div class="read-more-btn"><app-btn theme="no-border" fontSize="small">Read More <app-icon-btn
              [size]="16"></app-icon-btn> </app-btn>
        </div>
      </div>
      <div class="split" *ngIf="i==0||i==1"></div>
    </ng-container>
  </div>
</div>