import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { fadeInDown } from 'ng-animate';
@Component({
  selector: 'app-tri-article',
  templateUrl: './tri-article.component.html',
  styleUrls: ['./tri-article.component.scss']
})
export class TriArticleComponent implements OnInit {

  @Input() article_service_data: any[];
  @Output() setState = new EventEmitter();
  constructor(
  ) { }

  ngOnInit(): void {
  }
}
