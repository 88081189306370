import { Localization } from 'app/share/data-type/common.types';

export const IndustryHealthcareLocalization: {
  [key: string]: IndustryHealthcareLocalizationClass;
} = {
  en: {
    title: 'Healthcare',
    subTitle: 'Industry',
    description1:
      'Transportation of pharmaceuticals, medical devices, equipment, and supplies, with a focus on temperature-sensitive products, strict regulatory requirements, and secure transportation practices to ensure product safety and efficacy.',
  },
  cn: {},
  jp: {},
  th: {},
  nl: {},
  es: {},
};

export interface IndustryHealthcareLocalizationClass extends Localization {}
