import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RelatedServicesService {
  constructor() {}
  serviceDescriptions = service_descriptions;
  serviceRelations = service_relations; // for each route, list of related ServiceDescriptions

  getServices(route: string): ServiceDescription[] {
    let routeExistsInServiceRelations = route in this.serviceRelations;
    if (routeExistsInServiceRelations) {
      let relatedServices = this.serviceRelations[route];
      let services = relatedServices.map((service) => {
        return this.serviceDescriptions[service];
      });
      return services;
    } else {
      return [];
    }
  }
}

export interface ServiceDescription {
  title: string;
  desc: string;
  link: string;
}

const service_descriptions: { [id: string]: ServiceDescription } = {
  air: {
    title: 'Air Freight',
    desc: 'We offer a full range of air freight services.',
    link: '/services/air',
  },
  ocean: {
    title: 'Ocean Freight',
    desc: 'We offer a full range of ocean freight services.',
    link: '/services/ocean',
  },
  road: {
    title: 'Road Freight',
    desc: 'We offer a full range of road freight services.',
    link: '/services/road',
  },
  rail: {
    title: 'Rail Freight',
    desc: 'We offer a full range of rail freight services.',
    link: '/services/rail',
  },
  'freight-forwarding': {
    title: 'Freight Forwarding',
    desc: 'We offer a full range of freight forwarding services.',
    link: '/services/freight-forwarding',
  },
  'break-bulk-project-cargo': {
    title: 'Break-bulk & Project Cargo',
    desc: 'We offer a full range of break-bulk & project cargo services.',
    link: '/services/break-bulk-project-cargo',
  },
  'chemical-bulk-liquid': {
    title: 'Chemical Bulk Liquid',
    desc: 'We offer a full range of chemical bulk liquid services.',
    link: '/services/chemical-bulk-liquid',
  },
  'warehousing-distribution': {
    title: 'Warehousing & Distribution',
    desc: 'We offer a full range of warehousing & distribution services.',
    link: '/services/warehousing-distribution',
  },
  'supply-chain-solutions': {
    title: 'Supply Chain Solutions',
    desc: 'We offer a full range of supply chain solutions services.',
    link: '/services/supply-chain-solutions',
  },
  'intermodal-solutions': {
    title: 'Intermodal Solutions',
    desc: 'We offer a full range of intermodal solutions services.',
    link: '/services/intermodal-solutions',
  },
  'customs-house-brokerage': {
    title: 'Customs House Brokerage',
    desc: 'We offer a full range of customs house brokerage services.',
    link: '/services/customs-house-brokerage',
  },
  'cargo-insurance': {
    title: 'Cargo Insurance',
    desc: 'We offer a full range of cargo insurance services.',
    link: '/services/cargo-insurance',
  },
  'compliance-consulting': {
    title: 'Trade Compliance Advisory',
    desc: 'We offer a full range of compliance consulting services.',
    link: '/services/compliance-consulting',
  },
};

const service_relations: { [route: string]: string[] } = {
  // exactly 3 services per route
  '/services/air': ['ocean', 'road', 'rail'],
  '/services/ocean': ['air', 'road', 'rail'],
  '/services/road': ['air', 'ocean', 'rail'],
  '/services/rail': ['air', 'ocean', 'road'],
  '/services/freight-forwarding': [
    'break-bulk-project-cargo',
    'chemical-bulk-liquid',
    'warehousing-distribution',
  ],
  '/services/break-bulk-project-cargo': [
    'freight-forwarding',
    'chemical-bulk-liquid',
    'warehousing-distribution',
  ],
  '/services/chemical-bulk-liquid': [
    'freight-forwarding',
    'break-bulk-project-cargo',
    'warehousing-distribution',
  ],
  '/services/warehousing-distribution': [
    'freight-forwarding',
    'break-bulk-project-cargo',
    'chemical-bulk-liquid',
  ],
  '/services/supply-chain-solutions': [
    'intermodal-solutions',
    'customs-house-brokerage',
    'cargo-insurance',
  ],
  '/services/intermodal-solutions': [
    'supply-chain-solutions',
    'customs-house-brokerage',
    'cargo-insurance',
  ],
  '/services/customs-house-brokerage': [
    'intermodal-solutions',
    'cargo-insurance',
    'compliance-consulting',
  ],
  '/services/cargo-insurance': [
    'supply-chain-solutions',
    'intermodal-solutions',
    'customs-house-brokerage',
  ],
  '/services/compliance-consulting': [
    'customs-house-brokerage',
    'cargo-insurance',
    'supply-chain-solutions',
  ],
  '/industry': [
    'customs-house-brokerage',
    'freight-forwarding',
    'cargo-insurance',
  ],
  '/industry/automotive': [
    'intermodal-solutions',
    'customs-house-brokerage',
    'cargo-insurance',
  ],
  '/industry/chemicals': [
    'supply-chain-solutions',
    'intermodal-solutions',
    'customs-house-brokerage',
  ],
  '/industry/oil-gas': [
    'supply-chain-solutions',
    'intermodal-solutions',
    'customs-house-brokerage',
  ],
  '/industry/government': [
    'supply-chain-solutions',
    'intermodal-solutions',
    'customs-house-brokerage',
  ],
  '/industry/healthcare': [
    'supply-chain-solutions',
    'intermodal-solutions',
    'customs-house-brokerage',
  ],
  '/industry/retail': [
    'supply-chain-solutions',
    'intermodal-solutions',
    'customs-house-brokerage',
  ],
  '/industry/consumer-goods': [
    'supply-chain-solutions',
    'intermodal-solutions',
    'customs-house-brokerage',
  ],
  '/industry/cosmetics': [
    'supply-chain-solutions',
    'intermodal-solutions',
    'customs-house-brokerage',
  ],
  '/industry/electronics': [
    'supply-chain-solutions',
    'intermodal-solutions',
    'customs-house-brokerage',
  ],
  '/industry/fashion-apparel': [
    'supply-chain-solutions',
    'intermodal-solutions',
    'customs-house-brokerage',
  ],
  '/industry/pet-products': [
    'supply-chain-solutions',
    'intermodal-solutions',
    'customs-house-brokerage',
  ],
  '/industry/food-beverage': [
    'supply-chain-solutions',
    'intermodal-solutions',
    'customs-house-brokerage',
  ],
  '/industry/japanese-food': [
    'supply-chain-solutions',
    'intermodal-solutions',
    'customs-house-brokerage',
  ],
  '/industry/dairy': [
    'supply-chain-solutions',
    'intermodal-solutions',
    'customs-house-brokerage',
  ],
};
