import {
  NavMenuItemType,
  NavMenuType,
} from 'app/share/data-type/nav-menu.type';

// to=do: remove later
import { environment } from 'environments/environment';
const isDev = environment.cfApiUrl == 'https://dev-site.clearfreight.com/api/';

const menu_data: NavMenuType[] = [
  {
    itemTitle: 'ABOUT',
    key: 'about',
    routeAddress: 'about',
    canClick: true,
    isShowInBottom: true,
    itemDesc:
      'Our success lies in a rich heritage, a customer-forward thinking approach, and a commitment to excellence in customs brokerage and freight services.',
    menus: [
      {
        title: 'CLEARFREIGHT',
        routeAddress: '/',
        canClick: true,
      },
      // { title: 'LEADERSHIP', routeAddress: '/leadership', canClick: true },
      { title: 'HERITAGE', routeAddress: '/heritage', canClick: true },
      // { title: 'CAREERS', routeAddress: '/careers', canClick: true }
    ],
  },
  {
    itemTitle: 'SERVICES',
    key: 'services',
    routeAddress: 'services',
    canClick: true,
    isShowInBottom: true,
    itemDesc:
      'Explore our comprehensive array of services, spanning transportation modes such as ocean, air, road, and rail, logistics solutions including freight forwarding, warehousing, and supply chain management, as well as global trade services encompassing customs house brokerage, cargo insurance, and duty drawback assistance.',
    menus: [
      {
        title: 'TRANSPORTATION',
        routeAddress: '',
        children: [
          {
            title: 'Ocean',
            routeAddress: '/ocean',
            canClick: true,
          },
          {
            title: 'Air',
            routeAddress: '/air',
            canClick: true,
          },
          {
            title: 'Road',
            routeAddress: '/road',
            canClick: true,
          },
          {
            title: 'Rail',
            routeAddress: '/rail',
            canClick: true,
          },
        ],
      },
      {
        title: 'LOGISTICS',
        routeAddress: '',
        children: [
          {
            title: 'Freight Forwarding',
            routeAddress: '/freight-forwarding',
            canClick: true,
          },
          {
            title: 'Break-bulk & Project Cargo',
            routeAddress: '/break-bulk-project-cargo',
            canClick: true,
          },
          {
            title: 'Chemical Bulk Liquid',
            routeAddress: '/chemical-bulk-liquid',
            canClick: true,
          },
          {
            title: 'Warehousing & Distribution',
            routeAddress: '/warehousing-distribution',
            canClick: true,
          },
          {
            title: 'Supply Chain Solutions',
            routeAddress: '/supply-chain-solutions',
            canClick: true,
          },
          {
            title: 'Intermodal Solutions',
            routeAddress: '/intermodal-solutions',
            canClick: true,
          },
        ],
      },
      {
        title: 'GLOBAL TRADE',
        routeAddress: '',
        canClick: true,
        children: [
          {
            title: 'Customs House Brokerage',
            routeAddress: '/customs-house-brokerage',
            canClick: true,
          },
          {
            title: 'Cargo Insurance',
            routeAddress: '/cargo-insurance',
            canClick: true,
          },
          {
            title: 'Trade Compliance Advisory',
            routeAddress: '/compliance-consulting',
            canClick: true,
          },
        ],
      },
    ],
  },
  {
    itemTitle: 'INDUSTRY',
    key: 'industry',
    routeAddress: 'industry',
    isShowInBottom: true,
    canClick: true,
    itemDesc:
      'Specializing in a spectrum of industries, our expertise extends across diverse sectors, ensuring tailored solutions for your specific needs.',
    menus: [
      {
        title: 'INDUSTRY',
        routeAddress: '',
        canClick: true,
        children: [
          { title: 'Automotive', routeAddress: '/automotive', canClick: true },
          { title: 'Chemicals', routeAddress: '/chemicals', canClick: true },
          { title: 'Oil & Gas', routeAddress: '/oil-gas', canClick: true },
          { title: 'Government', routeAddress: '/government', canClick: true },
          { title: 'Healthcare', routeAddress: '/healthcare', canClick: true },
        ],
      },

      {
        title: '',
        routeAddress: '',
        canClick: true,
        children: [
          {
            title: 'Retail',
            routeAddress: '/retail',
            canClick: true,
          },
          {
            title: 'Consumer Goods',
            routeAddress: '/consumer-goods',
            canClick: true,
          },
          {
            title: 'Cosmetics',
            routeAddress: '/skin-care-cosmetics',
            canClick: true,
          },
          {
            title: 'Electronics',
            routeAddress: '/electronics',
            canClick: true,
          },
          {
            title: 'Fashion & Apparel',
            routeAddress: '/fashion-apparel',
            canClick: true,
          },
        ],
      },
      {
        title: '',
        routeAddress: '',
        canClick: true,
        children: [
          {
            title: 'Pet Products',
            routeAddress: '/pet-products',
            canClick: true,
          },
          {
            title: 'Food & Beverage',
            routeAddress: '/food-beverage',
            canClick: true,
          },
          {
            title: 'Japanese Food',
            routeAddress: '/food-beverage/japanese-food',
            canClick: true,
          },
          {
            title: 'Dairy',
            routeAddress: '/food-beverage/dairy',
            canClick: true,
          },
        ],
      },
    ],
  },
  {
    itemTitle: 'GLOBAL NETWORK',
    key: 'network',
    routeAddress: 'location',
    canClick: true,
    isShowInBottom: true,
    itemDesc:
      'Explore our expansive global network, uniting our headquarters in Los Angeles, California, with branch offices worldwide and an extensive agent network in over 100 countries, ensuring seamless connectivity for your international logistics needs.',
    menus: [{ title: 'LOCATION/BRANCHES', routeAddress: '/', canClick: true }],
  },
  {
    itemTitle: 'TECHNOLOGY',
    key: 'technology',
    routeAddress: 'technology',
    canClick: false,
    isShowInBottom: true,
    itemDesc:
      'Experience efficiency through our cutting-edge technology suite, featuring ClearView for streamlined shipment tracking, robust Order Management, seamless Custom Integrations, and the convenience of our Mobile App, enhancing your logistics experience.',
    menus: [
      {
        title: 'TECHNOLOGY',
        routeAddress: '/',
        canClick: false,
        children: [
          { title: 'ClearView', routeAddress: 'clearview', canClick: true },
          {
            title: 'Order Management',
            routeAddress: 'order-management',
            canClick: true,
          },
          { title: 'Mobile App', routeAddress: 'mobile-app', canClick: true },
          // { title: 'Analytics', routeAddress: '/analytics', canClick: true },
          // {
          //   title: 'API, EDI, Custom Integrations',
          //   routeAddress: '/custom-integrations',
          //   canClick: true,
          // },
        ],
      },
    ],
  },
  {
    itemTitle: 'RESOURCES',
    key: 'resources',
    routeAddress: 'resources',
    isShowInBottom: true,
    canClick: false,
    itemDesc: `Access our resource hub, where you'll find informative blog posts and essential forms, providing the practical information and tools necessary for your logistics needs.`,
    menus: [
      {
        title: 'NEWSROOM',
        routeAddress: '/newsroom',
        canClick: true,
        children: isDev // to=do: remove later
          ? [
              { title: 'Articles', routeAddress: '/', canClick: true },
              {
                title: 'Ports Statistics',
                routeAddress: '../portstats',
                canClick: true,
              },
              {
                title: 'Industry News',
                routeAddress: '../industry',
                canClick: true,
              },
              {
                title: 'Brokerage Updates',
                routeAddress: '../brokerage',
                canClick: true,
              },
            ]
          : [
              { title: 'Articles', routeAddress: '/', canClick: true },
              {
                title: 'Industry News',
                routeAddress: '/industry',
                canClick: true,
              },
            ],
      },
      { title: 'FORMS', routeAddress: '/forms', canClick: true },
    ],
  },
];
const concatRouteAddress = () => {
  let navData: NavMenuType[] = [];
  let getChildren = (
    parentRoute: string,
    mItems: NavMenuItemType[]
  ): NavMenuItemType[] => {
    let rev: NavMenuItemType[] = [];
    mItems.forEach((mSub: NavMenuItemType) => {
      let nPath = '';
      if (mSub.routeAddress?.indexOf('../') != -1) {
        let parentRouteArr = parentRoute.split('/');
        let upArr = mSub.routeAddress?.split('../') ?? [];
        let path = '';
        for (let i = 0; i < upArr.length; i++) {
          if (upArr[i] == '') parentRouteArr.pop();
          else path = upArr[i];
        }
        nPath = parentRouteArr.join('/') + '/' + path;
      } else {
        nPath = parentRoute + mSub.routeAddress ?? '';
      }
      let nItem: NavMenuItemType = { ...mSub, routeAddress: nPath };
      if (mSub.children) {
        nItem.children = getChildren(nPath, mSub.children);
      }
      rev.push(nItem);
    });
    return rev;
  };
  menu_data.forEach((item: NavMenuType) => {
    let nItem = { ...item };
    nItem.menus = getChildren(nItem.routeAddress ?? '', item.menus);
    navData.push(nItem);
  });
  return navData;
};
const nav_data = concatRouteAddress();
export { nav_data };
