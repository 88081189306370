import { Localization } from 'app/share/data-type/common.types';

export const IndustryFoodBevLocalization: {
  [key: string]: IndustryFoodBevLocalizationClass;
} = {
  en: {
    title: 'Food & Beverage',
    subTitle: 'Industry',
    description1:
      'Transportation of perishable and non-perishable food products, beverages, and ingredients, with a focus on temperature-controlled logistics, food safety compliance, and timely delivery to retailers, restaurants, and consumers. ',
  },
  cn: {},
  jp: {},
  th: {},
  nl: {},
  es: {},
};

export interface IndustryFoodBevLocalizationClass extends Localization {}
