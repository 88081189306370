<div class="body">
  <div class="content">
    <h1 class>Sandbox</h1>
    <app-contact-button width="150px" height="60px"></app-contact-button>
  </div>
  <div
    style="
      height: 300px;
      width: min(calc(90% + 100px), 1300px);
      overflow: hidden;
    "
  >
    <app-center-pic
      src="./assets/images/About/Heritage/CF-2.0-Drone-Shot.jpg"
    ></app-center-pic>
  </div>
</div>
