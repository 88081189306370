import { Injectable } from '@angular/core';
import { Localization } from 'app/share/data-type/common.types';

@Injectable({
  providedIn: 'root',
})
export class LocalizationService {
  private selectedLanguage: string = 'en';

  setLanguage(language: string) {
    this.selectedLanguage = language;
  }

  getTranslation(localization: { [key: string]: Localization }): Localization {
    const defaultLocalization = localization['en'] || {};

    const selectedLocalization = localization[this.selectedLanguage];
    // Remove certain properties if they are empty string or empty array
    Object.keys(selectedLocalization).forEach((key) => {
      if (
        selectedLocalization[key] === '' ||
        (Array.isArray(selectedLocalization[key]) &&
          (selectedLocalization[key]?.length ?? 0) === 0)
      ) {
        delete selectedLocalization[key];
      }
    });

    // Ensure that each property exists in the selected language's localization
    const translatedLocalization = {
      ...defaultLocalization,
      ...selectedLocalization,
    };

    return translatedLocalization;
  }

  constructor() {}
}
