import { Localization } from 'app/share/data-type/common.types';

export const DemoScheduleLocalization: {
  [key: string]: DemoScheduleLocalizationClass;
} = {
  en: {
    title: 'Schedule a Demo',
    description1: `We're excited to show you how our logistics technology platform, ClearView, can revolutionize the way you manage your supply chain. With ClearView, you can:  `,
    servicesList: [
      'Track your shipments in real time',
      'Manage your inventory',
      'View your documents',
      'Receive alerts and notifications',
      'And more!',
    ],
    name: 'Full Name',
    company: 'Company Name',
    email: 'Email',
    phone: 'Phone No.',
    textOptIn: 'Opt in to text notifications',
    country: 'Country',
    submit: 'Submit',
    textingDisclaimer:
      'By providing a telephone number and opting in, you are consenting to be contacted by SMS text message (our message frequency may vary). Message and data rates may apply. See our',
    privacyPolicy: 'Privacy Policy',
  },
  es: {},
  jp: {},
  cn: {},
  th: {},
  nl: {},
};

export interface DemoScheduleLocalizationClass extends Localization {
  name?: string;
  email?: string;
  company?: string;
  phone?: string;
  textOptIn?: string;
  country?: string;
  submit?: string;
  textingDisclaimer?: string;
  privacyPolicy?: string;
}
