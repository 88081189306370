import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

import { services_data, article_service } from './data';
import { imageTextFadeInType } from 'app/share/data-type/common.types';
import {
  ServicesDutyDrawbackLocalization,
  ServicesDutyDrawbackLocalizationClass,
} from './duty-drawback.localization';
import { LocalizationService } from 'app/services/localization.service';
@Component({
  selector: 'app-services-duty-drawback',
  templateUrl: './duty-drawback.component.html',
  styleUrls: ['./duty-drawback.component.scss'],
})
export class ServicesDutyDrawbackComponent implements OnInit, AfterViewInit {
  public localizedText: ServicesDutyDrawbackLocalizationClass;

  article_service_data = article_service;
  services_data = services_data;
  constructor(public localizationService: LocalizationService) {
    this.localizedText = this.localizationService.getTranslation(
      ServicesDutyDrawbackLocalization
    );
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {}
}
