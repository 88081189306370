<div class="industry">
  <app-sub-header
    src="./assets/images/Services/Global_Trade/CF-Customs-House-Brokerage.jpg"
    top="-400px"
  ></app-sub-header>
  <div class="manufacturing">
    <div class="body">
      <h3>{{ localizedText.subTitle }}</h3>
      <h1>{{ localizedText.title }}</h1>
      <div class="paragraph-spec-text">
        {{ localizedText.description1 }}
      </div>
      <div class="text">
        {{ localizedText.description2 }}
      </div>
      <h2>{{ localizedText.services }}</h2>
      <div>
        <app-item-list
          [item_data]="localizedText.servicesList ?? []"
        ></app-item-list>
      </div>

      <app-contact-and-related
        [services_data]="services_data"
        [article_service_data]="article_service_data"
      ></app-contact-and-related>
    </div>
  </div>
</div>
