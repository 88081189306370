import { Component, Input, OnInit } from '@angular/core';
import { quickContainerEvents } from 'app/share/data-type/tracking.types';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['../tracking.component.scss'],
})
export class EventsComponent implements OnInit {
  @Input() events: quickContainerEvents[] = [];

  constructor() {}

  getEventModeIcon(mode: string): string {
    switch (mode.toUpperCase()) {
      case 'SEA':
        return 'assets/images/global-ocean-forwarding-blue.png';
      case 'AIR':
        return 'assets/images/global-air-forwarding-blue.png';
      case 'ROA':
        return 'assets/images/domestic-transportation-blue.png';
      case 'RAI':
        return 'assets/images/rail-forwarding.png';
      default:
        return 'assets/images/global-ocean-forwarding-blue.png';
    }
  }

  ngOnInit(): void {}
}
