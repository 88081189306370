<div class="industry">
  <app-sub-header src="./assets/images/Services/Transportation/Road3.jpg" top="min(-30vh, 306px)"></app-sub-header>
  <div class="manufacturing">
    <div class="body">
      <h3>{{ localizedText.subTitle }}</h3>
      <h1>{{ localizedText.title }}</h1>
      <div class="text">{{ localizedText.description1 }}</div>
      <!-- <div class="text">{{ localizedText.description3 }}</div> -->
      <h2>{{ localizedText.services }}</h2>
      <div>
        <!-- <app-item-list
          [item_data]="localizedText.servicesList ?? []"
        ></app-item-list> -->
        <app-items-and-descs [item_data]="localizedText.itemDescList ?? []"></app-items-and-descs>
      </div>
      <div class="text">{{ localizedText.description2 }}</div>

      <div
        [ngStyle]="{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }"
      >
        {{ localizedText.outro }}
        <app-contact-button
          [ngStyle]="{ marginTop: '50px' }"
          [ngStyle]="{ marginTop: isMobile ? '-50' : '50px' }"
        ></app-contact-button>
      </div>
      <app-related-service [services_data]="services_data"></app-related-service>
    </div>

    <div class="related">
      <app-related-article [article_service_data]="article_service_data"></app-related-article>
    </div>
  </div>
</div>
