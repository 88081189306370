import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

import { Router } from '@angular/router';
@Component({
  selector: 'app-btn',
  templateUrl: './btn.component.html',
  styleUrls: ['./btn.component.scss'],
})
export class BtnComponent implements OnInit {
  @Input() link: string = 'javascript:;';
  @Input() height: number = 36;
  @Input() width: number = 160;
  @Input() fontSize: 'normal' | 'small' = 'normal';
  @Input() theme: '' | 'blue' | 'no-border' | string = '';
  @Output() setState = new EventEmitter();
  constructor(private router: Router) {}

  ngOnInit(): void {}

  navigateToPage() {
    this.router.navigate([this.link]);
  }
}
