import { NgModule } from '@angular/core';
import { ShareModule } from '../share/share.module';
import { PagesComponent } from './pages.component';
import { PagesRoutingModule } from './pages-routing.module';
import { AboutModule } from './about/about.module';

import { ResourcesRoutingModule } from './resources/resources-routing.module';
import { ResourcesModule } from './resources/resources.module';
import { ServicesComponent } from './templates/services/services.component';
import { IndustryComponent } from './templates/industry/industry.component';
import { QuoteRequestComponent } from './quote-request/quote-request.component';
import { DemoScheduleComponent } from './demo-schedule/demo-schedule.component';
import { ContactUSComponent } from './contact-us/contact-us.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ServicesAirComponent } from './services/air/air.component';
import { ServicesOceanComponent } from './services/ocean/ocean.component';
import { ServicesRailComponent } from './services/rail/rail.component';
import { ServicesRoadComponent } from './services/road/road.component';
import { LocationComponent } from './locaition/locaition.component';
import { TechnologyComponent } from './technology/technology.component';
import { ServicesBreakBulkProjectCargoComponent } from './services/break-bulk-project-cargo/break-bulk-project-cargo.component';
import { ServicesCargoInsuranceComponent } from './services/cargo-insurance/cargo-insurance.component';
import { ServicesChemicalBulkLiquidComponent } from './services/chemical-bulk-liquid/chemical-bulk-liquid.component';
import { ServicesCustomsHouseBrokerageComponent } from './services/customs-house-brokerage/customs-house-brokerage.component';
import { ServicesDutyDrawbackComponent } from './services/duty-drawback/duty-drawback.component';
import { ServicesFreightForwardingComponent } from './services/freight-forwarding/freight-forwarding.component';
import { ServicesIntermodalSolutionsComponent } from './services/intermodal-solutions/intermodal-solutions.component';
import { ServicesSupplyChainSolutionsComponent } from './services/supply-chain-solutions/supply-chain-solutions.component';
import { ServicesWarehousingDistributionComponent } from './services/warehousing-distribution/warehousing-distribution.component';
// TODO comment out sandbox component
import { SandboxComponent } from './sandbox/sandbox.component';
import { SearchComponent } from './search/search.component';
import { TrackingComponent } from './tracking/tracking.component';
import { GlossaryComponent } from './glossary/glossary.component';
import { IndustryAutomotiveComponent } from './industry/industry-automotive/industry-automotive.component';
import { IndustryChemicalsComponent } from './industry/industry-chemicals/industry-chemicals.component';
import { IndustryOilGasComponent } from './industry/industry-oil-gas/industry-oil-gas.component';
import { IndustryGovernmentComponent } from './industry/industry-government/industry-government.component';
import { IndustryHealthcareComponent } from './industry/industry-healthcare/industry-healthcare.component';
import { IndustryRetailComponent } from './industry/industry-retail/industry-retail.component';
import { IndustryConsumerGoodsComponent } from './industry/industry-consumer-goods/industry-consumer-goods.component';
import { IndustryCosmeticsComponent } from './industry/industry-cosmetics/industry-cosmetics.component';
import { IndustryElectronicsComponent } from './industry/industry-electronics/industry-electronics.component';
import { IndustryFashionComponent } from './industry/industry-fashion/industry-fashion.component';
import { IndustryPetsComponent } from './industry/industry-pets/industry-pets.component';
import { IndustryFoodBevComponent } from './industry/industry-food-bev/industry-food-bev.component';
import { IndustryJpFoodComponent } from './industry/industry-jp-food/industry-jp-food.component';
import { IndustryDairyComponent } from './industry/industry-dairy/industry-dairy.component';
import { TechnologyOrderManagementComponent } from './technology/order-management/order-management.component';
import { TechnologyMobileAppComponent } from './technology/mobile-app/mobile-app.component';
import { TechnologyAnalyticsComponent } from './technology/analytics/analytics.component';
import { TechnologyCustomIntegrationsComponent } from './technology/custom-integrations/custom-integrations.component';
import { ServicesComplianceConsultingComponent } from './services/compliance-consulting/compliance-consulting.component';
import { NlWarehouseComponent } from './nl-warehouse/nl-warehouse.component';
import { ImageCarouselComponent } from './nl-warehouse/image-carousel/image-carousel.component';
import { NlFormSubmittedComponent } from './nl-form-submitted/nl-form-submitted.component';
import { ScrollTriggerDirective } from './nl-warehouse/scroll-trigger.directive';
import { MilestonesComponent } from './tracking/milestones/milestones.component';
import { ContainersComponent } from './tracking/containers/containers.component';
import { EventsComponent } from './tracking/events/events.component';
import { PacksComponent } from './tracking/packs/packs.component';

@NgModule({
  declarations: [
    PagesComponent,
    ServicesComponent,
    ServicesAirComponent,
    ServicesOceanComponent,
    ServicesRailComponent,
    ServicesRoadComponent,
    ServicesBreakBulkProjectCargoComponent,
    ServicesCargoInsuranceComponent,
    ServicesChemicalBulkLiquidComponent,
    ServicesCustomsHouseBrokerageComponent,
    ServicesDutyDrawbackComponent,
    ServicesFreightForwardingComponent,
    ServicesIntermodalSolutionsComponent,
    ServicesSupplyChainSolutionsComponent,
    ServicesWarehousingDistributionComponent,
    LocationComponent,
    IndustryComponent,
    TechnologyComponent,
    PrivacyPolicyComponent,
    QuoteRequestComponent,
    DemoScheduleComponent,
    ContactUSComponent,
    SandboxComponent,
    SearchComponent,
    TrackingComponent,
    GlossaryComponent,
    IndustryAutomotiveComponent,
    IndustryChemicalsComponent,
    IndustryOilGasComponent,
    IndustryGovernmentComponent,
    IndustryHealthcareComponent,
    IndustryRetailComponent,
    IndustryConsumerGoodsComponent,
    IndustryCosmeticsComponent,
    IndustryElectronicsComponent,
    IndustryFashionComponent,
    IndustryPetsComponent,
    IndustryFoodBevComponent,
    IndustryJpFoodComponent,
    IndustryDairyComponent,
    TechnologyOrderManagementComponent,
    TechnologyMobileAppComponent,
    TechnologyAnalyticsComponent,
    TechnologyCustomIntegrationsComponent,
    ServicesComplianceConsultingComponent,
    NlWarehouseComponent,
    ImageCarouselComponent,
    NlFormSubmittedComponent,
    ScrollTriggerDirective,
    MilestonesComponent,
    ContainersComponent,
    EventsComponent,
    PacksComponent,
  ],
  imports: [
    ShareModule,
    PagesRoutingModule,
    AboutModule,
    ResourcesRoutingModule,
    ResourcesModule,
  ],
  exports: [AboutModule, ResourcesModule],
})
export class PagesModule {}
