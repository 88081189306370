<div class="newsroom">
  <app-center-pic
    src="./assets/images/Newsroom/CF-Header-Newsroom.jpg"
  ></app-center-pic>
  <div class="content">
    <h1 class="title">{{ localizedText.title }}</h1>
    <div class="sub-title">
      {{ localizedText.subTitle }}
    </div>
    <div class="newsroom-menu">
      <app-newsroom-menu [current]="current"></app-newsroom-menu>
    </div>
    <div
      class="newsroom-content"
      [ngStyle]="{
        '--showSearch': showSearch ? 'block' : 'none',
        '--newsWidth': showSearch ? '70%' : '100%'
      }"
    >
      <!-- <div class="search">
        <app-newsroom-search></app-newsroom-search>
      </div> -->
      <div class="new-list">
        <ng-content #news></ng-content>
      </div>
    </div>
  </div>
</div>
