<div @bounce moveOutside (moveOutside)="leaveMenu($event)" class="nav-menu">
  <div class="nav-menu-content">
    <div class="nav-col-1">
      <div>
        <h1 class="item-title">{{ item.itemTitle }}</h1>
        <div class="item-desc">
          {{ item.itemDesc }}
        </div>
        <div *ngIf="item.itemTitle == 'ABOUT'" class="who-we-are">
          <app-btn-no-route [width]="200" (click)="openVideoDialog()"
            >Who We Are
            <mat-icon class="play-button">
              play_circle_outline
            </mat-icon></app-btn-no-route
          >
        </div>
      </div>
      <div class="line"></div>
    </div>
    <div
      *ngFor="let m of item.menus; let i = index"
      class="nav-col-{{ i + 2 }}"
    >
      <div class="menu-title">
        <a *ngIf="m.routeAddress; else link" [routerLink]="[m.routeAddress]">
          {{ m.title }}
          <div *ngIf="m.title === ''" class="title-spacing"></div>
        </a>
        <ng-template #link>
          {{ m.title }}
        </ng-template>
      </div>
      <div class="sub-menu">
        <ul>
          <li *ngFor="let mItem of m.children">
            <a
              *ngIf="mItem.routeAddress; else subLink"
              [routerLink]="mItem.routeAddress"
            >
              {{ mItem.title }}</a
            >
            <ng-template #subLink>
              {{ mItem.title }}
            </ng-template>
          </li>
        </ul>
      </div>
    </div>
    <div class="nav-col-5"></div>
  </div>
</div>
