import { Localization } from 'app/share/data-type/common.types';

export const ServicesDutyDrawbackLocalization: {
  [key: string]: ServicesDutyDrawbackLocalizationClass;
} = {
  en: {
    subTitle: 'Global Trade',
    title: 'Duty Drawback',
    description1: `ClearFreight simplifies the duty drawback process to help you recover duties paid on imported goods that are later exported. Our duty drawback services are designed to maximize your refund potential, ensuring compliance with customs regulations. Rely on ClearFreight's experienced team to navigate the complexities of duty drawback, providing a seamless and efficient solution for recovering duties and promoting cost savings.`,
    description2: `Choose ClearFreight for comprehensive duty drawback support, from eligibility assessment to documentation and claim filing. Our experts will guide you through the entire process, leveraging our knowledge of global trade regulations to optimize duty recovery. Trust ClearFreight to streamline your duty drawback procedures and enhance your bottom line.`,
    servicesList: [
      'Simplified duty recovery',
      'Maximized refund potential',
      'Efficient duty recovery solution',
      'Comprehensive eligibility support',
      'Streamlined procedures for savings',
    ],
  },
  es: {},
  jp: {},
  cn: {},
  th: {},
  nl: {},
};

export interface ServicesDutyDrawbackLocalizationClass extends Localization {}
