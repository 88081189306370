import { Localization } from 'app/share/data-type/common.types';

export const QuoteRequestLocalization: {
  [key: string]: QuoteRequestLocalizationClass;
} = {
  en: {
    title: 'Request a Quote',
    description1: `With offices all over the world, ClearFreight is your global logistics partner. We offer a full range of services to meet your unique needs, from customs brokerage to freight forwarding to warehousing. Contact us today to request a quote for your next shipment.`,
    description2:
      'We will help you fill in the blanks, however, in your inquiry, please try to include:',
    fieldsList: ['Commodity', 'Incoterms', 'Special Requirements'],
    name: 'Name',
    email: 'Email',
    company: 'Company',
    phone: 'Phone No.',
    textOptIn: 'Opt in to text notifications',
    country: 'Country',
    origin: 'Origin',
    destination: 'Destination',
    subTitle: 'How can we help?',
    submit: 'Submit',
    textingDisclaimer:
      'By providing a telephone number and opting in, you are consenting to be contacted by SMS text message (our message frequency may vary). Message and data rates may apply. See our',
    privacyPolicy: 'Privacy Policy',
  },
  es: {},
  jp: {},
  cn: {},
  th: {},
  nl: {},
};

export interface QuoteRequestLocalizationClass extends Localization {
  name?: string;
  email?: string;
  company?: string;
  phone?: string;
  textOptIn?: string;
  country?: string;
  origin?: string;
  destination?: string;
  select?: string;
  fieldsList?: string[];
  submit?: string;
  textingDisclaimer?: string;
  privacyPolicy?: string;
}
