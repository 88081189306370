import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { fadeInDown } from 'ng-animate';
@Component({
  selector: 'app-vertical-text',
  templateUrl: './vertical-text.component.html',
  styleUrls: ['./vertical-text.component.scss']
})
export class VerticalTextComponent implements OnInit {
  @Input() link: string = "javascript:;";
  @Input() left: number = 120;
  @Input() width: number = 160;
  @Input() outerWidth: number = 260;
  @Input() fontSize: 'normal' | 'large' | 'small';
  @Input() theme: '' | 'white' | 'gray' | string = '';
  @Output() setState = new EventEmitter();
  constructor(
  ) { }

  ngOnInit(): void {
  }
}
