<div
  class="btn {{ theme }}"
  [ngStyle]="{
    '--height': height + 'px',
    '--width': width + 'px',
    '--fontSize': fontSize == 'normal' ? '18px' : '14px'
  }"
>
  <ng-content #text></ng-content>
</div>
