<div>
  <div class="main">
    <div class="detail-header">
      <div class="left-icon">
        <a [routerLink]="['../']">
          <app-icon-btn icon="arrow_backward"></app-icon-btn>
        </a>
      </div>
      <h1 class="title">
        {{ title }}
      </h1>
      <div class="file-icon">
        <a [href]="fileUrl" target="_blank">
          <img [src]="title === 'ISF 10+2 Form' ? 'assets/images/xls-icon.png' : 'assets/images/pdf-icon.png'" />
        </a>
      </div>
    </div>
    <div class="file-content">
      <iframe *ngIf="!isMobile" [src]="fileUrl | safeUrl" style="width: 100%; height: 1800px"></iframe>
      <pdf-viewer
        *ngIf="isMobile"
        [src]="fileUrl"
        [original-size]="true"
        [render-text]="true"
        [rotation]="0"
        [show-all]="false"
        [fit-to-page]="false"
        [zoom]="1"
        [zoom-scale]="'page-width'"
        [stick-to-page]="true"
        [autoresize]="true"
        [show-borders]="false"
        style="width: 100%; height: 1800px"
      ></pdf-viewer>
    </div>
    <div style="height: 200px"></div>
  </div>
</div>
