<div class="heritage">
  <app-sub-header src="./assets/images/About/Heritage/CF-1.0-Header.jpg"></app-sub-header>
  <div></div>
  <div class="content">
    <h1 class="title">{{ localizedText.title }}</h1>
    <h2 class="title2">
      {{ localizedText.subTitle }}
    </h2>
  </div>
  <div class="timeline">
    <video class="videobg1" #video1 [autoplay]="true" [loop]="video1" [muted]="video1" [poster]="videos[1].img">
      <source [src]="videos[1].video" type="video/mp4" />
    </video>
    <video class="videobg2" #video2 [autoplay]="true" [loop]="video2" [muted]="video2" [poster]="videos[1].img">
      <source [src]="videos[1].video" type="video/mp4" />
    </video>
    <div class="blue-bg">
      <div class="white-circle"></div>
      <div class="timeline-content">
        <div class="center-line"></div>
        <div class="content-row" *ngFor="let item of heritageList; let i = index" data-scroll attr.scroll-id="{{ i }}">
          <ng-container *ngIf="item.leftToRight; else rightToLeft">
            <div class="block-out with-year" [ngStyle]="{ '--max-width': '0' }">
              <div class="year left">
                <div class="year-text">{{ item.year }}</div>
                <div class="year-line"></div>
              </div>
            </div>
            <div class="desc right">
              <h1 *ngIf="item.title" class="title">{{ item.title }}</h1>
              <div [innerHTML]="item.content"></div>
              <div class="block-cover with-desc" [ngStyle]="{ width: '100%', height: '100%' }"></div>
            </div>
          </ng-container>
          <ng-template #rightToLeft>
            <div class="block-out with-desc" [ngStyle]="{ '--max-width': '0' }">
              <div class="desc left">
                <h1 *ngIf="item.title" class="title">{{ item.title }}</h1>
                <div [innerHTML]="item.content"></div>
              </div>
            </div>
            <div class="year right">
              <div class="year-line"></div>
              <div class="year-text">{{ item.year }}</div>
              <div class="block-cover with-year" [ngStyle]="{ width: '100%', height: '100%' }"></div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <div class="timeline-mobile">
    <video class="videobg1" #video1 [autoplay]="true" [loop]="video1" [muted]="video1" [poster]="videos[1].img">
      <source [src]="videos[1].video" type="video/mp4" />
    </video>
    <!-- <video class="videobg2" #video2 [autoplay]="true" [loop]="video2" [muted]="video2" [poster]="videos[1].img">
      <source [src]="videos[1].video" type="video/mp4" />
    </video> -->
    <div class="blue-bg">
      <div class="timeline-content">
        <div class="center-line"></div>
        <div class="content-row" *ngFor="let item of heritageList; let i = index" data-scroll attr.scroll-id="{{ i }}">
          <div class="block-out with-year" [ngStyle]="{ '--max-width': '0' }">
            <div class="year left">
              <div class="year-text">{{ item.year }}</div>
              <div class="year-line"></div>
            </div>
          </div>
          <div class="desc right">
            <h1 *ngIf="item.title" class="title">{{ item.title }}</h1>
            <div [innerHTML]="item.content"></div>
          </div>
        </div>
      </div>
      <div style="height: 50px"></div>
    </div>
  </div>
  <app-center-pic src="./assets/images/About/Heritage/CF-2.0-Drone-Shot.jpg" [top]="-360"></app-center-pic>
  <div class="end-text">
    <app-title-description [height]="isMobile ? 'auto' : '600px'" [spaceHeight]="isMobile ? '30px' : '80px'">
      <div
        [ngStyle]="{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }"
      >
        <div class="contact-desc">
          {{ localizedText.outro }}
        </div>
        <app-contact-button [ngStyle]="{ marginTop: isMobile ? '30px' : '50px' }"></app-contact-button>
      </div>
    </app-title-description>
  </div>
</div>
