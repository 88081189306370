import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { fadeInDown } from 'ng-animate';
import { FormControl } from '@angular/forms';
import { startWith, range, filter, map, Observable } from 'rxjs';
import { IDropdownSettings } from '../ng-multiselect-dropdown';
@Component({
  selector: 'app-newsroom-search',
  templateUrl: './newsroom-search.component.html',
  styleUrls: ['./newsroom-search.component.scss']
})
export class NewsRoomSearchComponent implements OnInit {
  service_options = [{ item_id: 1, item_text: "Ocean Freight" },
  { item_id: 2, item_text: "Air Freight" },
  { item_id: 3, item_text: "Customs Brokerage" },
  { item_id: 4, item_text: "Warehousing and Distribution" },
  { item_id: 5, item_text: "Land Transportation - Road and Rail" },
  { item_id: 6, item_text: "Cargo Insurance" }
  ];

  servicesSelectedItems: any[] = [];
  regionsSelectedItems: any[] = [];
  industrySelectedItems: any[] = [];

  dropdownSettings: IDropdownSettings = {};
  filteredOptions: Observable<string[]>;
  constructor(
  ) {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: false
    };
  }

  ngOnInit(): void {
  }

  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }
}
