import { Localization } from 'app/share/data-type/common.types';

export const HeritageLocalization: {
  [key: string]: HeritageLocalizationClass;
} = {
  en: {
    title: 'Heritage',
    subTitle: 'OVER FIVE DECADES OF EXPERTISE.',
    y1973: '1973',
    y1973Title: 'THE START',
    y1973Content:
      'Founded in San Francisco in 1973, ClearFreight has a long tenure of commitment to service excellence, training and developing the best teams, and responding to the changing needs of our clients.',
    y1973Content1:
      'Many of our team members started their careers at ClearFreight since its early years, and now form the backbone of supervision of our operations and customer support functions. ClearFreight has a culturally diverse team that speaks and writes many languages, which enables us to effectively assist clients and help them navigate the complex requirements of international trade.',
    y1975: '1975',
    y1975Content:
      'Clearfreight opened a second office in Los Angeles in 1975, which is now the location of our corporate headquarters.',
    y1985: '1985',
    y1985Title: 'EXPORT DIVISION',
    y1985Content:
      'In 1985, in response to the needs of existing clients, we launched our export division, and began offering outbound transportation services in addition to import traffic and customs brokerage. Since then, Clearfreight has developed a solid infrastructure to provide a comprehensive suite of door-to-door logistics',
    y2001: '2001',
    y2001Content:
      'Our Miami branch office opened in 2001 to serve shippers and consignees to and from South and Central America.',
    y2009: '2009',
    y2009Content:
      'In 2009 we opened Houston, Dallas, and Atlanta offices, added more coverage in U.S.A',
    y2014: '2014',
    y2014Title: 'EXPANDING',
    y2014Content:
      'In 2014, ClearFreight established additional coverage in Asia and Canada through an acquisition to expand our operations into Hong Kong, Shanghai, Beijing, Qingdao, Shenzhen, Guangzhou, Dalian and Xiamen. Our Toronto office became our first location in Canada, operating under Clearfreight Canada Inc',
    y2018: '2018',
    y2018Title: 'EXPANDING',
    y2018Content:
      'In 2018, we opened our first Latin America office in Santiago, Chile, marking our commitment to serving clients’ needs as they expand their international business to new global markets.',
    y2023: '2023',
    y2023Title: '50 YEARS',
    y2023Content:
      'ClearFreight celebrates 50 years anniversary in 2023. ClearFreight also expands into two more Asian Countries: Japan and Thailand. We are proud of our heritage and look forward to the next 50 years of serving our clients.',
    outro:
      'See how we can help you with your logistics needs. Contact us today.',
  },
  cn: {},
  jp: {},
  th: {},
  nl: {},
  es: {},
};

export interface HeritageLocalizationClass extends Localization {
  y1973?: string;
  y1973Title?: string;
  y1973Content?: string;
  y1973Content1?: string;
  y1975?: string;
  y1975Content?: string;
  y1985?: string;
  y1985Title?: string;
  y1985Content?: string;
  y2001?: string;
  y2001Content?: string;
  y2009?: string;
  y2009Content?: string;
  y2014?: string;
  y2014Title?: string;
  y2014Content?: string;
  y2018?: string;
  y2018Title?: string;
  y2018Content?: string;
  y2023?: string;
  y2023Title?: string;
  y2023Content?: string;
}
