import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { fadeInDown } from 'ng-animate';
import { imageTextFadeInType } from 'app/share/data-type/common.types';

import ScrollOut from 'scroll-out';
import { gsap } from 'gsap';
import { ScreenService } from 'app/services/screen.service';
@Component({
  selector: 'app-image-text-fadein',
  templateUrl: './image-text-fadein.component.html',
  styleUrls: ['./image-text-fadein.component.scss'],
})
export class ImageTextFadeInComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() imageTextFadeInData: imageTextFadeInType[] = [];
  @Input() bgimg: string = ''; //eg. url("~assets/images/Homepage/4/CF-Shapes-Together.png") no-repeat
  @Input() bgTopPostion: string = '0px';
  @Output() setState = new EventEmitter();
  private textimgPlayed: Record<string, boolean> = {};
  so: any;

  public isMobile: boolean = false;
  constructor(private screenService: ScreenService) {
    this.isMobile = this.screenService.isMobile();
  }
  ngOnDestroy(): void {
    this.so.teardown();
  }
  ngAfterViewInit(): void {
    this.so = ScrollOut({
      onShown: (element: any, ctx: any, scrollingElement: any) => {
        if (element.attributes['scroll-id']) {
          let i = element.attributes['scroll-id'].value;
          let leftToRight = element.attributes['scroll-dir'].value == 'true' ? true : false;
          //this.playAnim(false, 'imgtext_' + i, leftToRight ? fadeInLeft : fadeInRight, { timing: 1 })
          if (!this.textimgPlayed[i as string]) {
            this.textimgPlayed[i] = true;
            if (leftToRight) {
              gsap.fromTo('#' + 'imgtext_' + i, { width: '1%' }, { duration: 3, width: '100%' });
            } else {
              gsap.fromTo('#' + 'imgtext_' + i + ' .module-item-over', { width: '100%' }, { duration: 3, width: '0' });
            }
          }
        }
        //console.log('/* Triggered when an element is shown */')
      },
      onHidden: (element: any, ctx: any, scrollingElement: any) => {
        //console.log('/* Triggered when an element is hidden */')
      },
      onChange: (element: any, ctx: any, scrollingElement: any) => {
        //console.log('/* Triggered when an element changes visibility */')
      },
    });
  }

  ngOnInit(): void {}
}
