const material_url = "https://clrfwebservicestorage.blob.core.windows.net/clearfreight-dev-website/";//http://localhost:6080/
export const midVideo = { img: material_url + "CF-Homepage-Air-1.0.jpg", video: material_url + "CF-Homepage-Air-1.0.mp4" };
export const leaders = [
  { img: '', title: 'TITLE HERE', name: 'Name Here' },
  { img: '', title: 'TITLE HERE', name: 'Name Here' },
  { img: '', title: 'TITLE HERE', name: 'Name Here' },
  { img: '', title: 'TITLE HERE', name: 'Name Here' },
  { img: '', title: 'TITLE HERE', name: 'Name Here' },
  { img: '', title: 'TITLE HERE', name: 'Name Here' },
  { img: '', title: 'TITLE HERE', name: 'Name Here' },
  { img: '', title: 'TITLE HERE', name: 'Name Here' },
  { img: '', title: 'TITLE HERE', name: 'Name Here' }
]

export const videos = [
  { img: material_url + "CF-Homepage-Air-1.0.jpg", video: material_url + "CF-Homepage-Air-1.0.mp4" },
  { img: material_url + "CF-Homepage-Ocean_1.0.jpg", video: material_url + "CF-Homepage-Ocean_1.0.mp4" },
  { img: material_url + "CF-Homepage-Rail-1.0.jpg", video: material_url + "CF-Homepage-Rail-1.0.mp4" },
  { img: material_url + "CF-Homepage-Road-1.0.jpg", video: material_url + "CF-Homepage-Road-1.0.mp4" }];
