import { Component, OnInit } from '@angular/core';
import {
  ContactFormLocalization,
  ContactFormLocalizationClass,
} from './contact-form.localization';
import { LocalizationService } from 'app/services/localization.service';
import { UntypedFormControl, Validators, FormControl } from '@angular/forms';
import { MyErrorStateMatcher } from 'app/pages/quote-request/quote-request.component';
import { Observable, map, startWith } from 'rxjs';
import { ContactService } from 'app/services/contact.service';
import { ToastrService } from 'ngx-toastr';
import { contactParams } from 'app/share/data-type/common.types';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent implements OnInit {
  public localizedText: ContactFormLocalizationClass;

  nameFormControl = new UntypedFormControl('', [Validators.required]);
  companyNameFormControl = new UntypedFormControl('', [Validators.required]);
  companyEmailFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.email,
  ]);
  companyPhoneFormControl = new UntypedFormControl('', [Validators.required]);
  subjectControl = new UntypedFormControl('', [Validators.required]);
  servicesControl = new FormControl('', [Validators.required]);
  originControl = new UntypedFormControl('', [Validators.required]);
  destinationControl = new FormControl('', [Validators.required]);
  descriptionControl = new UntypedFormControl('', [Validators.required]);
  textingControl = new UntypedFormControl(false);
  matcher = new MyErrorStateMatcher();

  subject_options = [
    'Request a Quote',
    'Customer Service',
    'Employment Opportunities',
    'Become an Agent or Carrier Partner',
  ];
  filteredOptions: Observable<string[]>;

  constructor(
    private contactService: ContactService,
    private toastr: ToastrService,
    private localizationService: LocalizationService
  ) {
    this.localizedText = this.localizationService.getTranslation(
      ContactFormLocalization
    );
  }

  ngOnInit(): void {
    this.filteredOptions = this.servicesControl.valueChanges.pipe(
      startWith(''),
      map((val) => this.filter(val ?? ''))
    );
  }
  filter(val: string): string[] {
    return this.subject_options.filter(
      (option) => option.toLowerCase().indexOf(val.toLowerCase()) === 0
    );
  }
  handleSubmit(): void {
    if (this.validateForm()) {
      const params: contactParams = {
        name: this.nameFormControl.value,
        company: this.companyNameFormControl.value,
        email: this.companyEmailFormControl.value,
        phone: this.companyPhoneFormControl.value,
        address: 'N/A',
        goals: `New website contact submission </br></br>
        Area of interest: ${this.subjectControl.value}</br>
        Goals: ${this.descriptionControl.value}`,
        isNL: false,
        textEnabled: this.textingControl.value,
      };
      this.contactService.sendContact(params).subscribe((res) => {
        if (res) {
          this.toastr.success('Your message has been sent successfully.');
          this.nameFormControl.reset();
          this.companyNameFormControl.reset();
          this.companyEmailFormControl.reset();
          this.companyPhoneFormControl.reset();
          this.subjectControl.reset();
          this.descriptionControl.reset();
          this.textingControl.reset();
        } else {
          this.toastr.error('Your message failed to send.');
        }
      });
    } else if (!this.companyEmailFormControl.valid) {
      this.toastr.error('Please enter a valid email address.');
    } else {
      this.toastr.error('Please fill in all the required fields.');
    }
  }
  validateForm(): boolean {
    return (
      this.nameFormControl.valid &&
      this.companyNameFormControl.valid &&
      this.companyEmailFormControl.valid &&
      this.companyPhoneFormControl.valid &&
      this.subjectControl.valid &&
      this.descriptionControl.valid
    );
  }
  ngAfterViewInit(): void {}
}
