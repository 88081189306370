import { Injectable } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';

@Injectable({
  providedIn: 'root',
})
export class CustomPaginator extends MatPaginatorIntl {
  constructor() {
    super();
    this.itemsPerPageLabel = 'Page';
    this.nextPageLabel = 'Next Page';
    this.previousPageLabel = 'Previous Page';
    this.firstPageLabel = 'First Page';
    this.lastPageLabel = 'Last Page';
  }
}
