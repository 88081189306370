import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import {
  quickShipmentTracking,
  quickContainerTracking,
} from 'app/share/data-type/tracking.types';

@Injectable({
  providedIn: 'root',
})
// [To-do] remove dev environment variables
export class TrackingService {
  private readonly clearfreightAPIKey: string = environment.cfApiKey;
  private apiUrl: string = environment.cfApiUrl + 'Cargowise/';
  // private apiUrl: string = 'https://localhost:7276/Cargowise/';
  private readonly requestHeaders: HttpHeaders = new HttpHeaders({
    ApiKey: this.clearfreightAPIKey,
    // ApiKey: '52519c67-3c3a-4fcb-8106-16160c5f17e2',
  });

  constructor(private http: HttpClient) {}

  getShipmentTracking(
    trackingNumber: string
  ): Observable<quickShipmentTracking[]> {
    let requestURL = `${this.apiUrl}QuickShipmentTracking?JobNumber=${trackingNumber}`;
    return this.http.get<quickShipmentTracking[]>(requestURL, {
      headers: this.requestHeaders,
    });
  }

  getContainerTracking(
    containerNumber: string
  ): Observable<quickContainerTracking[]> {
    let requestURL = `${this.apiUrl}QuickContainerTracking?ContainerNumber=${containerNumber}`;
    return this.http.get<quickContainerTracking[]>(requestURL, {
      headers: this.requestHeaders,
    });
  }
}
