export interface NavMenuItemType {
  title: string,
  routeAddress: string | undefined,
  canClick?: boolean;
  newPage?: boolean;
  children?: NavMenuItemType[]
}
export interface NavMenuType {
  itemTitle: string,
  key: string,
  itemDesc: string,
  canClick?: boolean;
  newPage?: boolean;
  isShowInBottom?: boolean;
  isCurrentRoute?: boolean;
  routeAddress?: string;
  menus: NavMenuItemType[]
}
