import { Component, Input, OnInit } from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ScreenService } from 'app/services/screen.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-lookup-input',
  templateUrl: './lookup-input.component.html',
  styleUrls: ['./lookup-input.component.scss'],
})
export class LookupInputComponent implements OnInit {
  @Input() placeholder: string = '';
  @Input() height: string = '100%';
  @Input() width: string = '800px';
  @Input() name: string = '';

  clearFreightRefFormControl: FormControl;
  containerNumberFormControl: FormControl;
  isFormValid: boolean = false;

  public isMobile: boolean = false;

  constructor(private router: Router, private toastr: ToastrService, private screenService: ScreenService) {
    this.width = this.width;
    this.height = this.height;
    this.isMobile = this.screenService.isMobile();
  }

  ngOnInit(): void {
    this.clearFreightRefFormControl = new FormControl('', [Validators.required, Validators.pattern('^[SBsb]\\d{8}$')]);

    this.containerNumberFormControl = new FormControl('', [
      Validators.required,
      Validators.pattern('^[A-Z,a-z]{4}\\d{7}$'),
    ]);

    this.clearFreightRefFormControl.valueChanges.subscribe(() => {
      this.updateFormValidity();
    });

    this.containerNumberFormControl.valueChanges.subscribe(() => {
      this.updateFormValidity();
    });
  }

  updateFormValidity() {
    this.isFormValid = this.clearFreightRefFormControl.valid || this.containerNumberFormControl.valid;
  }

  submitOnEnter(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevents the form from being submitted multiple times
      this.track(); // Call your track method to handle form submission
    }
  }

  track() {
    if (!this.isFormValid) {
      return;
    }

    if (this.containerNumberFormControl.valid && this.clearFreightRefFormControl.valid) {
      alert('Shipment and Container number entered, only shipment number will be used for tracking');
      window.open('./tracking/shipment/' + this.clearFreightRefFormControl.value.toUpperCase(), '_self');
    } else if (this.clearFreightRefFormControl.valid) {
      window.open('./tracking/shipment/' + this.clearFreightRefFormControl.value.toUpperCase(), '_self');
    } else if (this.containerNumberFormControl.valid) {
      window.open('./tracking/container/' + this.containerNumberFormControl.value.toUpperCase(), '_self');
    }
  }
}
