import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ResourcesComponent } from './resources.component';
import { NewsroomVideosComponent } from './videos/videos.component';
import { NewsroomArticlesComponent } from './articles/articles.component';
import { FormsComponent } from './forms/forms.component';
import { MarketUpdatesComponent } from './market-updates/market-updates.component';
import { ArticleShowComponent } from './article-show/article-show.component';

import { StatsShowComponent } from './stats-show/stats-show.component';

import { NewsroomPressComponent } from './press/press.component';
import { NewsroomPressReleasesComponent } from './press-releases/press-releases.component';
import { NewsroomWhitepapersComponent } from './whitepapers/whitepapers.component';
import { FormDetailComponent } from './forms-detail/forms-detail.component';
import { FormCtpatComponent } from './forms-ctpat/forms-ctpat.component';
import { NewsroomPortStatsComponent } from './portstats/portstats.component';
import { NewsroomIndustryNewsComponent } from './industry-news/industry-news.component';
import { NewsroomBrokerageUpdatesComponent } from './brokerage-updates/brokerage-updates.component';
const cacheSec = 5;
const routes: Routes = [
  {
    path: '',
    data: { title: 'Resources | ClearFreight', revalidate: cacheSec },
    component: ResourcesComponent,
  },
  {
    path: 'newsroom',
    data: { title: 'News Room | ClearFreight', revalidate: cacheSec },
    children: [
      {
        path: '',
        data: {
          title: 'News Room Article | ClearFreight',
          revalidate: cacheSec,
        },
        component: NewsroomArticlesComponent,
      },
      {
        path: ':page',
        data: {
          title: 'News Room Article | ClearFreight',
          revalidate: cacheSec,
        },
        component: NewsroomArticlesComponent,
      },
    ],
  },
  {
    path: 'forms',
    children: [
      {
        path: '',
        data: { title: 'Forms | ClearFreight' },
        component: FormsComponent,
      },
      {
        path: ':id',
        data: { title: 'Forms | ClearFreight' },
        component: FormDetailComponent,
      },
    ],
  },
  {
    path: 'ctpat',
    data: { title: 'Forms C-TPAT | ClearFreight', revalidate: cacheSec },
    component: FormCtpatComponent,
  },
  {
    path: 'videos',
    data: { title: 'Resources | ClearFreight', revalidate: cacheSec },
    component: NewsroomVideosComponent,
  },
  {
    path: 'press',
    data: { title: 'Press | ClearFreight', revalidate: cacheSec },
    component: NewsroomPressComponent,
  },
  {
    path: 'press-releases',
    data: { title: 'Press Releases | ClearFreight', revalidate: cacheSec },
    component: NewsroomPressReleasesComponent,
  },
  {
    path: 'whitepapers',
    data: { title: 'Whitepapers | ClearFreight', revalidate: cacheSec },
    component: NewsroomWhitepapersComponent,
  },
  {
    path: 'portstats',
    data: { title: 'Port Stats | ClearFreight', revalidate: cacheSec },
    component: NewsroomPortStatsComponent,
  },
  {
    path: 'industry',
    data: { title: 'Industry News | ClearFreight', revalidate: cacheSec },
    component: NewsroomIndustryNewsComponent,
  },
  {
    path: 'brokerage',
    data: { title: 'Brokerage Updates | ClearFreight', revalidate: cacheSec },
    component: NewsroomBrokerageUpdatesComponent,
  },
  {
    path: 'market',
    data: { title: 'Market Updates | ClearFreight', revalidate: cacheSec },
    component: MarketUpdatesComponent,
  },
  {
    path: 'article',
    data: { title: 'Article | ClearFreight', revalidate: cacheSec },
    children: [
      {
        path: '',
        data: { title: 'Show Article | ClearFreight', revalidate: cacheSec },
        component: ArticleShowComponent,
      },
      {
        path: ':name',
        data: { title: 'Show Article | ClearFreight', revalidate: cacheSec },
        component: ArticleShowComponent,
      },
    ],
  },
  {
    path: 'stats',
    data: { title: 'Port Stats | ClearFreight', revalidate: cacheSec },
    children: [
      {
        path: '',
        data: { title: 'Show Port Stats | ClearFreight', revalidate: cacheSec },
        component: StatsShowComponent,
      },
      {
        path: ':name',
        data: { title: 'Show Port Stats | ClearFreight', revalidate: cacheSec },
        component: StatsShowComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ResourcesRoutingModule {}
