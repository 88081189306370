import { Component, OnInit } from '@angular/core';
import { ScreenService } from 'app/services/screen.service';
import { TrackingLocalization, TrackingLocalizationClass } from './tracking.localization';
import { LocalizationService } from 'app/services/localization.service';
import { SafeUrlPipe } from 'app/share/pipes/safe-url.pipe';
import { Router } from '@angular/router';
import { TrackingService } from 'app/services/tracking.service';
import {
  containersType,
  packsType,
  quickContainerTracking,
  quickShipmentMilestones,
  quickShipmentTracking,
} from 'app/share/data-type/tracking.types';
import { FlagService } from 'app/services/flag.service';
import { ToastrService } from 'ngx-toastr';
import { PackTypeService } from './packs/pack-type.service';

@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss'],
})
export class TrackingComponent implements OnInit {
  public isMobile: boolean = false;
  public localizedText: TrackingLocalizationClass;
  public trackingNumber: string = '';
  public shipmentData: quickShipmentTracking;
  public containerData: quickContainerTracking;
  public notFound: boolean = false;
  public isLoading: boolean = true;
  public showContainerDates: boolean = false;

  public clearViewUrl: string = 'https://clearview.clearfreight.com/';
  public webtrackingUrl: string = 'https://tracking.clearfreight.com/';

  constructor(
    private localizationService: LocalizationService,
    private screenService: ScreenService,
    private router: Router,
    private trackingService: TrackingService,
    private flagService: FlagService,
    private toastr: ToastrService,
    private packTypeService: PackTypeService
  ) {
    this.localizedText = this.localizationService.getTranslation(TrackingLocalization);
    this.isMobile = this.screenService.isMobile();
  }

  ngOnInit(): void {
    const route = this.router.url;

    if (route.includes('shipment')) {
      this.trackingNumber = route.split('/').pop() ?? '';
      this.trackingService.getShipmentTracking(this.trackingNumber).subscribe(
        data => {
          if (data.length === 0) {
            this.notFound = true;
          } else {
            this.shipmentData = data[0];
            this.shipmentData.milestones = this.removeDuplicateMilestones(this.shipmentData.milestones ?? []);
            this.isLoading = false;
          }
        },
        error => {
          this.toastr.error(error.error);
          this.notFound = true;
          this.isLoading = false;
        }
      );
    }
    if (route.includes('container')) {
      this.trackingNumber = route.split('/').pop() ?? '';
      this.trackingService.getContainerTracking(this.trackingNumber).subscribe(
        data => {
          if (data.length === 0) {
            this.notFound = true;
          } else {
            this.containerData = data[0];
          }
          this.isLoading = false;
        },
        error => {
          this.toastr.error(error.error);
          this.notFound = true;
          this.isLoading = false;
        }
      );

      this.showContainerDates =
        this.containerData.transportBooked !== null ||
        this.containerData.actualDelivery !== null ||
        this.containerData.emptyReadyforPickup !== null ||
        this.containerData.emptyReturnBy !== null ||
        this.containerData.emptyReturnedOn !== null;
    }
  }

  getFlagRoute(unloco: string): string {
    return `assets/images/flags/${this.flagService.getFlag(unloco)}.png`;
  }

  getTransportImage(mode: string): string {
    switch (mode.toUpperCase()) {
      case 'SEA':
        return 'assets/images/global-ocean-forwarding-blue.png';
      case 'AIR':
        return 'assets/images/global-air-forwarding-blue.png';
      case 'ROA':
        return 'assets/images/domestic-transportation-blue.png';
      case 'RAI':
        return 'assets/images/rail-forwarding.png';
      default:
        return 'assets/images/global-ocean-forwarding-blue.png';
    }
  }

  parseContainers(containers: string): containersType[] {
    let result: containersType[] = [];
    let containersMap = new Map<string, number>();
    // Remove all ( and ) from the string
    containers = containers.replace(/[()]/g, '');
    let containersArray = containers.split(',');

    containersArray.forEach(container => {
      let currContainerCount = containersMap.get(container);
      let newContainerCount = currContainerCount ? currContainerCount + 1 : 1;

      containersMap.set(container, newContainerCount);
    });
    containersMap.forEach((value, key) => {
      result.push({ quantity: value, type: key });
    });

    return result;
  }

  parsePacks(shipmentData: quickShipmentTracking): packsType[] {
    let result: packsType[] = [];

    if (shipmentData.inners) {
      result.push({
        quantity: shipmentData.inners,
        packType: shipmentData.innersType ?? '',
        type: 'Inner',
      });
    }
    if (shipmentData.outers) {
      result.push({
        quantity: shipmentData.outers,
        packType: shipmentData.outersType ?? '',
        type: 'Outer',
      });
    }

    return result;
  }

  getPackType(packType: string): string {
    return this.packTypeService.getPackType(packType);
  }

  getReleaseType(shipmentData: quickShipmentTracking): string {
    switch (shipmentData.releaseType) {
      case 'BRR':
        return 'Letter of Credit (Bank Release)';
      case 'BSD':
        return 'Sight Draft (Bank Release)';
      case 'BTD':
        return 'Time Draft (Bank Release)';
      case 'CAD':
        return 'Cash Against Documents';
      case 'CSH':
        return 'Cashier’s Check';
      case 'LOI':
        return 'Letter of Indemnity';
      case 'NON':
        return 'Non Negotiable';
      case 'PTL':
        return 'Pending Telex Release';
      case 'EBL':
        return 'Express Bill of Lading';
      case 'OBL':
        return 'Original Bill of Lading';
      case 'OBO':
        return 'Original Surrendered at Origin';
      case 'OBR':
        return 'Original Required at Destination';
      case 'SWB':
        return 'Sea Waybill';
      default:
        return shipmentData.releaseType ?? 'N/A';
    }
  }

  getContainerTypeName(type: string): string {
    switch (type) {
      case '20GP':
        return '20ft General Purpose';
      case '20RF':
        return '20ft Reefer';
      case '20OT':
        return '20ft Open Top';
      case '20FR':
        return '20ft Flat Rack';
      case '20TK':
        return '20ft Tank';
      case '40GP':
        return '40ft General Purpose';
      case '40RF':
        return '40ft Reefer';
      case '40OT':
        return '40ft Open Top';
      case '40FR':
        return '40ft Flat Rack';
      case '40TK':
        return '40ft Tank';
      case '40HC':
        return '40ft High Cube';
      case '45HC':
        return '45ft High Cube';
      default:
        return type;
    }
  }

  getPackingMode(mode: string): string {
    switch (mode.toUpperCase()) {
      case 'FCL':
        return 'Full Container Load';
      case 'LCL':
        return 'Less than Container Load';
      case 'BBK':
        return 'Break Bulk';
      case 'LRO':
        return 'Less than Truck Load';
      case 'LTL':
        return 'Less than Truck Load';
      case 'FRO':
        return 'Full Truck Load';
      case 'FTL':
        return 'Full Truck Load';
      case 'LSE':
        return 'Air Freight (Loose)';
      case 'ULD':
        return 'Air Freight (Unit Load Device)';
      case 'LRA':
        return 'Rail Freight (Loose)';
      case 'FRA':
        return 'Rail Freight (Full Container)';
      case 'COU':
        return 'Courier';
      default:
        return mode;
    }
  }

  getServiceLevel(shipmentData: quickShipmentTracking): string {
    switch (shipmentData.serviceLevel?.toUpperCase()) {
      case 'D2D':
        return 'Door to Door';
      case 'D2P':
        return 'Door to Port';
      case 'P2D':
        return 'Port to Door';
      case 'P2P':
        return 'Port to Port';
      default:
        return shipmentData.serviceLevel ?? 'N/A';
    }
  }

  getIncoterm(shipmentData: quickShipmentTracking): string {
    switch (shipmentData.incoterm?.toUpperCase()) {
      case 'EXW':
        return 'Ex Works';
      case 'FCA':
        return 'Free Carrier';
      case 'FAS':
        return 'Free Alongside Ship';
      case 'FOB':
        return 'Free on Board';
      case 'CFR':
        return 'Cost and Freight';
      case 'CIF':
        return 'Cost, Insurance and Freight';
      case 'CPT':
        return 'Carriage Paid To';
      case 'CIP':
        return 'Carriage and Insurance Paid To';
      case 'DAT':
        return 'Delivered at Terminal';
      case 'DAP':
        return 'Delivered at Place';
      case 'DDP':
        return 'Delivered Duty Paid';
      case 'DPU':
        return 'Delivered at Place Unloaded';
      case 'FC1':
        return 'Free Carrier (Seller Responsible)';
      case 'FC2':
        return 'Free Carrier (Buyer Responsible)';
      default:
        return shipmentData.incoterm ?? 'N/A';
    }
  }

  removeDuplicateMilestones(milestones: quickShipmentMilestones[]): quickShipmentMilestones[] {
    let result: quickShipmentMilestones[] = [];
    let milestonesMap = new Map<string, quickShipmentMilestones>();

    milestones.forEach(milestone => {
      let currMilestone = milestonesMap.get(milestone.milestoneDescription ?? '');
      if (currMilestone) {
        if (milestone.milestoneActualDate && !currMilestone.milestoneActualDate) {
          currMilestone.milestoneActualDate = milestone.milestoneActualDate;
        }
      } else {
        milestonesMap.set(milestone.milestoneDescription ?? '', milestone);
      }
    });

    milestonesMap.forEach((value, key) => {
      result.push(value);
    });

    return result;
  }
}
