<div
  class="headerbg"
  [ngStyle]="{ '--bg': 'url(' + src + ')  no-repeat', '--top': top }"
>
  <img
    src="./assets/images/About/ClearFreight/CF-Navy-Blue-Half-Circle-Header.png"
  />
  <div class="page-title">
    <div class="title-h2">ARTICLE</div>
    <div class="title-h1">{{ title }}</div>
  </div>
</div>
