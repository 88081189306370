import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { trigger, transition, useAnimation, style, state, animate } from '@angular/animations';
import { fadeIn, fadeInDown, fadeOut } from 'ng-animate';
@Component({
  selector: 'app-job-item',
  templateUrl: './job-item.component.html',
  styleUrls: ['./job-item.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('open', style(
        {
          height: '300px'
        }
      )),
      state('close', style(
        {
          height: '0px'
        }
      )),
      transition('open => close', animate(500)),
      transition('close => open', animate(500)),
    ])
  ]
})
export class JobItemComponent implements OnInit {
  @Input() isShow: boolean = false;
  @Input() title: string = "";
  @Input() location: string = "";
  @Output() setState = new EventEmitter();
  constructor(
  ) { }

  ngOnInit(): void {
  }
  toggle() {
    this.isShow = !this.isShow
  }
}
