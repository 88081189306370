import { Localization } from 'app/share/data-type/common.types';

export const IndustryElectronicsLocalization: {
  [key: string]: IndustryElectronicsLocalizationClass;
} = {
  en: {
    title: 'Electronics',
    subTitle: 'Industry',
    description1:
      'Transportation of electronic devices, components, and equipment, often requiring specialized handling, packaging, and temperature-controlled transportation to prevent damage and ensure product integrity. ',
  },
  cn: {},
  jp: {},
  th: {},
  nl: {},
  es: {},
};

export interface IndustryElectronicsLocalizationClass extends Localization {}
