import { Component, OnInit, AfterViewInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import ScrollOut from 'scroll-out';
import Splitting from "splitting";
import { gsap } from 'gsap';

import { videos, article_service, white_paper_service, press_article, news_data, title_data } from './data';
@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourcesComponent implements OnInit, AfterViewInit, OnDestroy {

  videos = videos;
  news_data = news_data;
  article_service_data = article_service;
  white_paper_data = white_paper_service;
  press_data = press_article;
  title_data = title_data;
  private so: any;//ScrollOut object

  private yearDescPlayed: Record<string, boolean> = {};
  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.so = ScrollOut({
      cssProps: {
        visibleY: false,
        viewportY: true
      },
      threshold: 0.8,
      onShown: (element: any, ctx: any, scrollingElement: any) => {
        /*if (element.className.indexOf('year left') != -1) {
          var chars = element.querySelectorAll(".char");
          chars.forEach((char: any, index: number) => {
            gsap.from(char, { opacity: 0, delay: index / chars.length });
          })
        }
        if (element.className.indexOf('year right') != -1) {
          var chars = element.querySelectorAll(".char");
          chars.forEach((char: any, index: number) => {
            gsap.from(char, { opacity: 0, delay: 1 - index / chars.length });
          })
        }*/
        //var element = el.parentElement;
        let yearWidth = '920px';
        let descWidth = '670px';
        if (element.className.indexOf('content-row') != -1) {

          let i = element.attributes['scroll-id'].value;
          if (!this.yearDescPlayed[i]) {
            this.yearDescPlayed[i] = true;
            let desc = element.querySelector('.desc');
            let year = element.querySelector('.year');
            let maxHeight = Math.max(desc.clientHeight, year.clientHeight) + 'px';
            //Sometimes the height of the year(left side) is not enough, manually adjust the height of the year
            if (maxHeight == year.clientHeight) {
              year.style.setProperty('--desc-height', "auto");
            } else {
              year.style.setProperty('--desc-height', maxHeight);
            }



            let outer = element.querySelector('.block-out');
            if (outer) {
              let outerWith = yearWidth;
              if (outer.className.indexOf('with-year') == -1) {
                outerWith = descWidth;
              }
              outer.style.setProperty('--max-width', outerWith);
              gsap.fromTo(outer, { width: 0 }, { width: outerWith, duration: 1 });
            }
            let cover = element.querySelector('.block-cover');
            if (cover) {
              let coverWidth = yearWidth;
              if (cover.className.indexOf('with-year') == -1) {
                coverWidth = descWidth;
              }
              let right = element.querySelector('.right');
              let coverHeight = right.clientHeight + 'px';
              cover.style.setProperty('--max-height', coverHeight);
              gsap.fromTo(cover, { width: coverWidth }, { width: '0', duration: 1 });
            }
          }


        }
      }
    })

    //Splitting();
  }
  ngOnDestroy() {
    this.so.teardown();
  }

}
