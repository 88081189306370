import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { get_forms_data } from '../forms/data';
import { formItemType } from 'app/share/data-type/common.types';
import { LocalizationService } from 'app/services/localization.service';
import { environment } from 'environments/environment';
import { ScreenService } from 'app/services/screen.service';
@Component({
  selector: 'app-forms-detail',
  templateUrl: './forms-detail.component.html',
  styleUrls: ['./forms-detail.component.scss'],
})
export class FormDetailComponent implements OnInit {
  fileUrl: string;
  title: string;

  public isMobile: boolean = false;

  constructor(
    private router: ActivatedRoute,
    private nav: Router,
    private localizationService: LocalizationService,
    private screenService: ScreenService
  ) {
    this.isMobile = this.screenService.isMobile();
  }

  ngOnInit(): void {
    let id = this.router.snapshot.params['id'] ?? '';
    let fileInfo: formItemType | undefined;
    if (id) {
      get_forms_data(this.localizationService).forEach(item => {
        item.files.forEach(f => {
          if (f.id === id) {
            fileInfo = f;
          }
        });
      });
    }

    if (!fileInfo) {
      this.nav.navigate(['../resources/forms']);
    } else {
      if (this.isMobile) {
        this.fileUrl = environment.host + fileInfo.path;
      } else {
        this.fileUrl = fileInfo.path;
      }

      this.title = fileInfo.name;
    }
  }
}
