import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

import { services_data, article_service, videos } from './data';
import { imageTextFadeInType } from 'app/share/data-type/common.types';
import { Router } from '@angular/router';
import { ScreenService } from 'app/services/screen.service';
@Component({
  selector: 'app-technology',
  templateUrl: './technology.component.html',
  styleUrls: ['./technology.component.scss'],
})
export class TechnologyComponent implements OnInit, AfterViewInit {
  //recommand map: jvectormap
  videos = videos;
  article_service_data = article_service;
  services_data = services_data;

  public isMobile: boolean = false;
  constructor(private router: Router,private screenService: ScreenService) {

    this.isMobile = this.screenService.isMobile();
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {}
  getDemoLink(): string {
    return this.router.url == '/technology' ? '../demo' : '../../demo';
  }
}
