import { Component, Input, OnInit } from '@angular/core';
import { NavMenuType } from 'app/share/data-type/nav-menu.type';

@Component({
  selector: 'app-mobile-line-nav',
  templateUrl: './mobile-line-nav.component.html',
  styleUrls: ['./mobile-line-nav.component.scss'],
})
export class MobileLineNavComponent implements OnInit {
  @Input() navMenu: NavMenuType;

  constructor() {}

  ngOnInit(): void {}

  handleNav(route: string | undefined) {
    if (route) {
      window.location.href = route;
    }
  }
}
