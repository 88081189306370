import { Localization } from 'app/share/data-type/common.types';

export const OrderManagementLocalization: {
  [key: string]: OrderManagementLocalizationClass;
} = {
  en: {
    title: 'Order Management',
    subTitle: 'Better Supplier and Buyer Relationships',
    intro: 'Experience the future',
    introList: [
      `Purchase Orders and Bookings in real-time`,
      `Collaborate with your suppliers`,
      `Transform your traditional setup`,
    ],
    description1: `Discover the future of supply chain management with ClearFreight's state-of-the-art Order Management System. Designed to integrate seamlessly into your existing framework, our cloud-based OMS transforms traditional supply chains into dynamic, interconnected networks. Say goodbye to linear limitations and welcome a new era of collaborative, real-time coordination.`,
    whyOMS: 'WHAT IS OMS',
    itemDescList1: [
      {
        item: 'Cloud-Based Real-Time Access',
        description:
          'Our system provides immediate updates and transparent communication across all stages of the supply chain, ensuring that every stakeholder stays informed and empowered.',
      },
      {
        item: 'User-Friendly Dashboards',
        description:
          'Tailored dashboards for consignees, suppliers, and forwarders offer intuitive navigation and powerful tools, streamlining your order management processes.',
      },
      {
        item: 'Comprehensive Integration',
        description:
          'Seamlessly connect your system with ours via Excel templates or API, allowing for effortless data and document exchange for streamlined operations.',
      },
      {
        item: 'Enhanced Security and Control',
        description:
          'Implement role-based access to secure sensitive information and empower your team with the right tools for their specific needs.',
      },
    ],
    services: 'Key Features',
    keyFeaturesRotate: `BOOKINGS AND PO'S`,
    servicesList: [
      'Online Supplier Bookings: Simplify your booking process with our online portal and approval system.',
      'Transparency from Factory to Final Delivery: Track your products every step of the way via OMS and ClearView, ensuring total visibility.',
      'Detailed Vendor KPIs: Monitor performance metrics to optimize your supply chain.',
      'Milestone Alerts: Stay proactive with real-time notifications on critical supply chain events.',
      'Extensive Data Management: Export crucial data into Excel for in-depth analysis and reporting.',
    ],
    servicesWDescList: [
      {
        item: 'Online Supplier Bookings:',
        description:
          'Simplify your booking process with our online portal and approval system.',
      },
      {
        item: 'Transparency from Factory to Final Delivery:',
        description:
          'Track your products every step of the way via OMS and ClearView, ensuring total visibility.',
      },
      {
        item: 'Detailed Vendor KPIs:',
        description:
          'Monitor performance metrics to optimize your supply chain.',
      },
      {
        item: 'Milestone Alerts:',
        description:
          'Stay proactive with real-time notifications on critical supply chain events.',
      },
      {
        item: 'Extensive Data Management',
        description:
          'Export crucial data into Excel for in-depth analysis and reporting.',
      },
    ],
    transformingSupplyChains: 'Transforming Supply Chains',
    description2:
      'Experience a shift from traditional linear models to a dynamic, network-based approach. Our system enhances collaboration, reduces redundancy, and accelerates processes, propelling your supply chain into the modern age.',
    ourCommitment: 'Our Commitment',
    description3: `At ClearView, we're dedicated to your success. Our OMS is more than a tool—it's a partnership. We work alongside you to ensure our system meets your unique needs, driving your business forward`,
    contactUs: 'Contact Us',
    description4: `Ready to elevate your order management? Contact us today to schedule a demo and discover how ClearView's OMS can revolutionize your supply chain.`,
  },
  cn: {},
  es: {},
  nl: {},
  th: {},
  jp: {},
};

export interface OrderManagementLocalizationClass extends Localization {
  introList?: string[];
  whyOMS?: string;
  itemDescList1?: { item: string; description: string }[];
  keyFeaturesRotate?: string;
  servicesWDescList?: { item: string; description: string }[];
  transformingSupplyChains?: string;
  ourCommitment?: string;
  contactUs?: string;
}
