import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-image-with-content',
  templateUrl: './image-with-content.component.html',
  styleUrls: ['./image-with-content.component.scss'],
})
export class ImageWithContentComponent implements OnInit {
  @Input() imageSrc: string = '';
  @Input() imageTop: string = '';
  @Input() imagePosition: 'left' | 'right' = 'left';

  constructor() {}
  ngOnInit() {}
}
