import { Component, OnInit } from '@angular/core';
import { videos } from '../data';
import { MobileAppLocalization, MobileAppLocalizationClass } from './mobile-app.localization';
import { LocalizationService } from 'app/services/localization.service';
import { ScreenService } from 'app/services/screen.service';

@Component({
  selector: 'app-mobile-app',
  templateUrl: './mobile-app.component.html',
  styleUrls: ['./mobile-app.component.scss'],
})
export class TechnologyMobileAppComponent implements OnInit {
  public localizedText: MobileAppLocalizationClass;
  public isMobile: boolean = false;
  videos = videos;
  img_srcs = [
    'assets/images/Technology/Mobile/Mobile1.png',
    'assets/images/Technology/Mobile/Mobile2.png',
    'assets/images/Technology/Mobile/Mobile3.png',
    'assets/images/Technology/Mobile/Mobile4.png',
  ];
  featuresList: {
    item: string;
    subtitle: string;
    description: string;
    imgSrc: string;
  }[];

  constructor(private localizationService: LocalizationService, private screenService: ScreenService) {
    this.localizedText = this.localizationService.getTranslation(MobileAppLocalization);
    this.isMobile = this.screenService.isMobile();
  }

  ngOnInit(): void {}
}
