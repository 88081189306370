const material_url =
  'https://clrfwebservicestorage.blob.core.windows.net/clearfreight-dev-website/'; //http://localhost:6080/
const imgage_url =
  'https://clrfwebservicestorage.blob.core.windows.net/newhome-dev';
export const article_list = [
  {
    id: '1',
    title: 'Lorem Ipsum',
    name: 'test2',
    imgUrl:
      '/Template%201.0/Related%20Articles/CF-Related-Article-Image-1.0.jpg',
  },
  {
    id: '2',
    title: 'Lorem Ipsum',
    name: 'test2',
    imgUrl:
      '/Template%201.0/Related%20Articles/CF-Related-Article-Image-2.0.jpg',
  },
  {
    id: '3',
    title: 'Lorem Ipsum',
    name: 'test2',
    imgUrl:
      '/Template%201.0/Related%20Articles/CF-Related-Article-Image-3.0.jpg',
  },
  {
    id: '4',
    title: 'Lorem Ipsum',
    name: 'test2',
    imgUrl:
      '/Template%201.0/Related%20Articles/CF-Related-Article-Image-2.0.jpg',
  },
  {
    id: '5',
    title: 'Lorem Ipsum',
    name: 'test2',
    imgUrl:
      '/Template%201.0/Related%20Articles/CF-Related-Article-Image-2.0.jpg',
  },
  {
    id: '6',
    title: 'Lorem Ipsum',
    name: 'test2',
    imgUrl:
      '/Template%201.0/Related%20Articles/CF-Related-Article-Image-1.0.jpg',
  },
  {
    id: '7',
    title: 'Lorem Ipsum',
    name: 'test2',
    imgUrl:
      '/Template%201.0/Related%20Articles/CF-Related-Article-Image-2.0.jpg',
  },
  {
    id: '8',
    title: 'Lorem Ipsum',
    name: 'test2',
    imgUrl:
      '/Template%201.0/Related%20Articles/CF-Related-Article-Image-3.0.jpg',
  },
  {
    id: '9',
    title: 'Lorem Ipsum',
    name: 'test2',
    imgUrl:
      '/Template%201.0/Related%20Articles/CF-Related-Article-Image-2.0.jpg',
  },
  {
    id: '10',
    title: 'Lorem Ipsum',
    name: 'test2',
    imgUrl:
      '/Template%201.0/Related%20Articles/CF-Related-Article-Image-2.0.jpg',
  },
  {
    id: '11',
    title: 'Lorem Ipsum',
    name: 'test2',
    imgUrl:
      '/Template%201.0/Related%20Articles/CF-Related-Article-Image-1.0.jpg',
  },
  {
    id: '12',
    title: 'Lorem Ipsum',
    name: 'test2',
    imgUrl:
      '/Template%201.0/Related%20Articles/CF-Related-Article-Image-2.0.jpg',
  },
];

export const services_data = [
  {
    title: 'SERVICE',
    desc: 'Lorem ipsum dolor sit amet,     consectetur adipiscing elit, sed     do eiusmod tempor incididunt ut    labore et dolore magna aliqua.',
    link: '',
  },
  {
    title: 'SERVICE',
    desc: 'Lorem ipsum dolor sit amet,     consectetur adipiscing elit, sed     do eiusmod tempor incididunt ut     labore et dolore magna aliqua.',
    link: '',
  },
  {
    title: 'SERVICE',
    desc: 'Lorem ipsum dolor sit amet,     consectetur adipiscing elit, sed     do eiusmod tempor incididunt ut    labore et dolore magna aliqua.',
    link: '',
  },
];
