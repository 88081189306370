import { Localization } from 'app/share/data-type/common.types';

export const CustomIntegrationsLocalization: {
  [key: string]: CustomIntegrationsLocalizationClass;
} = {
  en: {},
  es: {},
  cn: {},
  jp: {},
  th: {},
  nl: {},
};

export interface CustomIntegrationsLocalizationClass extends Localization {}
