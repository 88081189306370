import { Localization } from 'app/share/data-type/common.types';

export const ServicesRoadLocalization: {
  [key: string]: ServicesRoadLocalizationClass;
} = {
  en: {
    subTitle: 'Transportation',
    title: 'Road Transportation Services',
    description1:
      'ClearFreight is your trusted partner for seamless road transportation solutions, designed to meet the diverse needs of your supply chain. Our road services are tailored for efficiency, reliability, and flexibility, ensuring your cargo reaches its destination on time. Discover the advantages of choosing ClearFreight for your road transportation requirements: ',
    description2: `ClearFreight's road services redefine reliability, flexibility, and efficiency in transportation. From coast to coast, we are committed to delivering your cargo with precision and timeliness. Choose ClearFreight as your partner for innovative road transportation solutions. `,
    description3:
      'No matter how large or small your road transportation needs are, ClearFreight will provide the right-fit solution for you. Our experience gives our customers high-quality transportation services by utilizing the freight tracing system, which updates the location of the cargo, and various GPS systems, etc., which monitor the transportation status of freight, to provide high-quality transportation services to any destination in the USA.',
    services: 'Our road services include:',
    servicesList: [
      'Nationwide LTL, FTL truck',
      'Specialized Transportation: Flatbeds, Open-Deck. Air-Ride',
      'Nationwide intermodal service',
      'All sizes of vans and containers',
      'Transloading',
      'Team drivers and Time Critical (Expedited) Services',
      'Local pickup and delivery',
      'White glove service',
      'Cross Border service',
    ],
    itemDescList: [
      {
        item: `Nationwide Coverage:`,
        description: `Benefit from our extensive network that spans across the nation. ClearFreight provides comprehensive road transportation services, connecting key locations to support your domestic logistics needs.`,
      },
      {
        item: `Last-Mile and First-Mile Logistics:`,
        description: `Whether it's the critical "last-mile" or "first-mile," ClearFreight has the expertise and resources to manage the initial and final stages of your cargo's journey. Experience reliable and timely deliveries right to your doorstep.`,
      },
      {
        item: `LTL (Less-Than-Truckload) and FTL (Full-Truckload) Options:`,
        description: `Tailor your shipping strategy with our flexible options. ClearFreight offers both LTL and FTL services, allowing you to choose the mode that best suits the size and urgency of your shipments.`,
      },
      {
        item: `Dedicated Trucks – Single and Team Drivers:`,
        description: `Optimize your transportation efficiency with ClearFreight's dedicated truck services. Choose between single or team drivers to ensure timely deliveries and maximize the utilization of transport capacity.`,
      },
      {
        item: `Specialized Cargo Handling:`,
        description: `Trust ClearFreight to handle your specialized cargo with care. From delicate items to hazardous materials, our road services are equipped to handle a diverse range of shipments securely.`,
      },
      {
        item: `Cross Docking:`,
        description: `Streamline your supply chain with our cross-docking services. ClearFreight facilitates the efficient transfer of goods between trucks, reducing handling time and improving overall logistics efficiency.`,
      },
      {
        item: `Local Container Drayage:`,
        description: `Seamlessly connect your ocean shipments to the final destination with our local container drayage services. ClearFreight ensures a smooth transition from port to local delivery points.`,
      },
      {
        item: `Door Delivery Service:`,
        description: `Experience the convenience of door-to-door service. ClearFreight's road services extend to door delivery, providing end-to-end solutions for your transportation needs.`,
      },
      {
        item: `Drop-Ship Ocean Container Delivery:`,
        description: `ClearFreight simplifies the logistics chain with drop-ship ocean container delivery. Enhance your shipping strategy by seamlessly connecting ocean transport to final destinations.`,
      },
    ],
  },
  es: {},
  jp: {},
  cn: {},
  th: {},
  nl: {},
};

export interface ServicesRoadLocalizationClass extends Localization {
  itemDescList?: { item: string; description: string }[];
}
