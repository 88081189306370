import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FlagService {
  public countryCodeToPNGMap: Map<string, string> = new Map();

  constructor() {
    this.setCountryCodeMap();
  }

  public getFlag(countryCode: string): string {
    if (countryCode) {
      countryCode = countryCode.substring(0, 2).toUpperCase();
      const flag = this.countryCodeToPNGMap.get(countryCode);
      return flag ? flag : 'united-nations';
    } else {
      return 'united-nations';
    }
  }

  private setCountryCodeMap(): void {
    /// 2 Letter Codes
    this.countryCodeToPNGMap.set('AF', 'afghanistan');
    this.countryCodeToPNGMap.set('AL', 'albania');
    this.countryCodeToPNGMap.set('DZ', 'algeria');
    this.countryCodeToPNGMap.set('AS', 'american-samoa');
    this.countryCodeToPNGMap.set('AD', 'andorra');
    this.countryCodeToPNGMap.set('AO', 'angola');
    this.countryCodeToPNGMap.set('AI', 'anguilla');
    // this.countryCodeToPNGMap.set('AQ', 'antarctica'); // NO FLAG EXISTS
    this.countryCodeToPNGMap.set('AG', 'antigua-and-barbuda');
    this.countryCodeToPNGMap.set('AR', 'argentina');
    this.countryCodeToPNGMap.set('AM', 'armenia');
    this.countryCodeToPNGMap.set('AW', 'aruba');
    this.countryCodeToPNGMap.set('AU', 'australia');
    this.countryCodeToPNGMap.set('AT', 'austria');
    this.countryCodeToPNGMap.set('AX', 'aland-islands'); // Not recognized as a country?
    this.countryCodeToPNGMap.set('AZ', 'azerbaijan');
    this.countryCodeToPNGMap.set('BS', 'bahamas');
    this.countryCodeToPNGMap.set('BH', 'bahrain');
    this.countryCodeToPNGMap.set('BD', 'bangladesh');
    this.countryCodeToPNGMap.set('BB', 'barbados');
    this.countryCodeToPNGMap.set('BY', 'belarus');
    this.countryCodeToPNGMap.set('BE', 'belgium');
    this.countryCodeToPNGMap.set('BZ', 'belize');
    this.countryCodeToPNGMap.set('BJ', 'benin');
    this.countryCodeToPNGMap.set('BM', 'bermuda');
    this.countryCodeToPNGMap.set('BT', 'bhutan');
    this.countryCodeToPNGMap.set('BO', 'bolivia');
    this.countryCodeToPNGMap.set('BQ', 'bonaire');
    this.countryCodeToPNGMap.set('BA', 'bosnia-and-herzegovina');
    this.countryCodeToPNGMap.set('BW', 'botswana');
    this.countryCodeToPNGMap.set('BV', 'norway'); // Bouvet Island
    this.countryCodeToPNGMap.set('BR', 'brazil');
    this.countryCodeToPNGMap.set('IO', 'british-indian-ocean-territory');
    this.countryCodeToPNGMap.set('BN', 'brunei');
    this.countryCodeToPNGMap.set('BG', 'bulgaria');
    this.countryCodeToPNGMap.set('BF', 'burkina-faso');
    this.countryCodeToPNGMap.set('BI', 'burundi');
    this.countryCodeToPNGMap.set('CV', 'cape-verde'); // Cabo Verde
    this.countryCodeToPNGMap.set('KH', 'cambodia');
    this.countryCodeToPNGMap.set('CM', 'cameroon');
    this.countryCodeToPNGMap.set('CA', 'canada');
    this.countryCodeToPNGMap.set('KY', 'cayman-islands');
    this.countryCodeToPNGMap.set('CF', 'central-african-republic');
    this.countryCodeToPNGMap.set('TD', 'chad');
    this.countryCodeToPNGMap.set('CL', 'chile');
    this.countryCodeToPNGMap.set('CN', 'china');
    this.countryCodeToPNGMap.set('CX', 'christmas-island');
    this.countryCodeToPNGMap.set('CC', 'cocos-islands');
    this.countryCodeToPNGMap.set('CO', 'colombia');
    this.countryCodeToPNGMap.set('KM', 'comoros');
    this.countryCodeToPNGMap.set('CD', 'democratic-republic-of-congo');
    this.countryCodeToPNGMap.set('CG', 'republic-of-the-congo');
    this.countryCodeToPNGMap.set('CK', 'cook-islands');
    this.countryCodeToPNGMap.set('CR', 'costa-rica');
    this.countryCodeToPNGMap.set('HR', 'croatia');
    this.countryCodeToPNGMap.set('CU', 'cuba');
    this.countryCodeToPNGMap.set('CW', 'curacao');
    this.countryCodeToPNGMap.set('CY', 'cyprus');
    this.countryCodeToPNGMap.set('CZ', 'czech-republic'); // Czechia
    this.countryCodeToPNGMap.set('CI', 'ivory-coast'); // Côte d'Ivoire
    this.countryCodeToPNGMap.set('DK', 'denmark');
    this.countryCodeToPNGMap.set('DJ', 'djibouti');
    this.countryCodeToPNGMap.set('DM', 'dominica');
    this.countryCodeToPNGMap.set('DO', 'dominican-republic');
    this.countryCodeToPNGMap.set('EC', 'ecuador');
    this.countryCodeToPNGMap.set('EG', 'egypt');
    this.countryCodeToPNGMap.set('SV', 'salvador'); // El Salvador
    this.countryCodeToPNGMap.set('GQ', 'equatorial-guinea');
    this.countryCodeToPNGMap.set('ER', 'eritrea');
    this.countryCodeToPNGMap.set('EE', 'estonia');
    this.countryCodeToPNGMap.set('SZ', 'swaziland'); // Eswatini (renamed from Swaziland in 2018)
    this.countryCodeToPNGMap.set('ET', 'ethiopia');
    this.countryCodeToPNGMap.set('FK', 'falkland-islands');
    this.countryCodeToPNGMap.set('FO', 'faroe-islands');
    this.countryCodeToPNGMap.set('FJ', 'fiji');
    this.countryCodeToPNGMap.set('FI', 'finland');
    this.countryCodeToPNGMap.set('FR', 'france');
    this.countryCodeToPNGMap.set('GF', 'france'); // French Guiana
    this.countryCodeToPNGMap.set('PF', 'french-polynesia');
    this.countryCodeToPNGMap.set('TF', 'france'); // French Southern Territories
    this.countryCodeToPNGMap.set('GA', 'gabon');
    this.countryCodeToPNGMap.set('GM', 'gambia');
    this.countryCodeToPNGMap.set('GE', 'georgia');
    this.countryCodeToPNGMap.set('DE', 'germany');
    this.countryCodeToPNGMap.set('GH', 'ghana');
    this.countryCodeToPNGMap.set('GI', 'gibraltar');
    this.countryCodeToPNGMap.set('GR', 'greece');
    this.countryCodeToPNGMap.set('GL', 'greenland');
    this.countryCodeToPNGMap.set('GD', 'grenada');
    this.countryCodeToPNGMap.set('GP', 'france'); // Guadeloupe
    this.countryCodeToPNGMap.set('GU', 'guam');
    this.countryCodeToPNGMap.set('GT', 'guatemala');
    this.countryCodeToPNGMap.set('GG', 'guernsey');
    this.countryCodeToPNGMap.set('GN', 'guinea');
    this.countryCodeToPNGMap.set('GW', 'guinea-bissau');
    this.countryCodeToPNGMap.set('GY', 'guyana');
    this.countryCodeToPNGMap.set('HT', 'haiti');
    this.countryCodeToPNGMap.set('HM', 'australia'); // Heard Island and McDonald Islands
    this.countryCodeToPNGMap.set('VA', 'vatican-city');
    this.countryCodeToPNGMap.set('HN', 'honduras');
    this.countryCodeToPNGMap.set('HK', 'hong-kong');
    this.countryCodeToPNGMap.set('HU', 'hungary');
    this.countryCodeToPNGMap.set('IS', 'iceland');
    this.countryCodeToPNGMap.set('IN', 'india');
    this.countryCodeToPNGMap.set('ID', 'indonesia');
    this.countryCodeToPNGMap.set('IR', 'iran');
    this.countryCodeToPNGMap.set('IQ', 'iraq');
    this.countryCodeToPNGMap.set('IE', 'ireland');
    this.countryCodeToPNGMap.set('IM', 'isle-of-man');
    this.countryCodeToPNGMap.set('IL', 'israel');
    this.countryCodeToPNGMap.set('IT', 'italy');
    this.countryCodeToPNGMap.set('JM', 'jamaica');
    this.countryCodeToPNGMap.set('JP', 'japan');
    this.countryCodeToPNGMap.set('JE', 'jersey');
    this.countryCodeToPNGMap.set('JO', 'jordan');
    this.countryCodeToPNGMap.set('KZ', 'kazakhstan');
    this.countryCodeToPNGMap.set('KE', 'kenya');
    this.countryCodeToPNGMap.set('KI', 'kiribati');
    this.countryCodeToPNGMap.set('KP', 'north-korea');
    this.countryCodeToPNGMap.set('KR', 'south-korea');
    this.countryCodeToPNGMap.set('KW', 'kuwait');
    this.countryCodeToPNGMap.set('KG', 'kyrgyzstan');
    this.countryCodeToPNGMap.set('LA', 'laos');
    this.countryCodeToPNGMap.set('LV', 'latvia');
    this.countryCodeToPNGMap.set('LB', 'lebanon');
    this.countryCodeToPNGMap.set('LS', 'lesotho');
    this.countryCodeToPNGMap.set('LR', 'liberia');
    this.countryCodeToPNGMap.set('LY', 'libya');
    this.countryCodeToPNGMap.set('LI', 'liechtenstein');
    this.countryCodeToPNGMap.set('LT', 'lithuania');
    this.countryCodeToPNGMap.set('LU', 'luxembourg');
    this.countryCodeToPNGMap.set('MO', 'macao');
    this.countryCodeToPNGMap.set('MG', 'madagascar');
    this.countryCodeToPNGMap.set('MW', 'malawi');
    this.countryCodeToPNGMap.set('MY', 'malaysia');
    this.countryCodeToPNGMap.set('MV', 'maldives');
    this.countryCodeToPNGMap.set('ML', 'mali');
    this.countryCodeToPNGMap.set('MT', 'malta');
    this.countryCodeToPNGMap.set('MH', 'marshall-island');
    this.countryCodeToPNGMap.set('MQ', 'martinique');
    this.countryCodeToPNGMap.set('MR', 'mauritania');
    this.countryCodeToPNGMap.set('MU', 'mauritius');
    this.countryCodeToPNGMap.set('YT', 'france'); // Mayotte
    this.countryCodeToPNGMap.set('MX', 'mexico');
    this.countryCodeToPNGMap.set('FM', 'micronesia'); // Federated States of Micronesia
    this.countryCodeToPNGMap.set('MD', 'moldova');
    this.countryCodeToPNGMap.set('MC', 'monaco');
    this.countryCodeToPNGMap.set('MN', 'mongolia');
    this.countryCodeToPNGMap.set('ME', 'montenegro');
    this.countryCodeToPNGMap.set('MS', 'montserrat');
    this.countryCodeToPNGMap.set('MA', 'morocco');
    this.countryCodeToPNGMap.set('MZ', 'mozambique');
    this.countryCodeToPNGMap.set('MM', 'myanmar');
    this.countryCodeToPNGMap.set('NA', 'namibia');
    this.countryCodeToPNGMap.set('NR', 'nauru');
    this.countryCodeToPNGMap.set('NP', 'nepal');
    this.countryCodeToPNGMap.set('NL', 'netherlands');
    this.countryCodeToPNGMap.set('NC', 'france'); // New Caledonia
    this.countryCodeToPNGMap.set('NZ', 'new-zealand');
    this.countryCodeToPNGMap.set('NI', 'nicaragua');
    this.countryCodeToPNGMap.set('NE', 'niger');
    this.countryCodeToPNGMap.set('NG', 'nigeria');
    this.countryCodeToPNGMap.set('NU', 'niue');
    this.countryCodeToPNGMap.set('NF', 'norfolk-island');
    this.countryCodeToPNGMap.set('MP', 'northern-marianas-islands');
    this.countryCodeToPNGMap.set('NO', 'norway');
    this.countryCodeToPNGMap.set('OM', 'oman');
    this.countryCodeToPNGMap.set('PK', 'pakistan');
    this.countryCodeToPNGMap.set('PW', 'palau');
    this.countryCodeToPNGMap.set('PS', 'palestine');
    this.countryCodeToPNGMap.set('PA', 'panama');
    this.countryCodeToPNGMap.set('PG', 'papua-new-guinea');
    this.countryCodeToPNGMap.set('PY', 'paraguay');
    this.countryCodeToPNGMap.set('PE', 'peru');
    this.countryCodeToPNGMap.set('PH', 'philippines');
    this.countryCodeToPNGMap.set('PN', 'pitcairn-islands');
    this.countryCodeToPNGMap.set('PL', 'republic-of-poland');
    this.countryCodeToPNGMap.set('PT', 'portugal');
    this.countryCodeToPNGMap.set('PR', 'puerto-rico');
    this.countryCodeToPNGMap.set('QA', 'qatar');
    this.countryCodeToPNGMap.set('MK', 'republic-of-macedonia');
    this.countryCodeToPNGMap.set('RO', 'romania');
    this.countryCodeToPNGMap.set('RU', 'russia');
    this.countryCodeToPNGMap.set('RW', 'rwanda');
    this.countryCodeToPNGMap.set('RE', 'france'); // Réunion
    this.countryCodeToPNGMap.set('BL', 'france'); // Saint Barthelemy
    this.countryCodeToPNGMap.set('SH', 'united-kingdom'); // Saint Helena
    this.countryCodeToPNGMap.set('KN', 'saint-kitts-and-nevis');
    this.countryCodeToPNGMap.set('LC', 'st-lucia');
    this.countryCodeToPNGMap.set('MF', 'france'); // Saint Martin
    // this.countryCodeToPNGMap.set('PM', 'Saint Pierre and Miquelon'); // NO FLAG EXISTS
    this.countryCodeToPNGMap.set('VC', 'st-vincent-and-the-grenadines');
    this.countryCodeToPNGMap.set('WS', 'samoa');
    this.countryCodeToPNGMap.set('SM', 'san-marino');
    this.countryCodeToPNGMap.set('ST', 'sao-tome-and-principe');
    this.countryCodeToPNGMap.set('SA', 'saudi-arabia');
    this.countryCodeToPNGMap.set('SN', 'senegal');
    this.countryCodeToPNGMap.set('RS', 'serbia');
    this.countryCodeToPNGMap.set('SC', 'seychelles');
    this.countryCodeToPNGMap.set('SL', 'sierra-leone');
    this.countryCodeToPNGMap.set('SG', 'singapore');
    this.countryCodeToPNGMap.set('SX', 'sint-maarten');
    this.countryCodeToPNGMap.set('SK', 'slovakia');
    this.countryCodeToPNGMap.set('SI', 'slovenia');
    this.countryCodeToPNGMap.set('SB', 'solomon-islands');
    this.countryCodeToPNGMap.set('SO', 'somalia');
    this.countryCodeToPNGMap.set('ZA', 'south-africa');
    // this.countryCodeToPNGMap.set('GS', 'south Georgia and the South Sandwich Islands'); // NO FLAG EXISTS
    this.countryCodeToPNGMap.set('SS', 'south-sudan');
    this.countryCodeToPNGMap.set('ES', 'spain');
    this.countryCodeToPNGMap.set('LK', 'sri-lanka');
    this.countryCodeToPNGMap.set('SD', 'sudan');
    this.countryCodeToPNGMap.set('SR', 'suriname');
    this.countryCodeToPNGMap.set('SJ', 'norway'); // Svalbard and Jan Mayen
    this.countryCodeToPNGMap.set('SE', 'sweden');
    this.countryCodeToPNGMap.set('CH', 'switzerland');
    this.countryCodeToPNGMap.set('SY', 'syrian'); // Syrian Arab Republic
    this.countryCodeToPNGMap.set('TW', 'taiwan');
    this.countryCodeToPNGMap.set('TJ', 'tajikistan');
    this.countryCodeToPNGMap.set('TZ', 'tanzania');
    this.countryCodeToPNGMap.set('TH', 'thailand');
    this.countryCodeToPNGMap.set('TL', 'east-timor'); // Timor-Leste
    this.countryCodeToPNGMap.set('TG', 'togo');
    this.countryCodeToPNGMap.set('TK', 'tokelau');
    this.countryCodeToPNGMap.set('TO', 'tonga');
    this.countryCodeToPNGMap.set('TT', 'trinidad-and-tobago');
    this.countryCodeToPNGMap.set('TN', 'tunisia');
    this.countryCodeToPNGMap.set('TR', 'turkey');
    this.countryCodeToPNGMap.set('TM', 'turkmenistan');
    this.countryCodeToPNGMap.set('TC', 'turks-and-caicos');
    this.countryCodeToPNGMap.set('TV', 'tuvalu');
    this.countryCodeToPNGMap.set('UG', 'uganda');
    this.countryCodeToPNGMap.set('UA', 'ukraine');
    this.countryCodeToPNGMap.set('AE', 'united-arab-emirates');
    this.countryCodeToPNGMap.set('GB', 'united-kingdom');
    this.countryCodeToPNGMap.set('UM', 'united-states'); // United States Minor Outlying Islands
    this.countryCodeToPNGMap.set('US', 'united-states');
    this.countryCodeToPNGMap.set('UY', 'uruguay');
    this.countryCodeToPNGMap.set('UZ', 'uzbekistan');
    this.countryCodeToPNGMap.set('VU', 'vanuatu');
    this.countryCodeToPNGMap.set('VE', 'venezuela');
    this.countryCodeToPNGMap.set('VN', 'vietnam');
    this.countryCodeToPNGMap.set('VG', 'british-virgin-islands');
    this.countryCodeToPNGMap.set('VI', 'virgin-islands');
    this.countryCodeToPNGMap.set('WF', 'france'); // Wallis and Futuna
    this.countryCodeToPNGMap.set('EH', 'western-sahara');
    this.countryCodeToPNGMap.set('YE', 'yemen');
    this.countryCodeToPNGMap.set('ZM', 'zambia');
    this.countryCodeToPNGMap.set('ZW', 'zimbabwe');
    this.countryCodeToPNGMap.set('XK', 'kosovo');

    /// 3 Letter Codes
    this.countryCodeToPNGMap.set('AFG', 'afghanistan');
    this.countryCodeToPNGMap.set('ALB', 'albania');
    this.countryCodeToPNGMap.set('DZA', 'algeria');
    this.countryCodeToPNGMap.set('ASM', 'american-samoa');
    this.countryCodeToPNGMap.set('AND', 'andorra');
    this.countryCodeToPNGMap.set('AGO', 'angola');
    this.countryCodeToPNGMap.set('AIA', 'anguilla');
    // this.countryCodeToPNGMap.set('ATA', 'antarctica'); // NO FLAG EXISTS
    this.countryCodeToPNGMap.set('ATG', 'antigua-and-barbuda');
    this.countryCodeToPNGMap.set('ARG', 'argentina');
    this.countryCodeToPNGMap.set('ARM', 'armenia');
    this.countryCodeToPNGMap.set('ABW', 'aruba');
    this.countryCodeToPNGMap.set('AUS', 'australia');
    this.countryCodeToPNGMap.set('AUT', 'austria');
    this.countryCodeToPNGMap.set('ALA', 'aland-islands'); // Not recognized as a country?
    this.countryCodeToPNGMap.set('AZE', 'azerbaijan');
    this.countryCodeToPNGMap.set('BHS', 'bahamas');
    this.countryCodeToPNGMap.set('BHR', 'bahrain');
    this.countryCodeToPNGMap.set('BGD', 'bangladesh');
    this.countryCodeToPNGMap.set('BRB', 'barbados');
    this.countryCodeToPNGMap.set('BLR', 'belarus');
    this.countryCodeToPNGMap.set('BEL', 'belgium');
    this.countryCodeToPNGMap.set('BLZ', 'belize');
    this.countryCodeToPNGMap.set('BEN', 'benin');
    this.countryCodeToPNGMap.set('BMU', 'bermuda');
    this.countryCodeToPNGMap.set('BTN', 'bhutan');
    this.countryCodeToPNGMap.set('BOL', 'bolivia');
    this.countryCodeToPNGMap.set('BES', 'bonaire');
    this.countryCodeToPNGMap.set('BIH', 'bosnia-and-herzegovina');
    this.countryCodeToPNGMap.set('BWA', 'botswana');
    this.countryCodeToPNGMap.set('BVT', 'norway'); // Bouvet Island
    this.countryCodeToPNGMap.set('BRA', 'brazil');
    this.countryCodeToPNGMap.set('IOT', 'british-indian-ocean-territory');
    this.countryCodeToPNGMap.set('BNN', 'brunei');
    this.countryCodeToPNGMap.set('BGR', 'bulgaria');
    this.countryCodeToPNGMap.set('BFA', 'burkina-faso');
    this.countryCodeToPNGMap.set('BDI', 'burundi');
    this.countryCodeToPNGMap.set('CPV', 'cape-verde'); // Cabo Verde
    this.countryCodeToPNGMap.set('KHM', 'cambodia');
    this.countryCodeToPNGMap.set('CMR', 'cameroon');
    this.countryCodeToPNGMap.set('CAN', 'canada');
    this.countryCodeToPNGMap.set('CYM', 'cayman-islands');
    this.countryCodeToPNGMap.set('CAF', 'central-african-republic');
    this.countryCodeToPNGMap.set('TCD', 'chad');
    this.countryCodeToPNGMap.set('CHL', 'chile');
    this.countryCodeToPNGMap.set('CHN', 'china');
    this.countryCodeToPNGMap.set('CXR', 'christmas-island');
    this.countryCodeToPNGMap.set('CCK', 'cocos-islands');
    this.countryCodeToPNGMap.set('COL', 'colombia');
    this.countryCodeToPNGMap.set('COM', 'comoros');
    this.countryCodeToPNGMap.set('COD', 'democratic-republic-of-congo');
    this.countryCodeToPNGMap.set('COG', 'republic-of-the-congo');
    this.countryCodeToPNGMap.set('COK', 'cook-islands');
    this.countryCodeToPNGMap.set('CRI', 'costa-rica');
    this.countryCodeToPNGMap.set('HRV', 'croatia');
    this.countryCodeToPNGMap.set('CUB', 'cuba');
    this.countryCodeToPNGMap.set('CUW', 'curacao');
    this.countryCodeToPNGMap.set('CYP', 'cyprus');
    this.countryCodeToPNGMap.set('CZE', 'czech-republic'); // Czechia
    this.countryCodeToPNGMap.set('CIV', 'ivory-coast'); // Côte d'Ivoire
    this.countryCodeToPNGMap.set('DNK', 'denmark');
    this.countryCodeToPNGMap.set('DJI', 'djibouti');
    this.countryCodeToPNGMap.set('DMA', 'dominica');
    this.countryCodeToPNGMap.set('DOM', 'dominican-republic');
    this.countryCodeToPNGMap.set('ECU', 'ecuador');
    this.countryCodeToPNGMap.set('EGY', 'egypt');
    this.countryCodeToPNGMap.set('SLV', 'salvador'); // El Salvador
    this.countryCodeToPNGMap.set('GNQ', 'equatorial-guinea');
    this.countryCodeToPNGMap.set('ERI', 'eritrea');
    this.countryCodeToPNGMap.set('EST', 'estonia');
    this.countryCodeToPNGMap.set('SWZ', 'swaziland'); // Eswatini (renamed from Swaziland in 2018)
    this.countryCodeToPNGMap.set('ETH', 'ethiopia');
    this.countryCodeToPNGMap.set('FLK', 'falkland-islands');
    this.countryCodeToPNGMap.set('FRO', 'faroe-islands');
    this.countryCodeToPNGMap.set('FJI', 'fiji');
    this.countryCodeToPNGMap.set('FIN', 'finland');
    this.countryCodeToPNGMap.set('FRA', 'france');
    this.countryCodeToPNGMap.set('GUF', 'france'); // French Guiana
    this.countryCodeToPNGMap.set('PYF', 'french-polynesia');
    this.countryCodeToPNGMap.set('ATF', 'france'); // French Southern Territories
    this.countryCodeToPNGMap.set('GAB', 'gabon');
    this.countryCodeToPNGMap.set('GMB', 'gambia');
    this.countryCodeToPNGMap.set('GEO', 'georgia');
    this.countryCodeToPNGMap.set('DEU', 'germany');
    this.countryCodeToPNGMap.set('GHA', 'ghana');
    this.countryCodeToPNGMap.set('GIB', 'gibraltar');
    this.countryCodeToPNGMap.set('GRC', 'greece');
    this.countryCodeToPNGMap.set('GRL', 'greenland');
    this.countryCodeToPNGMap.set('GRD', 'grenada');
    this.countryCodeToPNGMap.set('GLP', 'france'); // Guadeloupe
    this.countryCodeToPNGMap.set('GUM', 'guam');
    this.countryCodeToPNGMap.set('GTM', 'guatemala');
    this.countryCodeToPNGMap.set('GGY', 'guernsey');
    this.countryCodeToPNGMap.set('GIN', 'guinea');
    this.countryCodeToPNGMap.set('GNB', 'guinea-bissau');
    this.countryCodeToPNGMap.set('GUY', 'guyana');
    this.countryCodeToPNGMap.set('HTI', 'haiti');
    this.countryCodeToPNGMap.set('HMD', 'australia'); // Heard Island and McDonald Islands
    this.countryCodeToPNGMap.set('VAT', 'vatican-city');
    this.countryCodeToPNGMap.set('HND', 'honduras');
    this.countryCodeToPNGMap.set('HKG', 'hong-kong');
    this.countryCodeToPNGMap.set('HUN', 'hungary');
    this.countryCodeToPNGMap.set('ISL', 'iceland');
    this.countryCodeToPNGMap.set('IND', 'india');
    this.countryCodeToPNGMap.set('IDN', 'indonesia');
    this.countryCodeToPNGMap.set('IRN', 'iran');
    this.countryCodeToPNGMap.set('IRQ', 'iraq');
    this.countryCodeToPNGMap.set('IRL', 'ireland');
    this.countryCodeToPNGMap.set('IMN', 'isle-of-man');
    this.countryCodeToPNGMap.set('ISR', 'israel');
    this.countryCodeToPNGMap.set('ITA', 'italy');
    this.countryCodeToPNGMap.set('JAM', 'jamaica');
    this.countryCodeToPNGMap.set('JPN', 'japan');
    this.countryCodeToPNGMap.set('JEY', 'jersey');
    this.countryCodeToPNGMap.set('JOR', 'jordan');
    this.countryCodeToPNGMap.set('KAZ', 'kazakhstan');
    this.countryCodeToPNGMap.set('KEN', 'kenya');
    this.countryCodeToPNGMap.set('KIR', 'kiribati');
    this.countryCodeToPNGMap.set('PRK', 'north-korea');
    this.countryCodeToPNGMap.set('KOR', 'south-korea');
    this.countryCodeToPNGMap.set('KWT', 'kuwait');
    this.countryCodeToPNGMap.set('KGZ', 'kyrgyzstan');
    this.countryCodeToPNGMap.set('LAO', 'laos');
    this.countryCodeToPNGMap.set('LVA', 'latvia');
    this.countryCodeToPNGMap.set('LBN', 'lebanon');
    this.countryCodeToPNGMap.set('LSO', 'lesotho');
    this.countryCodeToPNGMap.set('LBR', 'liberia');
    this.countryCodeToPNGMap.set('LBY', 'libya');
    this.countryCodeToPNGMap.set('LIE', 'liechtenstein');
    this.countryCodeToPNGMap.set('LTU', 'lithuania');
    this.countryCodeToPNGMap.set('LUX', 'luxembourg');
    this.countryCodeToPNGMap.set('MAC', 'macao');
    this.countryCodeToPNGMap.set('MDG', 'madagascar');
    this.countryCodeToPNGMap.set('MWI', 'malawi');
    this.countryCodeToPNGMap.set('MYS', 'malaysia');
    this.countryCodeToPNGMap.set('MDV', 'maldives');
    this.countryCodeToPNGMap.set('MLI', 'mali');
    this.countryCodeToPNGMap.set('MLT', 'malta');
    this.countryCodeToPNGMap.set('MHL', 'marshall-island');
    this.countryCodeToPNGMap.set('MTQ', 'martinique');
    this.countryCodeToPNGMap.set('MRT', 'mauritania');
    this.countryCodeToPNGMap.set('MUS', 'mauritius');
    this.countryCodeToPNGMap.set('MYT', 'france'); // Mayotte
    this.countryCodeToPNGMap.set('MEX', 'mexico');
    this.countryCodeToPNGMap.set('FSM', 'micronesia'); // Federated States of Micronesia
    this.countryCodeToPNGMap.set('MDA', 'moldova');
    this.countryCodeToPNGMap.set('MCO', 'monaco');
    this.countryCodeToPNGMap.set('MNG', 'mongolia');
    this.countryCodeToPNGMap.set('MNE', 'montenegro');
    this.countryCodeToPNGMap.set('MSR', 'montserrat');
    this.countryCodeToPNGMap.set('MAR', 'morocco');
    this.countryCodeToPNGMap.set('MOZ', 'mozambique');
    this.countryCodeToPNGMap.set('MMR', 'myanmar');
    this.countryCodeToPNGMap.set('NAM', 'namibia');
    this.countryCodeToPNGMap.set('NRU', 'nauru');
    this.countryCodeToPNGMap.set('NPL', 'nepal');
    this.countryCodeToPNGMap.set('NLD', 'netherlands');
    this.countryCodeToPNGMap.set('NCL', 'france'); // New Caledonia
    this.countryCodeToPNGMap.set('NZL', 'new-zealand');
    this.countryCodeToPNGMap.set('NIC', 'nicaragua');
    this.countryCodeToPNGMap.set('NER', 'niger');
    this.countryCodeToPNGMap.set('NGA', 'nigeria');
    this.countryCodeToPNGMap.set('NIU', 'niue');
    this.countryCodeToPNGMap.set('NFK', 'norfolk-island');
    this.countryCodeToPNGMap.set('MNP', 'northern-marianas-islands');
    this.countryCodeToPNGMap.set('NOR', 'norway');
    this.countryCodeToPNGMap.set('OMN', 'oman');
    this.countryCodeToPNGMap.set('PAK', 'pakistan');
    this.countryCodeToPNGMap.set('PLW', 'palau');
    this.countryCodeToPNGMap.set('PSE', 'palestine');
    this.countryCodeToPNGMap.set('PAN', 'panama');
    this.countryCodeToPNGMap.set('PNG', 'papua-new-guinea');
    this.countryCodeToPNGMap.set('PRY', 'paraguay');
    this.countryCodeToPNGMap.set('PER', 'peru');
    this.countryCodeToPNGMap.set('PHL', 'philippines');
    this.countryCodeToPNGMap.set('PCN', 'pitcairn-islands');
    this.countryCodeToPNGMap.set('POL', 'republic-of-poland');
    this.countryCodeToPNGMap.set('PRT', 'portugal');
    this.countryCodeToPNGMap.set('PRI', 'puerto-rico');
    this.countryCodeToPNGMap.set('QAT', 'qatar');
    this.countryCodeToPNGMap.set('MKD', 'republic-of-macedonia');
    this.countryCodeToPNGMap.set('ROU', 'romania');
    this.countryCodeToPNGMap.set('RUS', 'russia');
    this.countryCodeToPNGMap.set('RWA', 'rwanda');
    this.countryCodeToPNGMap.set('REU', 'france'); // Réunion
    this.countryCodeToPNGMap.set('BLM', 'france'); // Saint Barthelemy
    this.countryCodeToPNGMap.set('SHN', 'united-kingdom'); // Saint Helena
    this.countryCodeToPNGMap.set('KNA', 'saint-kitts-and-nevis');
    this.countryCodeToPNGMap.set('LCA', 'st-lucia');
    this.countryCodeToPNGMap.set('MAF', 'france'); // Saint Martin
    // this.countryCodeToPNGMap.set('SPM', 'Saint Pierre and Miquelon'); // NO FLAG EXISTS
    this.countryCodeToPNGMap.set('VCT', 'st-vincent-and-the-grenadines');
    this.countryCodeToPNGMap.set('WSM', 'samoa');
    this.countryCodeToPNGMap.set('SMR', 'san-marino');
    this.countryCodeToPNGMap.set('STP', 'sao-tome-and-principe');
    this.countryCodeToPNGMap.set('SAU', 'saudi-arabia');
    this.countryCodeToPNGMap.set('SEN', 'senegal');
    this.countryCodeToPNGMap.set('SRB', 'serbia');
    this.countryCodeToPNGMap.set('SYC', 'seychelles');
    this.countryCodeToPNGMap.set('SLE', 'sierra-leone');
    this.countryCodeToPNGMap.set('SGP', 'singapore');
    this.countryCodeToPNGMap.set('SXM', 'sint-maarten');
    this.countryCodeToPNGMap.set('SVK', 'slovakia');
    this.countryCodeToPNGMap.set('SVN', 'slovenia');
    this.countryCodeToPNGMap.set('SLB', 'solomon-islands');
    this.countryCodeToPNGMap.set('SOM', 'somalia');
    this.countryCodeToPNGMap.set('ZAF', 'south-africa');
    // this.countryCodeToPNGMap.set('SGS', 'south Georgia and the South Sandwich Islands'); // NO FLAG EXISTS
    this.countryCodeToPNGMap.set('SSD', 'south-sudan');
    this.countryCodeToPNGMap.set('ESP', 'spain');
    this.countryCodeToPNGMap.set('LKA', 'sri-lanka');
    this.countryCodeToPNGMap.set('SDN', 'sudan');
    this.countryCodeToPNGMap.set('SUR', 'suriname');
    this.countryCodeToPNGMap.set('SJM', 'norway'); // Svalbard and Jan Mayen
    this.countryCodeToPNGMap.set('SWE', 'sweden');
    this.countryCodeToPNGMap.set('CHE', 'switzerland');
    this.countryCodeToPNGMap.set('SYR', 'syrian'); // Syrian Arab Republic
    this.countryCodeToPNGMap.set('TWN', 'taiwan');
    this.countryCodeToPNGMap.set('TJK', 'tajikistan');
    this.countryCodeToPNGMap.set('TZA', 'tanzania');
    this.countryCodeToPNGMap.set('THA', 'thailand');
    this.countryCodeToPNGMap.set('TLS', 'east-timor'); // Timor-Leste
    this.countryCodeToPNGMap.set('TGO', 'togo');
    this.countryCodeToPNGMap.set('TKL', 'tokelau');
    this.countryCodeToPNGMap.set('TON', 'tonga');
    this.countryCodeToPNGMap.set('TTO', 'trinidad-and-tobago');
    this.countryCodeToPNGMap.set('TUN', 'tunisia');
    this.countryCodeToPNGMap.set('TUR', 'turkey');
    this.countryCodeToPNGMap.set('TKM', 'turkmenistan');
    this.countryCodeToPNGMap.set('TCA', 'turks-and-caicos');
    this.countryCodeToPNGMap.set('TUV', 'tuvalu');
    this.countryCodeToPNGMap.set('UGA', 'uganda');
    this.countryCodeToPNGMap.set('UKR', 'ukraine');
    this.countryCodeToPNGMap.set('ARE', 'united-arab-emirates');
    this.countryCodeToPNGMap.set('GBR', 'united-kingdom');
    this.countryCodeToPNGMap.set('UMI', 'united-states'); // United States Minor Outlying Islands
    this.countryCodeToPNGMap.set('USA', 'united-states');
    this.countryCodeToPNGMap.set('URY', 'uruguay');
    this.countryCodeToPNGMap.set('UZB', 'uzbekistan');
    this.countryCodeToPNGMap.set('VUT', 'vanuatu');
    this.countryCodeToPNGMap.set('VEN', 'venezuela');
    this.countryCodeToPNGMap.set('VNM', 'vietnam');
    this.countryCodeToPNGMap.set('VGB', 'british-virgin-islands');
    this.countryCodeToPNGMap.set('VIR', 'virgin-islands');
    this.countryCodeToPNGMap.set('WLF', 'france'); // Wallis and Futuna
    this.countryCodeToPNGMap.set('ESH', 'western-sahara');
    this.countryCodeToPNGMap.set('YEM', 'yemen');
    this.countryCodeToPNGMap.set('ZMB', 'zambia');
    this.countryCodeToPNGMap.set('ZWE', 'zimbabwe');
    this.countryCodeToPNGMap.set('RKS', 'kosovo');
  }
}
