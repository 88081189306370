<div class="industry">
  <app-sub-header
    src="./assets/images/Services/Logistics/CF-Warehousing.jpg"
    top="-500px"
  ></app-sub-header>
  <div class="manufacturing">
    <div class="body">
      <h3>{{ localizedText.subTitle }}</h3>
      <h1>{{ localizedText.title }}</h1>
      <div class="paragraph-spec-text">{{ localizedText.description1 }}</div>
      <div class="text">{{ localizedText.description2 }}</div>
      <h2>{{ localizedText.services }}</h2>
      <div>
        <app-item-list
          [item_data]="localizedText.servicesList ?? []"
        ></app-item-list>
      </div>
      <!-- <app-related-service [services_data]="services_data"></app-related-service> -->
    </div>

    <div class="related">
      <!-- <app-related-article [article_service_data]="article_service_data"></app-related-article> -->
      <div
        [ngStyle]="{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }"
      >
        {{ localizedText.outro }}
        <app-contact-button
          [ngStyle]="{ marginTop: '50px' }"
        ></app-contact-button>
      </div>
    </div>
  </div>
</div>
