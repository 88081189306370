// export const coverage_data = [  // All Countries
//   'AF',
//   'AX',
//   'AL',
//   'DZ',
//   'AS',
//   'AD',
//   'AO',
//   'AI',
//   'AG',
//   'AR',
//   'AM',
//   'AW',
//   'AU',
//   'AT',
//   'AZ',
//   'BS',
//   'BH',
//   'BD',
//   'BB',
//   'BY',
//   'BE',
//   'BZ',
//   'BJ',
//   'BM',
//   'BT',
//   'BO',
//   'BQ',
//   'BA',
//   'BW',
//   'BV',
//   'BR',
//   'IO',
//   'BN',
//   'BG',
//   'BF',
//   'BI',
//   'CV',
//   'KH',
//   'CM',
//   'CA',
//   'KY',
//   'CF',
//   'TD',
//   'CL',
//   'CN',
//   'CX',
//   'CC',
//   'CO',
//   'KM',
//   'CG',
//   'CD',
//   'CK',
//   'CR',
//   'CI',
//   'HR',
//   'CU',
//   'CW',
//   'CY',
//   'CZ',
//   'DK',
//   'DJ',
//   'DM',
//   'DO',
//   'EC',
//   'EG',
//   'SV',
//   'GQ',
//   'ER',
//   'EE',
//   'SZ',
//   'ET',
//   'FK',
//   'FO',
//   'FJ',
//   'FI',
//   'FR',
//   'GF',
//   'PF',
//   'TF',
//   'GA',
//   'GM',
//   'GE',
//   'DE',
//   'GH',
//   'GI',
//   'GR',
//   'GL',
//   'GD',
//   'GP',
//   'GU',
//   'GT',
//   'GG',
//   'GN',
//   'GW',
//   'GY',
//   'HT',
//   'HM',
//   'VA',
//   'HN',
//   'HK',
//   'HU',
//   'IS',
//   'IN',
//   'ID',
//   'IR',
//   'IQ',
//   'IE',
//   'IM',
//   'IL',
//   'IT',
//   'JM',
//   'JP',
//   'JE',
//   'JO',
//   'KZ',
//   'KE',
//   'KI',
//   'KP',
//   'KR',
//   'KW',
//   'KG',
//   'LA',
//   'LV',
//   'LB',
//   'LS',
//   'LR',
//   'LY',
//   'LI',
//   'LT',
//   'LU',
//   'MO',
//   'MK',
//   'MG',
//   'MW',
//   'MY',
//   'MV',
//   'ML',
//   'MT',
//   'MH',
//   'MQ',
//   'MR',
//   'MU',
//   'YT',
//   'MX',
//   'FM',
//   'MD',
//   'MC',
//   'MN',
//   'ME',
//   'MS',
//   'MA',
//   'MZ',
//   'MM',
//   'NA',
//   'NR',
//   'NP',
//   'NL',
//   'NC',
//   'NZ',
//   'NI',
//   'NE',
//   'NG',
//   'NU',
//   'NF',
//   'MP',
//   'NO',
//   'OM',
//   'PK',
//   'PW',
//   'PS',
//   'PA',
//   'PG',
//   'PY',
//   'PE',
//   'PH',
//   'PN',
//   'PL',
//   'PT',
//   'PR',
//   'QA',
//   'RE',
//   'RO',
//   'RU',
//   'RW',
//   'BL',
//   'SH',
//   'KN',
//   'LC',
//   'MF',
//   'PM',
//   'VC',
//   'WS',
//   'SM',
//   'ST',
//   'SA',
//   'SN',
//   'RS',
//   'SC',
//   'SL',
//   'SG',
//   'SX',
//   'SK',
//   'SI',
//   'SB',
//   'SO',
//   'ZA',
//   'GS',
//   'SS',
//   'ES',
//   'LK',
//   'SD',
//   'SR',
//   'SJ',
//   'SE',
//   'CH',
//   'SY',
//   'TW',
//   'TJ',
//   'TZ',
//   'TH',
//   'TL',
//   'TG',
//   'TK',
//   'TO',
//   'TT',
//   'TN',
//   'TR',
//   'TM',
//   'TC',
//   'TV',
//   'UG',
//   'UA',
//   'AE',
//   'GB',
//   'US',
//   'UM',
//   'UY',
//   'UZ',
//   'VU',
//   'VE',
//   'VN',
//   'VG',
//   'VI',
//   'WF',
//   'EH',
//   'YE',
//   'ZM',
//   'ZW',
// ];

export const coverage_data = [
  'CN',
  'TW',
  'US',
  'VN',
  'HK',
  'IN',
  'BR',
  'JP',
  'PE',
  'TH',
  'DE',
  'NL',
  'IT',
  'KR',
  'SG',
  'TR',
  'MY',
  'ES',
  'GB',
  'FR',
  'SA',
  'AE',
  'AU',
  'ID',
  'BE',
  'NZ',
  'PH',
  'CA',
  'PL',
  'PK',
  'PT',
  'MX',
  'IL',
  'BD',
  'CL',
  'SE',
  'KH',
  'CZ',
  'RO',
  'AR',
  'CH',
  'RU',
  'NI',
  'SK',
  'GR',
  'FI',
  'LT',
  'QA',
  'ZA',
  'LK',
  'BG',
  'BH',
  'EE',
  'IE',
  'CR',
  'MM',
  'UY',
  'LV',
  'TN',
  'GT',
  'HU',
  'MA',
  'SI',
  'UA',
  'AT',
  'DK',
  'EC',
  'SV',
  'EG',
  'CO',
  'KE',
  'CY',
  'OM',
  'HN',
  'MK',
  'JO',
  'HR',
  'NP',
  'PA',
  'LB',
  'NG',
  'KW',
  'NO',
  'DO',
  'CU',
  'BS',
  'GU',
  'DJ',
  'MT',
  'GH',
  'GI',
  'MO',
  'SO',
  'RS',
  'NA',
  'LU',
  'BY',
  'CG',
  'PR',
  'BO',
  'VE',
  'CI',
  'CM',
  'SN',
  'TT',
  'JM',
  'BF',
  'MC',
  'ET',
  'DZ',
  'MG',
  'MD',
  'TZ',
  'IQ',
  'BZ',
  'KZ',
  'MU',
  'GY',
  'SD',
  'BA',
  'BN',
  'UG',
  'PG',
  'CW',
  'BU',
  'AF',
  'MP',
  'ML',
  'YE',
  'MV',
  'AM',
  'AL',
  'FJ',
  'MZ',
  'FM',
  'SZ',
  'KN',
  'DM',
  'MQ',
  'GL',
  'TD',
  'AG',
  'WS',
  'LS',
  'GE',
  'SX',
  'MN',
  'IR',
  'VG',
  'CD',
  'IS',
  'GN',
  'GQ',
  'AD',
  'AW',
  'PY',
  'BM',
  'VI',
  'VC',
  'AO',
  'LR',
  'UZ',
  'AZ',
  'PF',
  'BB',
  'NC',
  'LY',
  'KI',
  'SC',
  'ZW',
  'HT',
  'ZM',
  'KG',
  'TG',
  'SY',
  'KY',
  'BW',
  'MW',
  'GA',
  'GF',
  'AS',
  'TO',
  'RW',
  'RE',
  'TM',
  'LC',
  'ME',
  'LA',
  'BJ',
  'AN',
  'MR',
  'BI',
  'SR',
  'TJ',
  'TC',
  'NE',
  'CK',
  'SL',
  'MH',
  'BT',
  'GM',
  'BQ',
  'SS',
  'AI',
  'PW',
  'CV',
  'SB',
  'SM',
  'TL',
  'CS',
  'AQ',
  'XK',
  'MS',
  'GP',
  'VU',
  'ER',
  'GD',
  'UH',
  'IA',
  'HE',
  'UI',
  'OL',
  'XQ',
  'CB',
  'ZI',
  'YT',
  'CC',
  'TE',
  'XC',
  'CX',
  'FK',
  'UU',
  'YA',
  'LI',
  'ST',
  'RR',
  'XN',
  'NR',
];
