import { Localization } from 'app/share/data-type/common.types';

export const IndustryGovernmentLocalization: {
  [key: string]: IndustryGovernmentLocalizationClass;
} = {
  en: {
    title: 'Government',
    subTitle: 'Industry',
  },
  cn: {},
  jp: {},
  th: {},
  nl: {},
  es: {},
};

export interface IndustryGovernmentLocalizationClass extends Localization {}
