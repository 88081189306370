import { Component, OnInit, AfterViewInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import ScrollOut from 'scroll-out';
import Splitting from 'splitting';
import { gsap } from 'gsap';
import { heritageData, videos } from './data';
import { LocalizationService } from 'app/services/localization.service';
import { HeritageLocalization, HeritageLocalizationClass } from './heritage.localization';
import { ScreenService } from 'app/services/screen.service';
@Component({
  selector: 'app-heritage',
  templateUrl: './heritage.component.html',
  styleUrls: ['./heritage.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeritageComponent implements OnInit, AfterViewInit, OnDestroy {
  heritageList;

  videos = videos;
  public localizedText: HeritageLocalizationClass;
  public isMobile: boolean = false;

  private so: any; //ScrollOut object

  private yearDescPlayed: Record<string, boolean> = {};
  constructor(private localizationService: LocalizationService, private screenService: ScreenService) {
    this.isMobile = this.screenService.isMobile();
    this.localizedText = this.localizationService.getTranslation(HeritageLocalization);
    this.heritageList = [
      {
        leftToRight: true,
        year: this.localizedText.y1973,
        title: this.localizedText.y1973Title,
        content: `<p>${this.localizedText.y1973Content}</p><p>${this.localizedText.y1973Content1}</p>`,
      },
      {
        leftToRight: false,
        year: this.localizedText.y1975,
        title: '',
        content: `<p>${this.localizedText.y1975Content}</p>`,
      },
      {
        leftToRight: true,
        year: this.localizedText.y1985,
        title: this.localizedText.y1985Title,
        content: `<p>${this.localizedText.y1985Content}</p>`,
      },
      {
        leftToRight: false,
        year: this.localizedText.y2001,
        title: '',
        content: `<p>${this.localizedText.y2001Content}</p>`,
      },
      {
        leftToRight: true,
        year: this.localizedText.y2009,
        title: '',
        content: `<p>${this.localizedText.y2009Content}</p>`,
      },
      {
        leftToRight: false,
        year: this.localizedText.y2014,
        title: this.localizedText.y2014Title,
        content: `<p>${this.localizedText.y2014Content}</p>`,
      },
      {
        leftToRight: true,
        year: this.localizedText.y2018,
        title: this.localizedText.y2018Title,
        content: `<p>${this.localizedText.y2018Content}</p>`,
      },
      {
        leftToRight: false,
        year: this.localizedText.y2023,
        title: this.localizedText.y2023Title,
        content: `<p>${this.localizedText.y2023Content}</p>`,
      },
    ];
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    if (!this.isMobile) {
      this.so = ScrollOut({
        cssProps: {
          visibleY: false,
          viewportY: true,
        },
        threshold: 0.8,
        onShown: (element: any, ctx: any, scrollingElement: any) => {
          /*if (element.className.indexOf('year left') != -1) {
            var chars = element.querySelectorAll(".char");
            chars.forEach((char: any, index: number) => {
              gsap.from(char, { opacity: 0, delay: index / chars.length });
            })
          }
          if (element.className.indexOf('year right') != -1) {
            var chars = element.querySelectorAll(".char");
            chars.forEach((char: any, index: number) => {
              gsap.from(char, { opacity: 0, delay: 1 - index / chars.length });
            })
          }*/
          //var element = el.parentElement;
          let yearWidth = '920px';
          let descWidth = '670px';
          if (element.className.indexOf('content-row') != -1) {
            let i = element.attributes['scroll-id'].value;
            if (!this.yearDescPlayed[i]) {
              this.yearDescPlayed[i] = true;
              let desc = element.querySelector('.desc');
              let year = element.querySelector('.year');
              let maxHeight = Math.max(desc.clientHeight, year.clientHeight) + 'px';
              //Sometimes the height of the year(left side) is not enough, manually adjust the height of the year
              if (maxHeight == year.clientHeight) {
                year.style.setProperty('--desc-height', 'auto');
              } else {
                year.style.setProperty('--desc-height', maxHeight);
              }

              let outer = element.querySelector('.block-out');
              if (outer) {
                let outerWith = yearWidth;
                if (outer.className.indexOf('with-year') == -1) {
                  outerWith = descWidth;
                }
                outer.style.setProperty('--max-width', outerWith);
                gsap.fromTo(outer, { width: 0 }, { width: outerWith, duration: 1 });
              }
              let cover = element.querySelector('.block-cover');
              if (cover) {
                let coverWidth = yearWidth;
                if (cover.className.indexOf('with-year') == -1) {
                  coverWidth = descWidth;
                }
                let right = element.querySelector('.right');
                let coverHeight = right.clientHeight + 'px';
                cover.style.setProperty('--max-height', coverHeight);
                gsap.fromTo(cover, { width: coverWidth }, { width: '0', duration: 1 });
              }
            }
          }
        },
      });
    }

    //Splitting();
  }
  ngOnDestroy() {
    this.so.teardown();
  }
}
