export const environment = {
  production: true,
  cfApiUrl: 'https://dev-site.clearfreight.com/api/',
  cfApiKey: 'be936303-88cb-4623-a506-13e9df93466f',
  googleAnalyticsId: 'G-FXCMQH3Q87',
  cookiebotCbId: '52519c67-3c3a-4fcb-8106-16160c5f17e',
  azureBlobUrl: 'https://clrfwebservicestorage.blob.core.windows.net',
  fileContainerName: 'dev-blogcache',
  host: 'https://dev-site.clearfreight.com/',
};
