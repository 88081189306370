<div class="related-article">
  <div class="related-article-body">
    <div class="list">
      <div class="image {{i==0?'current':''}}" *ngFor="let item of article_service_data;let i = index"
        [ngStyle]="{'--image':'url('+item.image+')'}">
        <div class="text">
          <div class="text-content">
            <h3>{{item.title}}</h3>
            <div><app-btn theme="no-border" fontSize="small">Read More <app-icon-btn [size]="16"></app-icon-btn>
              </app-btn></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>