import { Component, OnInit, ChangeDetectionStrategy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

import { FormControl, FormGroupDirective, NgForm, UntypedFormControl, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { IDropdownSettings } from 'app/share/components/ng-multiselect-dropdown';

import { startWith, range, filter, map, Observable } from 'rxjs';
import { QuoteRequestLocalization, QuoteRequestLocalizationClass } from './quote-request.localization';
import { LocalizationService } from 'app/services/localization.service';
import { ToastrService } from 'ngx-toastr';
import { ContactService } from 'app/services/contact.service';
import { contactParams } from 'app/share/data-type/common.types';
import { ListItem } from 'app/share/components/ng-multiselect-dropdown/multiselect.model';
import { ScreenService } from 'app/services/screen.service';

/**
 * Error when invalid control is dirty, touched, or submitted.
 *
 */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-quote-request',
  templateUrl: './quote-request.component.html',
  styleUrls: ['./quote-request.component.scss'],
})
export class QuoteRequestComponent implements OnInit, AfterViewInit {
  public localizedText: QuoteRequestLocalizationClass;
  nameFormControl = new UntypedFormControl('', [Validators.required]);
  companyNameFormControl = new UntypedFormControl('', [Validators.required]);
  emailFormControl = new UntypedFormControl('', [Validators.required, Validators.email]);
  phoneFormControl = new UntypedFormControl('', [Validators.required]);
  textingControl = new UntypedFormControl(false);
  countryControl = new UntypedFormControl('', [Validators.required]);
  servicesControl = new UntypedFormControl('', [Validators.required]);
  originControl = new UntypedFormControl('', [Validators.required]);
  destinationControl = new UntypedFormControl('', [Validators.required]);
  descriptionControl = new UntypedFormControl();
  matcher = new MyErrorStateMatcher();
  //autocomplate https://v5.material.angular.io/components/autocomplete/overview
  // and maybe this one  https://github.com/bob-fornal/multi-select/
  service_options = [
    { item_id: 1, item_text: 'Ocean Freight' },
    { item_id: 2, item_text: 'Air Freight' },
    { item_id: 3, item_text: 'Customs Brokerage' },
    { item_id: 4, item_text: 'Warehousing and Distribution' },
    { item_id: 5, item_text: 'Land Transportation - Road and Rail' },
    { item_id: 6, item_text: 'Cargo Insurance' },
  ];
  selectedServices: string[] = [];
  filteredOptions: Observable<string[]>;
  public isMobile: boolean = false;
  constructor(
    private localizationService: LocalizationService,
    private toastr: ToastrService,
    private screenService: ScreenService,
    private contactService: ContactService
  ) {
    this.isMobile = this.screenService.isMobile();
    this.localizedText = this.localizationService.getTranslation(QuoteRequestLocalization);
  }
  dropdownList: any[] = [];
  selectedItems: any[] = [];
  dropdownSettings: IDropdownSettings = {};
  ngOnInit(): void {
    this.selectedItems = [
      { item_id: 3, item_text: 'Pune' },
      { item_id: 4, item_text: 'Navsari' },
    ];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: false,
    };
  }
  onItemSelect(item: any) {
    this.selectedServices.push(item.item_text);
    this.servicesControl.setValue(this.selectedServices.join(', '));
  }
  onSelectAll(items: any) {
    this.selectedServices = items.map((item: { item_text: any }) => item.item_text);
    this.servicesControl.setValue(this.selectedServices.join(', '));
  }
  onItemDeSelect(item: any) {
    this.selectedServices = this.selectedServices.filter(service => service !== item.item_text);
    this.servicesControl.setValue(this.selectedServices.join(', '));
  }
  onDeSelectAll(items: any) {
    this.selectedServices = [];
    this.servicesControl.setValue('');
  }
  ngAfterViewInit(): void {}
  handleSubmit(): void {
    // Check if form controls are valid
    if (this.validateForm()) {
      // Send the request
      let params: contactParams = {
        name: this.nameFormControl.value,
        email: this.emailFormControl.value,
        company: this.companyNameFormControl.value,
        phone: this.phoneFormControl.value,
        textEnabled: this.textingControl.value,
        address: this.countryControl.value,
        goals: `New Quote Request </br></br>
        Services: ${this.servicesControl.value} </br>
        Origin: ${this.originControl.value} </br>
        Destination: ${this.destinationControl.value} </br>
        Description: ${this.descriptionControl.value}`,
        isNL: false,
      };
      this.contactService.sendContact(params).subscribe(
        res => {
          this.toastr.success('Your request has been sent successfully.');
          this.nameFormControl.reset();
          this.companyNameFormControl.reset();
          this.emailFormControl.reset();
          this.phoneFormControl.reset();
          this.textingControl.reset();
          this.countryControl.reset();
          this.servicesControl.reset();
          this.originControl.reset();
          this.destinationControl.reset();
          this.descriptionControl.reset();
        },
        err => {
          this.toastr.error('Something went wrong.');
        }
      );
    } else if (!this.emailFormControl.valid) {
      // Show error message
      this.toastr.error('Please enter a valid email address.');
    } else {
      // Show error message
      this.toastr.error('Please fill in all the required fields.');
    }
  }
  private validateForm(): boolean {
    // Check if form controls are valid
    if (
      this.nameFormControl.status === 'VALID' &&
      this.companyNameFormControl.status === 'VALID' &&
      this.emailFormControl.status === 'VALID' &&
      this.phoneFormControl.status === 'VALID' &&
      this.countryControl.status === 'VALID' &&
      this.servicesControl.status === 'VALID' &&
      this.originControl.status === 'VALID' &&
      this.destinationControl.status === 'VALID' &&
      this.descriptionControl.status === 'VALID'
    ) {
      return true;
    } else {
      return false;
    }
  }
}

interface ServiceItem {
  item_id: number;
  item_text: string;
}
