import {
  Directive,
  ElementRef,
  Output,
  EventEmitter,
  HostListener,
} from '@angular/core';

@Directive({
  selector: '[appScrollTrigger]',
})
export class ScrollTriggerDirective {
  @Output() triggerPointReached = new EventEmitter<void>();

  constructor(private el: ElementRef) {}

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    const element = this.el.nativeElement;
    const triggerOffset = 400; // Adjust this value to set the trigger point in pixels

    const rect = element.getBoundingClientRect();
    const windowHeight =
      window.innerHeight || document.documentElement.clientHeight;

    if (rect.top <= windowHeight - triggerOffset) {
      this.triggerPointReached.emit();
    }
  }
}
