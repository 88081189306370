import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-btn-no-route',
  templateUrl: './btn-no-route.component.html',
  styleUrls: ['./btn-no-route.component.scss'],
})
export class BtnNoRouteComponent implements OnInit {
  @Input() height: number = 36;
  @Input() width: number = 160;
  @Input() fontSize: 'normal' | 'small' = 'normal';
  @Input() theme: '' | 'blue' | 'no-border' | string = '';
  @Output() setState = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}
}
