<div class="location">
  <div class="search-col">
    <div class="location-search">
      <div class="search-bar open-search">
        <div class="search-box">
          <i class="material-icons close" (click)="discardSearch()">close</i>
          <input type="text" [(ngModel)]="searchKey" placeholder="Search Location" (keyup)="showSearch()" />
        </div>
      </div>
    </div>
    <div class="location-block" *ngIf="pageStatus == 1">
      <h4 class="block-item" (click)="setCurrentLocation('EUROPE', '')">
        <a href="javascript:;" (click)="setCurrentLocation('EUROPE', '')" class="blue">
          <span>EUROPE</span>
          <app-icon-btn></app-icon-btn>
        </a>
      </h4>
      <h4 class="block-item" (click)="setCurrentLocation('AMERICAS', '')">
        <a href="javascript:;" (click)="setCurrentLocation('AMERICAS', '')" class="blue">
          <span>AMERICAS</span>
          <app-icon-btn></app-icon-btn>
        </a>
      </h4>
      <h4 class="block-item" (click)="setCurrentLocation('ASIA', '')">
        <a href="javascript:;" (click)="setCurrentLocation('ASIA', '')" class="blue">
          <span>ASIA</span>
          <app-icon-btn></app-icon-btn>
        </a>
      </h4>
      <div class="coverage-disclaimer">
        <div class="row">
          <div class="cf-office"></div>
          <p>Indicates a country where ClearFreight has offices</p>
        </div>
        <div class="row">
          <div class="coverage-area"></div>
          <p>Indicates a country where ClearFreight moves shipments</p>
        </div>
      </div>
    </div>
    <div class="location-sub-block" *ngIf="pageStatus == 2">
      <div class="location-items">
        <div class="back">
          <a href="javascript:;" (click)="setCurrentLocation('', '')" class="blue">
            <app-icon-btn theme="gray" icon="arrow_backward"></app-icon-btn>
            <h4>{{ sub_area_data.areaName }}</h4>
          </a>
        </div>
        <ul class="items">
          <li
            class="item"
            *ngFor="let item of sub_area_data.children; let i = index"
            (click)="setCurrentLocation(currentContinentName, item.id)"
          >
            <div>
              <a href="javascript:;" (click)="setCurrentLocation(currentContinentName, item.id)" class="blue">
                {{ item.name }}
              </a>
            </div>
            <a href="javascript:;" (click)="setCurrentLocation(currentContinentName, item.id)" class="blue">
              <app-icon-btn></app-icon-btn>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="location-city-block" *ngIf="pageStatus == 3">
      <div class="city-items">
        <div class="back">
          <a href="javascript:;" (click)="setCurrentLocation(currentContinentName, '')" class="blue">
            <app-icon-btn theme="gray" icon="arrow_backward"></app-icon-btn>
            <h3>{{ current_city_data?.name }}</h3>
          </a>
        </div>
        <div class="info">
          <div class="item">
            <h4>address</h4>
            <div class="text">
              <a
                [href]="
                  'https://www.google.com/maps/search/?api=1&query=' + current_city_data?.address?.split(',')?.join('+')
                "
                target="_blank"
              >
                {{ current_city_data?.address }}
              </a>
            </div>
          </div>
          <div class="item">
            <h4>phone</h4>
            <div class="text">
              <div class="link-wrapper">
                <a [href]="'tel:' + current_city_data?.phone">{{ current_city_data?.phone }}</a>
                <button mat-icon-button class="btn" (click)="copyToClipboard(current_city_data?.phone || '')">
                  <mat-icon>file_copy</mat-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="item">
            <h4>email</h4>
            <div class="text">
              <div class="link-wrapper" *ngIf="current_city_data?.name != 'Los Angeles'">
                <a [href]="'mailto:' + current_city_data?.email">{{ current_city_data?.email }}</a>
                <button mat-icon-button class="btn" (click)="copyToClipboard(current_city_data?.email || '')">
                  <mat-icon>file_copy</mat-icon>
                </button>
              </div>
              <!-- Los Angeles Departments -->
              <div *ngIf="current_city_data?.name == 'Los Angeles'">
                <div class="link-wrapper" *ngFor="let item of elsDepartmentEmails">
                  <a [href]="'mailto:' + item.email">{{ item.email }}</a>
                  <button mat-icon-button class="btn" (click)="copyToClipboard(current_city_data?.email || '')">
                    <mat-icon>file_copy</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="location-search-results" *ngIf="isShowSearch">
        <ul>
          <li class="search-item" *ngFor="let item of searchResult" (click)="setLocationByID(item.id)">
            <a href="javascript:;" class="blue" (click)="setLocationByID(item.id)">
              {{ item.country }} - {{ item.name }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="map-col">
    <div class="location-map">
      <div
        class="map-content"
        mouseWheel
        (mouseWheelUp)="onMouseWheelUp($event)"
        (mouseWheelDown)="onMouseWheelDown($event)"
      >
        <mgl-map
          [style]="'mapbox://styles/clearfreight/clisgaqlo001e01pngh3w64sr'"
          [scrollZoom]="false"
          [zoom]="[zoomNumber]"
          [center]="mapCenter"
          (load)="mapInit($event)"
        >
          <ng-container *ngIf="pageStatus == 2 || pageStatus == 3">
            <mgl-marker
              *ngFor="let city of sub_area_data.children"
              [lngLat]="[city.latlng?.lng ?? 0, city.latlng?.lat ?? 0]"
            >
              <app-icon-btn
                icon="fiber_manual_record"
                [size]="30"
                *ngIf="!isMobile"
                [matTooltip]="city.name"
                matTooltipClass="mat-big-tooltip"
                matTooltipPosition="above"
                (click)="setCurrentLocation(currentContinentName, city.id)"
                [theme]="checkIsCurrent(city.id) ? 'blue' : 'no-border-navy-blue'"
              ></app-icon-btn>
              <app-icon-btn
                icon="fiber_manual_record"
                [size]="30"
                *ngIf="isMobile"
                (click)="setCurrentLocation(currentContinentName, city.id)"
                [theme]="checkIsCurrent(city.id) ? 'blue' : 'no-border-navy-blue'"
              ></app-icon-btn>
            </mgl-marker>
          </ng-container>
        </mgl-map>
      </div>
    </div>
    <div class="end-map">
      <div class="cover"></div>
    </div>
  </div>
</div>
