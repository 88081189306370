import { Localization } from 'app/share/data-type/common.types';

export const IndustryPetsLocalization: {
  [key: string]: IndustryPetsLocalizationClass;
} = {
  en: {
    title: 'Pets',
    subTitle: 'Industry',
  },
  cn: {},
  jp: {},
  th: {},
  nl: {},
  es: {},
};

export interface IndustryPetsLocalizationClass extends Localization {}
