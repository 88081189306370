import { Component, Input, OnInit } from '@angular/core';
import {
  formItemType,
  formCategoryType,
} from 'app/share/data-type/common.types';
import { get_forms_data } from './data';
import {
  FormsLocalization,
  FormsLocalizationClass,
} from './forms.localization';
import { LocalizationService } from 'app/services/localization.service';
import { ScreenService } from 'app/services/screen.service';
@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss'],
})
export class FormsComponent implements OnInit {
  public localizedText: FormsLocalizationClass;

  constructor(
    private localizationService: LocalizationService,
    screenService: ScreenService
  ) {
    this.localizedText = localizationService.getTranslation(FormsLocalization);
  }

  ngOnInit(): void {}
  forms = get_forms_data(this.localizationService).filter(
    (item) => item.type !== 'CTAPT'
  );
}
