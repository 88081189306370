import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { LocalizationService } from 'app/services/localization.service';
import {
  ContactButtonLocalization,
  ContactButtonLocalizationClass,
} from './contact-button.localization';
import { ScreenService } from 'app/services/screen.service';

@Component({
  selector: 'app-contact-button',
  template: `
    <button
      class="contact-button"
      [ngStyle]="{ width: width, height: height }"
      (click)="handleClick()"
    >
      {{ localizedText.contactButton }}
    </button>
  `,
  styleUrls: ['./contact-button.component.scss'],
})
export class ContactButtonComponent implements OnInit {
  public localizedText: ContactButtonLocalizationClass;
  public isMobile: boolean = false;
  @Input() width: string = '150px';
  @Input() height: string = '60px';

  constructor(
    public contactDialog: MatDialog,
    private localizationService: LocalizationService,
    private screenService: ScreenService
  ) {
    this.isMobile = this.screenService.isMobile();
    this.localizedText = this.localizationService.getTranslation(
      ContactButtonLocalization
    );
  }

  ngOnInit(): void {}

  public handleClick(): void {
    this.openContactDialog();
  }

  private openContactDialog(): void {
    if (this.isMobile) {
      this.contactDialog.open(ContactFormComponent, {
        width: '90vw',
        maxWidth: '100%',
        height: '80vh',
        maxHeight: '100%',
        panelClass: 'contact-dialog',
        autoFocus: true,
        disableClose: false,
      });
    } else {
      this.contactDialog.open(ContactFormComponent, {
        width: '75vw',
        maxWidth: '100%',
        height: '90vh',
        maxHeight: '100%',
        panelClass: 'contact-dialog',
        autoFocus: true,
        disableClose: false,
      });
    }
  }
}
