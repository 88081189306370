<div class="service-main">
  <div class="row">
    <div class="img">
      <img [src]="imgUrl">
    </div>
    <div class="title">
      {{title}}
    </div>
    <div class="content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
