<div class="milestones">
  <h2 class="table-name">Milestones</h2>
  <div class="milestones-table-container">
    <table>
      <tr>
        <th>Description</th>
        <th>Date</th>
        <th>Status</th>
      </tr>
      <tr *ngFor="let milestone of milestones">
        <td>{{ milestone.milestoneDescription }}</td>
        <td>{{ milestone.milestoneActualDate | date }}</td>
        <td>
          {{ milestone.milestoneStatus == 'Previous' ? 'Complete' : milestone.milestoneStatus }}
        </td>
      </tr>
    </table>
  </div>
</div>
