<ng-template #trackingBar>
  <div id="tracking-bar" class="{{ isShowTracking ? 'open-search' : 'close-search' }}">
    <div class="row">
      <a href="Https://tracking.clearfreight.com" target="_blank">Legacy WebTracking</a>
      <app-btn-no-route class="btn-clearview" theme="light-blue" [width]="300" (click)="navToClearView()">
        Track with ClearView
      </app-btn-no-route>
      <div class="input-container">
        <app-lookup-input width="'40%'"></app-lookup-input>
      </div>
    </div>
  </div>
</ng-template>
<div
  [ngStyle]="{
    '--header-height': headerFinalHeight,
    '--header-left': headerLeft + 'px'
  }"
>
  <div class="page-header">
    <!-- Toolbar -->
    <div *ngIf="!isMobile" [className]="headerClass">
      <div class="small-menu">
        <ul id="tool">
          <li>
            <a [class]="checkIsCurrent('quote') ? 'light-blue-white' : ''" [routerLink]="'/quote'">Request Quote</a>
          </li>
          <li>
            <a [class]="checkIsCurrent('demo') ? 'light-blue-white' : ''" [routerLink]="'/demo'">Schedule Demo</a>
          </li>
          <li>
            <a [class]="checkIsCurrent('contact') ? 'light-blue-white' : ''" [routerLink]="'/contact'">Contact</a>
          </li>
          <!-- <li class="search-icon">
            <a href="javascript:;" (click)="showSearch(!isShowSearch)">
              <app-icon-btn
                theme="{{ isShowSearch ? 'no-border-blue' : 'no-border' }}"
                icon="search"
              ></app-icon-btn
            ></a>
          </li> -->
          <li>
            <app-btn-no-route
              theme="light-blue-to-light-blue"
              fontSize="small"
              [width]="100"
              [height]="26"
              (click)="showTracking(!isShowTracking)"
            >
              Tracking
            </app-btn-no-route>
          </li>
        </ul>

        <div id="search-bar" class="{{ isShowSearch ? 'open-search' : 'close-search' }}">
          <div class="search-box">
            <i class="material-icons close" (click)="showSearch(!isShowSearch)">close</i>
            <input type="text" placeholder="Search" />
            <div class="submit" (click)="showSearch(!isShowSearch)">
              <i class="material-icons">arrow_forward</i>
            </div>
          </div>
        </div>
        <ng-container *ngTemplateOutlet="trackingBar"></ng-container>
      </div>
      <div class="toolbar" role="banner">
        <a [routerLink]="'/'">
          <img class="logo" alt="ClearFreight Logo" src="./assets/images/Header/CF-header-logo.png" />
        </a>
        <div class="header-menu">
          <ul id="nav">
            <li *ngFor="let item of menu">
              <a
                (mouseover)="showMenu(true, item.key)"
                [href]="item.canClick ? item.routeAddress : ('javascript:void(0);' | safeUrl)"
                [class]="{
                  cursorDefault: !item.canClick,
                  currentRoute: item.isCurrentRoute
                }"
              >
                {{ item.itemTitle }}
              </a>
            </li>
          </ul>
          <app-forms-login *ngIf="isShowLogin" (setState)="showLogin($event)"></app-forms-login>
        </div>
      </div>
    </div>

    <!-- Mobile Toolbar -->
    <div *ngIf="isMobile" [className]="headerClass">
      <div class="small-menu-mobile">
        <div class="header-bar">
          <a href="/">
            <img class="logo" alt="ClearFreight Logo" src="./assets/images/Header/CF-header-logo.png" />
          </a>
          <app-btn-no-route
            *ngIf="!isShowMobileMenu"
            theme="light-blue-to-light-blue"
            fontSize="small"
            [width]="100"
            [height]="26"
            (click)="showTracking(!isShowTracking)"
          >
            Tracking
          </app-btn-no-route>
          <button mat-button (click)="showMobileMenu(!isShowMobileMenu)">
            <i class="material-icons" style="color: #fff">{{ isShowMobileMenu ? 'close' : 'menu' }}</i>
          </button>
        </div>
      </div>
      <ng-container *ngTemplateOutlet="trackingBar"></ng-container>
      <!-- <div class="small-menu">
        <ul id="tool">
          <li>
            <a
              [class]="checkIsCurrent('quote') ? 'light-blue-white' : ''"
              [routerLink]="'/quote'"
              >Request Quote</a
            >
          </li>
          <li>
            <a
              [class]="checkIsCurrent('demo') ? 'light-blue-white' : ''"
              [routerLink]="'/demo'"
              >Schedule Demo</a
            >
          </li>
          <li>
            <a
              [class]="checkIsCurrent('contact') ? 'light-blue-white' : ''"
              [routerLink]="'/contact'"
              >Contact</a
            >
          </li>
          <li class="search-icon">
            <a href="javascript:;" (click)="showSearch(!isShowSearch)">
              <app-icon-btn
                theme="{{ isShowSearch ? 'no-border-blue' : 'no-border' }}"
                icon="search"
              ></app-icon-btn
            ></a>
          </li>
          <li>
            <app-btn
              theme="light-blue-to-light-blue"
              (click)="showLogin(true)"
              fontSize="small"
              [width]="100"
              [height]="26"
              >Login</app-btn
            >
          </li>
        </ul>

        <div
          id="search-bar"
          class="{{ isShowSearch ? 'open-search' : 'close-search' }}"
        >
          <div class="search-box">
            <i class="material-icons close" (click)="showSearch(!isShowSearch)"
              >close</i
            >
            <input type="text" placeholder="Search" />
            <div class="submit" (click)="showSearch(!isShowSearch)">
              <i class="material-icons">arrow_forward</i>
            </div>
          </div>
        </div>
      </div>
      <div class="toolbar" role="banner">
        <a [routerLink]="'/'"
          ><img
            class="logo"
            alt="ClearFreight Logo"
            src="./assets/images/Header/CF-header-logo.png"
        /></a>
        <div class="header-menu">
          <ul id="nav">
            <li *ngFor="let item of menu">
              <a
                (mouseover)="showMenu(true, item.key)"
                [href]="
                  item.canClick
                    ? item.routeAddress
                    : ('javascript:void(0);' | safeUrl)
                "
                [class]="{
                  cursorDefault: !item.canClick,
                  currentRoute: item.isCurrentRoute
                }"
              >
                {{ item.itemTitle }}
              </a>
            </li>
          </ul>
          <app-forms-login
            *ngIf="isShowLogin"
            (setState)="showLogin($event)"
          ></app-forms-login>
        </div>
      </div> -->
    </div>
  </div>

  <app-nav-menu *ngIf="isShowMenu" (setState)="showMenu($event)" [item]="currentMenuData"></app-nav-menu>
  <div class="page-base">
    <div id="mobile-menu" class="{{ isShowMobileMenu ? 'open-search' : 'close-search' }}">
      <mat-accordion>
        <ul class="mobile-menu-tool">
          <li>
            <a [class]="checkIsCurrent('quote') ? 'light-blue-white' : ''" [routerLink]="'/quote'">Request Quote</a>
            <a [routerLink]="'/quote'"><i class="material-icons">east</i></a>
          </li>
          <li>
            <a [class]="checkIsCurrent('demo') ? 'light-blue-white' : ''" [routerLink]="'/demo'">Schedule Demo</a>
            <a [routerLink]="'/demo'"><i class="material-icons">east</i></a>
          </li>
          <li>
            <a [class]="checkIsCurrent('contact') ? 'light-blue-white' : ''" [routerLink]="'/contact'">Contact</a>
            <a [routerLink]="'/contact'"><i class="material-icons">east</i></a>
          </li>
        </ul>
        <!-- <div class="mobile-contact"></div>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>Contact</mat-panel-title>
          </mat-expansion-panel-header>
        </mat-expansion-panel> -->
        <app-mobile-line-nav *ngFor="let item of navMenuData" [navMenu]="item"></app-mobile-line-nav>
      </mat-accordion>
    </div>
    <div class="content" role="main">
      <router-outlet></router-outlet>
    </div>
    <div class="buttom-video">
      <video
        #buttomVideo
        autoplay="true"
        [loop]="buttomVideo"
        [muted]="buttomVideo"
        [poster]="bottomVideo.img"
        playsinline
      >
        <source [src]="bottomVideo.video" type="video/mp4" />
      </video>
    </div>
    <div class="footer-container">
      <div class="footer">
        <ng-template #bottomMenu let-data="data">
          <li class="menu-subtitle" *ngFor="let item of data">
            <a *ngIf="item.canClick" class="black" [routerLink]="item.routeAddress">
              {{ item.title }}
            </a>
            <ng-container *ngIf="!item.canClick">
              {{ item.title }}
            </ng-container>
            <ng-container *ngIf="item.title != 'TRANSPORTATION' && item.children && item.children.length">
              <p class="space">&nbsp;</p>
              <p *ngFor="let m of item.children">
                <a *ngIf="m.canClick" class="black" [routerLink]="m.routeAddress">
                  {{ m.title }}
                </a>
                <ng-container *ngIf="!m.canClick">
                  {{ m.title }}
                </ng-container>
              </p>
            </ng-container>
          </li>
        </ng-template>
        <div class="footer-function">
          <div class="col-1">
            <img class="footer-logo" alt="ClearFreight Logo" src="./assets/images/Footer/CF-footer-logo.png" />
            <div class="sign-up-form" *ngIf="!isSendSubscribe">
              <div class="title">SIGN UP FOR EMAILS</div>

              <div class="input-group">
                <input placeholder="Email" [(ngModel)]="email" required />
                <a class="sub-button" (click)="subscribeEmail()">
                  <app-icon-btn theme="gray" [size]="30"></app-icon-btn>
                </a>
              </div>
            </div>
            <div class="sign-up-form" *ngIf="isSendSubscribe">
              <div class="title">Thanks for Subscribing!</div>
              <div class="title">We'll send news to your inbox.</div>
            </div>
          </div>
          <div class="col-2">
            <div class="menu-title">ABOUT</div>
            <ul>
              <ng-container
                [ngTemplateOutlet]="bottomMenu"
                [ngTemplateOutletContext]="{
                  data: isMobile ? getSubMenuWithoutChild('about').menus : getSubMenu('about').menus
                }"
              ></ng-container>
            </ul>
          </div>
          <div class="col-3">
            <div class="menu-title">SERVICES</div>
            <ul>
              <ng-container
                [ngTemplateOutlet]="bottomMenu"
                [ngTemplateOutletContext]="{
                  data: isMobile ? getSubMenuWithoutChild('services').menus : getSubMenu('services').menus.slice(0, 2)
                }"
              ></ng-container>
            </ul>
          </div>
          <div class="col-4">
            <div>&nbsp;</div>
            <ul>
              <ng-container
                [ngTemplateOutlet]="bottomMenu"
                [ngTemplateOutletContext]="{
                  data: isMobile ? [] : getSubMenu('services').menus.slice(2, 3)
                }"
              ></ng-container>
            </ul>
          </div>
          <div class="col-5">
            <div class="menu-title">RESOURCES</div>
            <ul>
              <ng-container
                [ngTemplateOutlet]="bottomMenu"
                [ngTemplateOutletContext]="{
                  data: isMobile ? getSubMenuWithoutChild('resources').menus : getSubMenu('resources').menus
                }"
              ></ng-container>
            </ul>
            <div class="menu-title hide-in-mobile">GLOBAL NETWORK</div>
            <ul class="hide-in-mobile">
              <ng-container
                [ngTemplateOutlet]="bottomMenu"
                [ngTemplateOutletContext]="{
                  data: isMobile ? getSubMenuWithoutChild('network').menus : getSubMenu('network').menus
                }"
              ></ng-container>
            </ul>
            <div class="menu-title hide-in-mobile">TECHNOLOGY</div>
            <ul class="hide-in-mobile">
              <ng-container
                [ngTemplateOutlet]="bottomMenu"
                [ngTemplateOutletContext]="{
                  data: isMobile ? getSubMenuWithoutChild('technology').menus : getSubMenu('technology').menus
                }"
              ></ng-container>
            </ul>
          </div>
          <div class="col-6">
            <div class="menu-title">GLOBAL NETWORK</div>
            <ul>
              <ng-container
                [ngTemplateOutlet]="bottomMenu"
                [ngTemplateOutletContext]="{
                  data: getSubMenu('network').menus
                }"
              ></ng-container>
            </ul>
          </div>
          <div class="col-7">
            <div class="menu-title">TECHNOLOGY</div>
            <ul>
              <ng-container
                [ngTemplateOutlet]="bottomMenu"
                [ngTemplateOutletContext]="{
                  data: getSubMenu('technology').menus
                }"
              ></ng-container>
            </ul>
          </div>
        </div>
        <app-associations></app-associations>

        <div class="footer-content">
          <div class="social">
            <a href="https://www.linkedin.com/company/clearfreight/" target="_blank">
              <img src="./assets/images/Footer/CF-Linkedin.png" />
            </a>
            <a href="https://twitter.com/clearfreight?lang=en" target="_blank">
              <img src="./assets/images/Footer/CF-Twitter.png" />
            </a>
          </div>
          <div class="text">
            © {{ year }} ClearFreight, Inc. |
            <a [routerLink]="['/privacy-policy/']">Privacy Policy</a>
            |
            <a [routerLink]="['/privacy-policy/']">Terms and Conditions</a>
          </div>
        </div>
      </div>
      <!-- <app-associations></app-associations> -->
    </div>
  </div>
</div>
