<div class="search-body">
  <h2>FILTER SEARCH</h2>
  <form>
    <ul>
      <li>
        <div class="multiple">
          <ng-multiselect-dropdown [placeholder]="'Service'" [settings]="dropdownSettings" [data]="service_options"
            [(ngModel)]="servicesSelectedItems" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
          </ng-multiselect-dropdown>
        </div>
      </li>
      <li>

        <div class="multiple">
          <ng-multiselect-dropdown [placeholder]="'Regions'" [settings]="dropdownSettings" [data]="service_options"
            [(ngModel)]="regionsSelectedItems" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
          </ng-multiselect-dropdown>
        </div>
      </li>
      <li>

        <div class="multiple">
          <ng-multiselect-dropdown [placeholder]="'Industry'" [settings]="dropdownSettings" [data]="service_options"
            [(ngModel)]="industrySelectedItems" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
          </ng-multiselect-dropdown>
        </div>
      </li>
      <li>
        <div class="submit">
          <app-btn [width]="100">APPLY</app-btn>
        </div>
      </li>
    </ul>
  </form>
</div>