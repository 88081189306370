import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  NgModule,
  AfterViewInit,
} from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { fadeInDown } from 'ng-animate';
import { relatedArticle } from 'app/share/data-type/post.types';
import { SwiperComponent } from 'app/share/components/swiper/swiper.component';
import { ScreenService } from 'app/services/screen.service';
import { SwiperOptions } from 'swiper';
import { throttleTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { register } from 'swiper/element/bundle';

@Component({
  selector: 'app-related-article',
  templateUrl: './related-article.component.html',
  styleUrls: ['./related-article.component.scss'],
})
export class RelatedArticleComponent implements OnInit, AfterViewInit {
  @Input() article_service_data: relatedArticle[];
  @Output() setState = new EventEmitter();
  public isMobile: boolean = false;
  @ViewChild('swiperCarousel')
  swiperCarousel: SwiperComponent;
  private THROTTLE_TIME: number = 100;
  currentIndex: number = -1;
  private picSubject = new Subject<number>();
  config: SwiperOptions = {
    pagination: false,
    navigation: false,
    centeredSlides: false,
    autoplay: false,
    loop: true,
    slidesPerView: 1.1,
    speed: 1000,
    spaceBetween: 20,
    parallax: false,
  };
  constructor(private screenService: ScreenService) {
    this.isMobile = this.screenService.isMobile();
  }
  ngAfterViewInit(): void {
    register();
  }
  ngOnInit(): void {
    const result = this.picSubject.pipe(throttleTime(this.THROTTLE_TIME));
    result.subscribe(e => {
      this.currentIndex = e;
    });
  }

  private getRoot(el: any): any {
    if (el?.className?.indexOf('list') != -1) {
      return el;
    } else {
      let parent = el.parentElement;
      return this.getRoot(parent);
    }
  }
  changeCurrent(e: MouseEvent, index: number) {
    this.picSubject.next(index);
  }
  isCurrent(index: number) {
    return this.currentIndex == index;
  }
  isNoCurrent() {
    return this.currentIndex == -1;
  }
  leaveArticle(ev?: MouseEvent) {
    this.currentIndex = -1;
  }
}
