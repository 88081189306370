import { LocalizationService } from 'app/services/localization.service';
import {
  ServicesLocalization,
  ServicesLocalizationClass,
} from './services.localization';
import { imageTextFadeInType } from 'app/share/data-type/common.types';

const imgage_url =
  'https://clrfwebservicestorage.blob.core.windows.net/newhome-dev';

export const get_img_text = (
  localizationService: LocalizationService
): imageTextFadeInType[] => {
  let localizedText: ServicesLocalizationClass =
    localizationService.getTranslation(ServicesLocalization);

  return [
    {
      leftToRight: true,
      img: imgage_url + '/Services/Transportation/CF-Transportation.jpg',
      text: {
        title: localizedText.transportation ?? 'Transportation',
        content: localizedText.transportationDescription ?? '',
        navList: [
          {
            title: localizedText.ocean ?? 'Ocean',
            path: '/services/ocean',
            canClick: true,
          },
          {
            title: localizedText.air ?? 'Air',
            path: '/services/air',
            canClick: true,
          },
          {
            title: localizedText.road ?? 'Road',
            path: '/services/trucking',
            canClick: true,
          },
          {
            title: localizedText.rail ?? 'Rail',
            path: '/services/rail',
            canClick: true,
          },
        ],
        btnColor: '',
      },
    },
    {
      leftToRight: false,
      img: imgage_url + '/Homepage/4/CF-2.0-Freight-Forwarding.jpg',
      text: {
        title: localizedText.logistics ?? 'Logistics',
        content: localizedText.logisticsDescription ?? '',
        navList: [
          {
            title: localizedText.freightForwarding ?? 'Freight Forwarding',
            path: '/services/freight-forwarding',
            canClick: true,
          },
          {
            title:
              localizedText.breakbulkProjectCargo ??
              'Break-bulk & Project Cargo',
            path: '/services/break-bulk-project-cargo',
            canClick: true,
          },
          {
            title: localizedText.chemicalBulk ?? 'Chemical Bulk Liquid',
            path: '/services/chemical-bulk-liquid',
            canClick: true,
          },
          {
            title:
              localizedText.warehousingDistribution ??
              'Warehousing & Distribution',
            path: '/services/warehousing-distribution',
            canClick: true,
          },
          {
            title:
              localizedText.supplyChainSolutions ?? 'Supply Chain Solutions',
            path: '/services/supply-chain-solutions',
            canClick: true,
          },
          {
            title: localizedText.intermodalSolutions ?? 'Intermodal Solutions',
            path: '/services/intermodal-solutions',
            canClick: true,
          },
        ],
        btnColor: 'blue',
      },
    },
    {
      leftToRight: true,
      img: './assets/images/Services/Global_Trade/CF-Global-Trade.jpg',
      text: {
        title: localizedText.globalTrade ?? 'Global Trade',
        content: localizedText.globalTradeDescription ?? '',
        navList: [
          {
            title:
              localizedText.customsHouseBrokerage ?? 'Customs House Brokerage',
            path: '/services/customs-house-brokerage',
            canClick: true,
          },
          {
            title: localizedText.cargoInsurance ?? 'Cargo Insurance',
            path: '/services/cargo-insurance',
            canClick: true,
          },
        ],
        btnColor: 'blue',
      },
    },
  ];
};

export const img_text = [
  {
    leftToRight: true,
    img: imgage_url + '/Homepage/4/CF-1.0-Customs-House-Brokerage.jpg',
    text: {
      title: 'Headline',
      content: `Lorem ipsum dolor sit amet, consectetur adipiscing
      elit, sed do eiusmod tempor incididunt ut labore
      et dolore magna aliqua. Ut enim ad minim veniam,
      quis nostrud exercitation ullamco laboris nisi ut
      aliquip ex ea commodo consequat. <a>test</a>`,
      btnColor: '',
    },
  },
  {
    leftToRight: false,
    img: imgage_url + '/Homepage/4/CF-2.0-Freight-Forwarding.jpg',
    text: {
      title: 'Headline',
      content: `Lorem ipsum dolor sit amet, consectetur adipiscing
      elit, sed do eiusmod tempor incididunt ut labore
      et dolore magna aliqua. Ut enim ad minim veniam,
      quis nostrud exercitation ullamco laboris nisi ut
      aliquip ex ea commodo consequat.`,
      btnColor: 'blue',
    },
  },
  {
    leftToRight: true,
    img: imgage_url + '/Homepage/4/CF-3.0-Breakbulk & Project Cargo.jpg',
    text: {
      title: 'Headline',
      content: `Lorem ipsum dolor sit amet, consectetur adipiscing
      elit, sed do eiusmod tempor incididunt ut labore
      et dolore magna aliqua. Ut enim ad minim veniam,
      quis nostrud exercitation ullamco laboris nisi ut
      aliquip ex ea commodo consequat.`,
      btnColor: 'blue',
    },
  },
];
export const article_service = [
  {
    id: '1',
    title: 'ARTICLE TITLE',
    name: 'test8',
    imgUrl:
      '/Template%201.0/Related%20Articles/CF-Related-Article-Image-1.0.jpg',
  },
  {
    id: '2',
    title: 'ARTICLE TITLE',
    name: 'test8',
    imgUrl:
      '/Template%201.0/Related%20Articles/CF-Related-Article-Image-2.0.jpg',
  },
  {
    id: '3',
    title: 'ARTICLE TITLE',
    name: 'test8',
    imgUrl:
      '/Template%201.0/Related%20Articles/CF-Related-Article-Image-3.0.jpg',
  },
];
