<div class="section-service">
  <div class="title">
    <h3>Our Services.</h3>
  </div>
  <div class="section-content">
    <div class="section-row">
      <app-our-service-col
        (click)="clickSection('CustomsBrokerage')"
        content="CUSTOMS BROKERAGE"
        img="assets/images/customs-brokerage.png"
      ></app-our-service-col>
      <app-our-service-col
        (click)="clickSection('GlobalOceanForwarding')"
        content="GLOBAL OCEAN FORWARDING"
        img="assets/images/global-ocean-forwarding.png"
      ></app-our-service-col>
      <app-our-service-col
        (click)="clickSection('GlobalAirForwarding')"
        content="GLOBAL AIR FORWARDING"
        img="assets/images/global-air-forwarding.png"
      ></app-our-service-col>
      <app-our-service-col
        (click)="clickSection('DomesticTransportation')"
        content="DOMESTIC TRANSPORTATION"
        img="assets/images/domestic-transportation.png"
      ></app-our-service-col>
    </div>
    <div class="section-row2">
      <app-our-service-col
        (click)="clickSection('ProjectLogistics')"
        content="PROJECT LOGISTICS"
        img="assets/images/project-logistics.png"
      ></app-our-service-col>
      <app-our-service-col
        (click)="clickSection('TechnologySolutions')"
        content="TECHNOLOGY SOLUTIONS"
        img="assets/images/technology-solutions.png"
      ></app-our-service-col>
      <app-our-service-col
        (click)="clickSection('WarehousingSolutions')"
        content="WAREHOUSING SOLUTIONS"
        img="assets/images/warehousing-solutions.png"
      ></app-our-service-col>
      <app-our-service-col
        (click)="clickSection('CargoInsurance')"
        content="CARGO INSURANCE"
        img="assets/images/cargo-insurance.png"
      ></app-our-service-col>
    </div>
  </div>
</div>
