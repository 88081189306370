import { Localization } from 'app/share/data-type/common.types';

export const IndustryFashionLocalization: {
  [key: string]: IndustryFashionLocalizationClass;
} = {
  en: {
    title: 'Fashion',
    subTitle: 'Industry',
    description1:
      'Transportation of apparel, footwear, accessories, and textiles, with a focus on fast and flexible supply chains to meet seasonal demands and fashion trends, including airfreight for expedited delivery. ',
  },
  cn: {},
  jp: {},
  th: {},
  nl: {},
  es: {},
};

export interface IndustryFashionLocalizationClass extends Localization {}
