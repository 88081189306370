<div class="image-text-body" [ngStyle]="{ '--bgimg': bgimg, '--bgTopPostion': bgTopPostion }">
  <div
    class="module-short {{ item.leftToRight ? 'lr' : 'rl' }}"
    data-scroll
    attr.scroll-id="{{ i }}"
    attr.scroll-dir="{{ item.leftToRight }}"
    [id]="'imgtext_' + i"
    *ngFor="let item of imageTextFadeInData; index as i"
  >
    <ng-container *ngIf="isMobile; else wide">
      <div class="module-item {{ i > 2 ? 'light-blue' : '' }}">
        <ng-container *ngIf="item.leftToRight; else mobileRightToLeft">
          <div class="module-item-img">
            <img [src]="item.img" />
          </div>
          <div class="module-item-text">
            <div class="text-body">
              <h1 class="text-title">{{ item.text.title }}</h1>
              <div class="text-content">{{ item.text.content }}</div>
              <li class="nav-list-item" *ngFor="let nav of item.text.navList">
                <a class="nav-list-link" href="{{ nav.path }}">{{ nav.title }}</a>
              </li>
              <div *ngIf="!item.text.navList" class="btn-learn-more">
                <app-btn theme="{{ i > 2 ? 'blue-to-blue' : 'light-blue-to-light-blue' }}" [link]="item.link ?? ''">
                  LEARN MORE
                </app-btn>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #mobileRightToLeft>
          <div class="module-item-img img-move-right">
            <img [src]="item.img" />
          </div>
          <div class="module-item-text">
            <div class="text-body move-right">
              <h1 class="text-title">{{ item.text.title }}</h1>
              <div class="text-content">{{ item.text.content }}</div>
              <li class="nav-list-item" *ngFor="let nav of item.text.navList">
                <a class="nav-list-link" href="{{ nav.path }}">{{ nav.title }}</a>
              </li>
              <div *ngIf="!item.text.navList" class="btn-learn-more">
                <app-btn theme="{{ i > 2 ? 'blue-to-blue' : 'light-blue-to-light-blue' }}" [link]="item.link ?? ''">
                  LEARN MORE
                </app-btn>
              </div>
            </div>
          </div>
          <div class="module-item-over {{ i > 3 ? 'light-blue' : '' }}"></div>
        </ng-template>
      </div>
    </ng-container>
    <ng-template #wide>
      <div class="module-item {{ item.leftToRight ? 'lr' : 'rl' }}">
        <ng-container *ngIf="item.leftToRight; else rightToLeft">
          <div class="module-item-img">
            <img [src]="item.img" />
          </div>
          <div class="module-item-text">
            <div class="text-body">
              <h1 class="text-title">{{ item.text.title }}</h1>
              <div class="text-content">{{ item.text.content }}</div>
              <li class="nav-list-item" *ngFor="let nav of item.text.navList">
                <a class="nav-list-link" href="{{ nav.path }}">{{ nav.title }}</a>
              </li>
              <div *ngIf="!item.text.navList" class="btn-learn-more">
                <app-btn
                  theme="{{ item.text.btnColor == 'blue' ? 'blue-to-blue' : 'light-blue-to-light-blue' }}"
                  [link]="item.link ?? ''"
                >
                  LEARN MORE
                </app-btn>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-template #rightToLeft>
          <div class="module-item-text">
            <div class="text-body move-right">
              <h1 class="text-title">{{ item.text.title }}</h1>
              <div class="text-content">{{ item.text.content }}</div>
              <li class="nav-list-item" *ngFor="let nav of item.text.navList">
                <a class="nav-list-link" href="{{ nav.path }}">{{ nav.title }}</a>
              </li>
              <div *ngIf="!item.text.navList" class="btn-learn-more">
                <app-btn
                  theme="{{ item.text.btnColor == 'blue' ? 'blue-to-blue' : 'light-blue-to-light-blue' }}"
                  [link]="item.link ?? ''"
                >
                  LEARN MORE
                </app-btn>
              </div>
            </div>
          </div>
          <div class="module-item-img">
            <img [src]="item.img" />
          </div>
          <div class="module-item-over {{ i > 1 ? 'light-blue' : '' }} {{ i == 1 ? 'special' : '' }}"></div>
        </ng-template>
      </div>
    </ng-template>
  </div>
</div>
