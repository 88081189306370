<div [class]="{'section-nav': type === 'big', 'section-nav-small': type === 'small'}">
  <div class="content">
    <div class="col">
      <a href="https://tracking.clearfreight.com/" target="_blank">
        <img class="img"
          [src]="type === 'big' ? 'assets/images/tracking-icon.png' : 'assets/images/tracking-small-icon.png'">
        <div class="text">
          TRACKING
        </div>
      </a>
    </div>
    <div class="col">
      <a routerLink="/forms">
        <img class="img" [src]="type === 'big' ? 'assets/images/forms-icon.png' : 'assets/images/forms-small-icon.png'">
        <div class="text">
          FORMS
        </div>
      </a>
    </div>
    <div class="col">
      <a routerLink="/contact">
        <img class="img"
          [src]="type === 'big' ? 'assets/images/contact-icon.png' : 'assets/images/contact-small-icon.jpg'">
        <div class="text">
          CONTACT
        </div>
      </a>
    </div>
  </div>
</div>