import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-items-and-descs',
  templateUrl: './items-and-descs.component.html',
  styleUrls: ['./items-and-descs.component.scss'],
})
export class ItemsAndDescsComponent implements OnInit {
  @Input() item_data: { item: string; description: string }[] = [
    { item: 'title', description: 'description' },
    { item: 'title', description: 'description' },
    { item: 'title', description: 'description' },
    { item: 'title', description: 'description' },
    { item: 'title', description: 'description' },
    { item: 'title', description: 'description' },
    { item: 'title', description: 'description' },
  ];
  @Output() setState = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}
}
