import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { Router } from "@angular/router";
import { ClickParams } from "../../data-type/common.types";

@Component({
  selector: 'app-forms-row',
  templateUrl: './forms-row.component.html',
  styleUrls: ['./forms-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormsRowComponent implements OnInit {

  @Input() title = '';
  @Input() fileUrl = '';
  @Input() type = 'Common';
  @Input() hrefUrl = '';
  @Output() onClick = new EventEmitter<ClickParams>();

  constructor(
    public router: Router
  ) { }

  ngOnInit(): void {
  }

  public handleClick() {
    this.onClick.emit({
      title: this.title,
      name: this.fileUrl
    });
  }

  public handleCtpatClick() {
    this.router.navigate(['/forms/ctpat-detail/'])
  }

}
