import { Localization } from 'app/share/data-type/common.types';

export const IndustryJpFoodLocalization: {
  [key: string]: IndustryJpFoodLocalizationClass;
} = {
  en: {
    title: 'Japanese Food',
    subTitle: 'Industry',
    description1:
      'Transportation of Japanese food products, including rice, noodles, sauces, and seasonings, with a focus on maintaining product quality, freshness, and authenticity throughout the supply chain.',
    description2:
      'Our team specializes in the intricate process of customs clearance for Japanese food products. We have in-depth understanding of Japanese food safety standards and import regulations. Our dedicated services ensure that Japanese food imports are cleared swiftly and compliantly',
  },
  cn: {},
  jp: {},
  th: {},
  nl: {},
  es: {},
};

export interface IndustryJpFoodLocalizationClass extends Localization {}
