import { Localization } from 'app/share/data-type/common.types';

export const IndustryDairyLocalization: {
  [key: string]: IndustryDairyLocalizationClass;
} = {
  en: {
    title: 'Dairy',
    subTitle: 'Industry',
    description1:
      'Transportation of dairy products, including milk, cheese, yogurt, and butter, with a focus on temperature-controlled logistics, food safety compliance, and timely delivery to retailers, wholesalers, and food service providers. ',
  },
  cn: {},
  jp: {},
  th: {},
  nl: {},
  es: {},
};

export interface IndustryDairyLocalizationClass extends Localization {}
