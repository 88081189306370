import { Localization } from 'app/share/data-type/common.types';

export const IndustryAutomotiveLocalization: {
  [key: string]: IndustryAutomotiveLocalizationClass;
} = {
  en: {
    title: 'Automotive',
    subTitle: 'Industry',
    description1:
      'Transportation of vehicles, automotive parts, and components from manufacturing plants to distribution centers and dealerships, often requiring specialized handling and transportation modes such as car carriers and dedicated freight services. ',
  },
  cn: {},
  jp: {},
  th: {},
  nl: {},
  es: {},
};

export interface IndustryAutomotiveLocalizationClass extends Localization {}
