<div class="contact">
  <div class="contact-main">
    <div class="body">
      <div class="left">
        <h1>{{ localizedText.title }}</h1>
      </div>
      <div class="right">
        <form>
          <ul>
            <li>
              <mat-form-field class="full" floatLabel="never">
                <mat-label>{{ localizedText.name }}</mat-label>
                <input
                  matInput
                  type="string"
                  placeholder="First Name"
                  [formControl]="nameFormControl"
                  [errorStateMatcher]="matcher"
                />
              </mat-form-field>
            </li>
            <li>
              <mat-form-field class="full" floatLabel="never">
                <mat-label>{{ localizedText.company }}</mat-label>
                <input
                  matInput
                  placeholder="Company Name"
                  [formControl]="companyNameFormControl"
                />
              </mat-form-field>
            </li>
            <li>
              <mat-form-field class="full" floatLabel="never">
                <mat-label>{{ localizedText.email }}</mat-label>
                <input
                  matInput
                  placeholder="Company Email"
                  [formControl]="companyEmailFormControl"
                />
              </mat-form-field>
            </li>
            <li>
              <mat-form-field class="full" floatLabel="never">
                <mat-label>{{ localizedText.phone }}</mat-label>
                <input
                  matInput
                  placeholder="Company Phone"
                  [formControl]="companyPhoneFormControl"
                />
              </mat-form-field>
              <div class="opt-in-container">
                <mat-checkbox [formControl]="textingControl" color="primary"
                  ><p class="opt-in">{{ localizedText.textOptIn }}</p>
                </mat-checkbox>
              </div>
            </li>
            <li>
              <mat-form-field class="full" floatLabel="never">
                <mat-label>{{ localizedText.interestedIn }}</mat-label>
                <input
                  matInput
                  [formControl]="subjectControl"
                  [matAutocomplete]="auto"
                />
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option
                    *ngFor="let option of filteredOptions | async"
                    [value]="option"
                  >
                    {{ option }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </li>
            <li>
              <mat-form-field class="full" floatLabel="never">
                <mat-label>{{ localizedText.howCanWeHelp }}</mat-label>
                <textarea
                  matInput
                  [formControl]="descriptionControl"
                  placeholder="Message"
                ></textarea>
              </mat-form-field>
            </li>
            <li>
              <div class="submit">
                <div class="button-container">
                  <a (click)="handleSubmit()">
                    <div class="btn" style="height: 50px; width: 150px">
                      {{ localizedText.submit }}
                    </div>
                  </a>
                </div>
              </div>
            </li>
            <li>
              <div class="texting-disclaimer">
                {{ localizedText.textingDisclaimer }}
                <a href="./privacy-policy" target="_blank">{{
                  localizedText.privacyPolicy
                }}</a
                >.
              </div>
            </li>
          </ul>
        </form>
      </div>
    </div>
  </div>
</div>
