<div class="industry">
  <app-sub-header
    src="./assets/images/Industry/CPG/CF-Consumer-Product-Goods.jpg"
    top="-300px"
  ></app-sub-header>
  <div class="manufacturing">
    <div class="body">
      <h3>{{ localizedText.subTitle }}</h3>
      <h1>{{ localizedText.title }}</h1>
      <div class="text">{{ localizedText.description1 }}</div>
      <div class="text">{{ localizedText.description2 }}</div>
      <h2>{{ localizedText.item1 }}</h2>
      <div>
        <app-item-list [item_data]="localizedText.items1 ?? []"></app-item-list>
      </div>

      <app-contact-and-related
        [outro]="localizedText.outro ?? ''"
        [services_data]="services_data"
        [article_service_data]="article_service_data"
      ></app-contact-and-related>
    </div>
  </div>
</div>
