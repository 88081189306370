<div class="video-dialog">
  <div class="row" style="height: 100%">
    <div class="col container" style="width: 30%">
      <h1>Who We Are</h1>
      <p>
        Click the play button to learn more about ClearFreight values, services,
        heritage, and more. Meet some of the people who make ClearFreight a
        great place to work and do business with.
      </p>
      <p class="close-button" (click)="closeSelf()">[X] Close</p>
    </div>

    <div class="col container" style="width: 70%">
      <div class="loading" *ngIf="videoLoading">
        <mat-spinner></mat-spinner>
      </div>
      <iframe
        width="80%"
        height="80%"
        src="https://www.youtube.com/embed/RANkUmpCETM"
        title='ClearFreight "Who We Are" Video'
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        (load)="videoLoading = false"
      ></iframe>
    </div>
  </div>
</div>
