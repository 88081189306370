import { Localization } from 'app/share/data-type/common.types';

export const IndustryLocalization: {
  [key: string]: IndustryLocalizationClass;
} = {
  en: {
    title: 'Industry',
    subTitle: 'We specialize in a wide range of industries',
    description1:
      'ClearFreight has been providing logistics services to a wide range of industries for over 50 years. Our team of experts has the knowledge and experience to handle the most complex logistics challenges.',
    description2:
      'We have extensive experience in a wide range of industries, including',
    industryList: [
      'Automotive',
      'Chemicals',
      'Oil & Gas',
      'Government',
      'Healthcare',
      'Retail',
      'Consumer Goods',
      'Cosmetics',
      'Electronics',
      'Fashion & Apparel',
      'Pet Products',
      'Food & Beverage',
      'Japanese Food',
      'Dairy',
    ],
    automotive: 'Automotive',
    chemicals: 'Chemicals',
    oilGas: 'Oil & Gas',
    government: 'Government',
    healthcare: 'Healthcare',
    retail: 'Retail',
    consumerGoods: 'Consumer Goods',
    cosmetics: 'Cosmetics',
    electronics: 'Electronics',
    fashionApparel: 'Fashion & Apparel',
    petProducts: 'Pet Products',
    foodBeverage: 'Food & Beverage',
    japaneseFood: 'Japanese Food',
    dairy: 'Dairy',
    automotiveDescription:
      'Transportation of vehicles, automotive parts, and components from manufacturing plants to distribution centers and dealerships, often requiring specialized handling and transportation modes such as car carriers and dedicated freight services. ',
    chemicalsDescription:
      'Transportation of hazardous and non-hazardous chemicals, including liquid bulk, gases, and solids, with a focus on compliance with safety regulations, proper packaging, and handling procedures. ',
    consumerGoodsDescription:
      'Transportation of a wide range of consumer products, including clothing, electronics, appliances, and household goods, typically requiring efficient distribution networks and timely delivery to retail stores or direct-to-consumer channels.',
    cosmeticsDescription:
      'Transportation of beauty and personal care products, including skincare, makeup, fragrance, and hair care items, often requiring careful handling, temperature-controlled storage, and secure transportation to maintain product quality and appearance. ',
    dairyDescription:
      'Transportation of dairy products, including milk, cheese, yogurt, and butter, with a focus on temperature-controlled logistics, food safety compliance, and timely delivery to retailers, wholesalers, and food service providers. ',
    electronicsDescription:
      'Transportation of electronic devices, components, and equipment, often requiring specialized handling, packaging, and temperature-controlled transportation to prevent damage and ensure product integrity. ',
    fashionApparelDescription:
      'Transportation of apparel, footwear, accessories, and textiles, with a focus on fast and flexible supply chains to meet seasonal demands and fashion trends, including airfreight for expedited delivery. ',
    foodBeverageDescription:
      'Transportation of perishable and non-perishable food products, beverages, and ingredients, with a focus on temperature-controlled logistics, food safety compliance, and timely delivery to retailers, restaurants, and consumers. ',
    japaneseFoodDescription:
      'Transportation of Japanese food products, including rice, noodles, sauces, and seasonings, with a focus on maintaining product quality, freshness, and authenticity throughout the supply chain. ',
    governmentDescription:
      'Transportation of goods and materials for government agencies, including military equipment, humanitarian aid, and disaster relief supplies, often requiring secure and reliable logistics services to support critical missions and operations. ',
    healthcareDescription:
      'Transportation of pharmaceuticals, medical devices, equipment, and supplies, with a focus on temperature-sensitive products, strict regulatory requirements, and secure transportation practices to ensure product safety and efficacy.',
    oilGasDescription:
      'Transportation of petroleum products, chemicals, equipment, and materials for the oil and gas industry, including pipeline transportation, marine shipping, and specialized logistics services for offshore projects and remote locations. ',
    retailDescription:
      'Transportation of merchandise, inventory, and supplies for retail stores, e-commerce fulfillment centers, and distribution networks, with a focus on efficient supply chain management, inventory optimization, and last-mile delivery solutions. ',
    petProductsDescription:
      'Transportation of pet products, including pet food, toys, accessories, and supplies, often requiring specialized handling, packaging, and temperature-controlled transportation to ensure product quality and safety. ',
  },
  es: {},
  cn: {},
  jp: {},
  th: {},
  nl: {},
};

export interface IndustryLocalizationClass extends Localization {
  industryList?: string[];
  automotive?: string;
  chemicals?: string;
  oilGas?: string;
  government?: string;
  healthcare?: string;
  retail?: string;
  consumerGoods?: string;
  cosmetics?: string;
  electronics?: string;
  fashionApparel?: string;
  petProducts?: string;
  foodBeverage?: string;
  japaneseFood?: string;
  dairy?: string;
  automotiveDescription?: string;
  chemicalsDescription?: string;
  oilGasDescription?: string;
  governmentDescription?: string;
  healthcareDescription?: string;
  retailDescription?: string;
  consumerGoodsDescription?: string;
  cosmeticsDescription?: string;
  electronicsDescription?: string;
  fashionApparelDescription?: string;
  petProductsDescription?: string;
  foodBeverageDescription?: string;
  japaneseFoodDescription?: string;
  dairyDescription?: string;
}
