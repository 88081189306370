const material_url = "https://clrfwebservicestorage.blob.core.windows.net/clearfreight-dev-website/";//http://localhost:6080/
export const heritageData = [
  {
    leftToRight: true, year: '1973', title: 'THE START', content:
      `<p>Founded in San Francisco in 1973, ClearFreight has a long
  tenure of commitment to service excellence, training and
  developing the best teams, and responding to the changing
  needs of our clients.</p>
  <p>
  Many of our team members started their careers at
ClearFreight since its early years, and now form the
backbone of supervision of our operations and customer
support functions. ClearFreight has a culturally diverse team
that speaks and writes many languages, which enables
us to effectively assist clients and help them navigate the
complex requirements of international trade.
  </p>` },
  {
    leftToRight: false, year: '1975', title: '', content:
      `<p>Clearfreight opened a second office in Los
      Angeles in 1975, which is now the location of
      our corporate headquarters.
</p>` },
  {
    leftToRight: true, year: '1985', title: 'EXPORT DIVISION', content:
      `<p>In 1985, in response to the needs of existing clients, we
    launched our export division, and began offering outbound
    transportation services in addition to import traffic and
    customs brokerage. Since then, Clearfreight has developed a
    solid infrastructure to provide a comprehensive suite of door-to-door logistics
</p>` },
  {
    leftToRight: false, year: '2001', title: '', content:
      `<p>Our Miami branch office opened in 2001 to
  serve shippers and consignees to and from
  South and Central America.
</p>`},
  {
    leftToRight: true, year: '2009', title: '', content:
      `<p>&nbsp;</p><p>In 2009 we opened Houston, Dallas, and Atlanta offices,
      added more coverage in U.S.A
</p>` }
  ,
  {
    leftToRight: false, year: '2014', title: 'EXPANDING', content:
      `<p>In 2014, ClearFreight established additional
      coverage in Asia and Canada through an
      acquisition to expand our operations into Hong
      Kong, Shanghai, Beijing, Qingdao, Shenzhen,
      Guangzhou, Dalian and Xiamen. Our Toronto
      office became our first location in Canada,
      operating under Clearfreight Canada Inc
</p>` }
  ,
  {
    leftToRight: true, year: '2018', title: 'EXPANDING', content:
      `<p>In 2018, we opened our first Latin America office in
      Santiago, Chile, marking our commitment to serving
      clients' needs as they expand their international business
      to new global markets.
</p>` }
]


export const videos = [
  { img: material_url + "CF-Homepage-Air-1.0.jpg", video: material_url + "CF-Homepage-Air-1.0.mp4" },
  { img: material_url + "CF-Homepage-Ocean_1.0.jpg", video: material_url + "CF-Homepage-Ocean_1.0.mp4" },
  { img: material_url + "CF-Homepage-Rail-1.0.jpg", video: material_url + "CF-Homepage-Rail-1.0.mp4" },
  { img: material_url + "CF-Homepage-Road-1.0.jpg", video: material_url + "CF-Homepage-Road-1.0.mp4" }];
