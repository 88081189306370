import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { fadeInDown } from 'ng-animate';
@Component({
  selector: 'app-title-description',
  templateUrl: './title-description.component.html',
  styleUrls: ['./title-description.component.scss'],
})
export class TitleDescriptionComponent implements OnInit {
  @Input() title: string = '';
  @Input() subTitle: string = '';
  @Input() height: string = '600px';
  @Input() spaceHeight: string = '80px';
  @Input() titleFontSize: string = '32px';
  @Output() setState = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}
}
