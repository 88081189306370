import { Component, OnInit } from '@angular/core';
import { LocalizationService } from 'app/services/localization.service';
import {
  IndustryCosmeticsLocalizationClass,
  IndustryCosmeticsLocalization,
} from './industry-costmetics.localization';
import {
  RelatedServicesService,
  ServiceDescription,
} from 'app/services/related-services.service';
import { relatedArticle } from 'app/share/data-type/post.types';
import { Router } from '@angular/router';
import { PostService } from 'app/services/post.service';

@Component({
  selector: 'app-industry-cosmetics',
  templateUrl: './industry-cosmetics.component.html',
  styleUrls: ['../industry-styles.scss'],
})
export class IndustryCosmeticsComponent implements OnInit {
  public localizedText: IndustryCosmeticsLocalizationClass;

  article_service_data: relatedArticle[];
  services_data: ServiceDescription[];

  constructor(
    private localizationService: LocalizationService,
    private relServService: RelatedServicesService,
    private relArticleService: PostService,
    private router: Router
  ) {
    this.localizedText = this.localizationService.getTranslation(
      IndustryCosmeticsLocalization
    );
    let route = this.router.url;
    this.services_data = this.relServService.getServices(route);
    this.relArticleService.getRelatedPostList().subscribe((data) => {
      this.article_service_data =
        this.relArticleService.mapPostDataToRelatedArticles(data);
    });
  }

  ngOnInit(): void {}
}
