import { Directive, EventEmitter, HostListener, Output } from "@angular/core";

@Directive({ selector: '[mouseWheel]' })
export class MouseWheelDirective {
  @Output() mouseWheelUp = new EventEmitter();
  @Output() mouseWheelDown = new EventEmitter();

  @HostListener('mousewheel', ['$event']) onMouseWheelChrome(event: any) {
    if (event.ctrlKey) {
      this.mouseWheelFunc(event);
      event.returnValue = false;//Prohibit browser scaling
    }
  }

  @HostListener('DOMMouseScroll', ['$event']) onMouseWheelFirefox(event: any) {
    if (event.ctrlKey) {
      this.mouseWheelFunc(event);
      event.returnValue = false;
    }
  }

  @HostListener('onmousewheel', ['$event']) onMouseWheelIE(event: any) {
    if (event.ctrlKey) {
      this.mouseWheelFunc(event);
      event.returnValue = false;
    }
  }

  mouseWheelFunc(event: any) {
    //var event = window.event;
    //event.detail -----    Firefox
    //event.wheelDelta -----IE/Opera/Chrome
    var delta = Math.max(-1, Math.min(1, (event.wheelDelta || -event.detail)));
    if (delta > 0) {
      this.mouseWheelUp.emit(event);
    } else if (delta < 0) {
      this.mouseWheelDown.emit(event);
    }
  }
}
