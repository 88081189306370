import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-article-header',
  templateUrl: './article-header.component.html',
  styleUrls: ['./article-header.component.scss']
})
export class ArticleHeaderComponent implements OnInit {
  bounce: any;
  @Input() src: string = "";
  @Input() top: string = "-250px";
  @Input() title: string | undefined = "Article Title";
  @Input() subTitle: string | undefined = "LOREM IPSUM";
  @Output() setState = new EventEmitter();
  constructor(
  ) { }

  ngOnInit(): void {
  }
}
