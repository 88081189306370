<div class="related-services">
  <h1>Related Services</h1>
  <div class="services-content">
    <ng-container *ngFor="let item of services_data; let i = index">
      <div class="item">
        <a href="" class="light-blue">
          <h3>{{ item.title }}</h3>
        </a>
        <div class="desc">{{ item.desc }}</div>
        <div class="btn-learn-more">
          <app-btn theme="blue" [link]="item.link" [fontSize]="isMobile ? 'small' : 'normal'">LEARN MORE</app-btn>
        </div>
      </div>
      <div class="split" *ngIf="i == 0 || i == 1"></div>
    </ng-container>
  </div>
</div>
