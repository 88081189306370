import { formCategoryType } from 'app/share/data-type/common.types';
import {
  FormsLocalization,
  FormsLocalizationClass,
} from './forms.localization';
import { LocalizationService } from 'app/services/localization.service';

export const get_forms_data = (
  localizationService: LocalizationService
): formCategoryType[] => {
  let localizedText: FormsLocalizationClass =
    localizationService.getTranslation(FormsLocalization);
  return [
    {
      type: localizedText.imp ?? 'IMPORT',
      files: [
        {
          id: 'customs-power-of-attorney',
          name:
            localizedText.customsPowerOfAttorney ?? 'Customs Power of Attorney',
          path: 'assets/files/ClearFreight Customs Power of Attorney.pdf',
        },
        {
          id: 'c-tpat',
          name: localizedText.ctpat ?? 'C-TPAT',
          path: '../resources/ctpat',
        },
      ],
    },
    {
      type: localizedText.exp ?? 'EXPORT',
      files: [
        {
          id: 'export-power-of-attorney',
          name:
            localizedText.exportPowerOfAttorney ?? 'Export Power of Attorney',
          path: 'assets/files/ClearFreight Export Power of Attorney.pdf',
        },
        {
          id: 'export-shipper-s-letter-of-instruction-sli',
          name:
            localizedText.exportShipperSLI ??
            "Export Shipper's Letter of Instruction (SLI)",
          path: 'assets/files/ClearFreight Export SLI.pdf',
        },
        {
          id: 'export-solas-vgm-certificate',
          name:
            localizedText.exportSolasVGMCertificate ??
            'Export SOLAS VGM Certificate',
          path: 'assets/files/ClearFreight SOLAS VGM Certificate.pdf',
        },
        {
          id: 'export-tariff-fmc',
          name: localizedText.exportTariffFMC ?? 'Export Tariff FMC',
          path: 'http://tariff.clearfreight.com/',
        },
      ],
    },

    {
      type: localizedText.financialDocuments ?? 'FINANCIAL DOCUMENTS',
      files: [
        {
          id: 'credit-app',
          name: localizedText.creditApp ?? 'Credit App',
          path: 'assets/files/ClearFreight Credit Application.pdf',
        },
        {
          id: 'routing-order',
          name: localizedText.routingOrder ?? 'Routing Order',
          path: 'assets/files/Microsoft Word - routingorder05.pdf',
        },
      ],
    },
    {
      type: localizedText.billOfLadingTerms ?? 'BILL OF LADING TERMS',
      files: [
        {
          id: 'bill-of-lading-terms-global',
          name:
            localizedText.billOfLadingTermsGlobal ??
            'Bill of Lading Terms & Conditions - Global',
          path: 'assets/files/Microsoft Word - Global terms and conditions.pdf',
        },
        {
          id: 'bill-of-lading-terms-jp',
          name:
            localizedText.billOfLadingTermsJP ??
            'Bill of Lading Terms & Conditions - Japan',
          path: 'assets/files/Microsoft Word - JP terms and conditions.pdf',
        },
        {
          id: 'bill-of-lading-terms-hk',
          name:
            localizedText.billOfLadingTermsHK ??
            'Bill of Lading Terms & Conditions - Hong Kong',
          path: 'assets/files/Microsoft Word - HKG terms and conditions.pdf',
        },
        {
          id: 'transportation-services',
          name:
            localizedText.transportationServices ??
            'Transportation Services - Domestic US',
          path: 'assets/files/Transportation Services.pdf',
        },
      ],
    },
    {
      type: localizedText.customerServiceTerms ?? 'CUSTOMER SERVICE TERMS',
      files: [
        {
          id: 'customer-service-terms-us',
          name:
            localizedText.customerServiceTermsUS ??
            'Customer Service Terms & Conditions - US',
          path: 'assets/files/USA-Terms-Service-ClearFreight.pdf',
        },
        {
          id: 'customer-service-terms-ca',
          name:
            localizedText.customerServiceTermsCA ??
            'Customer Service Terms & Conditions - Canada',
          path: 'assets/files/Canada - STANDARD TRADING CONDITIONS.pdf',
        },
        {
          id: 'customer-service-terms-nl',
          name:
            localizedText.customerServiceTermsNL ??
            'Customer Service Terms & Conditions - Netherlands',
          path: 'assets/files/CLEARFreight-Netherlands-General Conditions.pdf',
        },
        {
          id: 'customer-service-terms-cn',
          name:
            localizedText.customerServiceTermsCN ??
            'Customer Service Terms & Conditions - China',
          path: 'assets/files/Microsoft Word - Terms-Service-CLEARFREIGHT-China.pdf',
        },
        {
          id: 'customer-service-terms-hk',
          name:
            localizedText.customerServiceTermsHK ??
            'Customer Service Terms & Conditions - Hong Kong',
          path: 'assets/files/Microsoft Word - Terms-Service-CLEARFREIGHT-HONG_KONG.pdf',
        },
      ],
    },
    {
      type: 'Do not display',
      files: [
        {
          id: 'transportation-services',
          name:
            localizedText.transportationServices ??
            'Transportation Services - Domestic US',
          path: 'assets/files/Transportation Services.pdf',
        },
        {
          id: 'general-disclaimers',
          name: localizedText.generalDisclaimers ?? 'General Disclaimers',
          path: 'assets/files/ClearFreight Disclaimers.pdf',
        },
        {
          id: 'insurance-disclaimer',
          name: localizedText.insuranceDisclaimer ?? 'Insurance Disclaimer',
          path: 'assets/files/ClearFreight Insurance Disclaimer.pdf',
        },
      ],
    },
    {
      type: 'CTAPT',
      files: [
        {
          id: 'ctpat-cybersecurity',
          name:
            localizedText.ctpatCybersecurity ??
            'CTPAT & Cybersecurity - SCSS Bryan Smith - April 6, 2021',
          path: 'assets/files/CTPAT & Cybersecurity.pdf',
        },
        {
          id: 'ctpat-the-southern-border',
          name:
            localizedText.ctpatTheSouthernBorder ??
            'CTPAT & the Southern Border - SCSS Ricardo Villarreal - 04.08.2021',
          path: 'assets/files/CTPAT 2021 Southwest Border Overview.pdf',
        },
        {
          id: 'ctpat-presentation',
          name:
            localizedText.ctpatPresentation ??
            'CTPAT Presentation April 2021_v1 - Director Garza - April 6, 2021',
          path: 'assets/files/CTPAT Presentation.pdf',
        },
        {
          id: 'ctpat-in-the-maritime-environment',
          name:
            localizedText.ctpatMaritimeEnvironment ??
            'CTPAT in the Maritime Environment - CBP',
          path: 'assets/files/CTPAT in the Maritime Environment.pdf',
        },
        {
          id: 'ctpat-trade-compliance-forced-labor',
          name:
            localizedText.ctpatForcedLabor ??
            'CTPAT Trade Compliance & Forced Labor - CBP',
          path: 'assets/files/Trade Compliance & Forced Labor.pdf',
        },
        {
          id: 'ctpat-virtual-validation-process-2021',
          name:
            localizedText.ctpatVirtualValidation ??
            'CTPAT Virtual Validation Process 2021',
          path: 'assets/files/Virtual Validation Process 2021.pdf',
        },
        {
          id: 'ctpat-northern-border-seizure-fast-lane-2021',
          name:
            localizedText.ctpatNorthernBorder ??
            'CTPAT Northern Border Seizures & FAST Lane 2021',
          path: 'assets/files/Northern Border Seizures & FAST Lane 2021.pdf',
        },
        {
          id: 'supply-chain-security-standard-operating-procedures-2020',
          name:
            localizedText.supplyChainSecurity ??
            "Supply Chain Security Standard Operating Procedures (SOP's) 2020",
          path: 'assets/files/SOP 2020.pdf',
        },
        {
          id: 'ctpat-security-questionnaire-2020',
          name:
            localizedText.ctpatSecurityQuestionnaire ??
            'C-TPAT Security Questionnaire 2020',
          path: 'assets/files/C-TPAT Security Questionnaire.pdf',
        },
        {
          id: 'isf-form',
          name: localizedText.isfForm ?? 'ISF 10+2 Form',
          path: 'assets/files/ISF 10+2 Form.pdf',
        },
      ],
    },
  ];
};

// export const forms_data: formCategoryType[] = [
//   {
//     type: localizedText.imp ?? 'IMPORT',
//     files: [
//       {
//         id: 'customs-power-of-attorney',
//         name:
//           localizedText.customsPowerOfAttorney ?? 'Customs Power of Attorney',
//         path: 'assets/files/ClearFreight Customs Power of Attorney.pdf',
//       },
//       {
//         id: 'c-tpat',
//         name: localizedText.ctpat ?? 'C-TPAT',
//         path: '../resources/ctpat',
//       },
//     ],
//   },
//   {
//     type: localizedText.exp ?? 'EXPORT',
//     files: [
//       {
//         id: 'export-power-of-attorney',
//         name: localizedText.exportPowerOfAttorney ?? 'Export Power of Attorney',
//         path: 'assets/files/ClearFreight Export Power of Attorney.pdf',
//       },
//       {
//         id: 'export-shipper-s-letter-of-instruction-sli',
//         name:
//           localizedText.exportShipperSLI ??
//           "Export Shipper's Letter of Instruction (SLI)",
//         path: 'assets/files/ClearFreight Export SLI.pdf',
//       },
//       {
//         id: 'export-solas-vgm-certificate',
//         name:
//           localizedText.exportSolasVGMCertificate ??
//           'Export SOLAS VGM Certificate',
//         path: '',
//       },
//       {
//         id: 'export-tariff-fmc',
//         name: localizedText.exportTariffFMC ?? 'Export Tariff FMC',
//         path: 'http://tariff.clearfreight.com/',
//       },
//     ],
//   },
//   {
//     type: localizedText.financialDocuments ?? 'FINANCIAL DOCUMENTS',
//     files: [
//       {
//         id: 'credit-app',
//         name: localizedText.creditApp ?? 'Credit App',
//         path: '',
//       },
//       {
//         id: 'routing-order',
//         name: localizedText.routingOrder ?? 'Routing Order',
//         path: '',
//       },
//     ],
//   },
//   {
//     type: localizedText.termsAndConditions ?? 'TERMS AND CONDITIONS',
//     files: [
//       {
//         id: 'transportation-services',
//         name: localizedText.transportationServices ?? 'Transportation Services',
//         path: '',
//       },
//       {
//         id: 'general-disclaimers',
//         name: localizedText.generalDisclaimers ?? 'General Disclaimers',
//         path: '',
//       },
//       {
//         id: 'insurance-disclaimer',
//         name: localizedText.insuranceDisclaimer ?? 'Insurance Disclaimer',
//         path: '',
//       },
//     ],
//   },
//   {
//     type: 'CTAPT',
//     files: [
//       {
//         id: 'ctpat-cybersecurity',
//         name: 'CTPAT & Cybersecurity - SCSS Bryan Smith - April 6, 2021',
//         path: 'assets/files/CTPAT & Cybersecurity.pdf',
//       },
//       {
//         id: 'ctpat-the-southern-border',
//         name: 'CTPAT & the Southern Border - SCSS Ricardo Villarreal - 04.08.2021',
//         path: 'assets/files/CTPAT 2021 Southwest Border Overview.pdf',
//       },
//       {
//         id: 'ctpat-presentation',
//         name: 'CTPAT Presentation April 2021_v1 - Director Garza - April 6, 2021',
//         path: 'assets/files/CTPAT Presentation.pdf',
//       },
//       {
//         id: 'ctpat-in-the-maritime-environment',
//         name: 'CTPAT in the Maritime Environment - CBP',
//         path: 'assets/files/CTPAT in the Maritime Environment.pdf',
//       },
//       {
//         id: 'ctpat-trade-compliance-forced-labor',
//         name: 'CTPAT Trade Compliance & Forced Labor - CBP',
//         path: 'assets/files/Trade Compliance & Forced Labor.pdf',
//       },
//       {
//         id: 'ctpat-virtual-validation-process-2021',
//         name: 'CTPAT Virtual Validation Process 2021',
//         path: 'assets/files/Virtual Validation Process 2021.pdf',
//       },
//       {
//         id: 'ctpat-northern-border-seizure-fast-lane-2021',
//         name: 'CTPAT Northern Border Seizures & FAST Lane 2021',
//         path: 'assets/files/Northern Border Seizures & FAST Lane 2021.pdf',
//       },
//       {
//         id: 'supply-chain-security-standard-operating-procedures-2020',
//         name: "Supply Chain Security Standard Operating Procedures (SOP's) 2020",
//         path: 'assets/files/SOP 2020.pdf',
//       },
//       {
//         id: 'ctpat-security-questionnaire-2020',
//         name: 'C-TPAT Security Questionnaire 2020',
//         path: 'assets/files/C-TPAT Security Questionnaire.pdf',
//       },
//       {
//         id: 'isf-form',
//         name: 'ISF 10+2 Form',
//         path: 'assets/files/ISF 10+2 Form.pdf',
//       },
//     ],
//   },
// ];
