<div class="body">
  <div style="height: 300px; width: 100%; overflow: hidden">
    <app-center-pic
      src="./assets/images/About/Heritage/CF-2.0-Drone-Shot.jpg"
    ></app-center-pic>
  </div>
  <div class="content">
    <div class="search-bar">
      <input class="search-input" type="text" placeholder="Search" />
    </div>

    <h1 class="title">Showing Search Results for:</h1>
    <h2 class="subtitle">"{{ "test" }}"</h2>
  </div>
</div>
