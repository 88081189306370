<div class="services">
  <app-center-pic src="./assets/images/Template%201.0/CF-1.0-Header.jpg"></app-center-pic>
  <app-title-description
    [title]="localizedText.title ?? 'Services'"
    [subTitle]="localizedText.subTitle ?? 'ClearFreight Services'"
    [height]="isMobile ? 'auto' : '600px'"
    [spaceHeight]="isMobile ? '30px' : '80px'"
  >
    {{ localizedText.description1 }}
  </app-title-description>
  <div class="img-text">
    <div class="image-text-fadein">
      <app-image-text-fadein
        [imageTextFadeInData]="img_text_data"
        bgimg="url('./assets/images/Template%201.0/CF-Navy-Merge-BG.png') no-repeat"
        [bgTopPostion]="isMobile ? '-830px' : '-300px'"
      ></app-image-text-fadein>
    </div>
  </div>
  <div class="related-stuff">
    <div class="related">
      <app-related-article [article_service_data]="articles"></app-related-article>
    </div>
  </div>
</div>
