import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { OurServiceComponent } from './components/our-service/our-service.component';
import { OurServiceColComponent } from './components/our-service/our-service-col/our-service-col.component';
import { BottomNavComponent } from './components/bottom-nav/bottom-nav.component';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { SubHeaderComponent } from './components/sub-header/sub-header.component';
import { CenterPicComponent } from './components/center-pic/center-pic.component';
import { TitleDescriptionComponent } from './components/title-description/title-description.component';
import { ImageTextFadeInComponent } from './components/image-text-fadein/image-text-fadein.component';
import { RelatedArticleComponent } from './components/related-article/related-article.component';
import { TriArticleComponent } from './components/tri-article/tri-article.component';
import { DoubleArticleComponent } from './components/double-article/double-article.component';
import { NewsListComponent } from './components/news-list/news-list.component';
import { QuadrupleTitleComponent } from './components/quadruple-title/quadruple-title.component';
import { VerticalTextComponent } from './components/vertical-text/vertical-text.component';
import { LoginComponent } from './components/forms-login/forms-login.component';
import { BtnComponent } from './components/btn/btn.component';
import { MatIconModule } from '@angular/material/icon';
import { ServiceSectionComponent } from './components/service-section/service-section.component';
import { FormsRowComponent } from './components/forms-row/forms-row.component';
import { NewsRoomMenuComponent } from './components/newsroom-menu/newsroom-menu.component';
import { NewsRoomSearchComponent } from './components/newsroom-search/newsroom-search.component';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { MatDividerModule } from '@angular/material/divider';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';

import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MoveOutsideDirective } from '../directives/move-outside.directive';
import { NgxSwiperModule } from './components/swiper/swiper.module';
import { ArticleHeaderComponent } from './components/article-header/article-header.component';
import { RelatedServiceComponent } from './components/related-service/related-service.component';
import { NgMultiSelectDropDownModule } from './components/ng-multiselect-dropdown';
import { ItemListComponent } from './components/item-list/item-list.component';
import { IconBtnComponent } from './components/icon-btn/icon-btn.component';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { MouseWheelDirective } from 'app/directives/mousewheel.directive ';
import { RouterModule } from '@angular/router';
import { ContactButtonComponent } from './content-components/contact-button/contact-button.component';
import { ContactFormComponent } from './content-components/contact-button/contact-form/contact-form.component';
import { ItemsAndDescsComponent } from './content-components/items-and-descs/items-and-descs.component';
import { IconImgDescComponent } from './content-components/icon-img-desc/icon-img-desc.component';
import { PicImgDescComponent } from './content-components/pic-img-desc/pic-img-desc.component';
import { ImageWithContentComponent } from './content-components/image-with-content/image-with-content.component';
import { ContactAndRelatedComponent } from './content-components/contact-and-related/contact-and-related.component';
import { BtnNoRouteComponent } from './components/btn-no-route/btn-no-route.component';
import { LookupInputComponent } from './components/lookup-input/lookup-input.component';
import { VideoDialogComponent } from './components/nav-menu/video-dialog/video-dialog.component';
import { AssociationsComponent } from './components/associations/associations.component';
import { MobileLineNavComponent } from './components/mobile-line-nav/mobile-line-nav.component';
@NgModule({
  declarations: [
    OurServiceComponent,
    OurServiceColComponent,
    BottomNavComponent,
    NavMenuComponent,
    SubHeaderComponent,
    ArticleHeaderComponent,
    CenterPicComponent,
    TitleDescriptionComponent,
    ImageTextFadeInComponent,

    RelatedArticleComponent,
    RelatedServiceComponent,
    TriArticleComponent,
    DoubleArticleComponent,
    NewsListComponent,
    ItemListComponent,
    QuadrupleTitleComponent,
    VerticalTextComponent,
    LoginComponent,
    IconBtnComponent,
    BtnComponent,
    ServiceSectionComponent,
    FormsRowComponent,
    NewsRoomMenuComponent,
    NewsRoomSearchComponent,
    SafeUrlPipe,
    MoveOutsideDirective,
    MouseWheelDirective,
    ContactButtonComponent,
    ContactFormComponent,
    ItemsAndDescsComponent,
    IconImgDescComponent,
    PicImgDescComponent,
    ImageWithContentComponent,
    ContactAndRelatedComponent,
    BtnNoRouteComponent,
    LookupInputComponent,
    VideoDialogComponent,
    AssociationsComponent,
    MobileLineNavComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxSwiperModule,
    MatMenuModule,
    MatIconModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatSelectModule,
    NgMultiSelectDropDownModule,
    MatAutocompleteModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatTooltipModule,
    MatExpansionModule,
    ReactiveFormsModule,
    RouterModule,
    NgxMapboxGLModule.withConfig({
      accessToken: 'pk.eyJ1IjoiY2xlYXJmcmVpZ2h0IiwiYSI6ImNrZnptczIzazJlbXkycm10NmR1anpzbmQifQ.QMAT2fLlFPl-WVau7291eg',
    }),
  ],
  exports: [
    CommonModule,
    FormsModule,
    MatMenuModule,
    MatIconModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatSelectModule,
    NgMultiSelectDropDownModule,
    MatAutocompleteModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatTooltipModule,
    MatExpansionModule,
    ReactiveFormsModule,
    OurServiceComponent,
    BottomNavComponent,
    NavMenuComponent,
    SubHeaderComponent,
    ArticleHeaderComponent,
    CenterPicComponent,
    TitleDescriptionComponent,
    ImageTextFadeInComponent,
    RelatedArticleComponent,
    RelatedServiceComponent,
    TriArticleComponent,
    DoubleArticleComponent,
    NewsListComponent,
    ItemListComponent,
    ItemsAndDescsComponent,
    QuadrupleTitleComponent,
    VerticalTextComponent,
    LoginComponent,
    IconBtnComponent,
    BtnComponent,
    BtnNoRouteComponent,
    ServiceSectionComponent,
    FormsRowComponent,
    NewsRoomMenuComponent,
    NewsRoomSearchComponent,
    SafeUrlPipe,
    InfiniteScrollModule,
    NgxSwiperModule,
    MoveOutsideDirective,
    MouseWheelDirective,
    NgxMapboxGLModule,
    ContactButtonComponent,
    IconImgDescComponent,
    PicImgDescComponent,
    ImageWithContentComponent,
    ContactAndRelatedComponent,
    LookupInputComponent,
    AssociationsComponent,
    MobileLineNavComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ShareModule {}
