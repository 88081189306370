<div>
  <div class="main">
    <div class="header">
      <div class="left-icon">
        <a [routerLink]="['../forms/']">
          <app-icon-btn icon="arrow_backward"></app-icon-btn>
        </a>
      </div>
    </div>
    <app-title-description
      [title]="localizedText.title ?? 'C-TPAT'"
      height="auto"
      spaceHeight="0"
    >
      <div class="content">
        <div class="section1">
          {{ localizedText.description1 }}
        </div>
        <div class="section2">
          {{ localizedText.description2 }}
          <app-item-list
            [item_data]="localizedText.items1 ?? []"
          ></app-item-list>

          {{ localizedText.description3 }}
          <span
            ><a
              class="light-blue"
              [href]="
                'https://www.cbp.gov/border-security/ports-entry/cargo-security/ctpat'
              "
              [target]="'_blank'"
              >{{ localizedText.description4 }}</a
            ></span
          >
        </div>
        <div class="section3">
          <div class="row" *ngFor="let item of ctapt_data[0].files">
            <a class="light-blue" [routerLink]="['../forms/' + item.id]">
              {{ item.name }}</a
            >
          </div>
        </div>
      </div>
    </app-title-description>
    <div style="height: 100px"></div>
  </div>
</div>
