import { Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  AnimationBuilder,
  AnimationMetadata,
  AnimationPlayer,
  animate,
  query,
  stagger,
  style,
  transition,
  trigger,
  useAnimation,
} from '@angular/animations';
import { Title } from '@angular/platform-browser';
import { rotateInDownRight, rotateIn, backInRight, backInLeft, backOutLeft, backOutRight, fadeIn } from 'ng-animate';
import { DrawCircle } from './drawcircle';
import { midVideo, videos } from './data';
import { IInfiniteScrollEvent } from 'ngx-infinite-scroll';
import { LocalizationService } from 'app/services/localization.service';
import { AboutLocalization, AboutLocalizationClass } from './about.localization';
import { ScreenService } from 'app/services/screen.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  animations: [],
})
export class AboutComponent implements OnInit {
  midVideo = midVideo;
  videos = videos;
  public localizedText: AboutLocalizationClass;
  public isMobile: boolean = false;

  constructor(
    public router: Router,
    private screenService: ScreenService,
    private localizationService: LocalizationService
  ) {
    this.localizedText = localizationService.getTranslation(AboutLocalization);
    this.isMobile = this.screenService.isMobile();
  }

  ngOnInit(): void {}
}
