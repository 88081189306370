<div class="section-row" *ngIf="type === 'Common'">
  <div class="section-row-left section-content" (click)="handleClick()">{{title}}</div>
  <div class="section-row-right">
    <img (click)="handleClick()" src="./assets/images/global-icon.png">
  </div>
</div>
<div class="section-row" *ngIf="type === 'ETF'">
  <div class="section-row-left section-content">
    <a [href]="hrefUrl" [target]="'_blank'">
      {{title}}
    </a>
  </div>
  <div class="section-row-right">
    <a [href]="hrefUrl" [target]="'_blank'">
      <img src="./assets/images/global-icon.png">
    </a>
  </div>
</div>
<div class="section-row" *ngIf="type === 'CTPAT'">
  <div class="section-row-left section-content">
    <a (click)="handleCtpatClick()">
      {{title}}
    </a>
  </div>
  <div class="section-row-right">
    <a (click)="handleCtpatClick()">
      <img src="./assets/images/global-icon.png">
    </a>
  </div>
</div>