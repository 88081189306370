<!-- <div class="image-with-content">
  <div class="column left-column" [style.width]="leftColumnWidth">
    <img
      class="image"
      *ngIf="imagePosition === 'left'"
      [src]="imageSrc"
      [style.top]="imageTop"
      alt="Header Image"
    />
    <ng-content select=".left-content"></ng-content>
  </div>
  <div class="column right-column" [style.width]="rightColumnWidth">
    <img
      class="image"
      *ngIf="imagePosition === 'right'"
      [src]="imageSrc"
      [style.top]="imageTop"
      alt="Header Image"
    />
    <ng-content select=".right-content"></ng-content>
  </div>
</div> -->

<div
  class="image-with-content"
  [ngStyle]="{ '--bg': 'url(' + imageSrc + ') no-repeat', '--top': imageTop }"
>
  <div class="content">
    <ng-content class="content" select=".innerhtml"></ng-content>
  </div>
</div>
