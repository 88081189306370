import { Component, OnInit, ChangeDetectionStrategy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

import { FormControl, FormGroupDirective, NgForm, UntypedFormControl, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { DemoScheduleLocalization, DemoScheduleLocalizationClass } from './demo-schedule.localization';
import { LocalizationService } from 'app/services/localization.service';
import { ContactService } from 'app/services/contact.service';
import { ToastrService } from 'ngx-toastr';
import { contactParams } from 'app/share/data-type/common.types';
import { ScreenService } from 'app/services/screen.service';

/**
 * Error when invalid control is dirty, touched, or submitted.
 *
 */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-demo-schedule',
  templateUrl: './demo-schedule.component.html',
  styleUrls: ['./demo-schedule.component.scss'],
})
export class DemoScheduleComponent implements OnInit, AfterViewInit {
  public localizedText: DemoScheduleLocalizationClass;

  nameFormControl = new UntypedFormControl('', [Validators.required]);
  companyNameFormControl = new UntypedFormControl('', [Validators.required]);
  companyEmailFormControl = new UntypedFormControl('', [Validators.required, Validators.email]);
  companyPhoneFormControl = new UntypedFormControl('', [Validators.required]);
  textingControl = new UntypedFormControl(false);
  countryControl = new UntypedFormControl('', [Validators.required]);
  servicesControl = new FormControl('', [Validators.required]);
  originControl = new FormControl('', [Validators.required]);
  destinationControl = new FormControl('', [Validators.required]);
  descriptionControl = new FormControl();
  matcher = new MyErrorStateMatcher();
  public isMobile: boolean = false;
  constructor(
    private localizationService: LocalizationService,
    private toastr: ToastrService,
    private screenService: ScreenService,
    private contactService: ContactService
  ) {
    this.isMobile = this.screenService.isMobile();
    this.localizedText = this.localizationService.getTranslation(DemoScheduleLocalization);
  }

  handleSubmit(): void {
    if (this.validateForm()) {
      const params: contactParams = {
        name: this.nameFormControl.value,
        company: this.companyNameFormControl.value,
        email: this.companyEmailFormControl.value,
        phone: this.companyPhoneFormControl.value,
        address: this.countryControl.value,
        goals: 'A new demo request has been submitted at https://clearfreight.com/demo with the above information.',
        isNL: false,
        textEnabled: this.textingControl.value,
      };
      this.contactService.sendContact(params).subscribe(
        res => {
          this.toastr.success('Your request has been sent successfully!');
          this.nameFormControl.reset();
          this.companyNameFormControl.reset();
          this.companyEmailFormControl.reset();
          this.companyPhoneFormControl.reset();
          this.textingControl.reset();
          this.countryControl.reset();
        },
        err => {
          if (!this.companyEmailFormControl.valid) {
            this.toastr.error('Please enter a valid email address.');
          }
          this.toastr.error('Something went wrong.');
        }
      );
    }
  }

  validateForm(): boolean {
    return (
      this.nameFormControl.valid &&
      this.companyNameFormControl.valid &&
      this.companyEmailFormControl.valid &&
      this.companyPhoneFormControl.valid &&
      this.countryControl.valid
    );
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {}
}
