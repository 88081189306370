<div class="forms">
  <app-center-pic
    src="./assets/images/Forms/CF-Header-Forms.jpg"
  ></app-center-pic>
  <div class="content">
    <h1 class="title">Forms</h1>
  </div>

  <div class="forms-content">
    <h2>
      <a class="blue" href="../resources/forms/general-disclaimers">{{
        localizedText.generalDisclaimers
      }}</a>
    </h2>
    <app-form-item
      *ngFor="let item of forms; let i = index"
      [title]="item.type"
      [data]="item.files"
    >
    </app-form-item>
  </div>
</div>
