import { NgModule } from '@angular/core';

import { AreaTitleComponent } from './components/area-title/area-title.component';
import { JobItemComponent } from './components/job-item/job-item.component';
import { ShareModule } from "../../../share/share.module";
@NgModule({
  declarations: [
    AreaTitleComponent,
    JobItemComponent,
  ],
  imports: [
    ShareModule
  ], exports: [
    AreaTitleComponent,
    JobItemComponent
  ]
})
export class CareersModule { }
