import { Component, Input, OnInit } from '@angular/core';
import { containersType } from 'app/share/data-type/tracking.types';

@Component({
  selector: 'app-containers',
  templateUrl: './containers.component.html',
  styleUrls: ['../tracking.component.scss'],
})
export class ContainersComponent implements OnInit {
  @Input() containers: containersType[] = [];

  constructor() {}

  ngOnInit(): void {}
}
