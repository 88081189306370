import { Component, OnInit, ChangeDetectionStrategy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

import { services_data, article_service } from './data';
import { imageTextFadeInType } from 'app/share/data-type/common.types';
import { ServicesRoadLocalization, ServicesRoadLocalizationClass } from './road.localization';
import { LocalizationService } from 'app/services/localization.service';
import { relatedArticle } from 'app/share/data-type/post.types';
import { RelatedServicesService, ServiceDescription } from 'app/services/related-services.service';
import { Router } from '@angular/router';
import { PostService } from 'app/services/post.service';
import { ScreenService } from 'app/services/screen.service';
@Component({
  selector: 'app-services-road',
  templateUrl: './road.component.html',
  styleUrls: ['./road.component.scss'],
})
export class ServicesRoadComponent implements OnInit, AfterViewInit {
  // article_service_data = article_service;
  // services_data = services_data;

  article_service_data: relatedArticle[];
  services_data: ServiceDescription[];

  localizedText: ServicesRoadLocalizationClass;
  public isMobile: boolean = false;

  constructor(
    localizationService: LocalizationService,
    private relServService: RelatedServicesService,
    private relArticleService: PostService,
    private screenService: ScreenService,
    private router: Router
  ) {
    this.localizedText = localizationService.getTranslation(ServicesRoadLocalization);
    let route = this.router.url;
    this.services_data = this.relServService.getServices(route);
    this.relArticleService.getRelatedPostList().subscribe(data => {
      this.article_service_data = this.relArticleService.mapPostDataToRelatedArticles(data);
    });
    this.isMobile = this.screenService.isMobile();
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {}
}
