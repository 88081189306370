import {Component, OnInit, ChangeDetectionStrategy, Input} from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-bottom-nav',
  templateUrl: './bottom-nav.component.html',
  styleUrls: ['./bottom-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BottomNavComponent implements OnInit {

  @Input() type = 'big';

  constructor(
    public router:Router
  ) { }

  ngOnInit(): void {
  }

}
