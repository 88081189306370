<div class="related-article" moveOutside (moveOutside)="leaveArticle($event)" *ngIf="!isMobile">
  <div class="related-article-body">
    <h1>Related Articles</h1>
    <div class="list">
      <div
        class="image {{ isCurrent(i) ? 'current' : isNoCurrent() ? 'nocurrent' : '' }}"
        *ngFor="let item of article_service_data; let i = index"
        [ngStyle]="{ '--image': 'url(' + item.imgUrl + ')' }"
        (mouseenter)="changeCurrent($event, i)"
      >
        <div class="text">
          <div class="text-content">
            <div>{{ item.title }}</div>
            <div>
              <!-- <a href="">Read More <i class="material-icons">arrow_forward</i></a> -->
              <a class="read-more" [href]="'/resources/article/' + item.name">
                <p>Read More</p>
                <i class="material-icons">arrow_forward</i>
              </a>
              <!-- <app-btn
                [link]="'./resources/article/' + 'test8'"
                theme="no-border"
                fontSize="small"
                >Read More <app-icon-btn [size]="16"></app-icon-btn>
              </app-btn> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="related-article" *ngIf="isMobile">
  <div class="related-article-body">
    <h1>Related Articles</h1>
    <div class="list-mobile">
      <swiper-container loop="true" #swiperCarousel>
        <swiper-slide *ngFor="let item of article_service_data; let i = index">
          <div class="swiper-slide">
            <div
              class="image {{ isCurrent(i) ? 'current' : isNoCurrent() ? 'nocurrent' : '' }}"
              [ngStyle]="{ '--image': 'url(' + item.imgUrl + ')' }"
              (mouseenter)="changeCurrent($event, i)"
            >
              <div class="text">
                <div class="text-content">
                  <div class="content-title">{{ item.title }}</div>
                  <div>
                    <a class="read-more" [href]="'/resources/article/' + item.name">
                      <p>Read More</p>
                      <i class="material-icons">arrow_forward</i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper-container>
      <!-- <swiper [config]="config" #swiperCarousel>
        <div class="swiper-wrapper">
          <div class="swiper-slide" *ngFor="let item of article_service_data; let i = index">
            <div class="image {{
            isCurrent(i) ? 'current' : isNoCurrent() ? 'nocurrent' : ''
          }}" [ngStyle]="{ '--image': 'url(' + item.imgUrl + ')' }" (mouseenter)="changeCurrent($event, i)">
              <div class="text">
                <div class="text-content">
                  <div class="content-title">{{ item.title }}</div>
                  <div>
                    <a class="read-more" [href]="'/resources/article/' + item.name">
                      <p>Read More</p>
                      <i class="material-icons">arrow_forward</i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </swiper> -->
    </div>
  </div>
</div>
