import { Localization } from 'app/share/data-type/common.types';

export const IndustryOilGasLocalization: {
  [key: string]: IndustryOilGasLocalizationClass;
} = {
  en: {
    title: 'Oil & Gas',
    subTitle: 'Industry',
    description1:
      'Transportation of petroleum products, chemicals, equipment, and materials for the oil and gas industry, including pipeline transportation, marine shipping, and specialized logistics services for offshore projects and remote locations. ',
  },
  cn: {},
  jp: {},
  th: {},
  nl: {},
  es: {},
};

export interface IndustryOilGasLocalizationClass extends Localization {}
