import { Component, OnInit } from '@angular/core';
import { FormDataService } from '../nl-form-submitted/form-data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nl-form-submitted',
  templateUrl: './nl-form-submitted.component.html',
  styleUrls: ['./nl-form-submitted.component.scss'],
})
export class NlFormSubmittedComponent implements OnInit {
  name: string;
  email: string;
  goals: string;

  constructor(sharedDataService: FormDataService, public router: Router) {
    this.name = sharedDataService.getName();
    this.email = sharedDataService.getEmail();
    this.goals = sharedDataService.getGoals();
  }

  goBack() {
    this.router.navigate(['nl_warehouse']);
  }

  ngOnInit(): void {}
}
