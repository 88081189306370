import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { NgxCookiebotModule } from '@halloverden/ngx-cookiebot';
import { CookiebotConfig } from './cookiebot.config';
import { BrowserModule } from '@angular/platform-browser';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomPaginator } from './share/utils/custom-paginator';
@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
      progressBar: true,
      easing: 'ease-in',
      easeTime: 500,
      tapToDismiss: true,
      closeButton: true,
    }),
    BrowserModule.withServerTransition({
      appId: 'www-clearfreight',
    }),
    NgxCookiebotModule.forRoot(CookiebotConfig),
  ],
  providers: [{ provide: MatPaginatorIntl, useClass: CustomPaginator }],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
