import { Localization } from 'app/share/data-type/common.types';

export const IndustryConsumerGoodsLocalization: {
  [key: string]: IndustryConsumerGoodsLocalizationClass;
} = {
  en: {
    title: 'Consumer Goods',
    subTitle: 'Industry',
    description1:
      'Transportation of a wide range of consumer products, including clothing, electronics, appliances, and household goods, typically requiring efficient distribution networks and timely delivery to retail stores or direct-to-consumer channels.',
  },
  cn: {},
  jp: {},
  th: {},
  nl: {},
  es: {},
};

export interface IndustryConsumerGoodsLocalizationClass extends Localization {}
