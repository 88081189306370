<div class="associations">
  <a
    *ngFor="let association of associations"
    [href]="association.url"
    target="_blank"
    ><img
      [src]="association.name"
      [alt]="association.name"
      class="org-logo"
      draggable="false"
  /></a>
</div>
