<div>
  <div style="height: 50px"></div>
  <div class="main">
    <div class="title">
      CLEARFREIGHT PRIVACY POLICY
    </div>
    <div class="content">
      <div class="item">
        <p>
          Effective Date June 1st, 2022
        </p>
      </div>
      <div class="item">
        <p>
          This Privacy Policy is established by ClearFreight, Inc., a California corporation, to disclose the privacy practices for the ClearFreight website (the “Website”), ClearFreight Mobile, our mobile software application (the “App”), and ClearView, the desktop version of the App. It explains what types of information we may collect and store, as well as how we use it. By using our services, including accessing the Website and the App, you, the user and/or customer, agree to the collection and use of information in accordance with this Privacy Policy.
        </p>
      </div>
      <div class="item">
        <p>
          Note that this Privacy Policy may be amended at any time, without notice to users, so please regularly refer back to this Privacy Policy in order to see any updated practices.
        </p>
      </div>
      <div class="item">
        <p>
          You may withdraw the consent to use your data at any time. We will then refrain from further processing your Personal Data, except to the extent the processing of your Personal is lawful for other reasons even without your consent, such as fulfilling the contractual obligations, compliance with law, or to protect our or third parties legitimate interests (such as the uninterrupted use of our Website or App). If you have any questions, or if you wish to access, change, or delete any of your Personal Data, please contact us:
        </p>
      </div>
      <div class="item">
        <p>
          <a href="mailto:marketing@clearfreight.com">marketing@clearfreight.com</a>
        </p>
      </div>
    </div>
    <div class="title">
      THE TYPES OF DATA WE COLLECT
    </div>
    <div class="content">
      <div class="item">
        <p>
          ClearFreight collects certain data to provide services to our users, as well as to better understand how to serve our customers. The types of data we collect are:
        </p>
      </div>
      <div class="item">
        <p class="fontOblique underline">
          Data Given by the User
        </p>
        <p>
          In the course of providing our service, our customers will voluntarily provide and ClearFreight will utilize information in order to fulfill the service contract with our customer. This will include names, addresses, contact information, logistical and background information necessary to provide the services which our Company offers.
        </p>
      </div>
      <div class="item">
        <p class="fontOblique underline">
          Service Usage Data
        </p>
        <p>
          We automatically record certain information from the user’s device using various types of technology, such as cookies. This “automatically collected" information may include, but is not limited to the following:
        </p>
        <ul>
          <li>Unique user information, such as IP address;</li>
          <li>User location;</li>
          <li>Web browser and operating system information;</li>
          <li>Language of your device;</li>
          <li>Wireless connections you are using;</li>
          <li>Referring page that linked you to us;</li>
          <li>Your activities, including browsing and transaction history;</li>
          <li>Pages and content you view or click on during your use of our services, and when and for how long you do so;</li>
          <li>The next web site you visit after leaving our web site; </li>
          <li>Search terms you enter on our services or a referral site.</li>
        </ul>
      </div>
    </div>
    <div class="title">
      PERSONAL DATA
    </div>
    <div class="content">
      <div class="item">
        <p>
          Where necessary by law, we will always ask your consent for use of any <span class="underline">personally identifiable information</span> which can be linked back to a specific individual (“Personal Data”). Personal Data may include, but is not limited to, your name, email address, physical address, phone number, and messages that you send to us.
        </p>
      </div>
    </div>
    <div class="title">
      USE OF COLLECTED DATA
    </div>
    <div class="content">
      <div class="item">
        <p>
          We use your personal and non-personal data for such purposes as Website and App operation, improvements, and user communication, including though not limited to:
        </p>
        <ul>
          <li>Supply you with and fulfill your contracted and requested services;</li>
          <li>Provide you with service support;</li>
          <li>To operate the App and Website as per its intended use;</li>
          <li>Technical maintenance purposes; </li>
          <li>Notify you of new products, programs or services;</li>
          <li>Make the Website or App more useful to users;</li>
          <li>To understand our users and provide better tailored content and marketing towards them. </li>
        </ul>
      </div>
    </div>
    <div class="title">
      LAWFUL BASIS FOR USING PERSONAL DATA
    </div>
    <div class="content">
      <div class="item">
        <p>Your Personal Data is processed by ClearFreight on the basis of a lawful “justification” for such processing, to the extent required by or permissible under applicable law. The processing of Personal Data will be justified in most instances by one of the following:</p>
        <ul>
          <li>It is in fulfillment of the service contract to which the user/customer has requested and agreed;</li>
          <li>It is carried out subject to the Personal Data owner’s explicit consent; </li>
          <li>To comply with applicable legal regulations which ClearFreight’s services are subject to;</li>
          <li>To fulfill a task in the public interest or by virtue of some official authority vested in ClearFreight;</li>
          <li>To fulfill legitimate purpose pursued by ClearFreight or third-party, unless that legitimate purpose is overridden by the interests or rights of the user (or owner of Personal Data) requiring the protection of that data. </li>
        </ul>
      </div>
    </div>
    <div class="title">
      HOW WE SHARE YOUR INFORMATION
    </div>
    <div class="content">
      <div class="item">
        <p>
          We keep our customers’ information strictly confidential. We will not intentionally disclose the Personal Data or non-personal data that we collect or store on the service to any third parties without the consent of the applicable user, except in those circumstances described below.
        </p>
      </div>
      <div class="item">
        <p class="fontOblique underline">Google Analytics</p>
        <p>
          Google Analytics will have access to certain tracking and performance data of our users as described elsewhere in this Privacy Policy. For more information on the privacy practices of Google, please visit the Google Privacy and Terms web page: <a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>
        </p>
      </div>
      <div class="item">
        <p class="fontOblique underline">Law Enforcement & Legal Process Purposes</p>
        <p>
          We may disclose Personal Data or other information if required to do so by law or in the good-faith belief that such action is necessary to comply with applicable laws, in response to a facially valid court order, judicial or other government subpoena or warrant, or to otherwise cooperate with law enforcement or other governmental agencies.
        </p>
      </div>
      <div class="item">
        <p class="fontOblique underline">Legal Rights</p>
        <p>We also reserve the right to disclose Personal Data or other information that we believe, in good faith, is appropriate or necessary to: </p>
        <ul>
          <li>take precautions against liability; protect ourselves or others from fraudulent, abusive, or unlawful uses or activity;</li>
          <li>investigate and defend ourselves against any third-party claims or allegations;</li>
          <li>protect the security or integrity of the service and any facilities or equipment used to make the service available;</li>
          <li>protect our property or other legal rights;</li>
          <li>enforce our contracts;</li>
          <li>protect the rights, property, or safety of others.</li>
        </ul>
      </div>
    </div>
    <div class="title">
      STORAGE OF YOUR PERSONAL INFORMATION
    </div>
    <div class="content">
      <div class="item">
        <p>
          Personal Data collected from a user will be kept for as long as necessary to use it for the purpose it was collected and processed, unless otherwise required by law. Data which is not personally identifiable (Non-Personal Data) may be stored indefinitely.  We may store the information in locations outside ClearFreight’s direct control, such as secure servers or databases that are co-located under a separate hosting provider.
        </p>
      </div>
    </div>
    <div class="title">
      SECURITY
    </div>
    <div class="content">
      <div class="item">
        <p>To prevent unauthorized access, maintain data accuracy, and ensure the correct use of information, we have put in place appropriate physical, electronic, and managerial procedures to safeguard and secure the information that we collect. This includes protection against unauthorized or unlawful processing and against accidental loss, destruction or damage, using appropriate technical or organizational measures.</p>
      </div>
      <div class="item">
        <p class="fontOblique underline">Security Disclaimer</p>
        <p>While ClearFreight has taken reasonable steps to limit the uses of information provided by you, we cannot guarantee that the information will remain permanently secure. There is always a possibility that your information could be lost due to technical problems or stolen from our databases, our partners’ databases, or from the databases of any third-party platforms utilized by our Services. ClearFreight is not responsible for any such loss or theft or any other unanticipated security breach.</p>
      </div>
      <div class="item">
        <p class="fontOblique underline">Data Transfer Across National Borders</p>
        <p>Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. This means that your information may be transferred outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction. Notice that while the information is in another jurisdiction, it may be accessed by the courts, law enforcement, and national security authorities in those regions. By using our service or granting ClearFreight permission to collect and process your information, you also fully understand and unambiguously consent to this transfer, processing and storage of your information. </p>
      </div>
      <div class="item">
        <p>ClearFreight we will protect it as described in this Privacy Policy, and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information. For example, in order to comply with the GDPR, ClearFreight protects personally identifiable information from residents of the European Union, other European Economic Area countries, and Switzerland in accordance with applicable law, including, as applicable, reliance upon your consent and EU-approved Standard Contract Clauses. </p>
      </div>
    </div>
    <div class="title">
      YOUR RIGHTS TO ACCESS, CORRECT, OR DELETE YOUR DATA
    </div>
    <div class="content">
      <div class="item">
        <p>
          ClearFreight undertakes to respect the confidentiality of your Personal Data and to guarantee you can exercise your rights. Under certain applicable domestic and internatonal privacy laws, you have the right to:
        </p>
      </div>
      <div class="item">
        <ul>
          <li><span class="fontOblique underline">Request access to your Personal Data.</span> The right to access, update or delete the information we have on you. Whenever made possible, you can access, update or request deletion of your Personal Data directly within your account settings section. If you are unable to perform these actions yourself, please contact us to assist you. This also enables you to receive a copy of the Personal Data we hold about you.</li>
          <li><span class="fontOblique underline">Request correction of the Personal Data that we hold about you.</span> You have the right to have any incomplete or inaccurate information We hold about you corrected.</li>
          <li><span class="fontOblique underline">Object to processing of your Personal Data.</span> In the event your Personal Data is processed on the basis of your consent, you may object to the processing of your Personal Data and you also have the right to object where we are processing your Personal Data for direct marketing purposes.</li>
          <li><span class="fontOblique underline">Request erasure of your Personal Data.</span> You have the right to ask us to delete or remove Personal Data when there is no good reason for us to continue processing it.</li>
          <li><span class="fontOblique underline">Request the transfer of your Personal Data.</span> We will provide to you, or to a third-party you have chosen, your Personal Data in a structured, commonly used, machine-readable format. Please note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you.</li>
          <li><span class="fontOblique underline">Withdraw your consent.</span> You have the right to withdraw your consent on using your Personal Data. If you withdraw your consent, we may not be able to provide you with access to certain specific functionalities of our service.</li>
          <li><span class="fontOblique underline">Right to not be discriminated against.</span> You have the right not to be discriminated against or penalized by ClearFreight for exercising any of your rights. </li>
        </ul>
      </div>
      <div class="item">
        <p>To get information or to invoke your rights as detailed above, you may contact ClearFreight via the email below:</p>
      </div>
      <div class="item">
        <p>
          <a href="mailto:marketing@clearfreight.com">marketing@clearfreight.com</a>
        </p>
      </div>
    </div>
    <div class="title">
      GENERAL DATA PROTECTION REGULATION (GDPR)
    </div>
    <div class="content">
      <div class="item">
        <p>
          ClearFreight may collect data from citizens of the EU. Therefore, ClearFreight complies with the GDPR’s heightened regulations for collecting, processing, storing, and using the Personal Data of its users.  ClearFreight  handles Personal Data both as a Controller and as a Processor as defined in the GDPR. ClearFreight, Inc. will be the controller for User Personal Data as referred to in the “Collection of Personal Data” section of this Policy and will process some of that data via internal administration.
        </p>
      </div>
      <div class="item">
        <p>
          Certain data collection and processing may also be conducted by third party integrated services, advertising partners, and other affiliates. Insomuch as Article 28 of the GDPR requires, ClearFreight will take reasonable steps to employ third party affiliates in compliance with the privacy laws of the GDPR.
        </p>
      </div>
      <div class="item">
        <p>
          You have the right to complain to a Data Protection Authority about our collection and use of your Personal Data. For more information, if you are in the European Economic Area (EEA), please contact your local data protection authority in the EEA.
        </p>
      </div>
    </div>
    <div class="title">
      PERSONAL INFORMATION PROTECTION AND ELECTRONIC DOCUMENTS ACT (PIPEDA)
    </div>
    <div class="content">
      <div class="item">
        <p>
          ClearFreight may collect data from citizens of Canada. Therefore, ClearFreight also complies with the PIPEDA’s regulations for collecting, processing, storing, and using the Personal Data of its users. In accordance with PIPEDA requirements, we provide this Privacy Policy to make readily available the policies and practices relating to the management of personal information and we also further ensure that ClearFreight collects, uses, and discloses personal information for purposes that a reasonable person would consider appropriate in the circumstances.
        </p>
      </div>
    </div>
    <div class="title">
      CALIFORNIA CONSUMER PRIVACY ACT (CCPA)
    </div>
    <div class="content">
      <div class="item">
        <p>
          ClearFreight may collect data from residents of California. Therefore, in the event that our Company falls under the California Consumer Privacy Act (CCPA), we endeavor to comply with the CCPA’s requirements for the collection, use, and processing of Personal Data and the disclosure of consumer’s rights as described in this Privacy Policy.
        </p>
      </div>
      <div class="item">
        <p class="fontOblique underline">Do Not Sell My Personal Information</p>
        <p>ClearFreight does not sell your Personal Data. However, in accordance with California law, if you wish to opt out of your Personal Data being sold, please contact us at <a href="mailto:marketing@clearfreight.com">marketing@clearfreight.com</a> . </p>
      </div>
    </div>
    <div class="title">
      CALIFORNIA ONLINE PRIVACY POLICY ACT (CalOPPA)
    </div>
    <div class="content">
      <div class="item">
        <p>
          We comply with CalOPPA, requiring the prominent display and easy access of our Privacy Policy for Users when accessing the Website or App.
        </p>
      </div>
      <div class="item">
        <p class="fontOblique underline">Do Not Track Law</p>
        <p>
          California law requires that operators of websites disclose how they respond to a “Do Not Track” signal. Some browsers have incorporated “Do Not Track” features, wherein the browser will send a signal to the website or online service indicating that the user does not wish to be tracked. However, since there is no common understanding of how to interpret “Do Not Track“ signals as of yet, we do not currently respond to “Do Not Track” signals. We will continue to follow developments seeking a common industry understanding of how to treat “Do Not Track” signals.
        </p>
      </div>
      <div class="item">
        <p>
          Notice that third party partners integrated into our service may not change their tracking practices in response to DNT settings in your web browser and we do not obligate these parties to honor DNT settings. We utilize Google Analytics for our web analytics and you can opt out of your usage data being included in our Google Analytics reports by visiting <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">https://tools.google.com/dlpage/gaoptout</a>.
        </p>
      </div>
      <div class="item">
        <p>
          If you wish to know more about adjusting your browser settings, including sending “Do Not Track” signals, visit:
        </p>
      </div>
      <div class="item">
        <p>
          <a href="https://www.consumer.ftc.gov/articles/0042-online-tracking" target="_blank">https://www.consumer.ftc.gov/articles/0042-online-tracking</a>
        </p>
      </div>
    </div>
    <div class="title">
      YOUR CALIFORNIA PRIVACY RIGHTS (CALIFORNIA'S “SHINE THE LIGHT” LAW)
    </div>
    <div class="content">
      <div class="item">
        <p>
          Under California Civil Code Section 1798 (California's Shine the Light law), California residents with an established business relationship with us can request information once a year about sharing their Personal Data with third parties for the third parties' direct marketing purposes.
        </p>
      </div>
      <div class="item">
        <p>
          If you'd like to request more information under the California Shine the Light law, and if You are a California resident, You can contact us using the contact information provided below.
        </p>
      </div>
    </div>
    <div class="title">
      CHILDREN’S ONLINE PRIVACY
    </div>
    <div class="content">
      <div class="item">
        <p>
          ClearFreight’s Services are NOT intended for use by anyone under the age of 18. The Website or App does not knowingly collect personal information from children.
        </p>
      </div>
      <div class="item">
        <p>
          If we learn that Personal Data has been collected on the Service from persons under 18 years of age and without verifiable parental consent, then we will take the appropriate steps to delete this information. If you are a parent or guardian and discover that your child under 18 years of age has been using our services, then please alert us via email and request that we delete that child’s Personal Data from our systems. Please contact us using the contact information provided at the end of this Policy.
        </p>
      </div>
    </div>
    <div class="title">
      COOKIES
    </div>
    <div class="content">
      <div class="item">
        <p>
          ClearFreight utilizes “cookies” in the course of its operation. A “cookie” is a small text file placed on the user’s hard drive to track usage of a Website. Cookies help to prevent you from having to register or re-enter your information every time you visit a website and are also used to authenticate your identity. Our Company also uses cookies to gain knowledge of Internet or mobile usage, improve content and advertisements. In order to use cookies, we will ask your express consent to accept cookies on the ClearFreight Website or App.
        </p>
      </div>
      <div class="item">
        <p>
          In the course of improving your user experience, ClearFreight may use all of these cookie types:
        </p>
      </div>
      <div class="item">
        <ul class="letterPrefix">
          <li>
            <p class="fontOblique underline">Strictly Necessary Cookies</p>
            <p>These are necessary because they make it possible for you to move around a website and use its features, such as accessing secure areas. Without these cookies, some services can’t be provided.</p>
          </li>
          <li>
            <p class="fontOblique underline">Functionality Cookies</p>
            <p>These enable the Website in remembering customizing options you select on the Website. For example, the Website may use functionality cookies to remember your language preference or other customizing options.</p>
          </li>
          <li>
            <p class="fontOblique underline">Cookies Policy Notice/Acceptance Cookies</p>
            <p>These cookies identify if users have accepted the use of cookies on the Website.</p>
          </li>
          <li>
            <p class="fontOblique underline">Tracking and Performance Cookies</p>
            <p>These collect information about your service use, such as such as originating IP address, browser type, browser language, how a user got to the service (i.e., the URL of the last website you visited), the next website the user visits, what pages are visited, the length of each site visit, and the date and time of the user’s visit. The information gathered via these cookies may directly or indirectly identify you as an individual visitor. ClearFreight uses Google Analytics tracking and performance cookies. </p>
          </li>
        </ul>
      </div>
      <div class="item">
        <p class="fontOblique underline">Google Analytics</p>
        <p>Cookies placed by Google Analytics are used to help us understand how to make the best use of our content and find out how we can improve it. These cookies allow us to track your progress through our Website and App, such as which applications and/or pages you visit, and the time you spend on the Website. This data is then stored by Google in order to create reports.</p>
      </div>
      <div class="item">
        <p>
          The information generated by the Google cookies, including your IP address, can be transmitted and stored by Google on servers in the United States. Google may transfer this information to third parties when so required by law or where such third parties process the information on behalf of Google. Google will not associate your IP address with any other data held by Google. For more information on the privacy practices of Google, please visit the Google Privacy and Terms web page: <a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>
        </p>
      </div>
      <div class="item">
        <p>
          You can also opt-out of having made your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js and dc.js) from sharing information with Google Analytics about visits activity.
        </p>
      </div>
      <div class="item">
        <p class="fontOblique underline">Managing Cookies Through Your Browser</p>
        <p>
          Your browser should be able to enable, disable or delete cookies. To do this, follow the instructions provided by your browser (usually located within the “Help,” “Tools” or “Edit” facility). Note that if you set your browser to disable cookies, you may not be able to access certain parts of the Website and certain services may not work.
        </p>
      </div>
      <div class="item">
        <p>
          For more information about cookies, visit <a href="www.allaboutcookies.org" target="_blank">www.allaboutcookies.org</a>.
        </p>
      </div>
    </div>
    <div class="title">
      LINKS TO OTHER WEBSITES
    </div>
    <div class="content">
      <div class="item">
        <p>
          Our Website and App may contain links to and from the applications and/or websites of our partner networks, advertisers, third parties and affiliates. They are merely for informational purposes. If you follow a link to any of these applications and/or websites, please bear in mind that they have their own privacy policies and that we assume no responsibility or liability arising whatsoever nor endorse any practices from their policies.
        </p>
      </div>
    </div>
    <div class="title">
      CHOICE OF LAW
    </div>
    <div class="content">
      <div class="item">
        <p>
          ClearFreight is operated and provided in the State of California, U.S.A. As such, we are subject to the laws of the State of California, and such laws will govern this Privacy Policy. Accordingly, if you choose to access our Services you agree to do so subject to the laws, venue and jurisdiction of Los Angeles County, State of California.
        </p>
      </div>
    </div>
    <div class="title">
      TRANSFER OF OWNERSHIP
    </div>
    <div class="content">
      <div class="item">
        <p>
          User information, including Personal Data, may be disclosed and otherwise transferred to an acquirer, successor or assignee as part of any merger, acquisition, debt financing, sale of assets, or similar transaction, as well as in the event of an insolvency, bankruptcy, or receivership in which information is transferred to one or more third parties as one of our business assets. This can only be if the recipient of the User data commits to a Privacy Policy that has terms substantially consistent with this Privacy Policy.
        </p>
      </div>
    </div>
    <div class="title">
      CONTACT US
    </div>
    <div class="content">
      <div class="item">
        <p>
          If you have any questions about this Privacy Policy, please contact us at <a href="mailto:marketing@clearfreight.com">marketing@clearfreight.com</a>.
        </p>
      </div>
      <div class="item">
        <p>
          Mailing Address:
        </p>
      </div>
      <div class="item">
        <p>1960 E. Grand Ave. Suite 700</p>
        <p>El Segundo, CA 90245</p>
        <p>(310) 726-0400</p>
      </div>
    </div>
    <div style="height: 100px"></div>
  </div>
  <div style="height: 50px"></div>
  <app-bottom-nav type="small"></app-bottom-nav>
</div>
