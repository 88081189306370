import { Localization } from 'app/share/data-type/common.types';

export const ServicesFreightForwardingLocalization: {
  [key: string]: ServicesFreightForwardingLocalizationClass;
} = {
  en: {
    subTitle: 'Logistics',
    title: 'Freight Forwarding',
    description1: `ClearFreight's freight forwarding services offer a comprehensive and streamlined solution for the efficient movement of goods across borders. With a global network of partners and advanced technology, we ensure that your shipments reach their destinations on time and within budget. Our experienced team manages the complexities of international shipping, providing end-to-end visibility and proactive communication.`,
    description2: `Count on ClearFreight for a reliable and flexible freight forwarding experience. From customs clearance to transportation coordination, our team is dedicated to optimizing your supply chain. We leverage our industry relationships to secure competitive rates and expedite the customs clearance process, ensuring a smooth and cost-effective freight forwarding solution.`,
    servicesList: [
      'Tailored supply chain solutions',
      'Comprehensive approach',
      'Advanced technology & network',
      'Customized operational solutions',
      'Trusted partner for optimization',
    ],
  },
  es: {},
  jp: {},
  cn: {},
  th: {},
  nl: {},
};

export interface ServicesFreightForwardingLocalizationClass
  extends Localization {}
