<div
  class="description"
  [ngStyle]="{ '--height': height, '--space-height': spaceHeight, '--mobile-title-size': titleFontSize }"
>
  <div class="white-space"></div>
  <h1>{{ title }}</h1>
  <h2 *ngIf="subTitle">{{ subTitle }}</h2>
  <div class="text">
    <ng-content #description></ng-content>
  </div>
</div>
