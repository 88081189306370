<div class="form-item" *ngIf="title != 'Do not display'">
  <div class="title-part" (click)="toggle()">
    <h1>{{ title }}</h1>

    <i class="material-icons open {{ isShow ? '' : 'hidden' }}">remove</i>
    <i class="material-icons close {{ isShow ? 'hidden' : '' }}">add</i>
  </div>

  <div class="line" (click)="toggle()"></div>
  <div class="desc-part">
    <div class="expansion-panel-header"></div>
    <div
      class="expansion-panel-body"
      [@fadeInOut]="{
        value: isShow ? 'open' : 'close',
        params: { explandHeight: height }
      }"
    >
      <ul>
        <li *ngFor="let item of data">
          <h4>
            <a class="blue" href="{{ getItemLink(item) }}">{{ item.name }}</a>
          </h4>
        </li>
      </ul>
    </div>
  </div>
</div>
