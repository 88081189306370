import { Localization } from 'app/share/data-type/common.types';

export const TrackingLocalization: {
  [key: string]: TrackingLocalizationClass;
} = {
  en: {
    title: 'Tracking',
    item1: 'Quick Shipment Tracking',
    description1: 'Coming soon...',
    item2: 'ClearView',
    description2:
      'ClearView is our proprietary, web-based, track and trace system. It provides our customers with real-time visibility of their shipments, including status updates, document upload/download, and reporting capabilities. If you are an existing customer interested in using ClearView, please contact your ClearFreight representative.',
    login: 'Go To ClearView',
    description4:
      'For existing ClearView users, please click the below button to login.',
    item3: 'Web Tracking',
    description3: 'Please click the following link for ',
    webTracking: 'legacy web tracking',
  },
  es: {},
  cn: {},
  jp: {},
  th: {},
  nl: {},
};

export interface TrackingLocalizationClass extends Localization {
  login?: string;
  webTracking?: string;
  item3?: string;
}
