import { Localization } from 'app/share/data-type/common.types';

export const ServicesBreakBulkProjectCargoLocalization: {
  [key: string]: ServicesBreakBulkProjectCargoLocalizationClass;
} = {
  en: {
    subTitle: 'Logistics',
    title: 'Break Bulk and Project Cargo',
    description1:
      'With extensive experience and a robust global network, ClearFreight simplifies the complex process of handling break bulk and project cargo shipments. Our tailored door-to-door solutions cater to various industries, including oil & gas, engineering industrial projects, recycling, wastewater treatment, and wind/solar energy. Our services encompass:',
    servicesList: [
      'Air, Break Bulk vessel, and Roll-on/roll-off (RORO) vessel transportation.',
      'Special containers - over the top (or ISO), flat rack, reefer, platform containers, etc.',
      'Drayage and Transloading services',
      'Special trailers - flatbed, reefer trailer, curtain trailer, tarpaulin trailer, lowboy, step deck trailer, etc.	',
    ],
  },
  es: {},
  jp: {},
  cn: {},
  th: {},
  nl: {},
};

export interface ServicesBreakBulkProjectCargoLocalizationClass
  extends Localization {}
