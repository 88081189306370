import { Component, OnInit } from '@angular/core';
import { OrderManagementLocalization, OrderManagementLocalizationClass } from './order-management.localization';
import { LocalizationService } from 'app/services/localization.service';
import { videos } from '../data';
import { ScreenService } from 'app/services/screen.service';

@Component({
  selector: 'app-order-management',
  templateUrl: './order-management.component.html',
  styleUrls: ['./order-management.component.scss'],
})
export class TechnologyOrderManagementComponent implements OnInit {
  videos = videos;
  public localizedText: OrderManagementLocalizationClass;
  public isMobile: boolean = false;

  constructor(private localizationService: LocalizationService, private screenService: ScreenService) {
    this.localizedText = this.localizationService.getTranslation(OrderManagementLocalization);
    this.isMobile = this.screenService.isMobile();
  }

  ngOnInit(): void {}
}
