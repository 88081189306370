import { Localization } from 'app/share/data-type/common.types';

export const ServicesAirLocalization: {
  [key: string]: ServicesAirLocalizationClass;
} = {
  en: {
    subTitle: 'Transportation',
    title: 'Global Air Forwarding, enhancing speed and efficiency',
    description1: `Whether you require priority service, air/surface combinations, domestic air freight, or other customizable airfreight options, ClearFreight will tailor the best shipping plan to meet your specific needs, ensuring timely and budget-friendly deliveries. Our airfreight experts understand the uniqueness of each customer's requirements, and our flexibility allows us to align with your business needs seamlessly. We ensure a smooth and expedited delivery process, providing real-time milestone alerts throughout transit as needed.`,
    description2: `Our designation as an official airline agent by the International Air Transportation Association (IATA) empowers us to issue airway bills on behalf of the airlines, enabling us to pass on expedited service and cost savings directly to you.`,
    services: 'Air Freight Services',
    servicesList: [
      'Milestone tracking through ClearView platform',
      'Foreign airport to foreign airport shipments',
      'Consolidation Services',
      'Door-to-Door Service',
      'Multi-Modal Transportation [Sea/Air]',
      'Packing',
      'Warehousing',
      'Cargo Insurance',
      'HAZMAT Certified',
      'Automated Export System',
      'Track and Trace',
      'Carnet Shipments',
      'Export License Assistance',
      'Letter of Credit Negotiations',
      'C-TPAT Certified',
      'Dangerous Goods and Foods Specialist',
      'Radioactive Specialist',
      'In-House TSA Approved Screening Facility',
      'Automated milestone alerts – such “shipment departed”, “shipment arrived”',
    ],
  },
  es: {},
  jp: {},
  cn: {},
  th: {},
  nl: {},
};

export interface ServicesAirLocalizationClass extends Localization {}
