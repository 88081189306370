import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-video-dialog',
  templateUrl: './video-dialog.component.html',
  styleUrls: ['./video-dialog.component.scss'],
})
export class VideoDialogComponent implements OnInit {
  public videoLoading: boolean = true;

  constructor(private dialogRef: MatDialogRef<VideoDialogComponent>) {}

  closeSelf(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {}
}
