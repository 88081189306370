import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

import { article_service } from './data';
import { imageTextFadeInType } from 'app/share/data-type/common.types';
import {
  IndustryLocalization,
  IndustryLocalizationClass,
} from './industry.localization';
import { LocalizationService } from 'app/services/localization.service';
import {
  RelatedServicesService,
  ServiceDescription,
} from 'app/services/related-services.service';
import { Router } from '@angular/router';
import { PostService } from 'app/services/post.service';
import { relatedArticle } from 'app/share/data-type/post.types';
@Component({
  selector: 'app-temp-industry',
  templateUrl: './industry.component.html',
  styleUrls: ['./industry.component.scss'],
})
export class IndustryComponent implements OnInit, AfterViewInit {
  localizedText: IndustryLocalizationClass;
  article_service_data = article_service;
  services_data: ServiceDescription[];
  articles: relatedArticle[] = [];
  constructor(
    private localizationService: LocalizationService,
    private relServService: RelatedServicesService,
    private relArticleService: PostService,
    private router: Router
  ) {
    this.localizedText =
      this.localizationService.getTranslation(IndustryLocalization);
    let route = this.router.url;
    this.services_data = this.relServService.getServices(route);
    this.relArticleService.getRelatedPostList().subscribe((data) => {
      this.articles = this.relArticleService.mapPostDataToRelatedArticles(data);
    });
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {}
}
