import { Localization } from 'app/share/data-type/common.types';

export const ServicesRailLocalization: {
  [key: string]: ServicesRailLocalizationClass;
} = {
  en: {
    subTitle: 'Transportation',
    title: 'Rail Transportation Services, on track to success',
    description1:
      'ClearFreight’s rail transportation services provide a robust and economical solution for your shipping requirements. With a focus on optimizing transit times and minimizing costs, our dedicated team ensures the seamless movement of your goods via rail networks. Whether it’s for long-haul shipments or regional distribution, our rail services offer a dependable and efficient choice.',
    description2:
      'Benefit from ClearFreight’s extensive network and partnerships within the rail industry. Our technical approach includes reliable tracking systems to keep you updated on your cargo’s journey. Count on ClearFreight for cost-effective and timely rail transportation solutions tailored to meet your specific logistics needs.',
    services: 'Our rail services include:',
    servicesList: [
      'Nationwide rail transportation',
      'Dangerous Goods and Foods Specialist',
      'Nationwide intermodal service',
      'All container sizes',
      'Transloading',
      'Local pickup and delivery',
      'Cross Border service',
      'Visibility to all rail-based events through ClearView',
    ],
  },
};

export interface ServicesRailLocalizationClass extends Localization {}
