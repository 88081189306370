import { Component, Input, OnInit } from '@angular/core';
import { packsType } from 'app/share/data-type/tracking.types';
import { PackTypeService } from './pack-type.service';

@Component({
  selector: 'app-packs',
  templateUrl: './packs.component.html',
  styleUrls: ['../tracking.component.scss'],
})
export class PacksComponent implements OnInit {
  @Input() packs: packsType[] = [];

  constructor(private packTypeService: PackTypeService) {}

  ngOnInit(): void {}

  getPackType(packType: string): string {
    return this.packTypeService.getPackType(packType);
  }
}
