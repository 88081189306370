import { Component, Input, OnInit } from '@angular/core';
import { ServiceDescription } from 'app/services/related-services.service';
import { relatedArticle } from 'app/share/data-type/post.types';

@Component({
  selector: 'app-contact-and-related',
  templateUrl: './contact-and-related.component.html',
  styleUrls: ['./contact-and-related.component.scss'],
})
export class ContactAndRelatedComponent implements OnInit {
  @Input() outro: string;
  @Input() article_service_data: relatedArticle[];
  @Input() services_data: ServiceDescription[];

  constructor() {}

  ngOnInit(): void {}
}
