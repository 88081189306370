import { Component, Input, OnInit } from '@angular/core';

import { article_list } from '../newsroom/data';
@Component({
  selector: 'app-newsroom-market-updates',
  templateUrl: './market-updates.component.html',
  styleUrls: ['./market-updates.component.scss']
})
export class MarketUpdatesComponent implements OnInit {
  constructor() { }

  articles = article_list;
  ngOnInit(): void {

  }


}
