import { Component, Input, OnInit } from '@angular/core';

import { article_list } from '../newsroom/data';
import { NewsType, postItem } from 'app/share/data-type/post.types';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PageEvent } from '@angular/material/paginator';
import { PostService } from 'app/services/post.service';
@Component({
  selector: 'app-newsroom-industry-news',
  templateUrl: './industry-news.component.html',
  styleUrls: ['./industry-news.component.scss']
})
export class NewsroomIndustryNewsComponent implements OnInit {
  public get loadingId(): number {
    return this._loadingId;
  }
  public set loadingId(val: number) {
    this._loadingId = val;
  }

  constructor(private router: ActivatedRoute, private postService: PostService,
    private toastr: ToastrService) {
  }

  posts: postItem[] = [];
  pageSize = 8;
  pageIndex = 0;
  pageEvent: PageEvent;
  total: number;
  private _loadingId = 0;

  nextPage = '';
  loadingFileError = false;
  fileName = '';

  ngOnInit(): void {
    let page = this.router.snapshot.params["page"] ?? 0;
    this.pageIndex = page;
    this.fetchPostListData();
  }


  fetchPostListData() {
    this.loadingId = this.toastr.show("Loading...").toastId;
    let offset=(this.pageIndex??0)*this.pageSize;
    this.postService.getPostList(NewsType.Industry, offset, this.pageSize).subscribe(
      (res:any) => {
        let datas=res.datas;
        this.posts = datas;
        this.total = res.recordCount;

        this.toastr.clear(this.loadingId);
        this.loadingId = 0;
      },
      (err:any) => {
        this.toastr.clear(this.loadingId);
        this.loadingId = 0;
      }
    );
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.total = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;

    this.fetchPostListData();
  }
}
