<div class="industry">
  <app-sub-header
    src="./assets/images/Template%202.0/CF-1.0-Header.jpg"
    top="-300px"
  ></app-sub-header>
  <div class="manufacturing">
    <div class="body">
      <h3>{{ localizedText.subTitle }}</h3>
      <h1>{{ localizedText.title }}</h1>
      <div class="paragraph-spec-text">{{ localizedText.description1 }}</div>
      <h2>{{ localizedText.services }}</h2>
      <div>
        <app-items-and-descs
          [item_data]="localizedText.itemDescList ?? []"
        ></app-items-and-descs>
        <div class="text">{{ localizedText.description2 }}</div>

        <div
          [ngStyle]="{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }"
        >
          {{ localizedText.outro }}
          <app-contact-button
            [ngStyle]="{ marginTop: '50px' }"
          ></app-contact-button>
        </div>
        <app-related-service
          [services_data]="services_data"
        ></app-related-service>
      </div>

      <div class="related">
        <app-related-article
          [article_service_data]="article_service_data"
        ></app-related-article>
      </div>
    </div>
  </div>
</div>
