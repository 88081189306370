import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-associations',
  templateUrl: './associations.component.html',
  styleUrls: ['./associations.component.scss'],
})
export class AssociationsComponent implements OnInit {
  public aop: string = 'assets/images/Associations/AOP.png';
  public c5c: string = 'assets/images/Associations/C5C.png';
  public ctpat: string = 'assets/images/Associations/CTPAT.png';
  public iata: string = 'assets/images/Associations/IATA.png';
  public lacbfaa: string = 'assets/images/Associations/LACBFAA.png';
  public ncbfaa: string = 'assets/images/Associations/NCBFAA.png';
  public wca: string = 'assets/images/Associations/WCA.png';

  public associations: { name: string; url: string }[] = [
    { name: this.ncbfaa, url: 'https://www.ncbfaa.org/' },
    { name: this.lacbfaa, url: 'https://www.lacbffa.org/cpages/about' },
    {
      name: this.ctpat,
      url: 'https://www.cbp.gov/border-security/ports-entry/cargo-security/CTPAT',
    },
    { name: this.iata, url: 'https://www.iata.org/' },
    { name: this.wca, url: 'https://www.wcaworld.com/Home' },
    { name: this.aop, url: 'https://ao-partners.com/about-aop' },
    { name: this.c5c, url: 'https://con5con.com/' },
    // this.ncbfaa,
    // this.lacbfaa,
    // this.ctpat,
    // this.iata,
    // this.wca,
    // this.aop,
    // this.c5c,
  ];

  constructor() {}

  ngOnInit(): void {}
}
