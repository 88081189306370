import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

import { services_data, article_service } from './data';
import { imageTextFadeInType } from 'app/share/data-type/common.types';
import {
  ServicesWarehousingDistributionLocalization,
  ServicesWarehousingDistributionLocalizationClass,
} from './warehousing-distribution.localization';
import { LocalizationService } from 'app/services/localization.service';
@Component({
  selector: 'app-services-warehousing-distribution',
  templateUrl: './warehousing-distribution.component.html',
  styleUrls: ['./warehousing-distribution.component.scss'],
})
export class ServicesWarehousingDistributionComponent
  implements OnInit, AfterViewInit
{
  localizedText: ServicesWarehousingDistributionLocalizationClass;

  article_service_data = article_service;
  services_data = services_data;
  constructor(private localizationService: LocalizationService) {
    this.localizedText = this.localizationService.getTranslation(
      ServicesWarehousingDistributionLocalization
    );
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {}
}
