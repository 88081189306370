import { Localization } from 'app/share/data-type/common.types';

export const ServicesCargoInsuranceLocalization: {
  [key: string]: ServicesCargoInsuranceLocalizationClass;
} = {
  en: {
    subTitle: 'Logistics',
    title: 'Cargo Insurance',
    description1: `In the unpredictable world of international shipping, safeguarding your cargo from various perils is paramount. ClearFreight offers robust cargo insurance services to protect against potential risks such as damage, theft, collisions, and mishandling. Ensure peace of mind and financial security with our Shipper’s Interest Cargo Insurance. Here's why cargo insurance from ClearFreight is essential: `,
    description2: `For more information and to explore cargo insurance options, contact your local ClearFreight representative. `,
    services: 'Key Features:',
    keyFeatures: 'Key Features:',
    featuresList: [
      {
        item: `Complete Coverage:`,
        description: `Comprehensive protection against a range of perils, including storm damage, theft, collisions, and mishandling.`,
      },
      {
        item: `General Average Protection:`,
        description: `Safeguard your interests in situations involving voluntary sacrifices to save the vessel, cargo, or crew from common perils. Our insurance covers costs related to General Average, ensuring smooth transactions and release of cargo.`,
      },
      {
        item: `Costs for Minimizing Loss (Sue and Labor):`,
        description: `Coverage for costs incurred to minimize a loss, providing financial support in critical situations.`,
      },
      {
        item: `Damage Inspection (Survey):`,
        description: `Reimbursement for damage inspection costs, enhancing transparency and control over your shipments.`,
      },
    ],
    whyCargoInsurance: 'Why Cargo Insurance?',
    insuranceReasons: [
      {
        item: `No Need to Prove Fault:`,
        description: `Cargo Insurance pays covered claims without the need to prove fault, offering a hassle-free and efficient claims process.`,
      },
      {
        item: `Mitigation of Risk:`,
        description: `Mitigate risks associated with limited carrier liability and legal defenses by opting for cargo insurance.`,
      },
      {
        item: `Expert Assistance with Claims Processing:`,
        description: `Our dedicated team provides expert assistance with claims processing, ensuring timely resolutions and minimal disruptions.`,
      },
    ],
    carrierLiability: 'Carrier Liability Overview',
    liabilityOverview: [
      {
        item: `Limitations Based on Mode`,
        description: `Highlights varying liability limits for air, ocean, rail, and truck shipments, emphasizing potential coverage gaps.`,
      },
      {
        item: `Ocean:`,
        description: `Governed by the Carriage of Goods by Sea Act (COGSA), limiting liability to $500 per customary freight unit. Recovery only when the carrier is negligent. `,
      },
      {
        item: `Air:`,
        description: `Two liability conventions—Warsaw Convention limits liability to $9.07 per pound or $20 per kilogram. The Montreal Convention (used in the U.S.) changes this limitation to 19 Special Drawing Rights (SDRs), approximately $30 per kilogram. `,
      },
      {
        item: `Road and Rail`,
        description: `Governed by the Carriage of Goods by Sea Act (COGSA), limiting liability to $500 per customary freight unit. Recovery only when the carrier is negligent. `,
      },
    ],
    insuranceOptions: 'Cargo Insurance Coverage Options',
    optionsList: [
      {
        item: `All-Risk Coverage:`,
        description: `Broad protection covering loss or damage without the need to prove liability. Ideal for a wide range of shipments. `,
      },
      {
        item: `Free of Particular Average (FPA):`,
        description: `Specific coverage for named losses. Suitable for commodities like used goods, waste materials, and scrap metal. `,
      },
      {
        item: `With Average (WA):`,
        description: `Extends coverage to include heavy weather. Additional options can be added for enhanced protection. `,
      },
    ],
    howToObtain: 'How to Obtain Cargo Insurance:',
    contactUs: `Contact Us`,
    // servicesList: [
    //   'All-risk coverage',
    //   'Competitive rates',
    //   'Fast and efficient claims handling',
    //   'Coverage for all modes of transportation',
    //   'Coverage for all types of cargo',
    //   'Coverage for all types of shipments',
    //   'Coverage for all types of commodities',
    //   'Coverage for all types of packaging',
    // ],
  },
  es: {},
  jp: {},
  cn: {},
  th: {},
  nl: {},
};

export interface ServicesCargoInsuranceLocalizationClass extends Localization {
  keyFeatures?: string;
  featuresList?: { item: string; description: string }[];
  whyCargoInsurance?: string;
  insuranceReasons?: { item: string; description: string }[];
  carrierLiability?: string;
  liabilityOverview?: { item: string; description: string }[];
  insuranceOptions?: string;
  optionsList?: { item: string; description: string }[];
  howToObtain?: string;
  contactUs?: string;
}
