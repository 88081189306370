import { Component, Input, OnInit } from '@angular/core';

import { article_list, services_data } from '../newsroom/data';
import { ActivatedRoute, Router } from '@angular/router';
import { PostService } from 'app/services/post.service';
import { ToastrService } from 'ngx-toastr';

import { replaceHyperLink } from 'app/share/utils/replaceHtmlTag';
import { environment } from 'environments/environment';
import { postItem } from 'app/share/data-type/post.types';
import { GoogleAnalyticsService } from 'app/services/google-analytics.service';
import { ScreenService } from 'app/services/screen.service';
@Component({
  selector: 'app-newsroom-stats-show',
  templateUrl: './stats-show.component.html',
  styleUrls: ['./stats-show.component.scss']
})
export class StatsShowComponent implements OnInit {
  constructor(private activeRouter: ActivatedRoute,
    private router: Router, private postService: PostService,
    public gaService: GoogleAnalyticsService,
    private toastr: ToastrService, private screenService: ScreenService) {

    this.isMobile = this.screenService.isMobile();
    if(this.isMobile)this.header_img_top="-400px";
  }
  isMobile:boolean=false;
  header_img_top:string='-300px'
  services_data = services_data
  publishDate: Date;
  postItem: postItem | undefined = undefined;
  fileName = '';
  articles = article_list.slice(0, 3);
  ngOnInit(): void {
    this.fileName = this.activeRouter.snapshot.params["name"] ?? '';
    const fileUrl = `${environment.azureBlobUrl}/${environment.fileContainerName}/${this.fileName}.txt`;
    this.fetchPostDetailData(fileUrl);
  }

  fetchPostDetailData(fileUrl: string) {
    this.postService.getPostDetailFile(fileUrl).subscribe(res => {
      if (res.isPublish === false) {
        this.goToPages('list', null);
      }

      res.contentList = JSON.parse(res.content);
      this.postItem = res;

      if (typeof this.postItem.contentList == 'object') {
        this.postItem.contentList?.forEach((item, index) => {
          if ((item.type === 'Paragraph' || item.type === 'MultiParagraph') && item.value.indexOf('<hyperlink') > -1) {
            item.value = replaceHyperLink(item.value);
          }
        });
      }
      const dateArr = this.postItem.publishTime.split('/');
      this.publishDate = new Date(`${dateArr[2]}/${dateArr[1]}/${dateArr[0]}`);

      this.gaService.eventEmitter('View Post Detail', this.postItem.title);
    });
  }

  checkHtml(htmlStr: string) {
    var reg = /<[^>]+>/g;
    return reg.test(htmlStr);
  }

  public goToPages(type: 'list' | 'detail', item: postItem | null) {
    if (type === 'list') {
      this.router.navigate(['../newsroom']);
    } else {
      this.redirectTo(`../article/${item?.name}`);
    }
  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([uri]));
  }
}
