import { Component, Input, OnInit } from '@angular/core';

import { article_list } from '../newsroom/data';
@Component({
  selector: 'app-newsroom-press-releases',
  templateUrl: './press-releases.component.html',
  styleUrls: ['./press-releases.component.scss']
})
export class NewsroomPressReleasesComponent implements OnInit {
  constructor() { }

  articles = article_list.slice(0, 5);
  ngOnInit(): void {

  }


}
