<!-- <div class="section">
  <div class="page-title" [@listAnimation]="title.length">
    <h3 *ngFor="let item of title">
      {{ item
      }}{{ item === "," || item === "." || item === " " ? "&nbsp;" : null }}
    </h3>
  </div>
</div> -->

<div class="carousel-container">
  <app-image-carousel style="position: absolute; z-index: 0; width: 100%"></app-image-carousel>
  <div>
    <h3 class="carousel-text">
      Hello, new customers looking for dangerous goods storage!
      <br />
      Say hello to your new warehouse in Rotterdam, Europe
    </h3>
  </div>
</div>
<button
  style="position: fixed; z-index: 2"
  class="contact"
  mat-raised-button
  color="primary"
  (click)="scrollToSection('ContactUs')"
>
  Contact ↓
</button>

<div
  appScrollTrigger
  (triggerPointReached)="handleScrollTrigger1()"
  class="alt-section"
  [ngStyle]="{ height: isMobile ? 'auto' : 'min(850px, 85vh)' }"
>
  <div *ngIf="reachedPoint1" [@fadeIn] class="content" style="text-align: center">
    <div class="title" style="height: auto">
      <h3>
        Experience delivers.
        <br />
        This is our hazardous goods storage facility in Rotterdam.
      </h3>
      <h3 style="margin-top: 0px">Let's partner up.</h3>
    </div>
    <p>
      Welcome to our hazardous storage facility located in the heart of the bustling port of Rotterdam.
      <br />
      Committed to ensuring utmost safety and compliance, our warehouse operates in strict accordance with the latest
      PGS15 standard, providing you with peace of mind for your storage needs. Our status as a bonded warehouse provides
      you the unique advantage of maintaining goods within a customs-controlled environment.
    </p>
  </div>
</div>
<div class="features-container section" style="background-color: #eeeeee">
  <img src="assets/images/nl-carousel/Carousel1.png" />
  <div class="features">
    <p *ngFor="let feature of features; let i = index" [ngClass]="{ active: i === currentFeatureIndex }">
      {{ feature }}
    </p>
    <div class="features-controls">
      <p (click)="prev()">←</p>
      <p (click)="next()">→</p>
    </div>
  </div>
</div>
<div
  appScrollTrigger
  (triggerPointReached)="handleScrollTrigger2()"
  class="section"
  [ngStyle]="{ height: isMobile ? 'auto' : 'min(1020px, 100vh)' }"
>
  <div *ngIf="reachedPoint2" [@fadeIn] class="content">
    <div class="title" style="height: auto; margin-top: 50px">
      <h2>Our warehouse solutions in the Netherlands</h2>
    </div>
    <div class="warehouse-solutions">
      <p>
        Your assets are under constant vigilance with our comprehensive security features. An advanced alarm system
        combined with 24/7 video surveillance cameras ensures the utmost protection for your valuable goods. At our
        facility, safety, compliance, and convenience converge to provide you with a top-tier solution for your storage
        requirements.
      </p>
      <p>
        In addition to our storage capabilities, we also offer efficient order picking services to meet your
        distribution needs. Our team is dedicated to ensuring accurate and timely order fulfillment, enhancing the
        overall convenience and effectiveness of your operations.
      </p>
      <ul>
        ● Order Fulfillment
      </ul>
      <ul>
        ● Purchase Order Management
      </ul>
      <ul>
        ● Cross Docking
      </ul>
      <ul>
        ● Pick and Pack Services
      </ul>
      <ul>
        ● Reverse Logistics
      </ul>
      <ul>
        ● Cargo Consolidations
      </ul>
      <ul>
        ● Storage
      </ul>
      <ul>
        ● Inventory Management
      </ul>
      <ul>
        ● Export Ocean Container Loading
      </ul>
      <ul>
        ● EDI Capabilities
      </ul>
    </div>
    <button style="margin-top: 15px" mat-raised-button color="primary" (click)="scrollToSection('ContactUs')">
      Contact
    </button>
  </div>
</div>
<div class="alt-section" style="height: min(100px, 10vh)"></div>
<div class="section">
  <div class="content">
    <div class="title" style="height: auto; margin-top: 50px">
      <h2>
        <strong>{{ getDate() === '2024' ? 'NEW: ' : '' }}</strong>
        Secure Lithium Battery Storage Solutions
      </h2>
    </div>
    <div class="warehouse-solutions">
      <p>Looking for safe and efficient lithium battery storage? Our state-of-the-art facilities offer:</p>
      <ul>
        ● Enhanced Safety: Advanced fire suppression and temperature control systems.
      </ul>
      <ul>
        ● Optimal Conditions: Maintained humidity and temperature for battery longevity.
      </ul>
      <ul>
        ● 24/7 Security: Constant surveillance and robust security measures.
      </ul>
      <ul>
        ● Flexible Options: Short-term and long-term storage plans.
      </ul>
      <ul>
        ● Expert Handling: Professionally trained staff to ensure safe storage.
      </ul>
      <p>Contact us today for reliable and cost-effective storage solutions!</p>
    </div>
  </div>
</div>
<div
  appScrollTrigger
  (triggerPointReached)="handleScrollTrigger3()"
  class="alt-section fac-section"
  style="justify-content: center"
  [ngStyle]="{ height: isMobile ? 'auto' : 'min(465px, 46.5vh)' }"
>
  <div *ngIf="reachedPoint3" [@fadeIn] class="content">
    <p style="text-align: center">
      "At our facility in Rotterdam, we go beyond storage – we provide comprehensive solutions to optimize your supply
      chain processes."
    </p>
    <p style="text-align: center">Arjan Lagendijk, Logistic Manager ClearFreight Netherlands</p>
  </div>
</div>
<div
  #ContactUs
  appScrollTrigger
  (triggerPointReached)="handleScrollTrigger5()"
  class="section form-section"
  [ngStyle]="{ height: isMobile ? 'auto' : 'min(700px, 70vh)' }"
>
  <div class="title" [@listAnimation]="contactUs.length">
    <h2 class="partner-up" *ngFor="let item of contactUs">
      {{ item }}{{ item === ',' || item === '.' || item === ' ' ? '&nbsp;' : null }}
    </h2>
  </div>
  <div class="form">
    <div class="form-content">
      <mat-form-field appearance="fill">
        <mat-label>Full Name</mat-label>
        <input matInput [formControl]="name" required />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Email</mat-label>
        <input matInput [formControl]="email" required />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Message</mat-label>
        <textarea matInput [formControl]="goals" style="height: 70px"></textarea>
      </mat-form-field>
      <button mat-raised-button color="primary" (click)="handleSubmit()">Submit</button>
    </div>
  </div>
  <div class="contact-line">
    <p
      class="content"
      style="cursor: pointer"
      [ngStyle]="{ width: isMobile ? 'auto' : 'min(300px, 15vw)' }"
      (click)="mailTo()"
    >
      Email Address: {{ contactEmail }}
    </p>
    <div class="btn">
      <mat-icon (click)="copyToClipboard(contactEmail)">file_copy</mat-icon>
    </div>
    <!-- <button mat-raised-button color="primary" (click)="mailTo()">
        Email <img src="assets/images/mail-icon.jpg" />
      </button> -->
  </div>
  <div class="contact-line">
    <p class="content" [ngStyle]="{ width: isMobile ? 'auto' : 'min(300px, 15vw)' }">
      Phone Number: {{ contactPhoneFormatted }}
    </p>
    <div class="btn">
      <mat-icon (click)="copyToClipboard(contactPhone)">file_copy</mat-icon>
    </div>
  </div>
  <div *ngIf="reachedPoint5" [@fadeIn] class="content"></div>
  <div class="keywords">
    <p>Keywords:</p>
    <p>
      bonded warehouse Rotterdam, warehouse Rotterdam, Rotterdam, Netherlands, Europe, Warehouse, Storage, Dangerous
      Goods
    </p>
  </div>
</div>
