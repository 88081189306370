export function replaceHyperLink(val: string): string {
  const contentArr = val.split('<hyperlink');
  let returnStr = contentArr[0];
  contentArr.forEach((item, index) => {
    if (index > 0) {
      const beforeLinkStr = item.split('</hyperlink>')[0];
      const afterLinkStr = item.split('</hyperlink>')[1];
      const linkAttrArr = beforeLinkStr.split('>');
      const linkUrl = linkAttrArr[0].split('url=')[1];
      const linkContent = linkAttrArr[1];
      let aStr = '';
      if (afterLinkStr === undefined || !linkUrl || !linkContent) {
        if (afterLinkStr) {
          returnStr += afterLinkStr;
        }
      } else {
        aStr = '<a href=' + linkUrl + " target='_blank' class='hyperlink'>" + linkContent + '</a>';
        returnStr += aStr;
        returnStr += afterLinkStr;
      }
    }
  });

  return returnStr;
}
