import { Component, ElementRef, OnInit, AfterViewInit, ViewChild, HostListener } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { routeItem } from '../share/data-type/common.types';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { ContactService } from '../services/contact.service';
import * as _ from 'lodash';
import { animate, state, style, transition, trigger, useAnimation } from '@angular/animations';
import { fadeIn, fadeOut } from 'ng-animate';
import { gsap } from 'gsap';
import ScrollOut from 'scroll-out';
import { ScrollTrigger } from 'gsap/all';
import { NavMenuItemType, NavMenuType } from 'app/share/data-type/nav-menu.type';
import { nav_data } from './menu';
import { PagesLocalization, PagesLocalizationClass } from './pages.localization';
import { LocalizationService } from 'app/services/localization.service';
import { ScreenService } from 'app/services/screen.service';
const material_url = 'https://clrfwebservicestorage.blob.core.windows.net/clearfreight-dev-website/'; //http://localhost:6080/

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':leave', useAnimation(fadeOut, { params: { timing: 0.5, delay: 0 } })),
      transition(':enter', useAnimation(fadeIn)),
    ]),
  ],
})
export class PagesComponent implements OnInit, AfterViewInit {
  @ViewChild('buttomVideo')
  buttomVideoRef: ElementRef; //get video element
  headerClass: string = 'header';
  headerHeight: number = 103;
  headerSearchHeight: number = 75;
  headerTrackingHeight: number = 75;
  headerMobileMenuHeight: number = 50;
  headerLeft: number = 0;
  bottomVideo = {
    img: material_url + 'CF-Footer.jpg',
    video: material_url + 'CF-Footer.mp4',
  };
  menu: NavMenuType[] = [];
  currentMenuData: NavMenuType;
  navMenuData: NavMenuType[] = []; //nav_data after adjust routeAddress
  currentRoute = '';
  year: number;
  email = '';
  isSendSubscribe = false;
  isSending = false;
  isSubscribe = true;
  isShowMenu = false;
  isShowLogin = false;
  isShowSearch = false;
  isShowTracking = false;
  isShowMobileMenu = false;
  private lastScrollTop: number = 0;
  public isMobile: boolean = false;
  public localizedText: PagesLocalizationClass;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleServe: Title,
    private contactService: ContactService,
    private toastr: ToastrService,
    private localizationService: LocalizationService,
    private screenService: ScreenService
  ) {
    this.year = new Date().getFullYear();
    this.isMobile = this.screenService.isMobile();
    this.localizedText = this.localizationService.getTranslation(PagesLocalization);
    this.navMenuData = nav_data;
    this.router.events
      .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe(event => {
        this.currentRoute = event.urlAfterRedirects;
        let menuData: NavMenuType[] = [];
        this.navMenuData.forEach((item: NavMenuType) => {
          let nItem = _.clone(item);
          if (event.urlAfterRedirects.indexOf(nItem.routeAddress ?? 'nonenonenonenone') !== -1) {
            nItem.isCurrentRoute = true;
          }
          menuData.push(nItem);
        });
        this.menu = menuData;
      });

    this.setTitle();
  }
  public checkIsCurrent(url: string): boolean {
    if (this.currentRoute.indexOf('/' + url) === 0) return true;
    return false;
  }
  public get headerFinalHeight(): string {
    let total = this.headerHeight;
    if (this.isShowSearch) {
      total += this.headerSearchHeight;
    }
    if (this.isShowTracking) {
      total += this.headerTrackingHeight;
    }
    return total + 'px';
  }
  public setTitle() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          this.isShowMobileMenu = false;
          let child = this.activatedRoute.firstChild;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            } else if (child.snapshot.data && child.snapshot.data['title']) {
              return child.snapshot.data['title'];
            } else {
              return null;
            }
          }
          return null;
        })
      )
      .subscribe((data: any) => {
        if (data) {
          this.titleServe.setTitle(data);
        } else {
          this.titleServe.setTitle('ClearFreight');
        }
      });
  }

  ngOnInit(): void {
    // Register the ScrollTrigger plugin with gsap
    if (!this.isMobile) {
      gsap.registerPlugin(ScrollTrigger);
    }
  }
  ngAfterViewInit(): void {
    // Do the parallax effect on each section
    if (!this.isMobile) {
      gsap.from(this.buttomVideoRef.nativeElement, {
        top: this.isMobile ? '-80px' : `-580px`,
        ease: 'none', // Don't apply any easing function.
        scrollTrigger: {
          // Trigger the animation as soon as the section comes into view
          trigger: this.buttomVideoRef.nativeElement,
          // Animate on scroll/scrub
          scrub: true,
        },
      });
    }
  }
  //监听滚动，加载数据
  @HostListener('window:scroll', ['$event'])
  public onScroll = ($event: any) => {
    var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    var scrollLeft = document.documentElement.scrollLeft || document.body.scrollLeft;
    if (scrollTop > this.lastScrollTop && !this.isMobile) {
      //down
      this.headerClass = 'header hidden';
      this.isShowMenu = false;
      this.isShowLogin = false;
    } else if (scrollTop < this.lastScrollTop) {
      //up
      this.headerClass = 'header show';
    }
    this.headerLeft = -1 * scrollLeft;
    this.lastScrollTop = scrollTop;
  };
  subscribeEmail() {
    if (!this.email || !/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(this.email)) {
      this.toastr.warning("Please fill out the 'Email Address' field");
      return;
    }
    this.isSending = true;
    this.contactService.sendSubscribe({ email: this.email, isSubscribe: this.isSubscribe }).subscribe(res => {
      if (res) {
        this.email = '';
        this.isSendSubscribe = true;
        this.toastr.success('Subscribed successfully');
        this.isSending = false;
      }
    });
  }
  getSubMenu(menuKey: string): NavMenuType {
    let rev: NavMenuType = {
      itemTitle: '',
      key: '',
      itemDesc: '',
      menus: [],
    };
    this.navMenuData.forEach((item: NavMenuType) => {
      if (item.key == menuKey) {
        rev = item;
        return;
      }
    });
    return rev;
  }
  getSubMenuWithoutChild(menuKey: string): NavMenuType {
    let rev: NavMenuType = {
      itemTitle: '',
      key: '',
      itemDesc: '',
      menus: [],
    };
    this.navMenuData.forEach((item: NavMenuType) => {
      if (item.key == menuKey) {
        rev = _.cloneDeep(item);
        rev.menus.forEach(sm => {
          sm.children = [];
        });
        return;
      }
    });
    return rev;
  }
  showMenu(isShow: boolean, menu?: string) {
    this.isShowMenu = isShow;
    if (menu) {
      //change current menu data

      let subMenu = this.getSubMenu(menu);
      if (!subMenu.menus.length) {
        //no dropdown menu
        this.isShowMenu = false;
        return;
      } else {
        this.currentMenuData = subMenu;
      }
    }
  }
  showLogin(isShow: boolean) {
    this.isShowLogin = isShow;
  }
  showSearch(isShow: boolean) {
    this.isShowSearch = isShow;
  }
  showTracking(isShow: boolean) {
    this.isShowTracking = isShow;
  }
  showMobileMenu(isShow: boolean) {
    this.isShowMobileMenu = isShow;
  }
  navToClearView() {
    window.open('https://clearview.clearfreight.com', '_blank');
  }
  navigateToHome() {
    this.router.navigate(['/']);
  }
}
