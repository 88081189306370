const material_url =
  'https://clrfwebservicestorage.blob.core.windows.net/clearfreight-dev-website/'; //http://localhost:6080/
const imgage_url =
  'https://clrfwebservicestorage.blob.core.windows.net/newhome-dev';

export const article_service = [
  {
    id: '1',
    title: 'ARTICLE TITLE',
    imgUrl:
      '/Template%201.0/Related%20Articles/CF-Related-Article-Image-1.0.jpg',
  },
  {
    id: '2',
    title: 'ARTICLE TITLE',
    imgUrl:
      '/Template%201.0/Related%20Articles/CF-Related-Article-Image-2.0.jpg',
  },
  {
    id: '3',
    title: 'ARTICLE TITLE',
    imgUrl:
      '/Template%201.0/Related%20Articles/CF-Related-Article-Image-3.0.jpg',
  },
];

export const white_paper_service = [
  {
    title: 'WHITE PAPER TITLE',
    image:
      imgage_url +
      '/Template%201.0/Related%20Articles/CF-Related-Article-Image-1.0.jpg',
  },
  {
    title: 'WHITE PAPER TITLE',
    image:
      imgage_url +
      '/Template%201.0/Related%20Articles/CF-Related-Article-Image-2.0.jpg',
  },
  {
    title: 'WHITE PAPER TITLE',
    image:
      imgage_url +
      '/Template%201.0/Related%20Articles/CF-Related-Article-Image-3.0.jpg',
  },
];
export const press_article = [
  {
    title: 'WHITE PAPER TITLE',
    image:
      imgage_url +
      '/Template%201.0/Related%20Articles/CF-Related-Article-Image-1.0.jpg',
  },
  {
    title: 'WHITE PAPER TITLE',
    image:
      imgage_url +
      '/Template%201.0/Related%20Articles/CF-Related-Article-Image-3.0.jpg',
  },
];

export const videos = [
  {
    img: material_url + 'CF-Homepage-Air-1.0.jpg',
    video: material_url + 'CF-Homepage-Air-1.0.mp4',
  },
  {
    img: material_url + 'CF-Homepage-Ocean_1.0.jpg',
    video: material_url + 'CF-Homepage-Ocean_1.0.mp4',
  },
  {
    img: material_url + 'CF-Homepage-Rail-1.0.jpg',
    video: material_url + 'CF-Homepage-Rail-1.0.mp4',
  },
  {
    img: material_url + 'CF-Homepage-Road-1.0.jpg',
    video: material_url + 'CF-Homepage-Road-1.0.mp4',
  },
];

export const news_data = [
  {
    title: 'Lorem Ipsum',
    date: 'Date',
    desc: 'Lorem ipsum dolor sit amet,     consectetur adipiscing elit, sed     do eiusmod tempor incididunt ut    labore et dolore magna aliqua.',
    link: '',
  },
  {
    title: 'Lorem Ipsum',
    date: 'Date',
    desc: 'Lorem ipsum dolor sit amet,     consectetur adipiscing elit, sed     do eiusmod tempor incididunt ut     labore et dolore magna aliqua.',
    link: '',
  },
  {
    title: 'Lorem Ipsum',
    date: 'Date',
    desc: 'Lorem ipsum dolor sit amet,     consectetur adipiscing elit, sed     do eiusmod tempor incididunt ut    labore et dolore magna aliqua.',
    link: '',
  },
];

export const title_data = [
  {
    title: 'IMPORT',
    link: '',
  },
  {
    title: 'EXPORT',
    link: '',
  },
  {
    title: 'FINANCIAL DOCUMENTS',
    link: '',
  },
  {
    title: 'TERMS AND CONDITIONS',
    link: '',
  },
];
