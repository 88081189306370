import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
@Component({
  selector: 'app-icon-btn',
  templateUrl: './icon-btn.component.html',
  styleUrls: ['./icon-btn.component.scss']
})
export class IconBtnComponent implements OnInit {
  @Input() icon: string = 'arrow_forward';
  @Input() size: number = 24;
  @Input() theme: '' | 'blue' | 'no-border' | string = '';
  constructor(
  ) { }

  ngOnInit(): void {
  }
}
