<div class="industry">
  <app-sub-header
    src="./assets/images/Services/Logistics/CF-Breakbulk_&_Project_Cargo.jpg"
    top="-600px"
  ></app-sub-header>
  <div class="manufacturing">
    <div class="body">
      <h3>{{ localizedText.subTitle }}</h3>
      <h1>{{ localizedText.title }}</h1>
      <div class="text">{{ localizedText.description1 }}</div>
      <div>
        <app-item-list
          [item_data]="localizedText.servicesList ?? []"
        ></app-item-list>
      </div>

      <div
        [ngStyle]="{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }"
      >
        {{ localizedText.outro }}
        <app-contact-button
          [ngStyle]="{ marginTop: '50px' }"
        ></app-contact-button>
      </div>

      <app-related-service
        [services_data]="services_data"
      ></app-related-service>
    </div>

    <div class="related">
      <app-related-article
        [article_service_data]="articles"
      ></app-related-article>
    </div>
  </div>
</div>
