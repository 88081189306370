<div class="container">
  <mat-expansion-panel *ngIf="navMenu">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ navMenu.itemTitle }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="menu-group" *ngFor="let menu of navMenu.menus">
      <p class="menu-title" (click)="handleNav(menu.routeAddress)">
        {{ menu.title }}
      </p>
      <p
        class="menu-child"
        *ngFor="let child of menu.children"
        (click)="handleNav(child.routeAddress)"
      >
        {{ child.title }}
      </p>
    </div>
  </mat-expansion-panel>
</div>
