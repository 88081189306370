import { Component } from '@angular/core';
import { environment } from 'environments/environment';
import { NgxCookiebotService } from '@halloverden/ngx-cookiebot';
import { filter } from 'rxjs';
import { register } from 'swiper/element/bundle';

register();

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(private _cookieBotService: NgxCookiebotService) {
    if (environment.googleAnalyticsId) {
      const gaScript1 = document.createElement('script');
      gaScript1.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
      `;
      document.head.appendChild(gaScript1);

      this._cookieBotService.onServiceReady$
        .pipe(
          filter((ready: boolean) => {
            return ready;
          })
        )
        .subscribe(() => {
          this._cookieBotService.onConsentReady$.subscribe(() => {
            // register google tag manager
            const gTagManagerScript = document.createElement('script');
            gTagManagerScript.async = true;
            gTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalyticsId}`;
            document.head.appendChild(gTagManagerScript);
            // register google analytics
            const gaScript = document.createElement('script');
            gaScript.innerHTML = `
              gtag('js', new Date());
              gtag('config', '${environment.googleAnalyticsId}', {
                send_page_view: false,
                'custom_map': {
                  'dimension1': 'event_label',
                  'dimension2': 'user_email',
                }
              });
            `;
            document.head.appendChild(gaScript);

            const websightsScript = document.createElement('script');
            websightsScript.innerHTML = `
            (function () {
              var zi = document.createElement('script');
              zi.type = 'text/javascript';
              zi.async = true;
              zi.referrerPolicy = 'unsafe-url';
              zi.src = 'https://ws.zoominfo.com/pixel/63191ca80c95aefd507f5362';
              var s = document.getElementsByTagName('script')[0];
              s.parentNode.insertBefore(zi, s);
            })();
            `;
            document.head.appendChild(websightsScript);
          });
        });
    }
  }
}
