import { Localization } from 'app/share/data-type/common.types';

export const ServicesIntermodalSolutionsLocalization: {
  [key: string]: Localization;
} = {
  en: {
    subTitle: 'Logistics',
    title: 'Intermodal Solutions',
    description1: `At ClearFreight, we pride ourselves on delivering innovative intermodal solutions to optimize your transportation processes. Our intermodal services seamlessly integrate multiple modes of transportation, offering a flexible and efficient approach to meet your shipping needs. Explore the benefits of ClearFreight's intermodal solutions for a smarter, more sustainable, and cost-effective logistics experience: `,
    description2: `ClearFreight's intermodal solutions redefine the way you approach transportation, offering a seamless, cost-effective, and environmentally conscious logistics experience. Elevate your shipping strategy with ClearFreight—your partner in innovative intermodal solutions.`,
    services: 'Intermodal Services include:',
    servicesList: [
      'Air/Sea/Rail/Road',
      'Container drayage',
      'Cross Docking',
      'Transloading',
      'FTL (full truckload)',
      'LTL (less-than-trailer load',
      'Special trailers - flatbed, reefer trailer, curtain trailer, tarpaulin trailer, lowboy, step deck trailer, etc.',
    ],
    itemDescList: [
      {
        item: 'Multi-Modal Connectivity:',
        description:
          'Harness the power of our extensive network and connect various transportation modes—rail, road, sea, and air—to create a cohesive and efficient supply chain solution. ClearFreight ensures a smooth transition between modes, minimizing transit times and enhancing overall logistics efficiency.',
      },
      {
        item: 'Cost-Efficiency:',
        description:
          'Take advantage of cost-effective transportation by leveraging the benefits of intermodal solutions. ClearFreight optimizes routes and modes, providing a balance between cost savings and reliable service. Reduce expenses without compromising on the quality of transportation.',
      },
      {
        item: 'Door-to-Door Visibility:',
        description: `Gain real-time visibility into your shipments with ClearFreight's advanced tracking and monitoring systems. From origin to destination, track your cargo's journey seamlessly, ensuring transparency and control over your supply chain.`,
      },
      {
        item: 'Customized Intermodal Solutions:',
        description:
          'ClearFreight understands that every business has unique transportation requirements. Our team works closely with you to tailor intermodal solutions that align with your specific needs, ensuring a personalized and effective logistics strategy.',
      },
      {
        item: 'Integrated Technology:',
        description: `Stay ahead with ClearFreight's cutting-edge technology. Our integrated systems streamline communication, documentation, and logistics management, providing you with the tools to enhance overall efficiency.`,
      },
      {
        item: 'Risk Mitigation:',
        description: `ClearFreight's intermodal solutions include robust risk mitigation strategies. Benefit from our expertise in managing potential challenges across different transportation modes, safeguarding your shipments and minimizing disruptions.`,
      },
    ],
  },
  es: {},
  jp: {},
  cn: {},
  th: {},
  nl: {},
};

export interface ServicesIntermodalSolutionsLocalizationClass
  extends Localization {
  itemDescList?: { item: string; description: string }[];
}
