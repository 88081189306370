import {Component, OnInit, ChangeDetectionStrategy, Input} from '@angular/core';

@Component({
  selector: 'app-service-section',
  templateUrl: './service-section.component.html',
  styleUrls: ['./service-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceSectionComponent implements OnInit {

  @Input() title = '';
  @Input() imgUrl = '';
  constructor() { }

  ngOnInit(): void {
  }

}
