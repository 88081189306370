import {Component, OnInit, ChangeDetectionStrategy, EventEmitter,Output} from '@angular/core';

@Component({
  selector: 'app-our-service',
  templateUrl: './our-service.component.html',
  styleUrls: ['./our-service.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OurServiceComponent implements OnInit {


  @Output() scrollToSection = new EventEmitter<string>();
  constructor() { }

  ngOnInit(): void {
  }

  public clickSection(name: string) {
    this.scrollToSection.emit(name)
  }

}
