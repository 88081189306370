import { Component, Input, OnInit } from '@angular/core';
import {
  NewsroomLocalization,
  NewsroomLocalizationClass,
} from './newsroom.localization';
import { LocalizationService } from 'app/services/localization.service';

@Component({
  selector: 'app-newsroom',
  templateUrl: './newsroom.component.html',
  styleUrls: ['./newsroom.component.scss'],
})
export class NewsroomComponent implements OnInit {
  localizedText: NewsroomLocalizationClass;
  constructor(private localizationService: LocalizationService) {
    this.localizedText =
      this.localizationService.getTranslation(NewsroomLocalization);
  }
  @Input() current: string;

  @Input() showSearch: boolean = true;
  ngOnInit(): void {}
}
