<div class="newsroom">
  <app-article-header
    [top]="header_img_top"
    [title]="postItem?.title"
    src="./assets/images/Newsroom/CF-Header-Newsroom.jpg"
  ></app-article-header>
  <div class="content">
    <a class="gray" routerLink="/resources/newsroom">
      <div class="back">
        <i class="material-icons">arrow_back</i>
        <div>Back to all articles</div>
      </div>
    </a>
    <div class="news-content">

      <div *ngFor="let item of postItem?.contentList" [ngSwitch]="item.type">
        <div
          class="paragraph"
          [innerHTML]="item.value"
          *ngSwitchCase="'Paragraph'"
        ></div>
        <ng-container *ngSwitchCase="'MultiParagraph'">
          <div
            *ngFor="let i of item.value.split('\n')"
            [innerHTML]="i"
            class="paragraph"
          ></div>
        </ng-container>
        <div class="sub-title-h1" *ngSwitchCase="'Heading1'">
          <strong>{{ item.value }}</strong>
        </div>
        <div class="sub-title-h2" *ngSwitchCase="'Heading2'">
          <strong>{{ item.value }}</strong>
        </div>
        <div class="sub-title-h3" *ngSwitchCase="'Heading3'">
          <strong>{{ item.value }}</strong>
        </div>
        <div class="sub-title-h4" *ngSwitchCase="'Heading4'">
          <strong>{{ item.value }}</strong>
        </div>
        <div class="sub-title-h5" *ngSwitchCase="'Heading5'">
          <strong>{{ item.value }}</strong>
        </div>
        <div class="list" *ngSwitchCase="'List'">
          <ul>
            <li *ngFor="let i of item.list">{{ i }}</li>
          </ul>
        </div>
        <div class="image" *ngSwitchCase="'Image'">
          <img [src]="item.imageValue.url" />
        </div>
        <div class="image" *ngSwitchCase="'Video'">
          <video controls>
            <source [src]="item.imageValue.url" type="video/mp4" />
          </video>
        </div>
        <div *ngSwitchDefault></div>
      </div>
    </div>
    <div class="related">
      <!-- <app-related-service [services_data]="services_data"></app-related-service>
      <app-related-article [article_service_data]="articles"></app-related-article> -->
    </div>
  </div>
</div>
