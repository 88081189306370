import { Localization } from 'app/share/data-type/common.types';

export const FormsCtpatLocalization: {
  [key: string]: FormsCtpatLocalizationClass;
} = {
  en: {
    title: 'C-TPAT',
    description1: 'Customs-Trade Partnership Against Terrorism',
    description2: `C-TPAT is a voluntary government-business initiative to build cooperative relationships that strengthen and
        improve overall international supply chain and U.S. border security. C-TPAT offers trade-related businesses an
        opportunity to play an active role in the war against terrorism. ClearFreight has been a certified C-TPAT
        member since 2003 and was successfully validated and revalidated by Customs. CBP will offer benefits to certain
        certified C-TPAT member categories, including:`,
    items1: [
      'A reduced number of CBP inspections (reduced border delay times)',
      'Priority processing for CBP inspections. (Front of the Line processing for inspections when possible.)',
      "Assignment of a C-TPAT Supply Chain Security Specialist (SCSS) who will work with the company to validate and enhance security throughout the company's international supply chain.",
      'Potential eligibility for CBP Importer Self-Assessment program (ISA) with an emphasis on self-policing,not CBP audits.',
      'Eligibility to attend C-TPAT supply chain security training seminars.',
    ],
    description3: 'For more information, ',
    description4: 'click here.',
  },
  es: {},
  jp: {
    title: 'C-TPAT',
    description1: '対テロリズム関税取引連携',
    description2:
      'C-TPAT（シー・ティー・パット）は、国とビジネスが協力関係を構築し、国際的なサプライチェーンと米国の国境セキュリティを強化し改善するための自発的な取り組みです。C-TPATは貿易関連の事業者に、テロリズムとの戦いに積極的な役割を果たす機会を提供します。ClearFreightは2003年以来C-TPAT認定メンバーであり、税関によって成功裏に検証および再検証されました。CBPは、特定のC-TPAT認定メンバーカテゴリーに利点を提供します。これには以下が含まれます：',
    items1: [
      'CBP検査の減少（国境の遅延時間の短縮）',
      'CBP検査の優先処理（可能な場合は検査の前列処理）',
      'C-TPATサプライチェーンセキュリティスペシャリスト（SCSS）の指定。SCSSは企業と協力して国際的なサプライチェーン全体のセキュリティを検証および強化します。',
      'CBP輸入業者自己評価プログラム（ISA）への潜在的な資格。これはCBPの監査ではなく、自主監査を重視しています。',
      'C-TPATサプライチェーンセキュリティトレーニングセミナーへの参加資格。',
    ],
    description3: '詳細については、',
    description4: 'こちらをクリック。',
  },
  nl: {},
  cn: {},
  th: {},
};

export interface FormsCtpatLocalizationClass extends Localization {}
