import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AboutRoutingModule } from './about-routing.module';
import { AboutComponent } from './about.component';
import { ShareModule } from '../../share/share.module';
import { LeadershipComponent } from './leadership/leadership.component';
import { HeritageComponent } from './heritage/heritage.component';
import { CareersComponent } from './careers/careers.component';
import { CareersModule } from './careers/careers.module';

@NgModule({
  declarations: [
    AboutComponent,
    LeadershipComponent,
    HeritageComponent,
    CareersComponent,
  ],
  imports: [CommonModule, ShareModule, CareersModule, AboutRoutingModule],
})
export class AboutModule {}
