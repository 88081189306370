import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { get_forms_data } from '../forms/data';
import { LocalizationService } from 'app/services/localization.service';
import {
  FormsCtpatLocalization,
  FormsCtpatLocalizationClass,
} from './forms-ctpat.localization';
@Component({
  selector: 'app-forms-ctpat',
  templateUrl: './forms-ctpat.component.html',
  styleUrls: ['./forms-ctpat.component.scss'],
})
export class FormCtpatComponent implements OnInit {
  public localizedText: FormsCtpatLocalizationClass;

  constructor(
    public router: Router,
    private localizationService: LocalizationService
  ) {
    this.localizedText = localizationService.getTranslation(
      FormsCtpatLocalization
    );
  }
  ctapt_data = get_forms_data(this.localizationService).filter(
    (item) => item.type == 'CTAPT'
  );
  ngOnInit(): void {}
}
