import { Localization } from 'app/share/data-type/common.types';

export const ServicesComplianceConsultingLocalization: {
  [key: string]: ServicesComplianceConsultingLocalizationClass;
} = {
  en: {
    subTitle: 'Global Trade',
    title: 'Trade Compliance Advisory, a value-added resource',
    description1:
      'ClearFreight’s trade compliance team comprised of licensed customs brokers with professional experience exceeding twenty years each. Complementing their compliance knowledge, these individuals have work experience at an import or export company, bringing additional knowledge and insights that are very helpful in understanding your business needs',
    services: 'Trade Compliance Services:',
    servicesList: [
      'Customs Compliance Advisory',
      'Regulatory requirements for Partner Government Agencies ',
      'FDA Guidance for Food, Beverages, Cosmetics, Drugs, Medical devices',
      'HTS Advisory including product reviews and pre-classifications',
      'Duty Preference Program Guidance',
      'Binding Ruling Requests',
      'Post Entry Services ',
      'Country of Origin determination',
      'Anti-dumping and countervailing issues',
      'Intellectual Property Rights and Licensing requirements',
      'Resource library for compliance publications and materials',
      'Informed Compliance',
    ],
  },
  cn: {},
  es: {},
  nl: {},
  jp: {},
  th: {},
};

export interface ServicesComplianceConsultingLocalizationClass
  extends Localization {}
