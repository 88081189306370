<div class="events">
  <h2 class="table-name">Events</h2>
  <ul>
    <div class="events-table-container">
      <table>
        <tr>
          <th class="event-mode"></th>
          <th>Description</th>
          <th>Date</th>
          <th>Location</th>
          <th></th>
        </tr>
        <tr *ngFor="let event of events">
          <td class="event-mode">
            <img
              *ngIf="event.transportMode"
              src="{{ getEventModeIcon(event.transportMode) }}"
              alt="{{ event.transportMode }}"
            />
          </td>
          <td>{{ event.eventName }}</td>
          <td>{{ event.eventTime | date }}</td>
          <td>{{ event.locationUNLOCO }}</td>
          <td>{{ event.locationNameWithDiacriticals ?? '' }}</td>
        </tr>
      </table>
    </div>
  </ul>
</div>
