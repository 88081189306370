import { Localization } from 'app/share/data-type/common.types';

export const ContactButtonLocalization: {
  [key: string]: ContactButtonLocalizationClass;
} = {
  en: {
    contactButton: 'Contact Us',
  },
  es: {
    contactButton: 'Contáctenos',
  },
  jp: {
    contactButton: 'お問い合わせ',
  },
  cn: {
    contactButton: '联系我们',
  },
  th: {
    contactButton: 'ติดต่อเรา',
  },
  nl: {
    contactButton: 'Neem contact met ons op',
  },
};

export interface ContactButtonLocalizationClass extends Localization {
  contactButton?: string;
}
