import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FormDataService {
  private name: string;
  private email: string;
  private goals: string;

  setName(name: string) {
    this.name = name;
  }

  setEmail(email: string) {
    this.email = email;
  }

  setGoals(goals: string) {
    this.goals = goals;
  }

  getName() {
    return this.name;
  }

  getEmail() {
    return this.email;
  }

  getGoals() {
    return this.goals;
  }
}
