import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { fadeInDown } from 'ng-animate';
import { NavMenuType } from 'app/share/data-type/nav-menu.type';
import { ScreenService } from 'app/services/screen.service';
import { MatDialog } from '@angular/material/dialog';
import { VideoDialogComponent } from './video-dialog/video-dialog.component';
@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss'],
  animations: [
    trigger('bounce', [
      transition(
        '* => *',
        useAnimation(fadeInDown, {
          params: { timing: 0.45 },
        })
      ),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavMenuComponent implements OnInit {
  bounce: any;
  @Input() item: NavMenuType = {
    itemTitle: '',
    key: '',
    itemDesc: '',
    menus: [],
  };
  @Output() setState = new EventEmitter();
  public isMobile: boolean = false;

  constructor(
    private screenService: ScreenService,
    private videoDialog: MatDialog
  ) {
    this.isMobile = this.screenService.isMobile();
  }

  openVideoDialog(): void {
    this.videoDialog.open(VideoDialogComponent, {
      width: '90vw',
      maxWidth: '100%',
      height: '80vh',
      maxHeight: '100%',
      panelClass: 'video-dialog',
      autoFocus: true,
      disableClose: false,
    });
  }

  ngOnInit(): void {}
  leaveMenu(ev?: MouseEvent) {
    //moveOutside (moveOutside)="leaveMenu($event)"
    let element = document.querySelector('.header-menu');
    if (ev) {
      let clickedInside = element?.contains(ev.target as Node);

      if (clickedInside) {
        return;
      }
    }
    this.setState.emit(false);
  }
}
