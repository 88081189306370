import { Localization } from 'app/share/data-type/common.types';

export const AboutLocalization: { [key: string]: AboutLocalizationClass } = {
  en: {
    intro:
      'ClearFreight is a full-service, licensed NVOCC and IATA agent. We are a privately owned company with a global presence through our exclusive network of partners. Our headquarters are in Los Angeles, California, but we have branch offices worldwide. We have a global network of agents and partners in over 100 countries. Our team of experienced professionals is committed to providing our customers with the highest level of service and support. Your supply chain will be handled with the utmost care and attention.',
    our: 'Our',
    vision: 'Vision.',
    vision1:
      'At ClearFreight, we’ve created a collaborative environment that fosters loyalty and growth',
    vision2:
      'Our vision is to be an industry leader in delivering Customer Forward solutions in global logistics that ensures success for our customers and our people, by leveraging on our years of experience and commitment to technology advancements.',
    heritage: 'Heritage.',
    heritage1:
      'ClearFreight was founded in San Francisco in 1973 as a Customs House Broker. Yes, we know our stuff!',
    heritage2:
      'Over the years we’ve continually strived for excellence and have achieved steady growth. Our success is due to constant emphasis on the value of our people, coupled with the most innovative procedures and technology in the industry. That’s why we’re one of the most tenured companies in the world.',
    lookingAhead: 'We’re always looking ahead',
    outro:
      'We’re always looking ahead. If you’re looking to do great things, we’d love to hear from you.',
  },
  cn: {},
  jp: {},
  th: {},
  nl: {},
  es: {},
};

export interface AboutLocalizationClass extends Localization {
  intro?: string;
  our?: string;
  vision?: string;
  vision1?: string;
  vision2?: string;
  heritage?: string;
  heritage1?: string;
  heritage2?: string;
  lookingAhead?: string;
  outro?: string;
}
