<div class="leadership">
  <app-center-pic src="./assets/images/About/Leadership/CF-1.0-Header.jpg"></app-center-pic>

  <app-title-description title="Leadership">
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
    labore et dolore
    magna
    aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
    consequat.
    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
  </app-title-description>
  <div class="content">
    <div class="leader-item" *ngFor="let item of leaderDatas;index as i">
      <div class="img{{i%3==1?' light':''}}"></div>
      <h1 class="name">{{item.name}}</h1>
      <h2 class="title">{{item.title}}</h2>
    </div>
  </div>
</div>