import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { fadeInDown } from 'ng-animate';
@Component({
  selector: 'app-center-pic',
  templateUrl: './center-pic.component.html',
  styleUrls: ['./center-pic.component.scss']
})
export class CenterPicComponent implements OnInit {
  @Input() src: string = "";
  @Input() top: number = -470;
  @Output() setState = new EventEmitter();
  constructor(
  ) { }

  ngOnInit(): void {
  }
}
