<div class="industry">
  <app-sub-header
    src="./assets/images/Services/Global_Trade/CF-Cargo-Insurance2.jpg"
    top="min(-45vh, 459px)"
  ></app-sub-header>
  <div class="manufacturing">
    <div class="body">
      <h3>{{ localizedText.subTitle }}</h3>
      <h1>{{ localizedText.title }}</h1>
      <div class="text">{{ localizedText.description1 }}</div>
      <!-- <h2>{{ localizedText.services }}</h2>
      <div>
        <app-item-list
          [item_data]="localizedText.servicesList ?? []"
        ></app-item-list>
      </div> -->
      <h2>{{ localizedText.keyFeatures }}</h2>
      <app-items-and-descs [item_data]="localizedText.featuresList ?? []"></app-items-and-descs>
      <h2>{{ localizedText.whyCargoInsurance }}</h2>
      <app-items-and-descs [item_data]="localizedText.insuranceReasons ?? []"></app-items-and-descs>
      <h2>{{ localizedText.carrierLiability }}</h2>
      <app-items-and-descs [item_data]="localizedText.liabilityOverview ?? []"></app-items-and-descs>
      <h2>{{ localizedText.insuranceOptions }}</h2>
      <app-items-and-descs [item_data]="localizedText.optionsList ?? []"></app-items-and-descs>
      <h2>{{ localizedText.howToObtain }}</h2>
      <div class="text">{{ localizedText.description2 }}</div>

      <div
        [ngStyle]="{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }"
      >
        {{ localizedText.outro }}
        <app-contact-button [ngStyle]="{ marginTop: isMobile ? '0' : '50px' }"></app-contact-button>
      </div>

      <app-related-service [services_data]="services_data"></app-related-service>
    </div>

    <div class="related">
      <app-related-article [article_service_data]="article_service_data"></app-related-article>
    </div>
  </div>
</div>
