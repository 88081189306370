<div class="order-management">
  <div class="content">
    <h1 class="title">{{ localizedText.title }}</h1>
    <h2 class="title2">{{ localizedText.subTitle }}</h2>

    <div class="introduction">
      <div class="intro-text">
        <h3>{{ localizedText.intro }}</h3>
        <p>{{ localizedText.description1 }}</p>
        <!-- <app-item-list
            [item_data]="localizedText.introList ?? []"
          ></app-item-list> -->
      </div>
      <div class="intro-img">
        <img src="assets/images/Technology/Mobile/MobileGif2.gif" />
      </div>
    </div>
  </div>
  <div class="why-oms">
    <video
      class="videobg"
      #video1
      autoplay="true"
      [loop]="video1"
      [muted]="video1"
      preload="auto"
      [poster]="videos[0].img"
    >
      <source [src]="videos[0].video" type="video/mp4" />
    </video>
    <div class="blue-bg">
      <div class="rotate-title">
        <app-vertical-text [width]="800" fontSize="large" [outerWidth]="260" theme="white">
          {{ localizedText.titleRotate }}
        </app-vertical-text>
        <div class="rotate-title-mobile">{{ localizedText.titleRotate }}</div>
      </div>
      <div class="articles">
        <div class="big-title">
          {{ localizedText.services }}
        </div>
        <div class="why-oms-content">
          <div class="paragraph" *ngFor="let item of localizedText.featuresWTitleDesc">
            <img [src]="item.imgSrc" />
            <h3>{{ item.item }}</h3>
            <h4>{{ item.subtitle }}</h4>
            {{ item.description }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="key-features">
    <div class="light-blue-bg">
      <div class="big-title">
        {{ localizedText.whyMobile }}
      </div>
      <div class="key-features-content">
        <!-- <div class="rotate-title">
            <app-vertical-text
              [width]="800"
              fontSize="large"
              [outerWidth]="260"
              theme="white"
              >{{ localizedText.keyFeaturesRotate }}</app-vertical-text
            >
          </div> -->
        <div class="articles">
          <div class="articles-content">
            <div class="paragraph">
              <div *ngFor="let item of localizedText.itemsWTitleDesc">
                <h3>{{ item.item }}</h3>
                <p>{{ item.description }}</p>
              </div>
            </div>
            <div class="intro-img">
              <img src="assets/images/Technology/Mobile/MobileGif1.gif" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <video class="videobg" #video2 autoplay="true" [loop]="video2" [muted]="video2" [poster]="videos[1].img"></video>
  </div>
  <div class="tech-header"></div>
  <section class="schedule-demo">
    <div class="schedule-demo-content">
      <div class="left">
        <div class="line"></div>
      </div>
      <div class="demo-desc">
        <app-title-description
          [height]="isMobile ? '90px' : 'auto'"
          [spaceHeight]="isMobile ? '30px' : '80px'"
          [titleFontSize]="isMobile ? '28px' : '32px'"
          title="Schedule a Demo Today"
        ></app-title-description>
        <div class="btn-connect"><app-btn link="../demo">CONNECT</app-btn></div>
      </div>
    </div>
  </section>
</div>
