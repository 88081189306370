<div
  [ngStyle]="{ width: width, height: height, margin: margin }"
  (click)="navigate()"
  class="icon-img-desc"
>
  <div class="icon">
    <img [src]="svgSrc" alt="" />
  </div>
  <div class="vertical-line"></div>
  <div class="text">
    <h3 class="title">{{ title }}</h3>
    <p class="description">{{ description }}</p>
  </div>
</div>
