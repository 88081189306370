import { subscribeParams } from './../share/data-type/common.types';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { contactParams } from '../share/data-type/common.types';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  constructor(private http: HttpClient) {}

  sendContact(params: contactParams): Observable<unknown> {
    let requestURL = `${environment.cfApiUrl}CF/Contact`;
    return this.http.post<unknown>(requestURL, params);
  }

  sendSubscribe(params: subscribeParams): Observable<unknown> {
    let requestURL = `${environment.cfApiUrl}CF/SubscribeEmail`;
    return this.http.post<unknown>(requestURL, params);
  }

  sendContactNL(params: contactParams): Observable<unknown> {
    let requestURL = `${environment.cfApiUrl}CF/ContactNL`;
    return this.http.post<unknown>(requestURL, params);
  }
}
