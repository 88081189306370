<div class="carousel-button" *ngIf="isMobile">
  <button mat-icon-button class="btn btn-left" (click)="changeSlide('back')">
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>
  <button mat-icon-button class="btn btn-right" (click)="changeSlide('forward')">
    <mat-icon>keyboard_arrow_right</mat-icon>
  </button>
</div>
<div class="news-room-menu">
  <swiper-container *ngIf="isMobile" loop="true" slides-per-view="2" #swiperRef>
    <swiper-slide *ngFor="let item of items">
      <div class="swiper-slide">
        <a class="{{ item.isCurrent ? 'current' : '' }}" [routerLink]="[item.link]">
          <h4>{{ item.title }}</h4>
        </a>
      </div>
    </swiper-slide>
  </swiper-container>
  <swiper-container *ngIf="!isMobile" loop="true" initial-slide="2" slides-per-view="4" #swiperRef>
    <swiper-slide *ngFor="let item of items">
      <div class="swiper-slide">
        <a class="{{ item.isCurrent ? 'current' : '' }}" [routerLink]="[item.link]">
          <h4>{{ item.title }}</h4>
        </a>
      </div>
    </swiper-slide>
  </swiper-container>
</div>
