<div class="section">
  <div class="title"><h3>Thank you</h3></div>

  <div class="content">
    <p>
      Your response will be reviewed and replied to by a member of our team.
      Please reach out to
      <a href="mailto:info@clearfreight.nl">info&#64;clearfreight.nl</a> for any
      questions in the meantime.
    </p>
    <ul>
      <b>Name:</b>
      {{
        name
      }}
    </ul>
    <ul>
      <b>Email:</b>
      {{
        email
      }}
    </ul>
    <ul>
      <b>Goals:</b>
      {{
        goals
      }}
    </ul>
  </div>
  <button mat-raised-button color="primary" (click)="goBack()">Go Back</button>
</div>
