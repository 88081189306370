import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

import { services_data, article_service } from './data';
import { imageTextFadeInType } from 'app/share/data-type/common.types';
import {
  ServicesIntermodalSolutionsLocalization,
  ServicesIntermodalSolutionsLocalizationClass,
} from './intermodal-solutions.localization';
import { LocalizationService } from 'app/services/localization.service';
import { relatedArticle } from 'app/share/data-type/post.types';
import {
  RelatedServicesService,
  ServiceDescription,
} from 'app/services/related-services.service';
import { Router } from '@angular/router';
import { PostService } from 'app/services/post.service';
@Component({
  selector: 'app-services-intermodal-solutions',
  templateUrl: './intermodal-solutions.component.html',
  styleUrls: ['./intermodal-solutions.component.scss'],
})
export class ServicesIntermodalSolutionsComponent
  implements OnInit, AfterViewInit
{
  public localizedText: ServicesIntermodalSolutionsLocalizationClass;

  article_service_data: relatedArticle[];
  services_data: ServiceDescription[];
  constructor(
    private localizationService: LocalizationService,
    private relServService: RelatedServicesService,
    private relArticleService: PostService,
    private router: Router
  ) {
    this.localizedText = this.localizationService.getTranslation(
      ServicesIntermodalSolutionsLocalization
    );
    let route = this.router.url;
    this.services_data = this.relServService.getServices(route);
    this.relArticleService.getRelatedPostList().subscribe((data) => {
      this.article_service_data =
        this.relArticleService.mapPostDataToRelatedArticles(data);
    });
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {}
}
