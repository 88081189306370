import { Localization } from 'app/share/data-type/common.types';

export const ServicesOceanLocalization: {
  [key: string]: ServicesOceanLocalizationClass;
} = {
  en: {
    subTitle: 'Transportation',
    title: 'Global Ocean Forwarding, first mile till last mile, seamlessly',
    description1:
      'ClearFreight continue to invest in expanding our operations globally to support your needs and growth. We strategically invest in key markets and build trusted partnerships throughout major shipping hubs to ensure that you can trust ClearFreight to manage your cargo and secure your supply chain.',
    description2:
      'ClearFreight facilitates the smooth movement of your goods across international borders, offering comprehensive support for all aspects of the process, from the time goods leave the shipper’s facility until it’s delivered to consignee’s door. At origin or destination, we will provide the local services to support the expedited movement of cargo, including exports/import customs processing, multi-vendor consolidations, trucking, packing, transloading, palletizing and container loading.',
    description3:
      'Leveraging on our carriers and our extensive partner network carriers, we offer customizable solutions that will need your specific needs. Our flexibility and resourcefulness are often the reasons our clients like collaborating with us. We can respond quickly to your changing routes or sourcing strategy by not locking you down on a single carrier solution. We will collaborate with you to create a solution that best suits your requirements.',
    description4:
      'Our ClearView platform, provides a single stop for managing all your ocean shipments globally. Our EDI connections with ocean carriers facilitate real time updates and views on our platform tracks milestone, report delays, allow you to flag priority shipments, view, download/upload shipping documents and export/extract shipment reports.',
    services: 'Global Ocean Services',
    servicesList: [
      'Online bookings through ClearView',
      'Complete milestone tracking through ClearView',
      'SOC/COC Containers',
      'Reefer/Hazardous Cargo',
      'Express & regular Vessels ',
      'LCL/FCL',
      'Multi-vendor consolidations',
      'Sea/Air options',
      'Rail Service',
      'Road Transportation',
      'Transloading ',
      'Warehousing',
      'Cargo Insurance',
    ],
  },
  es: {},
  jp: {},
  cn: {},
  th: {},
  nl: {},
};

export interface ServicesOceanLocalizationClass extends Localization {}
