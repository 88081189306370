<app-newsroom current="Industry News">
  <div class="news-body">
    <div class="news-item" *ngFor="let item of posts; let i = index">
      <a routerLink="/resources/stats/{{ item.name }}">
        <div
          class="image"
          [ngStyle]="{ '--image': 'url(' + item.listImageUrl + ')' }"
        ></div>
      </a>
      <div class="text">
        <div class="text-content">
          <div class="article-date">{{ item.publishTime }}</div>
          <h3 class="article-title">
            <a class="blue" routerLink="/resources/stats/{{ item.name }}">{{
              item.title
            }}</a>
          </h3>
          <div class="article-brief">
            <a class="black" routerLink="/resources/stats/{{ item.name }}">
              {{
                item.introduction.length > 140
                  ? item.introduction.substring(0, 140) + "..."
                  : item.introduction
              }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="pager">
    <mat-paginator
      [hidePageSize]="false"
      [length]="total"
      (page)="handlePageEvent($event)"
      [pageSize]="pageSize"
      [showFirstLastButtons]="true"
    ></mat-paginator>
  </div>
</app-newsroom>
