import { Component, OnInit, ChangeDetectionStrategy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

import { img_text, article_service, get_img_text } from './data';
import { imageTextFadeInType } from 'app/share/data-type/common.types';
import { ServicesLocalization, ServicesLocalizationClass } from './services.localization';
import { LocalizationService } from 'app/services/localization.service';
import { PostService } from 'app/services/post.service';
import { relatedArticle } from 'app/share/data-type/post.types';
import { ScreenService } from 'app/services/screen.service';
@Component({
  selector: 'app-temp-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
})
export class ServicesComponent implements OnInit, AfterViewInit {
  localizedText: ServicesLocalizationClass;
  img_text_data: imageTextFadeInType[] = img_text;
  article_service_data = article_service;
  articles: relatedArticle[];
  public isMobile: boolean = false;

  constructor(
    private localizationService: LocalizationService,
    private screenService: ScreenService,
    private relArticleService: PostService
  ) {
    this.localizedText = this.localizationService.getTranslation(ServicesLocalization);
    this.img_text_data = get_img_text(this.localizationService);
    relArticleService.getRelatedPostList().subscribe(data => {
      console.log('data', data);
      this.articles = relArticleService.mapPostDataToRelatedArticles(data);
    });
    this.isMobile = this.screenService.isMobile();
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {}
  private getRoot(el: any): any {
    if (el?.className?.indexOf('list') != -1) {
      return el;
    } else {
      //debugger
      let parent = el.parentElement;
      return this.getRoot(parent);
    }
  }
  changeCurrent(e: MouseEvent, index: number) {
    e.stopPropagation();
    e.preventDefault();
    let element = e.target as any;
    let rootElement = this.getRoot(element);
    let images = rootElement.querySelectorAll('.image');
    for (let i = 0; i < images.length; i++) {
      if (i == index) {
        images[i].classList.add('current');
      } else {
        if (images[i].classList.contains('current')) images[i].classList.remove('current');
      }
    }
  }
}
