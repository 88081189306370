import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './about.component';
import { LeadershipComponent } from './leadership/leadership.component';
import { HeritageComponent } from './heritage/heritage.component';
import { CareersComponent } from './careers/careers.component';

const routes: Routes = [
  {
    path: '',
    data: { title: 'ClearFreight | Customer Forward' },
    component: AboutComponent,
  },
  // {
  //   path: 'leadership',
  //   data: { title: 'Leader Ship | ClearFreight' },
  //   component: LeadershipComponent,
  // },
  {
    path: 'heritage',
    data: { title: 'Heritage | ClearFreight' },
    component: HeritageComponent,
  },
  // {
  //   path: 'careers',
  //   data: { title: 'Careers | ClearFreight' },
  //   component: CareersComponent,
  // },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AboutRoutingModule {}
