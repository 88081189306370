<app-newsroom current="PRESS RELEASES">
  <div class="news-item" *ngFor="let item of articles; let i = index">
    <div class="image" [ngStyle]="{ '--image': 'url(' + item.imgUrl }"></div>
    <div class="text">
      <div class="text-content">
        <div class="article-date">{{ item.title }}</div>
        <h3 class="article-title">{{ item.title }}</h3>
        <div class="article-brief">{{ item.title }}</div>
      </div>
    </div>
  </div>

  <div class="pager">
    <mat-paginator
      [hidePageSize]="false"
      [showFirstLastButtons]="true"
    ></mat-paginator>
  </div>
</app-newsroom>
