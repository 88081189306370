import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { fadeInDown } from 'ng-animate';
@Component({
  selector: 'app-quadruple-title',
  templateUrl: './quadruple-title.component.html',
  styleUrls: ['./quadruple-title.component.scss']
})
export class QuadrupleTitleComponent implements OnInit {

  @Input() title_data: any[];
  @Output() setState = new EventEmitter();
  constructor(
  ) { }

  ngOnInit(): void {
  }
}
