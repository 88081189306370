import { FileDataItem } from './file-storage';
type newsType='comm'|'stats'|'industry'|'brokerage'
export class NewsType{
  static Comm='comm';
  static Stats='stats';
  static Industry='industry';
  static Brokerage='brokerage';
}
export interface recentListItem {
  id: string;
  title: string;
  introduction: string;
  imgUrl: string;
}

export interface postItem {
  id: string;
  title: string;
  content: string;
  contentList?: postContentItem[];
  isPublish: boolean;
  isDelete: boolean;
  userId: string;
  createdTime: Date;
  modifiedTime: Date;
  introduction: string;
  detailImageId: number;
  detailImageUrl: string;
  listImageId: number;
  listImageUrl: string;
  appImageId: number;
  appImageUrl: string;
  publishTime: string;
  name: string;
}
export interface pagerData<T>{
  recordCount:number;
  datas:T[];
}
export interface postContentItem {
  type: string;
  value: string;
  list?: string[];
  inputValue?: '';
  imageValue: {
    id: number;
    url: string;
  };
}

export interface filePostData {
  blogs: postItem[];
  nextFile: string;
}

export interface relatedArticle {
  id: string;
  name: string;
  title: string;
  imgUrl: string;
}
