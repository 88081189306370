import { Localization } from 'app/share/data-type/common.types';

export const MobileAppLocalization: {
  [key: string]: MobileAppLocalizationClass;
} = {
  en: {
    title: 'ClearFreight Mobile',
    subTitle: 'Effortless Shipment Tracking',
    intro: 'Track your shipments with ease',
    description1: `ClearFreight Mobile is you go-to solution for hassle-free shipment tracking. Stay informed, prioritize shipments, and visualize the journey of your goods in real-time. `,
    services: 'Features Overview',
    featuresWTitleDesc: [
      {
        item: 'Alerts Section',
        subtitle: 'Receive Instant Updates on Your Shipments',
        description:
          'Stay ahead of the curve with our real-time alerts. Get notified about any updates or delays to your shipments may experience. Our intelligent system ensures you are always in the loop.',
        imgSrc: 'assets/images/Technology/Mobile/Mobile1.png',
      },
      {
        item: 'Priority Shipments',
        subtitle: 'Keep Track of What Matters Most',
        description:
          'Effortlessly manage and prioritize specific shipments with our "Priority Shipments" feature. Stay focused on critical deliveries and ensure timely actions.',
        imgSrc: 'assets/images/Technology/Mobile/Mobile2.png',
      },
      {
        item: 'Your Shipments Tab',
        subtitle: 'Comprehensive Overview at Your Fingertips',
        description:
          'Explore all stages of your shipments seamlessly. From booked to delivered, our "Your Shipments" tab provides a comprehensive overview, making tracking shipments a breeze.',
        imgSrc: 'assets/images/Technology/Mobile/Mobile3.png',
      },
      {
        item: 'Map View',
        subtitle: 'Visualize Shipments on a Global Scale',
        description:
          'Experience the power of geographical tracking with our interactive "Map" view. See the real-time location of your shipments on a world map, providing a clear and intuitive perspective.',
        imgSrc: 'assets/images/Technology/Mobile/Mobile4.png',
      },
    ],
    titleRotate: 'ANYWHERE, ANYTIME',
    whyMobile: 'Why ClearFreight Mobile?',
    items1: [
      `User-Friendly Interface: Navigate effortlessly through our intuitive design.`,
      `Real-Time Updates: Receive instant alerts for updates or delays.`,
      `Customizable Prioritization: Focus on what matters most to you.`,
      `Comprehensive Tracking: Explore every stage of your shipments in one place.`,
      `Global Visualization: See your shipments on a world map for enhanced clarity.`,
    ],
    itemsWTitleDesc: [
      {
        item: 'User-Friendly Interface',
        description: 'Navigate effortlessly through our intuitive design.',
      },
      {
        item: 'Real-Time Updates',
        description: 'Receive instant alerts for updates or delays.',
      },
      {
        item: 'Customizable Prioritization',
        description: 'Focus on what matters most to you.',
      },
      {
        item: 'Comprehensive Tracking',
        description: 'Explore every stage of your shipments in one place.',
      },
      {
        item: 'Global Visualization',
        description: 'See your shipments on a world map for enhanced clarity.',
      },
    ],
    getStarted: 'Get Started Today',
    description2:
      'Download ClearFreight Mobile and experience the future of shipment tracking. Currently, we are available on the App Store. All you need is a ClearView account.',
  },
  cn: {},
  es: {},
  nl: {},
  jp: {},
  th: {},
};

export interface MobileAppLocalizationClass extends Localization {
  featuresWTitleDesc?: {
    item: string;
    subtitle: string;
    description: string;
    imgSrc: string;
  }[];
  itemsWTitleDesc?: { item: string; description: string }[];
  whyMobile?: string;
  getStarted?: string;
  titleRotate?: string;
}
