import { Localization } from 'app/share/data-type/common.types';

export const ContactFormLocalization: {
  [key: string]: ContactFormLocalizationClass;
} = {
  en: {
    title: 'Contact Us',
    name: 'Full Name',
    company: 'Company Name',
    email: 'Email',
    phone: 'Phone No.',
    textOptIn: 'Opt in to text notifications',
    interestedIn: 'I am interested in',
    howCanWeHelp: 'Message',
    submit: 'Submit',
    textingDisclaimer:
      'By providing a telephone number and opting in, you are consenting to be contacted by SMS text message (our message frequency may vary). Message and data rates may apply. See our',
    privacyPolicy: 'Privacy Policy',
  },
  es: {},
  jp: {},
  cn: {},
  th: {},
  nl: {},
};

export interface ContactFormLocalizationClass extends Localization {
  name?: string;
  email?: string;
  company?: string;
  phone?: string;
  interestedIn?: string;
  howCanWeHelp?: string;
  submit?: string;
  textOptIn?: string;
  textingDisclaimer?: string;
  privacyPolicy?: string;
}
