import { Localization } from 'app/share/data-type/common.types';

export const IndustryRetailLocalization: {
  [key: string]: IndustryRetailLocalizationClass;
} = {
  en: {
    title: 'Retail',
    subTitle: 'Industry',
    description1:
      'Transportation of merchandise, inventory, and supplies for retail stores, e-commerce fulfillment centers, and distribution networks, with a focus on efficient supply chain management, inventory optimization, and last-mile delivery solutions. ',
  },
  cn: {},
  jp: {},
  th: {},
  nl: {},
  es: {},
};

export interface IndustryRetailLocalizationClass extends Localization {}
