import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { fadeInDown } from 'ng-animate';
@Component({
  selector: 'app-area-title',
  templateUrl: './area-title.component.html',
  styleUrls: ['./area-title.component.scss']
})
export class AreaTitleComponent implements OnInit {
  @Input() title: string = "";
  @Output() setState = new EventEmitter();
  constructor(
  ) { }

  ngOnInit(): void {
  }
}
