import { Localization } from 'app/share/data-type/common.types';

export const ServicesCustomsHouseBrokerageLocalization: {
  [key: string]: ServicesCustomsHouseBrokerageLocalizationClass;
} = {
  en: {
    subTitle: 'Global Trade',
    title: 'Customs Brokerage, exceeding your expectations',
    description1:
      'With more than 5 decades of experience as a customs broker, ClearFreight commands a distinct advantage in understanding the complex regulatory compliance requirements for imports. ClearFreight entry teams are managed by licensed customs brokers and a Compliance team with extensive technical knowledge and years of experience to guide you through all the compliance requirements necessary to ensure a timely and expedited clearance process. Our team of compliance experts are continually being educated on the most recent regulatory issues assuring you the most accurate up to date knowledgeable brokerage team.',
    description2:
      'ClearFreight is a certified and validated Customs-Trade Partnership Against Terrorism (C-TPAT) Partner since January 2003. C-TPAT is a joint government-business initiative between the US Bureau of Customs and Border Protection and the trade community designed to build cooperative relationships that strengthen overall supply chain and border security.',
    services: 'Customs Brokerage Services',
    servicesList: [
      'Customs Entry Processing',
      'Post Entry Summary Processing',
      'PGA (Partner Government Agency) Processing',
      'Pre-Arrival and Overtime Clearances',
      'HTS Product Classifications',
      'ACH Duty payments',
      'Customs Bonds',
      'Landed cost reporting',
      'ClearView -Visibility Platform for all shipment milestones',
    ],
  },
  cn: {},
  es: {},
  nl: {},
  jp: {},
  th: {},
};

export interface ServicesCustomsHouseBrokerageLocalizationClass
  extends Localization {
  tradeComplianceAdvisoryTitle?: string;
  tradeComplianceAdvisoryDescription?: string;
  tradeComplianceServices?: string;
  tradeComplianceServicesList?: string[];
}
