import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CareersComponent implements OnInit {

  work_location: string[] = ["AMERICAS", "EUROPE", "ASIA"];

  constructor() { }

  ngOnInit(): void {
  }

}
