import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { fadeInDown } from 'ng-animate';
import { ScreenService } from 'app/services/screen.service';
@Component({
  selector: 'app-related-service',
  templateUrl: './related-service.component.html',
  styleUrls: ['./related-service.component.scss'],
})
export class RelatedServiceComponent implements OnInit {
  @Input() services_data: any[] = [];
  @Output() setState = new EventEmitter();

  public isMobile: boolean = false;

  constructor(private screenService: ScreenService) {
    this.isMobile = this.screenService.isMobile();
  }

  ngOnInit(): void {}

  private getRoot(el: any): any {
    if (el?.className?.indexOf('list') != -1) {
      return el;
    } else {
      let parent = el.parentElement;
      return this.getRoot(parent);
    }
  }
  changeCurrent(e: MouseEvent, index: number) {
    e.stopPropagation();
    e.preventDefault();
    let element = e.target as any;
    let rootElement = this.getRoot(element);
    let images = rootElement.querySelectorAll('.image');
    for (let i = 0; i < images.length; i++) {
      if (i == index) {
        images[i].classList.add('current');
      } else {
        if (images[i].classList.contains('current')) images[i].classList.remove('current');
      }
    }
  }
}
