<ng-template #rightImg>
  <div class="right-img">
    <img src="./assets/images/Technology/Technology-Header-1.png" />
  </div>
</ng-template>
<ng-template #techHeader>
  <div class="tech-header"></div>
</ng-template>
<div class="technology">
  <ng-container *ngIf="isMobile">
    <ng-container *ngTemplateOutlet="rightImg"></ng-container>
  </ng-container>
  <div class="content">
    <h1 class="title">ClearView</h1>
    <h2 class="title2">A CLEAR GLOBAL PERSPECTIVE</h2>
  </div>
  <ng-container [ngTemplateOutlet]="techHeader" *ngIf="isMobile"></ng-container>
  <section class="modes">
    <ng-container [ngTemplateOutlet]="rightImg" *ngIf="!isMobile"></ng-container>

    <div class="white-bg">
      <div class="modes-module">
        <div class="module-item module-ocean">
          <h1 class="item-title">Global Visibility</h1>
          <div class="item-content">
            As the logistics technology platform for ClearFreight, ClearView provides our clients with complete global
            visibility, enabling them to track, trace, and manage their shipments from anywhere in the world.
          </div>
          <div class="btn-learn-more"><app-btn>LEARN MORE</app-btn></div>
        </div>
        <div class="module-item module-air">
          <h1 class="item-title">Reporting & Analytics</h1>
          <div class="item-content">
            ClearView offers reporting and analytics tools that allow you to analyze your quote data and identify
            trends, so you can optimize your operations and make data-driven decisions.
          </div>
          <div class="btn-learn-more"><app-btn>LEARN MORE</app-btn></div>
        </div>

        <div class="module-item module-road">
          <h1 class="item-title">Pricing & Booking</h1>
          <div class="item-content">
            With online booking, customers are empowered to request quotes and book shipments directly, eliminating the
            need for manual data entry.
          </div>
          <div class="btn-learn-more"><app-btn>LEARN MORE</app-btn></div>
        </div>
        <div class="module-item module-rail">
          <h1 class="item-title">Custom Integrations</h1>
          <div class="item-content">
            Our custom integration capabilities enable data to move seamlessly throughout the platform without manual
            intervention, helping you manage and automate complex shipment processes from origin to destination.
          </div>
          <div class="btn-learn-more"><app-btn>LEARN MORE</app-btn></div>
        </div>
      </div>
      <ng-container [ngTemplateOutlet]="techHeader" *ngIf="!isMobile"></ng-container>
    </div>
  </section>
  <section class="global-visiblity">
    <div class="tech-ship">
      <div class="rotate-title">
        <app-vertical-text [width]="800" fontSize="large" [outerWidth]="260" theme="white">
          GLOBAL VISIBILITY
        </app-vertical-text>
      </div>
      <div class="rotate-title-mobile">GLOBAL VISIBILITY</div>
      <div class="articles">
        <div class="left">
          <p>
            Supply chain visibility and resiliency have become increasingly important, and it is essential to have the
            necessary data and technology for forecasting and planning as we look to the future. Data is one of the most
            valuable assets for businesses, enabling them to anticipate and prevent costly disruptions, and track the
            movement of goods worldwide.
          </p>
          <p>
            ClearView offers real-time visibility into your supply chain, enabling you to streamline your logistics
            processes and execute them more efficiently. By monitoring and analyzing your logistics data, we can help
            you optimize your operations, reduce costs, and enhance your overall supply chain performance. With
            increased transparency across your global network, you can make agile, customer-focused decisions backed by
            real insights, giving you the confidence to adapt and succeed in a constantly changing business landscape.
          </p>
          <p>
            With our commitment to providing exceptional customer service and support, you can count on our team to help
            you navigate the complexities of global logistics and ensure that your shipments are handled with the utmost
            care and attention to detail.
          </p>
        </div>
        <div class="right"></div>
      </div>
    </div>
  </section>
  <section class="app-ui">
    <img src="./assets/images/Technology/Technology5.png" />
  </section>
  <section class="technology-reporting-analytics">
    <div class="gray-bg">
      <div class="rotate-title">
        <app-vertical-text [width]="800" fontSize="large" [outerWidth]="260" theme="blue">
          REPORTING & ANALYTICS
        </app-vertical-text>
      </div>
      <div class="rotate-title-mobile">REPORTING & ANALYTICS</div>
      <div class="articles">
        <div class="left">
          <img src="./assets/images/Technology/Technology-Reporting&analytics-7.png" />
        </div>
        <div class="right">
          <div class="title-include">ClearView offers several product features for reporting, including:</div>
          <div class="paragraph">
            <h3>TRACK & TRACE</h3>
            <div>
              ClearView provides a complete picture of your supply chain, from inventory to delivery, allowing users to
              track and monitor their shipments in real-time.
            </div>
          </div>
          <div class="paragraph">
            <h3>CUSTOMIZABLE REPORTS</h3>
            <div>
              Users can create customized reports that speak to their unique benchmarks and KPIs and communicate
              concisely and visually to management and other key stakeholders.
            </div>
          </div>
          <div class="paragraph">
            <h3>ANALYTICS POWER</h3>
            <div>
              ClearView provides powerful analytics capabilities, allowing users to capture and express exactly what is
              going on in their business, making reports and analytics more influential and powerful.
            </div>
            <div>
              Overall, ClearView’s reporting features provide users with the ability to create customized reports, track
              shipments in real-time, report on personalized success factors, add filters and conditional statements,
              and gain powerful insights through analytics capabilities.
            </div>
          </div>
        </div>
        <div style="width: 200px"></div>
      </div>
    </div>
  </section>
  <section class="pricing-booking">
    <video
      class="videobg"
      #video1
      autoplay="true"
      [loop]="video1"
      [muted]="video1"
      preload="auto"
      [poster]="videos[0].img"
    >
      <source [src]="videos[0].video" type="video/mp4" />
    </video>
    <div class="blue-bg">
      <div class="rotate-title">
        <app-vertical-text [width]="800" fontSize="large" [outerWidth]="260" theme="white">
          PRICING & BOOKING
        </app-vertical-text>
      </div>
      <div class="rotate-title-mobile">PRICING & BOOKING</div>
      <div class="articles">
        <div class="title-include">
          ClearView offers several product features for booking and requesting quotes, including:
        </div>
        <div class="booking-content">
          <div class="paragraph">
            <h3>INTEGRATED BOOKING</h3>
            ClearView allows you to book shipments directly from your quotes, eliminating the need for manual data entry
            and saving time.
          </div>
          <div class="paragraph">
            <h3>CUSTOMIZABLE QUOTE TEMPLATES</h3>
            You can create custom templates for your quote requests, which can be tailored to specific regions, modes of
            transport, and customer requirements.
          </div>
          <div class="paragraph">
            <h3>AUTOMATED QUOTE REQUESTS</h3>
            ClearView lets you manage rates and contracts with your carriers, so you can access accurate pricing
            information and make informed decisions.
          </div>
          <div class="paragraph">
            <h3>REAL-TIME TRACKING</h3>
            ClearView provides real-time visibility into the status of your shipments, allowing you to track them from
            origin to destination.
          </div>
          <div class="paragraph">
            <h3>CARRIER RATE MANAGEMENT</h3>
            ClearView lets you manage rates and contracts with your carriers, so you can access accurate pricing
            information and make informed decisions.
          </div>
          <div class="paragraph">
            <h3>COLLABORATION TOOLS</h3>
            You can collaborate with your customers, carriers, and other stakeholders through ClearView, using features
            like shared workflows and document exchange.
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="custom-integration">
    <div class="light-blue-bg">
      <div class="big-title">Simplify your processes and gain access to global connections with ease.</div>
      <div class="custom-integration-content">
        <div class="rotate-title">
          <app-vertical-text [width]="800" fontSize="large" [outerWidth]="260" theme="white">
            CUSTOM INTEGRATIONS
          </app-vertical-text>
        </div>
        <div class="rotate-title-mobile">CUSTOM INTEGRATIONS</div>
        <div class="articles">
          <div class="articles-content">
            <div class="paragraph">
              ClearView offers both vertical and horizontal integration, providing comprehensive and flexible solutions
              for supply chain operations of any size and complexity. With our deep integration interfaces, you can
              enjoy increased control and visibility over your supply chain operations, even if you have multiple teams
              working across different systems, locations, languages, and currencies. The platform offers flexible
              integrations to government systems, third-party systems, customers, partners, and geographies, providing
              you with powerful productivity gains and real-time data on the status of your shipments.
            </div>
            <div class="paragraph paragraph-img">
              <img src="./assets/images/Technology/Technology-Custom-Integrations-11.png" />
            </div>
            <div class="paragraph">
              Empowering all links in the chain to access the same information on the same database at the same time, no
              matter where they are located, you can unlock powerful productivity gains and reimagine your operations.
              Start doing more with less today with our comprehensive and flexible integrations.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-circle"></div>

    <video class="videobg" #video2 autoplay="true" [loop]="video2" [muted]="video2" [poster]="videos[1].img">
      <source [src]="videos[1].video" type="video/mp4" />
    </video>
  </section>
  <section class="schedule-demo">
    <div class="schedule-demo-content">
      <div class="left">
        <div class="line"></div>
      </div>
      <div class="demo-desc">
        <app-title-description
          [height]="isMobile ? '90px' : 'auto'"
          [spaceHeight]="isMobile ? '30px' : '80px'"
          [titleFontSize]="isMobile ? '28px' : '32px'"
          title="Schedule a Demo Today"
        ></app-title-description>
        <div class="btn-connect"><app-btn [link]="getDemoLink()">CONNECT</app-btn></div>
      </div>
    </div>
  </section>
</div>
