<div class="clearfreight">
  <app-sub-header src="./assets/images/About/ClearFreight/CF-1.0-Header.jpg"></app-sub-header>
  <app-title-description
    title="ClearFreight"
    [height]="isMobile ? 'auto' : '650px'"
    [spaceHeight]="isMobile ? '30px' : '80px'"
    [titleFontSize]="'30px'"
  >
    {{ localizedText.intro }}
  </app-title-description>
  <div class="vision-heritage-description">
    <div class="headerbg"></div>
    <div class="videobg">
      <div class="ring"></div>

      <video class="videobg1" #video1 [autoplay]="true" [loop]="video1" [muted]="video1" [poster]="videos[1].img">
        <source [src]="videos[1].video" type="video/mp4" />
      </video>
      <div class="info">
        <div class="our-vision">
          <h1 class="title">
            {{ localizedText.our }}
            <br />
            {{ localizedText.vision }}&nbsp;&nbsp;&nbsp;&nbsp;
          </h1>
          <h1 class="title-mobile">{{ localizedText.our }}&nbsp;{{ localizedText.vision }}</h1>
          <div class="content">
            <p>
              {{ localizedText.vision1 }}
            </p>
            <p>
              {{ localizedText.vision2 }}
            </p>
          </div>
        </div>
        <div class="our-heritage">
          <h1 class="title">
            {{ localizedText.our }}
            <br />
            {{ localizedText.heritage }}
          </h1>
          <h1 class="title-mobile">{{ localizedText.our }}&nbsp;{{ localizedText.heritage }}</h1>
          <div class="content">
            <p>
              {{ localizedText.heritage1 }}
            </p>
            <p>{{ localizedText.heritage2 }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="looking-ahead-out">
      <div class="videobg2-container">
        <video #video2 class="videobg2" [autoplay]="true" [loop]="video2" muted="video2" [poster]="videos[1].img">
          <source [src]="videos[1].video" type="video/mp4" />
        </video>
      </div>
      <div class="dark-blue-curved"></div>
      <div class="looking-ahead">
        <h1>{{ localizedText.lookingAhead }}</h1>
      </div>
    </div>
  </div>

  <div class="mid-video">
    <video #video autoplay="true" [loop]="video" [muted]="video" [poster]="midVideo.img">
      <source [src]="midVideo.video" type="video/mp4" />
    </video>
  </div>
  <app-title-description [height]="isMobile ? 'auto' : '550px'" [spaceHeight]="isMobile ? '30px' : '80px'">
    <div
      [ngStyle]="{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }"
    >
      <div class="contact-desc">
        {{ localizedText.outro }}
      </div>
      <app-contact-button [ngStyle]="{ marginTop: isMobile ? '30px' : '50px' }"></app-contact-button>
    </div>
  </app-title-description>
</div>
<div class="leadership"></div>
<div class="heritage"></div>
<div class="careers"></div>
