import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-img-desc',
  templateUrl: './icon-img-desc.component.html',
  styleUrls: ['./icon-img-desc.component.scss'],
})
export class IconImgDescComponent implements OnInit {
  @Input() svgSrc: string = '';
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() width: string = 'min(90%)';
  @Input() height: string = 'auto';
  @Input() margin: string = '20px';
  @Input() destination: string = '.';

  constructor() {}

  navigate() {
    window.open(this.destination, '_self');
  }

  ngOnInit(): void {}
}
