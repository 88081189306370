<div>
  <div class="body">
    <div style="height: min(300px 30vh); width: 100%; overflow: hidden">
      <app-center-pic src="./assets/images/About/Heritage/CF-2.0-Drone-Shot.jpg"></app-center-pic>
    </div>
    <div class="search-box">
      <app-lookup-input [width]="isMobile ? '100%' : '800px'"></app-lookup-input>
    </div>
    <div class="content">
      <div class="tracking-content">
        <div class="loading" *ngIf="isLoading">
          <mat-spinner></mat-spinner>
        </div>
        <div class="not-found" *ngIf="notFound">[!] No Records Found for {{ trackingNumber }}</div>
        <!-- B/L Details -->
        <div class="bl-details" *ngIf="shipmentData">
          <h1 class="data-title">
            Shipment: {{ shipmentData.jobNum }}
            <br *ngIf="isMobile" />
            <a class="cv-link" [href]="shipmentData.clearViewUrl" target="_blank" *ngIf="isMobile">Open in ClearView</a>
            <a
              class="cv-link"
              [href]="shipmentData.clearViewUrl"
              target="_blank"
              *ngIf="!isMobile"
              [matTooltip]="
                'You must have a ClearView account to view this. If your ClearView account has access to multiple companies, you will need to be logged into a company with access to this shipment.'
              "
              [matTooltipClass]="'mat-medium-tooltip'"
            >
              Open in ClearView
            </a>
          </h1>
          <div class="routing-bar">
            <div class="port-container">
              <img class="country-flag" [src]="getFlagRoute(shipmentData.origin ?? '')" [alt]="shipmentData.origin" />
              <div class="routing-port">
                <strong>Origin:</strong>
                {{ shipmentData.origin ?? '' }}
              </div>
            </div>

            <div class="routing-line"></div>
            <img class="transport-mode" [src]="getTransportImage(shipmentData.transportMode ?? '')" />
            <div class="routing-line"></div>
            <div class="port-container">
              <div class="routing-port">
                <strong>Destination:</strong>
                {{ shipmentData.destination ?? '' }}
              </div>
              <img class="country-flag" [src]="getFlagRoute(shipmentData.destination ?? '')" />
            </div>
          </div>
          <div class="row shipment-content">
            <div class="col raised" [ngStyle]="{ width: isMobile ? '100%' : '50%' }" style="height: 100%">
              <h2 class="table-name">Details:</h2>
              <div class="row shipment-detail" style="margin-top: 2vh">
                <div class="actual-estimate">
                  <strong>Departure</strong>
                  <table class="dates">
                    <tr>
                      <td class="mobile-header">Estimated:</td>
                      <td class="mobile-header">Actual:</td>
                    </tr>
                    <tr>
                      <td class="gray">{{ shipmentData.etd ?? '' }}</td>
                      <td class="gray">{{ shipmentData.atd ?? '' }}</td>
                    </tr>
                  </table>
                </div>
                <div class="gray-border" *ngIf="isMobile"></div>
                <div class="actual-estimate">
                  <strong>Arrival</strong>
                  <table class="dates">
                    <tr>
                      <td class="mobile-header">Estimated:</td>
                      <td class="mobile-header">Actual:</td>
                    </tr>
                    <tr>
                      <td class="gray">{{ shipmentData.eta ?? '' }}</td>
                      <td class="gray">{{ shipmentData.ata ?? '' }}</td>
                    </tr>
                  </table>
                </div>
                <div class="gray-border" *ngIf="isMobile"></div>
              </div>
              <div class="row">
                <table class="data-table" *ngIf="!isMobile">
                  <tr>
                    <th>Release Type</th>
                    <th>Service Level</th>
                    <th>Incoterm</th>
                  </tr>
                  <tr>
                    <td>{{ getReleaseType(shipmentData) }}</td>
                    <td>{{ getServiceLevel(shipmentData) }}</td>
                    <td>{{ getIncoterm(shipmentData) }}</td>
                  </tr>
                </table>
                <table class="data-table-mobile" *ngIf="isMobile">
                  <tr>
                    <th>Release Type</th>
                    <th>Service Level</th>
                  </tr>
                  <tr>
                    <td>{{ getReleaseType(shipmentData) }}</td>
                    <td>{{ getServiceLevel(shipmentData) }}</td>
                  </tr>
                  <div class="gray-border"></div>
                  <tr>
                    <th>Incoterm</th>
                    <th>Booked On</th>
                  </tr>
                  <tr>
                    <td>{{ getIncoterm(shipmentData) }}</td>
                    <td>{{ shipmentData.bookedDate ?? 'N/A' }}</td>
                  </tr>
                  <div class="gray-border"></div>
                  <tr>
                    <th>Release Status</th>
                    <th>Release Date</th>
                  </tr>
                  <tr>
                    <td>{{ shipmentData.releaseStatus ?? 'N/A' }}</td>
                    <td>{{ shipmentData.releaseDate ?? 'N/A' }}</td>
                  </tr>
                </table>
              </div>
              <div class="row">
                <table class="data-table" *ngIf="!isMobile">
                  <tr>
                    <th>Booked On</th>
                    <th>Release Status</th>
                    <th>Release Date</th>
                  </tr>
                  <tr>
                    <td>{{ shipmentData.bookedDate ?? 'N/A' }}</td>
                    <td>{{ shipmentData.releaseStatus ?? 'N/A' }}</td>
                    <td>{{ shipmentData.releaseDate ?? 'N/A' }}</td>
                  </tr>
                </table>
              </div>
            </div>

            <div class="col" [ngStyle]="{ width: isMobile ? '88%' : '40%' }">
              <!-- Containers subcomponent -->
              <div class="table-container" style="width: 100%">
                <app-containers [containers]="parseContainers(shipmentData.containers ?? '')"></app-containers>
              </div>

              <!-- Packs subcomponent -->
              <div class="table-container" style="width: 100%">
                <app-packs [packs]="parsePacks(shipmentData)"></app-packs>
              </div>
            </div>
          </div>
          <!-- Milestones subcomponent -->
          <div class="table-container" [ngStyle]="{ width: isMobile ? '88%' : '100%' }">
            <app-milestones [milestones]="shipmentData.milestones ?? []"></app-milestones>
          </div>
        </div>

        <!-- Container Details -->
        <div class="container-details" *ngIf="containerData">
          <h1 class="data-title">
            Container Details: {{ containerData.containerNum }}
            <br *ngIf="isMobile" />
            <a class="cv-link" [href]="containerData.containerID" target="_blank" *ngIf="isMobile">Open in ClearView</a>
            <a
              class="cv-link"
              [href]="containerData.containerID"
              target="_blank"
              *ngIf="!isMobile"
              [matTooltip]="
                'You must have a ClearView account to view this. If your ClearView account has access to multiple companies, you will need to be logged into a company with access to this container.'
              "
              [matTooltipClass]="'mat-medium-tooltip'"
            >
              Open in ClearView
            </a>
          </h1>
          <div class="routing-bar">
            <div class="port-container">
              <img
                class="country-flag"
                [src]="getFlagRoute(containerData.loadPort ?? '')"
                [alt]="containerData.loadPort"
              />
              <div class="routing-port">
                <strong>Origin:</strong>
                {{ containerData.loadPort }}
              </div>
            </div>
            <div class="routing-line"></div>
            <img class="transport-mode" [src]="getTransportImage(containerData.transportMode ?? '')" />
            <div class="routing-line"></div>
            <div class="port-container">
              <div class="routing-port">
                <strong>Destination:</strong>
                {{ containerData.dischargePort }}
              </div>
              <img
                class="country-flag"
                [src]="getFlagRoute(containerData.dischargePort ?? '')"
                [alt]="containerData.dischargePort"
              />
            </div>
          </div>
          <div class="row raised">
            <div class="col" style="width: 100%">
              <h2 class="table-name">Details:</h2>
              <div class="row">
                <table class="data-table" *ngIf="!isMobile">
                  <tr>
                    <th>Container Type</th>
                    <th>Total Packages (Outer)</th>
                    <th>Packages Type</th>
                    <th>Packing Mode</th>
                  </tr>
                  <tr>
                    <td>
                      {{ getContainerTypeName(containerData.type ?? '') }}
                    </td>
                    <td>{{ containerData.packages ?? '' }}</td>
                    <td>{{ getPackType(containerData.packagesUQ ?? '') }}</td>
                    <td>
                      {{ getPackingMode(containerData.packingMode ?? '') }}
                    </td>
                  </tr>
                  <div class="gray-border"></div>
                  <tr>
                    <th>Latest Vessel:</th>
                    <th>Voyage Number:</th>
                    <th>Gross Weight:</th>
                    <th>Weight Verified:</th>
                  </tr>
                  <tr>
                    <td>{{ containerData.vessel ?? '' }}</td>
                    <td>{{ containerData.voyage ?? '' }}</td>
                    <td>{{ containerData.grossWeight ?? '' }}{{ ' ' }}{{ containerData.grossWeightUQ ?? '' }}</td>
                    <td>{{ containerData.verifiedMethod ?? '' }}</td>
                  </tr>
                  <div class="gray-border"></div>
                  <div *ngIf="showContainerDates">
                    <tr>
                      <th>Transport Booked On:</th>
                      <th>Actual Delivery:</th>
                    </tr>
                    <tr>
                      <td>{{ containerData.transportBooked ?? '' }}</td>
                      <td>{{ containerData.actualDelivery ?? '' }}</td>
                    </tr>
                    <div class="gray-border"></div>
                    <tr>
                      <th>Empty Ready for Pickup:</th>
                      <th>Empty Return By:</th>
                      <th>Empty Returned On:</th>
                      <th></th>
                    </tr>
                    <tr>
                      <td>{{ containerData.emptyReadyforPickup ?? '' }}</td>
                      <td>{{ containerData.emptyReturnBy ?? '' }}</td>
                      <td>{{ containerData.emptyReturnedOn ?? '' }}</td>
                      <td></td>
                    </tr>
                  </div>
                </table>
                <table class="data-table-mobile" *ngIf="isMobile">
                  <tr>
                    <th>Container Type</th>
                    <th>Total Packages (Outer)</th>
                  </tr>
                  <tr>
                    <td>
                      {{ getContainerTypeName(containerData.type ?? '') }}
                    </td>
                    <td>{{ containerData.packages ?? '' }}</td>
                  </tr>
                  <div class="gray-border"></div>
                  <tr>
                    <th>Packages Type</th>
                    <th>Packing Mode</th>
                  </tr>
                  <tr>
                    <td>
                      {{ getContainerTypeName(containerData.type ?? '') }}
                    </td>
                    <td>{{ containerData.packages ?? '' }}</td>
                  </tr>
                  <div class="gray-border"></div>
                  <tr>
                    <th>Latest Vessel:</th>
                    <th>Voyage Number:</th>
                  </tr>
                  <tr>
                    <td>{{ containerData.vessel ?? '' }}</td>
                    <td>{{ containerData.voyage ?? '' }}</td>
                  </tr>
                  <div class="gray-border"></div>
                  <tr>
                    <th>Gross Weight:</th>
                    <th>Weight Verified:</th>
                  </tr>
                  <tr>
                    <td>{{ containerData.grossWeight ?? '' }}{{ ' ' }}{{ containerData.grossWeightUQ ?? '' }}</td>
                    <td>{{ containerData.verifiedMethod ?? '' }}</td>
                  </tr>
                  <div class="gray-border"></div>
                  <div *ngIf="showContainerDates">
                    <tr>
                      <th>Transport Booked On:</th>
                      <th>Actual Delivery:</th>
                    </tr>
                    <tr>
                      <td>{{ containerData.transportBooked ?? '' }}</td>
                      <td>{{ containerData.actualDelivery ?? '' }}</td>
                    </tr>
                    <div class="gray-border"></div>
                    <tr>
                      <th>Empty Ready for Pickup:</th>
                      <th>Empty Return By:</th>
                    </tr>
                    <tr>
                      <td>{{ containerData.emptyReadyforPickup ?? '' }}</td>
                      <td>{{ containerData.emptyReturnBy ?? '' }}</td>
                    </tr>
                    <div class="gray-border"></div>
                    <tr>
                      <th>Empty Returned On:</th>
                      <th></th>
                    </tr>
                    <tr>
                      <td>{{ containerData.emptyReturnedOn ?? '' }}</td>
                      <td></td>
                    </tr>
                  </div>
                </table>
              </div>
            </div>
          </div>
          <!-- Events subcomponent -->
          <div class="table-container" [ngStyle]="{ width: isMobile ? '88%' : '100%' }">
            <app-events [events]="containerData.events ?? []"></app-events>
          </div>
        </div>
      </div>
      <!-- <h1 class="title">{{ localizedText.title }}</h1> -->
      <!-- <div class="content-section">
        <h2 class="subtitle">{{ localizedText.item1 }}</h2>
        <p class="text-blurb">{{ localizedText.description1 }}</p>
      </div> -->
      <!-- <div class="content-section">
        <h2 class="subtitle">{{ localizedText.item2 }}</h2>
        <p class="text-blurb">{{ localizedText.description2 }}</p>
        <p style="margin-top: 2vh" class="text-blurb">
          {{ localizedText.description4 }}
        </p>
        <div class="button-container">
          <a [href]="clearViewUrl" target="_blank">
            <div class="btn" style="height: 50px; width: 300px">
              Go to ClearView
            </div>
          </a>
        </div>
      </div>
      <div class="content-section" style="flex-direction: row">
        <p class="text-blurb">{{ localizedText.description3 }}</p>
        <a
          [href]="webtrackingUrl"
          style="margin-left: 5px"
          class="inline-link"
          target="_blank"
          >{{ localizedText.webTracking }}</a
        >
      </div> -->
    </div>
  </div>
</div>
