<div class="demo">
  <app-center-pic src="./assets/images/Schedule%20a%20Demo/CF-Header-Schedule-Demo.jpg" [top]="0"></app-center-pic>
  <div class="demo-main">
    <div class="body">
      <div class="left">
        <h1>{{ localizedText.title }}</h1>
        <div class="text">
          {{ localizedText.description1 }}
        </div>
        <div>
          <app-item-list [item_data]="localizedText.servicesList ?? []"></app-item-list>
        </div>
      </div>
      <div class="right">
        <div class="right-h3">{{ localizedText.subTitle }}</div>
        <form>
          <ul>
            <li>
              <mat-form-field class="full" floatLabel="never">
                <mat-label>{{ localizedText.name }}</mat-label>
                <input
                  matInput
                  type="string"
                  placeholder="First Name"
                  [formControl]="nameFormControl"
                  [errorStateMatcher]="matcher"
                />
              </mat-form-field>
            </li>
            <li>
              <mat-form-field class="full" floatLabel="never">
                <mat-label>{{ localizedText.company }}</mat-label>
                <input matInput placeholder="Company Name" [formControl]="companyNameFormControl" />
              </mat-form-field>
            </li>
            <li>
              <mat-form-field
                [ngClass]="{
                  half: !isMobile,
                  full: isMobile
                }"
                floatLabel="never"
              >
                <mat-label>{{ localizedText.email }}</mat-label>
                <input matInput placeholder="Company Email" [formControl]="companyEmailFormControl" />
              </mat-form-field>
              <mat-form-field
                [ngClass]="{
                  half: !isMobile,
                  full: isMobile
                }"
                floatLabel="never"
              >
                <mat-label>{{ localizedText.phone }}</mat-label>
                <input matInput placeholder="Company Phone" [formControl]="companyPhoneFormControl" />
              </mat-form-field>
            </li>
            <li>
              <div class="opt-in-container">
                <mat-checkbox [formControl]="textingControl" color="primary">
                  <p class="opt-in">{{ localizedText.textOptIn }}</p>
                </mat-checkbox>
              </div>
            </li>

            <li>
              <mat-form-field class="full" floatLabel="never">
                <mat-label>{{ localizedText.country }}</mat-label>
                <input matInput [formControl]="countryControl" />
              </mat-form-field>
            </li>
            <li>
              <div class="submit">
                <div class="button-container">
                  <a (click)="handleSubmit()">
                    <div
                      class="btn"
                      [ngStyle]="{ height: isMobile ? '40px' : '50px', width: isMobile ? '66vw' : '150px' }"
                    >
                      {{ localizedText.submit }}
                    </div>
                  </a>
                </div>
              </div>
            </li>
            <li>
              <div class="texting-disclaimer">
                {{ localizedText.textingDisclaimer }}
                <a href="./privacy-policy">{{ localizedText.privacyPolicy }}</a>
                .
              </div>
            </li>
          </ul>
        </form>
      </div>
    </div>
  </div>
</div>
