import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShareModule } from '../../share/share.module';
import { ResourcesRoutingModule } from './resources-routing.module';

import { ResourcesComponent } from './resources.component';
import { NewsroomComponent } from './newsroom/newsroom.component';
import { NewsroomVideosComponent } from './videos/videos.component';
import { NewsroomArticlesComponent } from './articles/articles.component';
import { FormsComponent } from './forms/forms.component';
import { MarketUpdatesComponent } from './market-updates/market-updates.component';
import { ArticleShowComponent } from './article-show/article-show.component';
import { FormItemComponent } from './forms/components/form-item/form-item.component';
import { NewsroomPressComponent } from './press/press.component';
import { NewsroomPressReleasesComponent } from './press-releases/press-releases.component';
import { NewsroomWhitepapersComponent } from './whitepapers/whitepapers.component';
import { FormDetailComponent } from './forms-detail/forms-detail.component';
import { FormCtpatComponent } from './forms-ctpat/forms-ctpat.component';
import { NewsroomPortStatsComponent } from './portstats/portstats.component';
import { StatsShowComponent } from './stats-show/stats-show.component';
import { NewsroomIndustryNewsComponent } from './industry-news/industry-news.component';
import { NewsroomBrokerageUpdatesComponent } from './brokerage-updates/brokerage-updates.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
@NgModule({
  declarations: [
    ResourcesComponent,
    NewsroomComponent,
    FormsComponent,
    FormDetailComponent,
    FormCtpatComponent,
    NewsroomVideosComponent,
    NewsroomArticlesComponent,
    MarketUpdatesComponent,
    NewsroomPressComponent,
    NewsroomPressReleasesComponent,
    NewsroomWhitepapersComponent,
    NewsroomPortStatsComponent,
    NewsroomIndustryNewsComponent,
    NewsroomBrokerageUpdatesComponent,
    ArticleShowComponent,
    StatsShowComponent,
    FormItemComponent,
  ],
  imports: [CommonModule, ShareModule, ResourcesRoutingModule, PdfViewerModule],
  exports: [],
  providers: [],
})
export class ResourcesModule {}
