<div class="packs">
  <h2 class="table-name">Total Packs</h2>
  <div class="packs-table-container">
    <table>
      <tr>
        <th>Pieces</th>
        <th>Pack Type</th>
        <th>Inners/Outers</th>
      </tr>
      <tr *ngFor="let pack of packs">
        <td>{{ pack.quantity }}</td>
        <td>{{ getPackType(pack.packType ?? '') }}</td>
        <td>{{ pack.type }}</td>
      </tr>
    </table>
  </div>
</div>
