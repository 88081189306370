<div @fadeInDown class="forms-login" (click)="outClick($event)">
  <div class="forms-width">
    <div class="forms-login-content">

      <div class="close" (click)="leaveForm()"><mat-icon>close</mat-icon></div>
      <h2>Login</h2>
      <ul>
        <li><input placeholder="Email" [(ngModel)]="email" required /></li>
        <li><input placeholder="Password" [(ngModel)]="password" required /></li>
        <!--<li><input placeholder="Company Code" [(ngModel)]="companyCode" required /></li>-->
        <li>
          <div class="submit light" (click)="leaveForm()"><mat-icon>arrow_forward</mat-icon></div>
        </li>
      </ul>
      <!--<div class="submit"><mat-icon>arrow_forward</mat-icon></div>
      <div class="track">
        <h2>Track & Trace</h2>
        <ul>
          <li><input class="light" placeholder="Track Code" [(ngModel)]="trackCode" required /></li>
        </ul>
      </div>
    -->
    </div>
  </div>
</div>