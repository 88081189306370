const imgage_url = "https://clrfwebservicestorage.blob.core.windows.net/newhome-dev";

const material_url = "https://clrfwebservicestorage.blob.core.windows.net/clearfreight-dev-website/";//"http://localhost:6080/";//
export const videos = [
  { img: material_url + "CF-Homepage-Air-1.0.jpg", video: material_url + "CF-Homepage-Air-1.0.mp4" },//
  { img: material_url + "CF-Homepage-Ocean_1.0.jpg", video: material_url + "CF-Homepage-Ocean_1.0.mp4" },
  { img: material_url + "CF-Homepage-Rail-1.0.jpg", video: material_url + "CF-Homepage-Rail-1.0.mp4" },
  { img: material_url + "CF-Homepage-Road-1.0.jpg", video: material_url + "CF-Homepage-Road-1.0.mp4" }];
export const img_text = [
  {
    leftToRight: true,
    img: imgage_url + '/Homepage/4/CF-1.0-Customs-House-Brokerage.jpg',
    text: {
      title: 'Customs House Brokerage',
      content: `Lorem ipsum dolor sit amet, consectetur adipiscing
      elit, sed do eiusmod tempor incididunt ut labore
      et dolore magna aliqua. Ut enim ad minim veniam,
      quis nostrud exercitation ullamco laboris nisi ut
      aliquip ex ea commodo consequat.`,
      btnColor: ''
    }
  },
  {
    leftToRight: false,
    img: imgage_url + '/Homepage/4/CF-2.0-Freight-Forwarding.jpg',
    text: {
      title: 'Freight Forwarding & Logistics',
      content: `Lorem ipsum dolor sit amet, consectetur adipiscing
      elit, sed do eiusmod tempor incididunt ut labore
      et dolore magna aliqua. Ut enim ad minim veniam,
      quis nostrud exercitation ullamco laboris nisi ut
      aliquip ex ea commodo consequat.`,
      btnColor: 'blue'
    }
  },
  {
    leftToRight: true,
    img: imgage_url + '/Homepage/4/CF-3.0-Breakbulk & Project Cargo.jpg',
    text: {
      title: 'Breakbulk & Project Cargo',
      content: `Lorem ipsum dolor sit amet, consectetur adipiscing
      elit, sed do eiusmod tempor incididunt ut labore
      et dolore magna aliqua. Ut enim ad minim veniam,
      quis nostrud exercitation ullamco laboris nisi ut
      aliquip ex ea commodo consequat.`,
      btnColor: 'blue'
    }
  }
];
export const article_service = [

  {
    title: 'ARTICLE TITLE',
    image: imgage_url + '/Template%201.0/Related%20Articles/CF-Related-Article-Image-1.0.jpg'
  },
  {
    title: 'ARTICLE TITLE',
    image: imgage_url + '/Template%201.0/Related%20Articles/CF-Related-Article-Image-2.0.jpg'
  },
  {
    title: 'ARTICLE TITLE',
    image: imgage_url + '/Template%201.0/Related%20Articles/CF-Related-Article-Image-3.0.jpg'
  }
]

export const services_data = [
  {
    title: 'SERVICE',
    desc: 'Lorem ipsum dolor sit amet,     consectetur adipiscing elit, sed     do eiusmod tempor incididunt ut    labore et dolore magna aliqua.',
    link: ''
  }, {
    title: 'SERVICE',
    desc: 'Lorem ipsum dolor sit amet,     consectetur adipiscing elit, sed     do eiusmod tempor incididunt ut     labore et dolore magna aliqua.',
    link: ''
  }, {
    title: 'SERVICE',
    desc: 'Lorem ipsum dolor sit amet,     consectetur adipiscing elit, sed     do eiusmod tempor incididunt ut    labore et dolore magna aliqua.',
    link: ''
  }
]
