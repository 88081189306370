import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

import { services_data, article_service } from './data';
import { imageTextFadeInType } from 'app/share/data-type/common.types';
import {
  ServicesBreakBulkProjectCargoLocalizationClass,
  ServicesBreakBulkProjectCargoLocalization,
} from './break-bulk-project-cargo.localization';
import { LocalizationService } from 'app/services/localization.service';
import { Router } from '@angular/router';
import { PostService } from 'app/services/post.service';
import {
  RelatedServicesService,
  ServiceDescription,
} from 'app/services/related-services.service';
import { relatedArticle } from 'app/share/data-type/post.types';
@Component({
  selector: 'app-services-break-bulk-project-cargo',
  templateUrl: './break-bulk-project-cargo.component.html',
  styleUrls: ['./break-bulk-project-cargo.component.scss'],
})
export class ServicesBreakBulkProjectCargoComponent
  implements OnInit, AfterViewInit
{
  article_service_data = article_service;
  services_data: ServiceDescription[];
  articles: relatedArticle[] = [];

  localizedText: ServicesBreakBulkProjectCargoLocalizationClass;

  constructor(
    private localizationService: LocalizationService,
    private relServService: RelatedServicesService,
    private relArticleService: PostService,
    private router: Router
  ) {
    this.localizedText = this.localizationService.getTranslation(
      ServicesBreakBulkProjectCargoLocalization
    );
    let route = this.router.url;
    this.services_data = this.relServService.getServices(route);
    this.relArticleService.getRelatedPostList().subscribe((data) => {
      this.articles = this.relArticleService.mapPostDataToRelatedArticles(data);
    });
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {}
}
