import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScreenService {
  // function to check if the screen is mobile
  isMobile(): boolean {
    const screenWidth = window.innerWidth;
    return screenWidth < 768;
  }

  constructor() {}
}
