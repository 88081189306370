import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { trigger, transition, useAnimation, style, state, animate } from '@angular/animations';
import { fadeIn, fadeInDown, fadeOut } from 'ng-animate';
import { formItemType } from 'app/share/data-type/common.types';
import { ScreenService } from 'app/services/screen.service';
@Component({
  selector: 'app-form-item',
  templateUrl: './form-item.component.html',
  styleUrls: ['./form-item.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('open', style(
        {
          height: '{{explandHeight}}px'
        }
      ), { params: { explandHeight: 300 } }),
      state('close', style(
        {
          height: '0px'
        }
      )),
      transition('open => close', animate(500)),
      transition('close => open', animate(500)),
    ])
  ]
})
export class FormItemComponent implements OnInit, OnChanges {
  @Input() isShow: boolean = false;
  @Input() title: string = "";
  @Input() data: formItemType[] = [];
  @Output() setState = new EventEmitter();

  public isMobile: boolean = false;
  constructor(screenService: ScreenService
  ) {
    this.isMobile = screenService.isMobile();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes["data"]) {
      const currentValue = changes["data"].currentValue;
      if (this.isMobile)
        this.height = currentValue.length * 60
      else
        this.height = currentValue.length * 79
    }
  }
  ngOnInit(): void {
  }
  toggle() {
    this.isShow = !this.isShow
  }
  public height: number = 0;
  getItemLink(item: formItemType) {
    if (item.id == 'c-tpat' || item.id == 'export-tariff-fmc') {
      return item.path;
    } else {
      return '../resources/forms/' + item.id;
    }
  }
}
