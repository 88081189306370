<div class="contact-and-related">
  <div
    [ngStyle]="{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }"
  >
    {{ outro }}
    <app-contact-button [ngStyle]="{ marginTop: '50px' }"></app-contact-button>
  </div>
  <app-related-service [services_data]="services_data"></app-related-service>

  <div class="related">
    <app-related-article
      [article_service_data]="article_service_data"
    ></app-related-article>
  </div>
</div>
