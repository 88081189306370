<div
  class="lookup-input"
  [ngStyle]="{
    width: width,
    height: height
  }"
  [matTooltip]="'Valid CF Ref. #: X######## (B or S + 8 digits) or Container #: XXXX####### (4 letters, 7 digits)'"
  matTooltipClass="mat-medium-tooltip"
  matTooltipPosition="above"
  *ngIf="!isMobile"
>
  <form (submit)="track()">
    <div class="search-box">
      <input
        type="text"
        #matInput
        [placeholder]="'ClearFreight Ref. #'"
        [formControl]="clearFreightRefFormControl"
        [name]="'CFRef'"
        (keydown)="submitOnEnter($event)"
      />
    </div>
    <div class="spacer"><p>OR</p></div>
    <div class="search-box">
      <input
        type="text"
        #matInput
        [placeholder]="'Container #'"
        [formControl]="containerNumberFormControl"
        [name]="'ContainerNum'"
        (keydown)="submitOnEnter($event)"
      />
    </div>
  </form>
  <div class="track-btn">
    <app-btn-no-route [width]="100" [theme]="isFormValid ? 'light-blue-to-blue' : 'disabled'" (click)="track()">
      TRACK
    </app-btn-no-route>
  </div>
</div>
<div
  class="lookup-input"
  [ngStyle]="{
    width: width,
    height: height
  }"
  *ngIf="isMobile"
>
  <form (submit)="track()">
    <div class="search-box">
      <input
        type="text"
        #matInput
        [placeholder]="'ClearFreight Ref. #'"
        [formControl]="clearFreightRefFormControl"
        [name]="'CFRef'"
        (keydown)="submitOnEnter($event)"
      />
    </div>
    <div class="spacer"><p>OR</p></div>
    <div class="search-box">
      <input
        type="text"
        #matInput
        [placeholder]="'Container #'"
        [formControl]="containerNumberFormControl"
        [name]="'ContainerNum'"
        (keydown)="submitOnEnter($event)"
      />
    </div>
  </form>
  <div class="track-btn">
    <app-btn-no-route [width]="100" [theme]="isFormValid ? 'light-blue-to-blue' : 'disabled'" (click)="track()">
      TRACK
    </app-btn-no-route>
  </div>
</div>
