import { Localization } from 'app/share/data-type/common.types';

export const ServicesLocalization: {
  [key: string]: ServicesLocalizationClass;
} = {
  en: {
    title: 'Services',
    subTitle: 'We offer a full range of services',
    description1:
      'ClearFreight, Inc. is a full service, licensed Customs Broker and Freight Forwarder company offering personalized services in all facets of international logistics and supply chain management.',
    transportation: 'Transportation',
    transportationDescription:
      'ClearFreight provides comprehensive transportation solutions, encompassing air, ocean, road, rail, and intermodal services.',
    air: 'Air',
    ocean: 'Ocean',
    road: 'Road',
    rail: 'Rail',
    logistics: 'Logistics',
    logisticsDescription:
      'ClearFreight delivers a complete suite of logistics services, including warehousing, distribution, and efficient supply chain management.',
    freightForwarding: 'Freight Forwarding',
    breakbulkProjectCargo: 'Break-bulk & Project Cargo',
    chemicalBulk: 'Chemical Bulk Liquid',
    warehousingDistribution: 'Warehousing & Distribution',
    supplyChainSolutions: 'Supply Chain Solutions',
    intermodalSolutions: 'Intermodal Solutions',
    globalTrade: 'Global Trade',
    globalTradeDescription:
      'ClearFreight covers a wide spectrum of global trade services, offering expertise in customs brokerage, and trade consulting.',
    customsHouseBrokerage: 'Customs House Brokerage',
    cargoInsurance: 'Cargo Insurance',
    dutyDrawback: 'Duty Drawback',
  },
};

export interface ServicesLocalizationClass extends Localization {
  transportation?: string;
  transportationDescription?: string;
  air?: string;
  ocean?: string;
  road?: string;
  rail?: string;
  logistics?: string;
  logisticsDescription?: string;
  freightForwarding?: string;
  breakbulkProjectCargo?: string;
  chemicalBulk?: string;
  warehousingDistribution?: string;
  supplyChainSolutions?: string;
  intermodalSolutions?: string;
  globalTrade?: string;
  globalTradeDescription?: string;
  customsHouseBrokerage?: string;
  cargoInsurance?: string;
  dutyDrawback?: string;
}
