import { Component, Input, OnInit } from '@angular/core';
import { quickShipmentMilestones } from 'app/share/data-type/tracking.types';

@Component({
  selector: 'app-milestones',
  templateUrl: './milestones.component.html',
  styleUrls: ['../tracking.component.scss'],
})
export class MilestonesComponent implements OnInit {
  @Input() milestones: quickShipmentMilestones[] = [];

  constructor() {}

  ngOnInit(): void {}
}
