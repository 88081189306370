import { Localization } from 'app/share/data-type/common.types';

export const IndustryCosmeticsLocalization: {
  [key: string]: IndustryCosmeticsLocalizationClass;
} = {
  en: {
    title: 'Cosmetics',
    subTitle: 'Industry',
    description1:
      'Transportation of beauty and personal care products, including skincare, makeup, fragrance, and hair care items, often requiring careful handling, temperature-controlled storage, and secure transportation to maintain product quality and appearance. ',
  },
  cn: {},
  jp: {},
  th: {},
  nl: {},
  es: {},
};

export interface IndustryCosmeticsLocalizationClass extends Localization {}
