<div class="job-item">
  <div class="title-part">
    <h1>{{title}}</h1>
    <h2>{{location}}</h2>
  </div>
  <div class="desc-part">
    <div class="expansion-panel-header" (click)="toggle()">
      <div class="line"></div>
      <i class="material-icons open {{isShow?'':'hidden'}}">arrow_downward</i>
      <i class="material-icons close {{isShow?'hidden':''}}">arrow_forward</i>

    </div>
    <div class="expansion-panel-body" [@fadeInOut]="isShow?'open':'close'">
      <ng-content #item></ng-content>
      <a href="">
        <div class="btn-apply"><app-btn>APPLY</app-btn> </div>
      </a>
    </div>
  </div>
</div>