import { Localization } from 'app/share/data-type/common.types';
import {
  formCategoryType,
  formItemType,
} from 'app/share/data-type/common.types';

export const FormsLocalization: { [key: string]: FormsLocalizationClass } = {
  en: {
    imp: 'IMPORT',
    ctpat: 'C-TPAT',
    customsPowerOfAttorney: 'Customs Power of Attorney',
    exp: 'EXPORT',
    exportPowerOfAttorney: 'Export Power of Attorney',
    exportShipperSLI: "Export Shipper's Letter of Instruction (SLI)",
    exportSolasVGMCertificate: 'Export SOLAS VGM Certificate',
    exportTariffFMC: 'Export Tariff FMC',
    financialDocuments: 'FINANCIAL DOCUMENTS',
    creditApp: 'Credit App',
    routingOrder: 'Routing Order',
    termsAndConditions: 'OTHER TERMS AND CONDITIONS',
    billOfLadingTerms: 'BILL OF LADING TERMS & CONDITIONS',
    billOfLadingTermsGlobal: 'Bill of Lading Terms & Conditions - Global',
    billOfLadingTermsJP: 'Bill of Lading Terms & Conditions - Japan',
    billOfLadingTermsHK: 'Bill of Lading Terms & Conditions - Hong Kong',
    customerServiceTerms: 'CUSTOMER SERVICE TERMS & CONDITIONS',
    customerServiceTermsUS: 'Customer Service Terms & Conditions - US',
    customerServiceTermsCA: 'Customer Service Terms & Conditions - Canada',
    customerServiceTermsNL: 'Customer Service Terms & Conditions - Netherlands',
    customerServiceTermsCN: 'Customer Service Terms & Conditions - China',
    customerServiceTermsHK: 'Customer Service Terms & Conditions - Hong Kong',
    transportationServices: 'Transportation Services - Domestic US',
    generalDisclaimers: 'General Disclaimers',
    insuranceDisclaimer: 'Insurance Disclaimer',
    ctpatCybersecurity:
      'CTPAT & Cybersecurity - SCSS Bryan Smith - April 6, 2021',
    ctpatTheSouthernBorder:
      'CTPAT & the Southern Border - SCSS Ricardo Villarreal - 04.08.2021',
    ctpatPresentation:
      'CTPAT Presentation April 2021_v1 - Director Garza - April 6, 2021',
    ctpatMaritimeEnvironment: 'CTPAT in the Maritime Environment - CBP',
    ctpatForcedLabor: 'CTPAT Trade Compliance & Forced Labor - CBP',
    ctpatVirtualValidation: 'CTPAT Virtual Validation Process 2021',
    ctpatNorthernBorder: 'CTPAT Northern Border Seizures & FAST Lane 2021',
    supplyChainSecurity:
      "Supply Chain Security Standard Operating Procedures (SOP's) 2020",
    ctpatSecurityQuestionnaire: 'C-TPAT Security Questionnaire 2020',
    isfForm: 'ISF 10+2 Form',
  },
  es: {},
  jp: {},
  cn: {},
  th: {},
  nl: {},
};

export interface FormsLocalizationClass extends Localization {
  imp?: string;
  exp?: string;
  financialDocuments?: string;
  termsAndConditions?: string;
  ctpat?: string;
  customsPowerOfAttorney?: string;
  exportPowerOfAttorney?: string;
  exportShipperSLI?: string;
  exportSolasVGMCertificate?: string;
  exportTariffFMC?: string;
  creditApp?: string;
  routingOrder?: string;
  billOfLadingTerms?: string;
  billOfLadingTermsGlobal?: string;
  billOfLadingTermsJP?: string;
  billOfLadingTermsHK?: string;
  customerServiceTerms?: string;
  customerServiceTermsUS?: string;
  customerServiceTermsCA?: string;
  customerServiceTermsNL?: string;
  customerServiceTermsCN?: string;
  customerServiceTermsHK?: string;
  transportationServices?: string;
  generalDisclaimers?: string;
  insuranceDisclaimer?: string;
  ctpatCybersecurity?: string;
  ctpatTheSouthernBorder?: string;
  ctpatPresentation?: string;
  ctpatMaritimeEnvironment?: string;
  ctpatForcedLabor?: string;
  ctpatVirtualValidation?: string;
  ctpatNorthernBorder?: string;
  supplyChainSecurity?: string;
  ctpatSecurityQuestionnaire?: string;
  isfForm?: string;
}
