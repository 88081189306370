<div class="containers">
  <h2 class="table-name">Containers</h2>
  <div class="containers-table-container">
    <table>
      <tr>
        <th>Type</th>
        <th>Count</th>
      </tr>
      <tr *ngFor="let container of containers">
        <td>{{ container.type }}</td>
        <td>{{ container.quantity }}</td>
      </tr>
    </table>
  </div>
</div>
