import { Component, OnInit } from '@angular/core';
import { ContactButtonComponent } from 'app/share/content-components/contact-button/contact-button.component';

@Component({
  selector: 'app-sandbox',
  templateUrl: './sandbox.component.html',
  styleUrls: ['./sandbox.component.scss'],
})
export class SandboxComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
