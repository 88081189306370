import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PackTypeService {
  constructor() {}

  getPackType(packType: string): string {
    switch (packType) {
      case 'BAG':
        return 'Bag(s)';
      case 'BBG':
        return 'Bulk Bag(s)';
      case 'BBK':
        return 'Break Bulk(s)';
      case 'BIN':
        return 'Bin(s)';
      case 'BLC':
        return 'Bale, Compressed(s)';
      case 'BLU':
        return 'Bale, Uncompressed(s)';
      case 'BND':
        return 'Bundle(s)';
      case 'BOT':
        return 'Bottle(s)';
      case 'BOX':
        return 'Box(s)';
      case 'BSK':
        return 'Basket(s)';
      case 'CAS':
        return 'Case(s)';
      case 'CNT':
        return 'Container(s)';
      case 'COI':
        return 'Coil(s)';
      case 'CRD':
        return 'Cradle(s)';
      case 'CRT':
        return 'Crate(s)';
      case 'CTN':
        return 'Carton(s)';
      case 'CYL':
        return 'Cylinder(s)';
      case 'DOZ':
        return 'Dozen(s)';
      case 'DRM':
        return 'Drum(s)';
      case 'ENV':
        return 'Envelope(s)';
      case 'GRS':
        return 'Gross(s)';
      case 'KEG':
        return 'Keg(s)';
      case 'MIX':
        return 'Mix(s)';
      case 'PAI':
        return 'Pail(s)';
      case 'PCE':
        return 'Piece(s)';
      case 'PKG':
        return 'Package(s)';
      case 'PLT':
        return 'Pallet(s)';
      case 'REL':
        return 'Reel(s)';
      case 'RLL':
        return 'Roll(s)';
      case 'SET':
        return 'Set(s)';
      case 'SHT':
        return 'Sheet(s)';
      case 'SKD':
        return 'Skid(s)';
      case 'SPL':
        return 'Spool(s)';
      case 'TOT':
        return 'Tote(s)';
      case 'TUB':
        return 'Tube(s)';
      case 'UNT':
        return 'Unit(s)';
      case 'WDC':
        return 'Wooden Case(s)';
      default:
        return packType ?? '';
    }
  }
}
