import { Localization } from 'app/share/data-type/common.types';

export const IndustryChemicalsLocalization: {
  [key: string]: IndustryChemicalsLocalizationClass;
} = {
  en: {
    title: 'Chemicals',
    subTitle: 'Industry',
    description1:
      'Transportation of hazardous and non-hazardous chemicals, including liquid bulk, gases, and solids, with a focus on compliance with safety regulations, proper packaging, and handling procedures. ',
  },
  cn: {},
  jp: {},
  th: {},
  nl: {},
  es: {},
};

export interface IndustryChemicalsLocalizationClass extends Localization {}
