import {Component, OnInit, ChangeDetectionStrategy, Input} from '@angular/core';
import {animate, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'app-our-service-col',
  templateUrl: './our-service-col.component.html',
  styleUrls: ['./our-service-col.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('textState', [
      transition(':enter', [
        style({
          opacity: 0,
        }),
        animate('2.5s ease-in-out',
          style({
            opacity: 1,
          })
        )
      ])
    ])
  ]
})
export class OurServiceColComponent implements OnInit {

  @Input() content = '';
  @Input() img = '';


  constructor() { }

  ngOnInit(): void {
  }

}
