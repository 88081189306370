<div class="resources">
  <app-center-pic src="./assets/images/Resources/CF-Header-Resources.jpg"></app-center-pic>
  <div></div>
  <div class="content">
    <h1 class="title">Resources</h1>
    <h2 class="title2">
      LOREM IPSUM
    </h2>
  </div>
  <div class="two-cricle">
    <section class="video-section">
      <div class="video-content">
        <div class="rotate-title">
          <app-vertical-text [width]="260" fontSize="small"> VIDEOS</app-vertical-text>
        </div>
        <div class="video">
          <div class="video-cover">
            <video #video autoplay="true" [loop]="video" [muted]="video" [poster]="videos[1].img">
              <source [src]="videos[1].video" type="video/mp4">
            </video>
          </div>
          <div class="btn-view-all">
            <app-btn>VIEW ALL</app-btn>
          </div>
        </div>
      </div>
    </section>
    <section class="featured-articles">

      <div class="articles-content">
        <div class="rotate-title">
          <app-vertical-text [width]="300" [left]="72" fontSize="small" theme="white"> FEATURED
            ARTICLES</app-vertical-text>
        </div>
        <div class="articles">
          <app-tri-article [article_service_data]="article_service_data"></app-tri-article>
          <div class="btn-view-all">
            <app-btn>READ ALL</app-btn>
          </div>
        </div>
      </div>
    </section>
    <section class="white-paper">
      <div class="articles-content">
        <div class="rotate-title">
          <app-vertical-text [width]="480" fontSize="small"> WHITE PAPERS</app-vertical-text>
        </div>
        <div class="articles">
          <app-tri-article [article_service_data]="article_service_data"></app-tri-article>
          <div class="btn-view-all">
            <app-btn>READ ALL</app-btn>
          </div>
        </div>
      </div>
    </section>
    <section class="press-releases">
      <div class="articles-content">
        <div class="rotate-title">
          <app-vertical-text [width]="480" theme="white" fontSize="small">PRESS RELEASES</app-vertical-text>
        </div>
        <div class="articles">
          <app-double-article [article_service_data]="press_data"></app-double-article>
          <div class="btn-view-all">
            <app-btn theme="blue">READ ALL</app-btn>
          </div>
        </div>
      </div>
    </section>
    <section class="press">
      <div class="articles-content">
        <div class="rotate-title">
          <app-vertical-text [width]="260" theme="white" fontSize="small"> PRESS</app-vertical-text>
        </div>
        <div class="articles">
          <app-double-article [article_service_data]="press_data"></app-double-article>
          <div class="btn-view-all">
            <app-btn>READ ALL</app-btn>
          </div>
        </div>
      </div>
    </section>
  </div>
  <div class="bg-white">
    <section class="market-updates">
      <div class="articles-content">
        <div class="rotate-title">
          <app-vertical-text [width]="260" [left]="72" fontSize="small"> MARKET UPDATES</app-vertical-text>
        </div>
        <div class="articles">
          <app-new-list [news_data]="news_data"></app-new-list>
          <div class="btn-view-all">
            <app-btn>VIEW ALL</app-btn>
          </div>
        </div>
      </div>
    </section>
    <section class="forms">
      <div class="articles-content">
        <div class="rotate-title">
          <app-vertical-text [width]="260" fontSize="small"> FORMS</app-vertical-text>
        </div>
        <div class="articles">
          <app-quadruple-title [title_data]="title_data"></app-quadruple-title>
        </div>
      </div>

    </section>
  </div>
</div>