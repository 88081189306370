import { Localization } from 'app/share/data-type/common.types';

export const NewsroomLocalization: {
  [key: string]: NewsroomLocalizationClass;
} = {
  en: {
    title: 'Newsroom',
    subTitle: 'Stay up to date!',
  },
  cn: {},
  es: {},
  jp: {},
  th: {},
  nl: {},
};

export interface NewsroomLocalizationClass extends Localization {}
