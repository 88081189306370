import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { fadeInY } from 'ng-animate';
@Component({
  selector: 'app-forms-login',
  templateUrl: './forms-login.component.html',
  styleUrls: ['./forms-login.component.scss'],
  animations: [
    trigger('fadeInDown', [transition('* => *', useAnimation(fadeInY('-20px', '0'), {
      params: { timing: 0.2, fromOpacity: 0 }
    }))])
  ]
})
export class LoginComponent implements OnInit {
  email: string;
  password: string;
  companyCode: string;
  trackCode: string;
  @Input() item = {};
  @Output() setState = new EventEmitter();
  constructor(
  ) { }

  ngOnInit(): void {
  }
  leaveForm() {
    this.setState.emit(false)
  }
  outClick(e: MouseEvent) {

    e.stopPropagation();
    e.preventDefault();
    if ((e.target as any).className.indexOf('forms-login') != -1) {
      this.leaveForm();
    }
  }
}
